import { useCallback } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { QUERY_PARAMS } from '@constants/routes.const';
import { PreferredFiscalPackage } from '@shared/types';
import { comeFromProductFunnelState, lastProductFunnelRouteState } from '@state';

export default function useNavigateToProjectFunnelWithPreferredChoice() {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const setLastProductFunnelRoute = useSetRecoilState(lastProductFunnelRouteState);
  const setComeFromProductFunnel = useSetRecoilState(comeFromProductFunnelState);

  const navigateToProjectFunnelWithPreferredChoice = useCallback(
    ({
      route,
      preferredEnvelope,
      preferredTransfer,
      additionalSearchParams,
    }: {
      route: string;
      preferredEnvelope?: PreferredFiscalPackage;
      preferredTransfer?: boolean;
      additionalSearchParams?: Record<string, string>;
    }) => {
      setComeFromProductFunnel(true);
      setLastProductFunnelRoute(`${location.pathname}${location.search}`);
      if (preferredEnvelope) {
        searchParams.set(
          QUERY_PARAMS.PREFERRED_ENVELOPE.key,
          QUERY_PARAMS.PREFERRED_ENVELOPE.values[preferredEnvelope],
        );
      }
      if (preferredTransfer) {
        searchParams.set(
          QUERY_PARAMS.PREFERRED_TRANSFER.key,
          preferredTransfer
            ? QUERY_PARAMS.PREFERRED_TRANSFER.values.TRUE
            : QUERY_PARAMS.PREFERRED_TRANSFER.values.FALSE,
        );
      }
      if (additionalSearchParams) {
        Object.entries(additionalSearchParams).forEach(([key, value]) => {
          searchParams.set(key, value);
        });
      }
      navigate({
        pathname: route,
        search: searchParams.toString(),
      });
    },
    [location.pathname, location.search],
  );

  return navigateToProjectFunnelWithPreferredChoice;
}
