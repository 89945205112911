import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { InputDate } from '@components/InputDate/InputDate';
import checkRulesQuestionBirthdate from '@features/project/components/questions/QuestionBirthday/QuestionBirthday.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useTryNewWording from '@hooks/useTryNewWording';
import { FunnelAnswersKeys } from '@shared/types';
import { questionLayoutSubtitleState } from '@state';
import { formatFromApiDate, formatToApiDate } from '@utils/date.utils';
import { isStringDefined } from '@utils/input.utils';

export default function QuestionBirthday() {
  const setQuestionLayoutSubtitle = useSetRecoilState(questionLayoutSubtitleState);
  const { isValid } = useContext(QuestionContext);
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.BIRTHDATE,
    checkRulesQuestionBirthdate,
  );
  const resume = useResumeFunnelAnswers();
  const tryNewWording = useTryNewWording();

  useEffect(() => {
    if (tryNewWording) {
      setQuestionLayoutSubtitle(' ');
    }
  }, []);

  const onChange = (newBirthdate: string) => {
    update(formatToApiDate(newBirthdate), { debounceError: true });
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <InputDate
        name="birthdate"
        onChange={onChange}
        date={formatFromApiDate(answer)}
        valid={isValid}
        error={isStringDefined(answer) && !isValid}
        disabled={resume.data?.secondaryProject}
        debounceDuration={DEBOUNCE_DURATION}
      />
    </Box>
  );
}
