import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { ProjectType } from '@shared/types';
import { isNumberDefined } from '@utils/input.utils';

export default function checkRulesQuestionFinancialPatrimony(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (ruleParams.tryNewWording) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.financial-patrimony.info.abtest',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  if (!isNumberDefined(ruleParams.questionState.financialPatrimony)) {
    isValid = false;
  } else if (isAmountIncorrect(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.financial-patrimony.error.incorrect-amount',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  } else if (isLessThanTheInitialAmount(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.financial-patrimony.error.less-than-initial-amount',
      infoBoxLevel: InfoBoxLevel.ERROR,
      infoBoxMessageValues: {
        initialAmount: ruleParams.questionState.initialPayment,
      },
    });
  }

  if (ruleParams.questionState.projectType === ProjectType.CHILDREN) {
    showInfo(infoBoxMessages);
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * RG1
 */
function isAmountIncorrect(ruleParams: QuestionRuleParams): boolean {
  const { financialPatrimony } = ruleParams.questionState;
  return (
    financialPatrimony !== undefined && (financialPatrimony < 0 || financialPatrimony > 10000000)
  );
}

/**
 * RG2
 */
function isLessThanTheInitialAmount(ruleParams: QuestionRuleParams): boolean {
  const { financialPatrimony, initialPayment } = ruleParams.questionState;

  return financialPatrimony !== undefined && financialPatrimony < initialPayment!;
}

/**
 * RG3
 */
function showInfo(infoBoxMessages: InfoBoxContextValue[]) {
  infoBoxMessages.push({
    infoBoxMessage: 'step1.question.financial-patrimony.av-kids.info',
    infoBoxLevel: InfoBoxLevel.INFO,
  });
  return true;
}
