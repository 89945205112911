import { useCookies } from 'react-cookie';
import { useRecoilValue } from 'recoil';

import { YOMONI_UTM_COOKIE_NAME } from '@constants/app.const';
import useQuestionLists from '@features/project/hooks/useQuestionLists';
import { DEFAULT_RESPONSE } from '@features/project/question.utils';
import { FrontStep1Payload, FunnelAnswers, Utm } from '@shared/types';
import {
  businessReferralState,
  preferredEnvelopeState,
  preferredTransferState,
  questionsSelector,
} from '@state';
import { getObjetFromBase64Json } from '@utils/b64';

export default function useSubmitStep1() {
  const answers = useRecoilValue(questionsSelector);
  const businessReferral = useRecoilValue(businessReferralState);
  const [cookies] = useCookies([YOMONI_UTM_COOKIE_NAME]);
  const preferredEnvelope = useRecoilValue(preferredEnvelopeState);
  const preferredTransfer = useRecoilValue(preferredTransferState);
  const { questions } = useQuestionLists();

  const createPayload = (): FrontStep1Payload => {
    const utmCookie = getObjetFromBase64Json(cookies[YOMONI_UTM_COOKIE_NAME]) as unknown as Utm;

    const payloadAsMap = questions.toSubmit.reduce((acc, question) => {
      if (question.payloadKeys?.length) {
        question.payloadKeys.forEach((key) => acc.set(key, answers[key] ?? DEFAULT_RESPONSE[key]));
      }
      return acc;
    }, new Map<keyof FunnelAnswers, FunnelAnswers[keyof FunnelAnswers]>());

    const answersPayload = Object.fromEntries(payloadAsMap.entries()) as unknown as FunnelAnswers;

    return {
      ...answersPayload,
      businessReferral,
      utm: utmCookie,
      preferredEnvelope,
      preferredTransfer,
    };
  };

  return { createPayload };
}
