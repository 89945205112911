import { Button, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

import useTracking from '@hooks/tracking/useTracking';
import { CdpEvent } from '@shared/types';
import { BACKDROP, GRAY_MAIN, WHITE } from '@theme';

interface OutlinedButtonProps {
  onClick: () => void;
  titleId: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element;
  href?: string;
  disabled?: boolean;
  stretch?: boolean;
  cdpEvent?: CdpEvent;
}

export default function OutlinedButton({
  onClick,
  titleId,
  type,
  icon,
  href,
  disabled,
  stretch,
  cdpEvent,
}: OutlinedButtonProps) {
  const intl = useIntl();
  const { spacing } = useTheme();
  const { sendEvent } = useTracking();

  const handleClick = () => {
    if (cdpEvent) {
      sendEvent(cdpEvent);
    }

    onClick();
  };

  return (
    <Button
      data-testid="outlined-button"
      type={type}
      variant="outlined"
      disabled={disabled}
      sx={{
        flex: stretch ? 'auto' : 'none',
        px: spacing(3),
        py: spacing(1.5),
        textTransform: 'none',
        ':hover': {
          // Disable hover effect on mobile
          '@media (hover: none)': {
            backgroundColor: `${WHITE} !important`,
            borderColor: `${BACKDROP} !important`,
          },
        },
      }}
      onClick={handleClick}
      href={href}
      endIcon={icon}>
      <Typography variant="md" fontWeight="fontWeightBold" color={disabled ? GRAY_MAIN : undefined}>
        {intl.formatMessage({ id: titleId })}
      </Typography>
    </Button>
  );
}

OutlinedButton.defaultProps = {
  type: 'button',
  icon: undefined,
  href: undefined,
  disabled: false,
  stretch: false,
  cdpEvent: undefined,
};
