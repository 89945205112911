import { Container, styled } from '@mui/material';
import { ComponentProps } from 'react';

import { BOX_SHADOW_MD, WHITE } from '@theme';

type ContainerProps = ComponentProps<typeof Container>;
export interface StyledContainerProps extends ContainerProps {
  isMobile?: boolean;
  width?: string;
  height?: string;
}

export const StyledContainer = styled(Container, {
  shouldForwardProp: (prop) => !['isMobile', 'width', 'height'].includes(prop.toString()),
})<StyledContainerProps>(({ isMobile = false, theme, width, height }) => ({
  position: 'absolute',
  top: isMobile ? 0 : '50%',
  left: isMobile ? 0 : '50%',
  transform: isMobile ? 'none' : 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: width ?? (isMobile ? '100%' : 'fit-content'),
  height: height ?? (isMobile ? '100%' : 'fit-content'),
  maxHeight: isMobile ? '100%' : '90vh',
  padding: theme.spacing(3),
  borderRadius: theme.spacing(0.5),
  backgroundColor: WHITE,
  boxShadow: BOX_SHADOW_MD,
  ':focus-visible': {
    outline: 'none',
  },
}));

export default StyledContainer;
