import useTitle from '@features/project/hooks/usePreferredChoice/hooks/useTitle';

/**
 * Handle the generic logic of the preferred choice like updating the question title
 */
const usePreferredChoice = ({
  translationQuestionPrefix,
  titleValues,
}: {
  translationQuestionPrefix: string;
  titleValues?: Record<string, string | number>;
  infoBoxMessagePrefix?: string;
}) => {
  useTitle({ translationQuestionPrefix, titleValues });
};

export default usePreferredChoice;
