import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';

import { UserExpertiseEnum } from '@constants/user-expertise.const';
import ResumeClient from '@services/clients/resume';
import { ResumeResponse } from '@shared/types';
import { wasUserExpertiseAlreadySentState } from '@state';

export default function useUserExpertise() {
  const { data: resume } = useQuery<ResumeResponse, Error>('resume', () =>
    ResumeClient.getResume(),
  );
  const [wasUserExpertiseAlreadySentStateValue, setWasUserExpertiseAlreadySentState] =
    useRecoilState(wasUserExpertiseAlreadySentState);

  useEffect(() => {
    if (!wasUserExpertiseAlreadySentStateValue && resume && window.dataLayer) {
      window.dataLayer.push({
        event: 'eventTracking',
        category: 'user-segmentation',
        action: 'filter',
        label: resume.step1.isExpert ? UserExpertiseEnum.EXPERT : UserExpertiseEnum.NEOPHYTE,
      });
      setWasUserExpertiseAlreadySentState(true);
    }
  }, [resume]);

  return {};
}
