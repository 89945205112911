import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionEarningPotential from '@features/project/components/questions/QuestionEarningsPotential/QuestionEarningsPotential.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { BUTTONS_TERNARY_ANSWER } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { TernaryAnswer } from '@shared/types';
import { earningsPotentialQuizQuestionState, questionsSelector } from '@state';

export default function QuestionEarningsPotential() {
  const { isMobile } = useDimensions();
  const { updateContext } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const setEarningsPotentialQuizStateValue = useSetRecoilState(earningsPotentialQuizQuestionState);
  usePreferredChoice({ translationQuestionPrefix: 'step1.question.earnings-potential' });

  useEffect(() => {
    updateContext(checkRulesQuestionEarningPotential({ questionState }));
  }, []);

  const onClick = (newTernaryAnswer: TernaryAnswer) => {
    setEarningsPotentialQuizStateValue(newTernaryAnswer);
    updateContext(
      checkRulesQuestionEarningPotential({
        questionState: {
          ...questionState,
          earningsPotentialQuiz: newTernaryAnswer,
        },
      }),
    );
  };

  return (
    <ToggleButtonGroup
      disabled={questionState.earningsPotentialQuiz !== undefined}
      buttons={BUTTONS_TERNARY_ANSWER}
      selectedValue={questionState.earningsPotentialQuiz}
      onChange={onClick}
      itemProps={{ xs: isMobile ? 12 : undefined }}
    />
  );
}
