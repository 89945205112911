import { Box } from '@mui/system';
import { useContext, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import Loading from '@components/Loading/Loading';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import ROUTES, { EXTERNAL_ROUTES, ExternalRoutesEnum, QUERY_PARAMS } from '@constants/routes.const';
import { ProductFunnelQuestionsContext } from '@features/productFunnel/components/ProductFunnelQuestions.context';
import useButtons from '@features/productFunnel/components/questions/QuestionFiscalPackage/useButtons';
import { CLASSIC_QUESTIONS } from '@features/project/questionList';
import useTracking from '@hooks/tracking/useTracking';
import useNavigateToProjectFunnelWithPreferredChoice from '@hooks/useNavigateToProjectFunnelWithPreferredChoice';
import { UserContext } from '@providers/UserProvider';
import { CdpCategory, CdpEventName } from '@shared/types';
import { projectTypeQuestionState } from '@state';
import { getPerStep1NavigationProps } from '@utils/navigation.utils';
import { createUrlWithAdditionalParams } from '@utils/url.utils';

interface QuestionFiscalPackageProps {
  handleNext: (callback: () => void) => void;
}

export default function QuestionFiscalPackage({ handleNext }: QuestionFiscalPackageProps) {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { sendEvent } = useTracking();
  const setProjectType = useSetRecoilState(projectTypeQuestionState);
  const navigateToProjectFunnelWithPreferredChoice =
    useNavigateToProjectFunnelWithPreferredChoice();
  const { update: updateContext } = useContext(ProductFunnelQuestionsContext);

  const wantsTransfer =
    searchParams.get(QUERY_PARAMS.PREFERRED_TRANSFER.key) ===
    QUERY_PARAMS.PREFERRED_TRANSFER.values.TRUE;

  const { buttons, isLoading } = useButtons({ wantsTransfer });

  useEffect(() => {
    let questionTitleId;
    if (wantsTransfer) {
      questionTitleId = 'productFunnel.question.fiscalPackage.title.transfer';
    } else {
      switch (searchParams.get(QUERY_PARAMS.RECIPIENT.key)) {
        case QUERY_PARAMS.RECIPIENT.values.PERSONAL:
          questionTitleId = 'productFunnel.question.fiscalPackage.title.me';
          break;
        case QUERY_PARAMS.RECIPIENT.values.BUSINESS:
          questionTitleId = 'productFunnel.question.fiscalPackage.title.my-company';
          break;
        default:
          questionTitleId = 'productFunnel.question.fiscalPackage.title';
      }
    }

    updateContext({
      questionTitleId,
      specificPreviousRoute: wantsTransfer ? `/${ROUTES.FUNNEL_SELECTION}` : undefined,
    });
  }, [searchParams]);

  const onClick = (value: string) => {
    const button = buttons.find((b) => b.value === value);

    const callback = () => {
      sendEvent({
        event: CdpEventName.PRODUCT_FUNNEL_QUESTION_FISCAL_PACKAGE_SELECTION,
        properties: {
          category: CdpCategory.QUESTION,
          productFunnelFiscalPackage: value,
        },
      });
      button?.onClick(
        ({ preferredEnvelope, preferredTransfer, project, additionalSearchParams }) => {
          if (button.hasExternalLink) {
            if (user) {
              navigate(getPerStep1NavigationProps(additionalSearchParams));
            } else {
              window.open(
                createUrlWithAdditionalParams(
                  EXTERNAL_ROUTES[ExternalRoutesEnum.PER].STEP_1,
                  additionalSearchParams,
                ),
                '_self',
              );
            }
          } else {
            if (project) {
              setProjectType(project);
            }
            navigateToProjectFunnelWithPreferredChoice({
              route: `/${ROUTES.PROJECT}/${CLASSIC_QUESTIONS[1].path}`,
              preferredEnvelope,
              preferredTransfer,
              additionalSearchParams,
            });
          }
        },
      );
    };

    if (button?.hasExternalLink) {
      // Don't call handleNext if the button has an external link to avoid transitioning problem
      callback();
    } else {
      handleNext(callback);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      {isLoading ? (
        <Loading />
      ) : (
        <ToggleButtonGroup
          buttons={buttons}
          onChange={onClick}
          containerProps={{ sx: { maxWidth: '490px' } }}
          itemProps={{ xs: 12, display: 'flex' }}
          iconAboveText
          withoutBorderOnSelected
        />
      )}
    </Box>
  );
}
