import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { ProjectType } from '@shared/types';

export default function checkRulesQuestionPrimaryResidency(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.ownerMainResidence) {
    isValid = false;
  }

  if (shouldShowInfoChildrenProject(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.primary-residence.info.kid',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q9-RG1
 */
function shouldShowInfoChildrenProject(rulesParams: QuestionRuleParams) {
  return rulesParams.questionState.projectType === ProjectType.CHILDREN;
}
