import { Box, Typography } from '@mui/material';
import { TypographyTypeMap } from '@mui/material/Typography/Typography';
import { useIntl } from 'react-intl';

type TypoProps = TypographyTypeMap['props'];
export interface ModalTitleProps extends TypoProps {
  titleId?: string;
  isMobile?: boolean;
}

export default function ModalTitle({
  titleId,
  textAlign = 'center',
  isMobile,
  variant = 'h1',
  ...props
}: ModalTitleProps) {
  const intl = useIntl();

  if (!titleId) return null;

  return (
    <Box
      mb={{ xs: 3, lg: 4 }}
      sx={{ textAlign, width: '100%' }}
      mt={{ xs: isMobile ? 3 : 0, lg: 0 }}>
      <Typography variant={variant} {...props}>
        {intl.formatMessage({ id: titleId })}{' '}
      </Typography>
    </Box>
  );
}

ModalTitle.defaultProps = {
  titleId: undefined,
  isMobile: false,
};
