import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Typography } from '@mui/material';
import { Box, Stack } from '@mui/system';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { createSearchParams, useNavigate } from 'react-router-dom';

import { ReactComponent as CalendarPlus } from '@assets/icons/calendar-plus.svg';
import { ReactComponent as Search } from '@assets/icons/search.svg';
import { ReactComponent as Switch } from '@assets/icons/switch.svg';
import ExternalLink from '@components/links/ExternalLink/ExternalLink';
import QuestionLayout from '@components/QuestionLayout/QuestionLayout';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import ROUTES, {
  EXTERNAL_ROUTES,
  PRODUCT_FUNNEL_ROUTES,
  PROJECT_ROUTES,
  QUERY_PARAMS,
} from '@constants/routes.const';
import useTracking from '@hooks/tracking/useTracking';
import useDimensions from '@hooks/useDimensions';
import useNavigateToProjectFunnelWithPreferredChoice from '@hooks/useNavigateToProjectFunnelWithPreferredChoice';
import useTransition from '@hooks/useTransition';
import { UserContext } from '@providers/UserProvider';
import { CdpCategory, CdpEventName } from '@shared/types';

function ArrowRight() {
  return <KeyboardBackspaceIcon style={{ transform: 'rotate(180deg)' }} />;
}

export default function QuestionFunnelSelection() {
  const { isMobile } = useDimensions();
  const navigate = useNavigate();
  const { sendEvent } = useTracking();
  const { user } = useContext(UserContext);
  const { isTransitioning, transition } = useTransition();
  const navigateToProjectFunnelWithPreferredChoice =
    useNavigateToProjectFunnelWithPreferredChoice();
  const intl = useIntl();

  const buttons = [
    {
      value: 'i-know-what-i-want',
      StartIcon: ArrowRight,
      titleId: 'funnelSelection.question.funnel-selection.button.i-know-what-i-want',
      onClick: () => navigate(`/${ROUTES.PRODUCT_FUNNEL}/${PRODUCT_FUNNEL_ROUTES.RECIPIENT}`),
    },
    {
      value: 'i-want-a-transfer',
      StartIcon: Switch,
      titleId: 'funnelSelection.question.funnel-selection.button.i-want-a-transfer',
      onClick: () =>
        navigate({
          pathname: `/${ROUTES.PRODUCT_FUNNEL}/${PRODUCT_FUNNEL_ROUTES.FISCAL_PACKAGE}`,
          search: createSearchParams({
            [QUERY_PARAMS.PREFERRED_TRANSFER.key]: QUERY_PARAMS.PREFERRED_TRANSFER.values.TRUE,
          }).toString(),
        }),
    },
    {
      value: 'i-want-an-online-recommendation',
      StartIcon: Search,
      titleId: 'funnelSelection.question.funnel-selection.button.i-want-an-online-recommendation',
      onClick: () => {
        navigateToProjectFunnelWithPreferredChoice({
          route: `/${ROUTES.PROJECT}/${PROJECT_ROUTES.PROJECT_SELECTION}`,
        });
      },
    },
    {
      value: 'i-need-advice',
      StartIcon: CalendarPlus,
      titleId: 'funnelSelection.question.funnel-selection.button.i-need-advice',
      onClick: () =>
        window.open(
          'https://calendly.com/d/dsw-sfj-rq8?utm_source=tunnel&utm_medium=onsite&utm_campaign=choix-accompagnement',
          '_blank',
          'noopener noreferrer',
        ),
    },
  ];

  const onClick = (value: string) => {
    transition(() => {
      sendEvent({
        event: CdpEventName.CLICK_FUNNEL_SELECTION,
        properties: {
          category: CdpCategory.QUESTION,
          funnelSelection: value,
        },
      });
      buttons.find((b) => b.value === value)?.onClick();
    });
  };

  return (
    <Box>
      <QuestionLayout
        headerTitle="funnelSelection.header-title"
        title="funnelSelection.question.funnel-selection.title"
        fade={!isTransitioning}
        paddingBottom={isMobile ? 0 : undefined}
        mt={isMobile ? undefined : 4}>
        <Box display="flex" justifyContent="center">
          <ToggleButtonGroup
            selectedValue="none"
            buttons={buttons}
            onChange={onClick}
            containerProps={{ sx: { maxWidth: '490px' } }}
            itemProps={{ xs: 12, display: 'flex' }}
            iconAboveText
            withoutBorderOnSelected
          />
        </Box>
      </QuestionLayout>
      <Box display="flex" justifyContent="center" pb={isMobile ? 10 : 0}>
        <Stack display="flex" flexDirection="column" spacing={isMobile ? 3 : 4} textAlign="center">
          <Typography variant="md">
            <ExternalLink
              message={intl.formatMessage({ id: 'funnelSelection.resume' })}
              to={EXTERNAL_ROUTES.CORE.RESUME}
              shouldTargetSelf
            />
          </Typography>
          {!user && (
            <Typography variant="md">
              <ExternalLink
                message={intl.formatMessage({ id: 'funnelSelection.login' })}
                to={EXTERNAL_ROUTES.CORE.LOGIN}
                shouldTargetSelf
              />
            </Typography>
          )}
        </Stack>
      </Box>
    </Box>
  );
}
