import { Toggle, rescaleTo100 } from '@hooks/toggle/toggle.utils';
import useYomoniCdpCookie from '@hooks/useYomoniCdpCookie';

const parseMultiRollout = (value: string) => {
  const parts = value.split(':')[1]?.split(',') || [];
  return parts.map((part) => {
    const [percentage, option] = part.split('-');
    return { percentage: parseInt(percentage, 10), value: option };
  });
};

export default function useToggleWithOptions(toggle: Toggle) {
  const segmentId = useYomoniCdpCookie()?.yomoniCdpCookie.anonymousId;
  const toggleEnvValue = process.env[toggle]; // format: multiRollout:33-OPTION1,33-OPTION2,34-OPTION3

  if (toggleEnvValue && toggleEnvValue.startsWith('multiRollout') && segmentId) {
    const options = parseMultiRollout(toggleEnvValue);
    const hashValue = rescaleTo100(segmentId);

    let cumulativePercentage = 0;
    const optionMatch = options.find((option) => {
      cumulativePercentage += option.percentage;
      return hashValue <= cumulativePercentage;
    })?.value;

    return optionMatch ?? false;
  }

  return false;
}
