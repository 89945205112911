import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionDelegation from '@features/project/components/questions/QuestionDelegation/QuestionDelegation.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { BUTTONS_TERNARY_ANSWER } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { FunnelAnswersKeys, TernaryAnswer } from '@shared/types';

export default function QuestionDelegation() {
  const { isMobile } = useDimensions();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ,
    checkRulesQuestionDelegation,
  );
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.delegation',
  });

  const onClick = (newTernaryAnswer: TernaryAnswer) => {
    update(newTernaryAnswer);
  };

  return (
    <ToggleButtonGroup
      disabled={answer !== undefined}
      buttons={BUTTONS_TERNARY_ANSWER}
      selectedValue={answer}
      onChange={onClick}
      itemProps={{ xs: isMobile ? 12 : undefined }}
    />
  );
}
