import {
  CHILDREN_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  DEFAULT_INITIAL_INVESTMENT_MIN_VALUE,
  FIRST_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT,
  TRADING_ACCOUNT_LEGAL_ENTITY_MIN_INITIAL_INVESTMENT,
  TRADING_ACCOUNT_MIN_INITIAL_INVESTMENT,
} from '@constants/simulation-parameters-edge.const';
import { BusinessReferral, FiscalPackage, ProjectType } from '@shared/types';

export interface GetMinimumInitialInvestmentParams {
  fiscalPackage?: FiscalPackage;
  hasActiveLifeInsurance?: boolean;
  projectType?: ProjectType;
  businessReferral?: BusinessReferral;
}

export const getMinimumInitialInvestment = ({
  fiscalPackage,
  hasActiveLifeInsurance,
  projectType,
  businessReferral,
}: GetMinimumInitialInvestmentParams): { value: number; message: string } => {
  let value = DEFAULT_INITIAL_INVESTMENT_MIN_VALUE;
  let message = 'notification.ERR_INITIAL_INVESTMENT_TOO_LOW';
  const isBusinessReferralPartner =
    !!businessReferral &&
    [BusinessReferral.DECATHLON, BusinessReferral.BANKIN].includes(businessReferral);

  switch (fiscalPackage) {
    case 'ASSURANCE_VIE':
      if (projectType === 'ENFANT') {
        value = CHILDREN_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT;
        message = 'notification.ERR_INITIAL_INVESTMENT_CHILDREN_LIFE_INSURANCE_TOO_LOW';
      } else if (hasActiveLifeInsurance || isBusinessReferralPartner) {
        value = LIFE_INSURANCE_MIN_INITIAL_INVESTMENT;
        message = 'notification.ERR_INITIAL_INVESTMENT_LIFE_INSURANCE_TOO_LOW';
      } else {
        value = FIRST_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT;
        message = 'notification.ERR_INITIAL_INVESTMENT_FIRST_LIFE_INSURANCE_TOO_LOW';
      }
      break;
    case 'COMPTE_TITRE':
      if (projectType === 'EPARGNE_PERSONNE_MORALE') {
        value = TRADING_ACCOUNT_LEGAL_ENTITY_MIN_INITIAL_INVESTMENT;
        message = 'notification.ERR_INITIAL_INVESTMENT_TRADING_ACCOUNT_LEGAL_ENTITY_TOO_LOW';
      } else {
        value = TRADING_ACCOUNT_MIN_INITIAL_INVESTMENT;
        message = 'notification.ERR_INITIAL_INVESTMENT_TRADING_ACCOUNT_TOO_LOW';
      }
      break;
    case 'PEA':
      value = STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT;
      message = 'notification.ERR_INITIAL_INVESTMENT_STOCKS_SAVING_PLAN_TOO_LOW';
      break;
    default:
      break;
  }
  return { value, message };
};

export default getMinimumInitialInvestment;
