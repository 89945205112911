import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { ProjectType } from '@shared/types';
import { isNumberDefined } from '@utils/input.utils';

export default function checkRulesRealEstatePatrimony(ruleParams: QuestionRuleParams): RulesResult {
  const returnResponse: RulesResult = {
    isValid: true,
    infoBoxMessages: [],
  };

  const { netPropertyPatrimony, projectType } = ruleParams.questionState;

  if (projectType !== ProjectType.LEGAL_ENTITY) {
    returnResponse.infoBoxMessages.push({
      infoBoxLevel: InfoBoxLevel.INFO,
      infoBoxMessage: 'step1.question.realEstatePatrimony.info',
    });
  }

  if (projectType === ProjectType.CHILDREN) {
    returnResponse.infoBoxMessages.push({
      infoBoxLevel: InfoBoxLevel.INFO,
      infoBoxMessage: 'step1.question.realEstatePatrimony.info.children',
    });
  }

  if (ruleParams.tryNewWording) {
    returnResponse.infoBoxMessages.push({
      infoBoxLevel: InfoBoxLevel.INFO,
      infoBoxMessage: 'step1.question.realEstatePatrimony.info.abtest',
    });
  }

  if (!isNumberDefined(netPropertyPatrimony)) {
    returnResponse.isValid = false;
    return returnResponse;
  }

  if (
    netPropertyPatrimony &&
    (netPropertyPatrimony < 0 ||
      netPropertyPatrimony > 10000000 ||
      Number.isNaN(netPropertyPatrimony))
  ) {
    returnResponse.isValid = false;
    returnResponse.infoBoxMessages.push({
      infoBoxMessage: 'step1.question.realEstatePatrimony.error.max',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  return returnResponse;
}
