import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionSavingsProducts from '@features/project/components/questions/QuestionSavingsProducts/QuestionSavingsProducts.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import { FunnelAnswersKeys, SavingsProducts } from '@shared/types';

export default function QuestionSavingsProducts() {
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.SAVINGS_PRODUCTS,
    checkRulesQuestionSavingsProducts,
  );
  const buttons = [
    {
      value: SavingsProducts.OWNER,
      titleId: 'step1.question.savings-products.button.yes',
    },
    {
      value: SavingsProducts.NOT_OWNER,
      titleId: 'step1.question.savings-products.button.no',
    },
  ];

  const onChange = (newSavingsProducts: SavingsProducts) =>
    update(newSavingsProducts, { autoValidate: true });

  return (
    <ToggleButtonGroup
      buttons={buttons}
      selectedValue={answer}
      onChange={onChange}
      itemProps={{ xs: 6, sm: 2 }}
    />
  );
}
