import { useMediaQuery, useTheme } from '@mui/material';

export enum Breakpoint {
  XL = 5,
  LG = 4,
  MD = 3,
  SM = 2,
  XS = 1,
}

export default function useDimensions(): { isMobile: boolean; currentBreakpoint: Breakpoint } {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const isLg = useMediaQuery(theme.breakpoints.only('lg'));
  const isMd = useMediaQuery(theme.breakpoints.only('md'));
  const isSm = useMediaQuery(theme.breakpoints.only('sm'));
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  let currentBreakpoint: Breakpoint = Breakpoint.XL;
  if (isLg) {
    currentBreakpoint = Breakpoint.LG;
  }
  if (isMd) {
    currentBreakpoint = Breakpoint.MD;
  }
  if (isSm) {
    currentBreakpoint = Breakpoint.SM;
  }
  if (isXs) {
    currentBreakpoint = Breakpoint.XS;
  }

  return { isMobile, currentBreakpoint };
}
