import type { ComponentProps } from 'react';
import { ForwardedRef, forwardRef } from 'react';
import { IMask, IMaskInput } from 'react-imask';
import { useIntl } from 'react-intl';

import { InputText, InputTextProps } from '@components/InputText/InputText';
import { isMobileDimensions } from '@utils/global.utils';

interface InputDateProps extends Omit<InputTextProps, 'onChange'> {
  date?: string;
  onChange: (date: string) => void;
  name?: string;
  valid?: boolean;
  error?: boolean;
  debounceDuration?: number;
}

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
  (
    { date, onChange, name, valid, error, debounceDuration, ...rest }: InputDateProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const isMobile = isMobileDimensions('md');
    const intl = useIntl();
    return (
      <InputText
        {...rest}
        name={name}
        valid={valid}
        error={error}
        ref={ref}
        placeholder={intl.formatMessage({ id: 'placeholder.date' })}
        defaultValue={date}
        sx={{ width: isMobile ? '100%' : '290px' }}
        debounceDuration={debounceDuration}
        InputProps={{ inputProps: { inputMode: 'numeric' } }}
        maskProps={
          {
            mask: 'd/m/y',
            lazy: true,
            overwrite: true,
            blocks: {
              d: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 31,
                maxLength: 2,
              },
              m: {
                mask: IMask.MaskedRange,
                from: 1,
                to: 12,
                maxLength: 2,
              },
              y: {
                mask: IMask.MaskedRange,
                from: 1900,
                to: 2100,
                maxLength: 4,
              },
            },
            onAccept: (value) => onChange(value),
          } as ComponentProps<typeof IMaskInput>
        }
      />
    );
  },
);

InputDate.defaultProps = {
  date: undefined,
  name: undefined,
  valid: false,
  error: false,
  debounceDuration: undefined,
};

export default InputDate;
