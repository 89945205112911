import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FiscalPackage, LossReaction } from '@shared/types';

export default function checkRulesQuestionLossReaction(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.tenPercentLossQuiz) {
    isValid = false;
  }

  if (wantStockSavingsPlanButReactsToMarket(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.loss-reaction.wants-stock-savings-plan-but-reacts-to-market',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q24-RG1
 */
function wantStockSavingsPlanButReactsToMarket(params: QuestionRuleParams) {
  return (
    params.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN &&
    params.questionState.tenPercentLossQuiz &&
    [LossReaction.INCERTAIN, LossReaction.VENTE_PARTIELLE, LossReaction.VENTE_TOTALE].includes(
      params.questionState.tenPercentLossQuiz,
    )
  );
}
