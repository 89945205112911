import { IconButton, styled } from '@mui/material';

interface StyledCrossIcon {
  isMobile: boolean;
}

export const StyledCrossIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<StyledCrossIcon>(({ isMobile = false, theme }) => ({
  position: 'absolute',
  top: '0',
  right: isMobile ? 'auto' : '0',
  left: isMobile ? '0' : 'auto',
  padding: 0,
  margin: theme.spacing(3),
  zIndex: 3,
}));

export default StyledCrossIconButton;
