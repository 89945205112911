import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { resumeQuery } from '@queries';
import { ResumeResponse } from '@shared/types';
import {
  businessReferralState,
  deductibilityState,
  desensitizationState,
  fiscalPackageState,
  initialPaymentState,
  investmentHorizonState,
  isTransferState,
  monthlyInvestmentState,
  preferredEnvelopeState,
  preferredTransferState,
  profileIdSelector,
  profileIdState,
  retirementAgeState,
  riskLevelState,
  thematicState,
  transferAmountState,
} from '@state';
import { getFiscalPackageFromProfileId, validateAndGetThematic } from '@utils/global.utils';
import { isDefined } from '@utils/input.utils';

export interface UseResume extends Partial<Omit<UseQueryResult<ResumeResponse, Error>, 'data'>> {
  resume: ResumeResponse | undefined;
}

const computeTransferState = (
  isTransferStateValue: boolean | undefined,
  resume: ResumeResponse,
) => {
  // Already initialised or user already interacted with the switch
  if (isDefined(isTransferStateValue)) {
    return isTransferStateValue;
  }

  // If the user has already submitted the step
  if (isDefined(resume?.step1Response?.transfer)) {
    return resume.step1Response.transfer;
  }

  // If the user wishes to do a transfer
  return !!resume?.projectParameters?.preferredTransfer;
};

export default function useResume(): UseResume {
  const { data: resume, ...rest } = resumeQuery();

  const [monthlyInvestmentSliderStateValue, setMonthlyInvestmentSliderStateValue] =
    useRecoilState(monthlyInvestmentState);
  const [investmentHorizonSliderStateValue, setInvestmentHorizonSliderStateValue] =
    useRecoilState(investmentHorizonState);
  const [initialPaymentStateValue, setInitialPaymentStateValue] =
    useRecoilState(initialPaymentState);
  const [transferAmountStateValue, setTransferAmountStateValue] =
    useRecoilState(transferAmountState);
  const [riskProfileStateValue, setRiskProfileStateValue] = useRecoilState(riskLevelState);
  const [thematicStateValue, setThematicStateValue] = useRecoilState(thematicState);
  const [isTransferStateValue, setIsTransferStateValue] = useRecoilState(isTransferState);

  const setProfileIdStateValue = useSetRecoilState(profileIdState);
  const profileIdStateValue = useRecoilValue(profileIdSelector);

  const setPackageStateValue = useSetRecoilState(fiscalPackageState);
  const setDesensitizationStateValue = useSetRecoilState(desensitizationState);
  const setDeductibilityStateValue = useSetRecoilState(deductibilityState);
  const setRetirementAgeStateValue = useSetRecoilState(retirementAgeState);

  const setBusinessReferralStateValue = useSetRecoilState(businessReferralState);

  const setPreferredFiscalPackage = useSetRecoilState(preferredEnvelopeState);
  const setPreferredTransfer = useSetRecoilState(preferredTransferState);

  useEffect(() => {
    if (resume === undefined) {
      return;
    }

    setMonthlyInvestmentSliderStateValue(
      monthlyInvestmentSliderStateValue || resume.step0.monthlyDeposit,
    );
    setInvestmentHorizonSliderStateValue(investmentHorizonSliderStateValue || resume.step0.horizon);
    setInitialPaymentStateValue(
      initialPaymentStateValue || resume.step1Response.initialPayment || 0,
    );
    setTransferAmountStateValue(
      transferAmountStateValue || resume.step1Response.transferAmount || 0,
    );

    setIsTransferStateValue(computeTransferState(isTransferStateValue, resume!));
    setRiskProfileStateValue(
      riskProfileStateValue || resume.step2?.riskLevel || resume.step1Response.riskLevel,
    );

    let thematic = validateAndGetThematic(
      resume.step1Response.thematic,
      resume.step1Response.package,
    );
    if (thematicStateValue !== undefined) {
      thematic = thematicStateValue;
    } else if (resume.step2) {
      thematic = validateAndGetThematic(resume.step2.thematic, resume.step2.package);
    }
    setThematicStateValue(thematic);

    setDesensitizationStateValue(resume?.step1Response?.isDesensitized || false);
    setDeductibilityStateValue(resume?.step1Response?.isDeductible || false);

    const profileIdValue =
      profileIdStateValue || resume.step2?.profileId || resume.step1Response.profileId;
    setProfileIdStateValue(profileIdValue);
    setPackageStateValue(getFiscalPackageFromProfileId(profileIdValue));

    if (resume.step1.retirementAge) {
      setRetirementAgeStateValue(resume.step1.retirementAge);
    }

    setBusinessReferralStateValue(resume.step0.businessReferral);

    setPreferredFiscalPackage(resume?.projectParameters?.preferredEnvelope);
    setPreferredTransfer(resume?.projectParameters?.preferredTransfer);
  }, [resume]);

  return { resume, ...rest };
}
