import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import ROUTES, { QUERY_PARAMS } from '@constants/routes.const';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { CLASSIC_QUESTIONS } from '@features/project/questionList';
import { BusinessReferral, FiscalPackage, ProjectType } from '@shared/types';
import { isNumberDefined } from '@utils/input.utils';

const MIN_AMOUNT = 1000;
const MIN_AMOUNT_AV = MIN_AMOUNT;
const MIN_AMOUNT_DISCOUNTED = 300;
const MIN_AMOUNT_LEGAL_ENTITY = 10000;
const MIN_AMOUNT_CTO = 5000;
const MIN_AMOUNT_PEA = 5000;
const MAX_AMOUNT_PEA = 150000;
const MAX_AMOUNT = 10000000;

export default function checkRulesQuestionInitialAmount(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid;
  let infoBoxMessages;

  if (
    ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN ||
    ruleParams.preferredEnvelope === FiscalPackage.TRADING_ACCOUNT
  ) {
    ({ isValid, infoBoxMessages } = checkRulesWithPreference(ruleParams));
  } else {
    ({ isValid, infoBoxMessages } = checkRulesWithoutPreference(ruleParams));
  }

  infoBoxMessages.push(getInfoMessage(ruleParams));

  return {
    isValid,
    infoBoxMessages,
  };
}

function checkRulesWithoutPreference(ruleParams: QuestionRuleParams) {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { initialPayment } = ruleParams.questionState;

  if (!isNumberDefined(initialPayment)) {
    return { isValid: false, infoBoxMessages };
  }

  if (isDiscounted(ruleParams)) {
    isValid = checkMinAndMax(infoBoxMessages, initialPayment, MIN_AMOUNT_DISCOUNTED, MAX_AMOUNT);
  } else if (isLegalEntityProject(ruleParams)) {
    isValid = checkMinAndMax(infoBoxMessages, initialPayment, MIN_AMOUNT_LEGAL_ENTITY, MAX_AMOUNT);
  } else {
    // Q2-RG1
    isValid = checkMinAndMax(infoBoxMessages, initialPayment, MIN_AMOUNT, MAX_AMOUNT);
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

function checkRulesWithPreference(ruleParams: QuestionRuleParams) {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { initialPayment } = ruleParams.questionState;

  if (!isNumberDefined(initialPayment)) {
    isValid = false;
  }

  if (
    ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN &&
    !ruleParams.preferredTransfer &&
    initialPayment !== undefined &&
    (initialPayment < MIN_AMOUNT_PEA || initialPayment > MAX_AMOUNT_PEA)
  ) {
    isValid = false;
    if (isNumberDefined(initialPayment)) {
      if (initialPayment < MIN_AMOUNT_PEA) {
        infoBoxMessages.push({
          infoBoxMessage: 'step1.question.initial-amount.error.amount.pea.opening.notEnough',
          infoBoxMessageValues: { min: MIN_AMOUNT_PEA, minLifeInsurance: MIN_AMOUNT_AV },
          infoBoxLink: `/${ROUTES.PROJECT}/${CLASSIC_QUESTIONS[0].path}?${
            QUERY_PARAMS.PREFERRED_ENVELOPE.key
          }=${QUERY_PARAMS.PREFERRED_ENVELOPE.values[FiscalPackage.LIFE_INSURANCE]}`,
          infoBoxLevel: InfoBoxLevel.ERROR,
        });
      } else if (initialPayment > MAX_AMOUNT_PEA) {
        infoBoxMessages.push({
          infoBoxMessage: 'step1.question.initial-amount.error.amount.pea.opening.tooMuch',
          infoBoxMessageValues: { max: MAX_AMOUNT_PEA },
          infoBoxLevel: InfoBoxLevel.ERROR,
        });
      }
    }
  }

  if (
    ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN &&
    ruleParams.preferredTransfer === true &&
    initialPayment !== undefined &&
    (initialPayment < MIN_AMOUNT_PEA || initialPayment > MAX_AMOUNT)
  ) {
    isValid = false;
    if (isNumberDefined(initialPayment)) {
      if (initialPayment < MIN_AMOUNT_PEA) {
        infoBoxMessages.push({
          infoBoxMessage: 'step1.question.initial-amount.error.amount.pea.transfering.notEnough',
          infoBoxMessageValues: { min: MIN_AMOUNT_PEA },
          infoBoxLevel: InfoBoxLevel.ERROR,
        });
      } else if (initialPayment > MAX_AMOUNT) {
        infoBoxMessages.push({
          infoBoxMessage: 'step1.question.initial-amount.error.amount.pea.transfering.tooMuch',
          infoBoxMessageValues: { min: MIN_AMOUNT_PEA, maxTransfer: MAX_AMOUNT },
          infoBoxLevel: InfoBoxLevel.ERROR,
        });
      }
    }
  }

  if (
    ruleParams.preferredEnvelope === FiscalPackage.TRADING_ACCOUNT &&
    initialPayment !== undefined
  ) {
    if (isLegalEntityProject(ruleParams)) {
      isValid = checkMinAndMax(
        infoBoxMessages,
        initialPayment,
        MIN_AMOUNT_LEGAL_ENTITY,
        MAX_AMOUNT,
      );
    } else {
      isValid = checkMinAndMax(infoBoxMessages, initialPayment, MIN_AMOUNT_CTO, MAX_AMOUNT);
    }
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q2-RG2
 * Q2-RG3
 */
function isDiscounted(ruleParams: QuestionRuleParams): boolean {
  const { projectType } = ruleParams.questionState;

  return projectType !== ProjectType.LEGAL_ENTITY && shouldBeDiscounted(ruleParams);
}

function shouldBeDiscounted(ruleParams: QuestionRuleParams): boolean {
  const { projectType } = ruleParams.questionState;
  const { businessReferral, isAlreadyClient, hasActiveLifeInsurance } = ruleParams;

  return (
    (isAlreadyClient && hasActiveLifeInsurance) ||
    projectType === ProjectType.CHILDREN ||
    businessReferral === BusinessReferral.DECATHLON ||
    businessReferral === BusinessReferral.BANKIN
  );
}

/**
 * Q2-RG4
 */
function getInfoMessage(ruleParams: QuestionRuleParams) {
  if (ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN) {
    return {
      infoBoxMessage: `step1.question.initial-amount.info.pea.${
        ruleParams.preferredTransfer === true ? 'transfering' : 'opening'
      }`,
      infoBoxLevel: InfoBoxLevel.INFO,
    };
  }
  return {
    infoBoxMessage: 'step1.question.initial-amount.info',
    infoBoxLevel: InfoBoxLevel.INFO,
  };
}

/**
 * Q2-RG5
 */
function isLegalEntityProject(ruleParams: QuestionRuleParams): boolean {
  const { projectType } = ruleParams.questionState;

  return projectType === ProjectType.LEGAL_ENTITY;
}

function checkMinAndMax(
  infoBoxMessages: InfoBoxContextValue[],
  initialPayment: number | undefined,
  min: number,
  max: number,
) {
  if (
    initialPayment !== undefined &&
    isNumberDefined(initialPayment) &&
    (initialPayment < min || initialPayment > max)
  ) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.initial-amount.error.amount',
      infoBoxMessageValues: { min, max },
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
    return false;
  }
  return true;
}
