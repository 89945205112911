import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionFinancialPatrimony from '@features/project/components/questions/QuestionFinancialPatrimony/QuestionFinancialPatrimony';
import checkRulesQuestionFinancialPatrimony from '@features/project/components/questions/QuestionFinancialPatrimony/QuestionFinancialPatrimony.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.FINANCIAL_PATRIMONY,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.financial-patrimony.title',
  questionSubtitle: 'step1.question.financial-patrimony.subtitle',
  cdpEvent: CdpEventName.QUESTION_FINANCIAL_PATRIMONY,
  Component: QuestionFinancialPatrimony,
  payloadKeys: [FunnelAnswersKeys.FINANCIAL_PATRIMONY],
  rulesFn: checkRulesQuestionFinancialPatrimony,
};

export default {
  classic: baseDescription,
  legalEntity: {
    ...baseDescription,
    questionTitle: 'step1.question.financial-patrimony.legal-entity.title',
    questionSubtitle: 'step1.question.financial-patrimony.legal-entity.subtitle',
  },
  realEstate: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.financial-patrimony.av-kids.subtitle',
  },
};
