import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { ChildrenNumber, ProjectType } from '@shared/types';

export default function checkRulesQuestionChildrenNumber(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.nbChildren) {
    isValid = false;
  }

  if (kidProjectHasNoChildren(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.children.error',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (isKidProjectAndSelectionIsDone(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.children.info',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  if (ruleParams.tryNewWording) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.children.info.abtest',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q8-RG1
 */
function isKidProjectAndSelectionIsDone(ruleParams: QuestionRuleParams) {
  return (
    ruleParams.questionState.projectType === ProjectType.CHILDREN &&
    ruleParams.questionState.nbChildren
  );
}

/**
 * Q8-RG2
 */
function kidProjectHasNoChildren(ruleParams: QuestionRuleParams) {
  return (
    ruleParams.questionState.projectType === ProjectType.CHILDREN &&
    ruleParams.questionState.nbChildren === ChildrenNumber.NONE
  );
}
