import { useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import LoadingModal from '@components/LoadingModal/LoadingModal';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import useTracking from '@hooks/tracking/useTracking';
import useResume from '@hooks/useResume';
import ProjectClient from '@services/clients/project';
import {
  CdpCategory,
  CdpEventName,
  isPerPackage,
  SendSimulationParamResponse,
} from '@shared/types';
import { isSimulationErrorState, profileSelector, projectSelector } from '@state';
import { navigateToStep3 } from '@utils/navigation.utils';

interface ValidateSimulationButtonProps {
  titleId: string;
  onClick?: () => void;
}

export default function ValidateSimulationButton({
  titleId,
  onClick,
}: ValidateSimulationButtonProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const { resume, isLoading: isLoadingResume } = useResume();
  const { sendEvent } = useTracking();

  const isSimulationErrorStateValue = useRecoilValue(isSimulationErrorState);
  const {
    desensitization,
    deductibility,
    initialPayment,
    transferAmount,
    horizon,
    monthlyInvestment,
    retirementAge,
    isTransfer,
  } = useRecoilValue(projectSelector);

  const { fiscalPackage, profileId: currentProfileId } = useRecoilValue(profileSelector);

  const [isLoading, setIsLoading] = useState(false);

  const { refetch: sendPatchPerSubscriptionQuery, isLoading: isPatchPerSubscriptionLoading } =
    useQuery<{}, Error>(
      'patchPerSubscription',
      async () =>
        ProjectClient.patchPerSubscription(
          resume!.step1Response.contractId,
          currentProfileId,
          desensitization,
          deductibility,
          initialPayment,
          transferAmount,
        ),
      { enabled: false },
    );

  const { data: sendSimulationParamResponse, refetch: sendSimulationParam } = useQuery<
    SendSimulationParamResponse,
    Error
  >(
    'sendSimulationParamResponse',
    async () =>
      ProjectClient.sendSimulationParam({
        simulationPackageParams: {
          transfert: !!isTransfer,
          profil: {
            id: currentProfileId,
          },
        },
        simulationAmountsAndHorizonParams: {
          horizon,
          monthlyDeposit: monthlyInvestment,
          initialPayment,
          retirementAge,
          transferAmount,
          package: fiscalPackage,
        },
      }),
    { enabled: false },
  );

  useEffect(() => {
    if (sendSimulationParamResponse && !sendSimulationParamResponse.step3Blocked) {
      setIsLoading(false);
    } else if (!isPatchPerSubscriptionLoading) {
      setIsLoading(false);
    }
  }, [sendSimulationParamResponse, isPatchPerSubscriptionLoading]);

  const validateSimulation = async () => {
    setIsLoading(true);

    if (onClick) {
      onClick();
    }

    sendEvent({
      event: CdpEventName.CLICK_VALIDATE_SIMULATION,
      properties: {
        category: CdpCategory.NAVIGATION,
      },
    });

    if (isPerPackage(fiscalPackage)) {
      await sendPatchPerSubscriptionQuery();
    } else {
      await sendSimulationParam();
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: '/tunnel-proposition/fin',
          title: 'Validation simulation',
        },
      });
    }

    navigateToStep3(navigate);
  };

  return (
    <>
      <PrimaryButton
        titleId={titleId}
        onClick={validateSimulation}
        loading={isLoading}
        disabled={!resume || isLoadingResume || isSimulationErrorStateValue || isLoading}
        sx={{ flex: 'auto', minWidth: theme.spacing(15) }}
      />
      <LoadingModal
        isOpen={isLoading}
        messageId="footer.mayTakeAWhile"
        titleId="footer.prepareNextSteps"
      />
    </>
  );
}

ValidateSimulationButton.defaultProps = {
  onClick: undefined,
};
