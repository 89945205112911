export enum FiscalPackage {
  LIFE_INSURANCE = 'ASSURANCE_VIE',
  TRADING_ACCOUNT = 'COMPTE_TITRE',
  STOCK_SAVINGS_PLAN = 'PEA',
  RETIREMENT_SAVING_PLAN = 'PER',
  RETIREMENT_SAVING_PLAN_INSURANCE = 'PER_ASSURANCE',
  // when adding a new fiscal package, don't forget to add a fiscalPackage.<newFiscalPackage> translation
}

export function isLifeInsurancePackage(fiscalPackage: FiscalPackage) {
  return (
    fiscalPackage === FiscalPackage.LIFE_INSURANCE
  );
}


export function isCtoPackage(fiscalPackage: FiscalPackage) {
  return (
    fiscalPackage === FiscalPackage.TRADING_ACCOUNT
  );
}

export function isPeaPackage(fiscalPackage: FiscalPackage) {
  return (
    fiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN
  );
}

export function isPerPackage(fiscalPackage: FiscalPackage) {
  return (
    fiscalPackage === FiscalPackage.RETIREMENT_SAVING_PLAN ||
    fiscalPackage === FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE
  );
}

export function isPerInsurancePackage(fiscalPackage: FiscalPackage) {
  return fiscalPackage === FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE;
}
