import ROUTES from '@constants/routes.const';
import Recommendation from '@features/recommendation/pages/Recommendation';
import Step2Layout from '@layouts/Step2Layout';

function step2Routes() {
  return [
    {
      path: '',
      element: <Step2Layout />,
      children: [
        {
          path: ROUTES.RECOMMANDATION,
          element: <Recommendation />,
        },
      ],
    },
  ];
}

export default step2Routes;
