import { PropsWithChildren, useEffect } from 'react';
import { useResetRecoilState } from 'recoil';

import Footer from '@layouts/components/Footer/Footer';
import FunnelLayout from '@layouts/FunnelLayout';
import RequireAuth from '@services/auth/RequireAuth';
import { profileSelector, projectSelector } from '@state';
import { GRAY_LIGHT } from '@theme';

function Step2Layout({ children }: PropsWithChildren<{}>) {
  const resetProjectSelector = useResetRecoilState(projectSelector);
  const resetProfileSelector = useResetRecoilState(profileSelector);

  useEffect(() => {
    resetProjectSelector();
    resetProfileSelector();
  }, []);

  return (
    <RequireAuth>
      <FunnelLayout backgroundColor={GRAY_LIGHT}>
        {children}
        <Footer />
      </FunnelLayout>
    </RequireAuth>
  );
}

export default Step2Layout;
