import { QUERY_PARAMS } from '@constants/routes.const';
import { PreferredFiscalPackage, FiscalPackage } from '@shared/types';

export const createUrlWithAdditionalParams = (
  url: string,
  additionalParams?: Record<string, string>,
) => {
  if (!additionalParams || Object.keys(additionalParams).length === 0) {
    return url;
  }

  const urlWithParams = new URL(url);

  Object.entries(additionalParams).forEach(([key, value]) => {
    urlWithParams.searchParams.set(key, value);
  });

  return urlWithParams.toString();
};

export function extractPreferredEnvelope(
  searchParams: URLSearchParams,
): PreferredFiscalPackage | undefined {
  const envelopeWishParamStr = searchParams.get(QUERY_PARAMS.PREFERRED_ENVELOPE.key);

  switch (envelopeWishParamStr) {
    case QUERY_PARAMS.PREFERRED_ENVELOPE.values[FiscalPackage.LIFE_INSURANCE]:
      return FiscalPackage.LIFE_INSURANCE;
    case QUERY_PARAMS.PREFERRED_ENVELOPE.values[FiscalPackage.TRADING_ACCOUNT]:
      return FiscalPackage.TRADING_ACCOUNT;
    case QUERY_PARAMS.PREFERRED_ENVELOPE.values[FiscalPackage.STOCK_SAVINGS_PLAN]:
      return FiscalPackage.STOCK_SAVINGS_PLAN;
    default:
      return undefined;
  }
}

export function extractPreferredTransfer(searchParams: URLSearchParams): boolean | undefined {
  switch (searchParams.get(QUERY_PARAMS.PREFERRED_TRANSFER.key)) {
    case QUERY_PARAMS.PREFERRED_TRANSFER.values.TRUE:
      return true;
    case QUERY_PARAMS.PREFERRED_TRANSFER.values.FALSE:
      return false;
    default:
      return undefined;
  }
}
