import { Box, Collapse, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useResetRecoilState } from 'recoil';

import { ReactComponent as ArrowDown } from '@assets/icons/arrow-down.svg';
import IconBadge from '@components/IconBadge/IconBadge';
import InputText from '@components/InputText/InputText';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionAnnualIncome, {
  shouldDisplayExactInput,
} from '@features/project/components/questions/QuestionAnnualIncome/QuestionAnnualIncome.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { AnnualIncome, FunnelAnswersKeys } from '@shared/types';
import { grossAnnualIncomeQuestionState } from '@state';
import { GRAY_LIGHT, GRAY_MAIN } from '@theme';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

export default function QuestionAnnualIncome() {
  const intl = useIntl();
  const { isMobile } = useDimensions();
  const { isValid } = useContext(QuestionContext);

  const resetAnnualIncomeStateValue = useResetRecoilState(grossAnnualIncomeQuestionState);
  const { answer: annualIncomeRange, update: updateAnnualIncomeRange } = useFunnelAnswer(
    FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE,
    checkRulesQuestionAnnualIncome,
  );
  const { answer: annualIncome, update: updateAnnualIncome } = useFunnelAnswer(
    FunnelAnswersKeys.GROSS_ANNUAL_INCOME,
    checkRulesQuestionAnnualIncome,
  );

  const bottomRef = useRef<null | HTMLDivElement>(null);

  const displayExactInput = shouldDisplayExactInput(annualIncomeRange);
  if (!displayExactInput) {
    resetAnnualIncomeStateValue();
  }

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  };

  useEffect(() => {
    if (displayExactInput) {
      scrollToBottom();
    }
  }, [displayExactInput]);

  const buttons = [
    {
      value: AnnualIncome.LESS_THAN_30_000,
      titleId: 'step1.question.annual-income.button.LESS_THAN_30_000',
    },
    {
      value: AnnualIncome.BETWEEN_30_000_AND_45_000,
      titleId: 'step1.question.annual-income.button.BETWEEN_30_000_AND_45_000',
    },
    {
      value: AnnualIncome.BETWEEN_45_000_AND_60_000,
      titleId: 'step1.question.annual-income.button.BETWEEN_45_000_AND_60_000',
    },
    {
      value: AnnualIncome.BETWEEN_60_000_AND_100_000,
      titleId: 'step1.question.annual-income.button.BETWEEN_60_000_AND_100_000',
    },
    {
      value: AnnualIncome.BETWEEN_100_000_AND_150_000,
      titleId: 'step1.question.annual-income.button.BETWEEN_100_000_AND_150_000',
    },
    {
      value: AnnualIncome.MORE_THAN_150_000,
      titleId: 'step1.question.annual-income.button.MORE_THAN_150_000',
    },
  ];

  const onChangeRange = (newAnnualIncomeRange: AnnualIncome) => {
    updateAnnualIncomeRange(newAnnualIncomeRange, {
      autoValidate: true,
      debounceError: false,
    });
  };

  const onChangeNumber = (newAnnualIncome: number) => {
    updateAnnualIncome(newAnnualIncome, {
      autoValidate: false,
      debounceError: true,
    });
  };

  return (
    <Box>
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={annualIncomeRange}
        onChange={onChangeRange}
        itemProps={{ xs: 12, sm: 4 }}
      />

      <Collapse in={displayExactInput}>
        <Stack alignItems="center" mt={3}>
          <IconBadge sx={{ backgroundColor: GRAY_LIGHT }}>
            <ArrowDown stroke={GRAY_MAIN} />
          </IconBadge>
          <Typography variant="lg" fontWeight="fontWeightBold" mt={2}>
            {intl.formatMessage({ id: 'step1.question.annual-income.exact.title' })}
          </Typography>
          <Typography variant="md" fontWeight="fontWeightLight" mt={1}>
            {intl.formatMessage({ id: 'step1.question.annual-income.exact.subtitle' })}
          </Typography>
          <InputText
            name="annual-income"
            debounceDuration={DEBOUNCE_DURATION}
            placeholder="Entrez une valeur"
            error={isNumberDefined(annualIncome) && !isValid}
            valid={isValid}
            suffix={intl.formatMessage({ id: 'step1.question.annual-income.input.suffix' })}
            maskProps={{
              mask: Number,
              scale: 0,
              thousandsSeparator: ' ',
              onAccept: (value) => onChangeNumber(parseInt(removeWhitespaces(value), 10)),
            }}
            value={annualIncome !== undefined ? String(annualIncome) : ''}
            InputProps={{
              inputProps: {
                inputMode: 'numeric',
                sx: { textAlign: 'right', paddingRight: '0 !important' },
              },
            }}
            sx={{ width: isMobile ? '100%' : '344px', mt: 2 }}
          />
        </Stack>
        <Box ref={bottomRef} />
      </Collapse>
    </Box>
  );
}
