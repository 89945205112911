import yomoniClient from '@services/api';

async function getSsoToken(): Promise<string> {
  return yomoniClient.get<string, any>('/sso/generate').then((response) => response.data);
}

const SsoTokenClient = {
  getSsoToken,
};

export default SsoTokenClient;
