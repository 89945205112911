export enum ProjectType {
  SIGNIFICANT_PURCHASE = 'ACHAT_IMPORTANT',
  CHILDREN = 'ENFANT',
  SAVINGS = 'EPARGNE',
  EMERGENCY_SAVINGS = 'EPARGNE_SECURITE',
  CHILDREN_STUDY = 'ETUDES_ENFANTS',
  REAL_ESTATE = 'IMMOBILIER',
  INHERITANCE = 'TRANSMISSION',
  RETIREMENT = 'RETRAITE',
  LEGAL_ENTITY = 'EPARGNE_PERSONNE_MORALE',
}
