import { PRODUCT_FUNNEL_ROUTES } from '@constants/routes.const';
import QuestionRecipient from '@features/productFunnel/components/questions/QuestionRecipient/QuestionRecipient';
import { ProductQuestion } from '@features/project/question.utils';
import { CdpEventName } from '@shared/types';

const description: ProductQuestion = {
  path: PRODUCT_FUNNEL_ROUTES.RECIPIENT,
  headerTitle: 'productFunnel.question.layout.header.title',
  questionTitle: 'productFunnel.question.recipient.title',
  cdpEvent: CdpEventName.PRODUCT_FUNNEL_QUESTION_RECIPIENT_SELECTION,
  Component: QuestionRecipient,
};

export default description;
