import { RulesResult } from '@features/project/components/Step1Questions';
import {
  CLASSIC_QUESTIONS,
  CLASSIC_QUESTIONS_REORDERED,
  KIDS_LIFE_INSURANCE_QUESTIONS,
  KIDS_REAL_ESTATE_QUESTIONS,
  LEGAL_ENTITY_QUESTIONS,
  QUESTIONS_TO_HIDE_IF_PREFERRED_FISCAL_PACKAGE,
  QUESTIONS_TO_HIDE_IF_TRANSFER_PEA,
  REAL_ESTATE_QUESTIONS,
} from '@features/project/questionList';
import {
  CdpEventName,
  FiscalPackage,
  FunnelAnswers,
  FunnelAnswersKeys,
  PreferredFiscalPackage,
  ProjectType,
  TernaryAnswer,
  Thematic,
  UserDetail,
} from '@shared/types';

export interface QuestionRuleParams {
  user?: UserDetail;
  isAlreadyClient?: boolean;
  hasActiveLifeInsurance?: boolean;
  businessReferral?: string;
  preferredEnvelope?: PreferredFiscalPackage;
  preferredTransfer?: boolean;
  questionState: Partial<FunnelAnswers>;
  tryNewWording?: boolean;
}

export interface Question {
  path: string;
  headerTitle: string;
  questionTitle?: string;
  questionSubtitle?: string;
  cdpEvent: CdpEventName;
  Component: () => JSX.Element;
  payloadKeys?: FunnelAnswersKeys[];
  rulesFn: (params: QuestionRuleParams) => RulesResult;
}

export interface QuestionListsParams {
  thematic?: Thematic;
  projectType?: ProjectType;
  preferredEnvelope?: PreferredFiscalPackage;
  preferredTransfer?: boolean;
  tryQuestionsWithNewOrder?: boolean;
}

export interface QuestionLists {
  toDisplay: Array<Question>;
  toSubmit: Array<Question>;
}

// Could have been a generics but it would have required a big refactor
export interface ProductQuestion extends Omit<Question, 'payloadKeys' | 'rulesFn' | 'Component'> {
  Component: (props: { handleNext: (callbakc: () => void) => void }) => JSX.Element;
}

export const DEBOUNCE_DURATION = 1000;

export function canSubscribeToRealEstate(isLoggedIn: boolean, isBypassImmoProspect: boolean) {
  return isLoggedIn || isBypassImmoProspect;
}

export const BUTTONS_TERNARY_ANSWER = [
  {
    value: TernaryAnswer.OUI,
    titleId: 'step1.question.quiz.button.OUI',
  },
  {
    value: TernaryAnswer.NON,
    titleId: 'step1.question.quiz.button.NON',
  },
  {
    value: TernaryAnswer.NE_SAIS_PAS,
    titleId: 'step1.question.quiz.button.NE_SAIS_PAS',
  },
];

export function getQuestions(questionListsParams: QuestionListsParams): QuestionLists {
  let questionsToDisplay: Array<Question>;
  let questionsToSubmit: Array<Question>;
  let questionsToHide: Array<Question> = questionListsParams.preferredEnvelope
    ? QUESTIONS_TO_HIDE_IF_PREFERRED_FISCAL_PACKAGE[questionListsParams.preferredEnvelope]
    : [];
  if (questionListsParams.thematic === Thematic.REAL_ESTATE) {
    questionsToDisplay =
      questionListsParams.projectType === ProjectType.CHILDREN
        ? KIDS_REAL_ESTATE_QUESTIONS
        : REAL_ESTATE_QUESTIONS;
    questionsToSubmit = questionsToDisplay;
  } else if (questionListsParams.projectType === ProjectType.CHILDREN) {
    questionsToDisplay = questionListsParams.preferredEnvelope
      ? KIDS_LIFE_INSURANCE_QUESTIONS.filter(
          (question: Question) => !questionsToHide.includes(question),
        )
      : KIDS_LIFE_INSURANCE_QUESTIONS;
    questionsToSubmit = KIDS_LIFE_INSURANCE_QUESTIONS;
  } else if (questionListsParams.projectType === ProjectType.LEGAL_ENTITY) {
    questionsToDisplay = questionListsParams.preferredEnvelope
      ? LEGAL_ENTITY_QUESTIONS.filter((question: Question) => !questionsToHide.includes(question))
      : LEGAL_ENTITY_QUESTIONS;
    questionsToSubmit = LEGAL_ENTITY_QUESTIONS;
  } else {
    const preferredFiscalPackageIsPea =
      questionListsParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN;

    if (preferredFiscalPackageIsPea && questionListsParams.preferredTransfer) {
      questionsToHide = [...questionsToHide, ...QUESTIONS_TO_HIDE_IF_TRANSFER_PEA];
    }

    const classicQuestions: Array<Question> = questionListsParams.tryQuestionsWithNewOrder
      ? CLASSIC_QUESTIONS_REORDERED
      : CLASSIC_QUESTIONS;

    questionsToDisplay = classicQuestions.filter(
      (question: Question) => !questionsToHide.includes(question),
    );
    questionsToSubmit = classicQuestions;
  }
  return {
    toDisplay: questionsToDisplay,
    toSubmit: questionsToSubmit,
  };
}

export const DEFAULT_RESPONSE: { [key in FunnelAnswersKeys]?: any } = {
  [FunnelAnswersKeys.PROJECT_TYPE]: ProjectType.SAVINGS,
};
