export const getObjetFromBase64Json = (
  base64: string,
): Record<'string | number | symbol', unknown> | null => {
  try {
    return JSON.parse(atob(base64));
  } catch {
    return null;
  }
};

export default { getObjetFromBase64: getObjetFromBase64Json };
