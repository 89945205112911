import QuestionAnnualIncomeDescription from '@features/project/components/questions/QuestionAnnualIncome/QuestionAnnualIncomeDescription';
import QuestionBirthdayDescription from '@features/project/components/questions/QuestionBirthday/QuestionBirthdayDescription';
import QuestionBirthdayChildrenDescription from '@features/project/components/questions/QuestionBirthdayChildren/QuestionBirthdayChildrenDescription';
import QuestionChildrenNumberDescription from '@features/project/components/questions/QuestionChildrenNumber/QuestionChildrenNumberDescription';
import QuestionDelegationDescription from '@features/project/components/questions/QuestionDelegation/QuestionDelegationDescription';
import QuestionEarningsPotentialDescription from '@features/project/components/questions/QuestionEarningsPotential/QuestionEarningsPotentialDescription';
import QuestionEsgPreferenceDescription from '@features/project/components/questions/QuestionEsgPreference/QuestionEsgPreferenceDescription';
import QuestionEtfKnowledgeDescription from '@features/project/components/questions/QuestionEtfKnowledge/QuestionEtfKnowledgeDescription';
import QuestionFinancialLossesDescription from '@features/project/components/questions/QuestionFinancialLosses/QuestionFinancialLossesDescription';
import QuestionFinancialPatrimonyDescription from '@features/project/components/questions/QuestionFinancialPatrimony/QuestionFinancialPatrimonyDescription';
import QuestionHorizonDescription from '@features/project/components/questions/QuestionHorizon/QuestionHorizonDescription';
import QuestionInitialAmountDescription from '@features/project/components/questions/QuestionInitialAmount/QuestionInitialAmountDescription';
import QuestionLossReactionDescription from '@features/project/components/questions/QuestionLossReaction/QuestionLossReactionDescription';
import QuestionPrimaryResidenceDescription from '@features/project/components/questions/QuestionPrimaryResidence/QuestionPrimaryResidenceDescription';
import QuestionProjectDescription from '@features/project/components/questions/QuestionProject/QuestionProjectDescription';
import QuestionRealEstateInvestmentDescription from '@features/project/components/questions/QuestionRealEstateInvestment/QuestionRealEstateInvestmentDescription';
import QuestionRealRestatePatrimonyDescription from '@features/project/components/questions/QuestionRealEstatePatrimony/QuestionRealRestatePatrimonyDescription';
import QuestionRealEstateValueLossDescription from '@features/project/components/questions/QuestionRealEstateValueLoss/QuestionRealEstateValueLossDescription';
import QuestionRecurringPaymentsDescription from '@features/project/components/questions/QuestionReccuringPayments/QuestionRecurringPaymentsDescription';
import QuestionRentMortgageDescription from '@features/project/components/questions/QuestionRentMortgage/QuestionRentMortgageDescription';
import QuestionRiskRewardDescription from '@features/project/components/questions/QuestionRiskReward/QuestionRiskRewardDescription';
import QuestionRiskRewardPercentageDescription from '@features/project/components/questions/QuestionRiskRewardPercentage/QuestionRiskRewardPercentageDescription';
import QuestionSavingsCapacityDescription from '@features/project/components/questions/QuestionSavingsCapacity/QuestionSavingsCapacityDescription';
import QuestionSavingsProductsDescription from '@features/project/components/questions/QuestionSavingsProducts/QuestionSavingsProductsDescription';
import QuestionTaxResidencyDescription from '@features/project/components/questions/QuestionTaxResidency/QuestionTaxResidencyDescription';
import QuestionWithdrawalHalfDescription from '@features/project/components/questions/QuestionWithdrawalHalf/QuestionWithdrawalHalfDescription';
import QuestionWithdrawalTwoYearsDescription from '@features/project/components/questions/QuestionWithdrawalTwoYears/QuestionWithdrawalTwoYearsDescription';
import { Question } from '@features/project/question.utils';
import { FiscalPackage } from '@shared/types';

export const CLASSIC_QUESTIONS: Array<Question> = [
  QuestionProjectDescription.classic,
  QuestionInitialAmountDescription.classic,
  QuestionRecurringPaymentsDescription.classic,
  QuestionHorizonDescription.classic,
  QuestionBirthdayDescription.classic,
  QuestionTaxResidencyDescription.classic,
  QuestionEsgPreferenceDescription.classic,
  QuestionChildrenNumberDescription.classic,
  QuestionAnnualIncomeDescription.classic,
  QuestionPrimaryResidenceDescription.classic,
  QuestionRentMortgageDescription.classic,
  QuestionRealRestatePatrimonyDescription.classic,
  QuestionFinancialPatrimonyDescription.classic,
  QuestionSavingsCapacityDescription.classic,
  QuestionWithdrawalTwoYearsDescription.classic,
  QuestionWithdrawalHalfDescription.classic,
  QuestionSavingsProductsDescription.classic,
  QuestionEarningsPotentialDescription.classic,
  QuestionEtfKnowledgeDescription.classic,
  QuestionDelegationDescription.classic,
  QuestionFinancialLossesDescription.classic,
  QuestionRiskRewardDescription.classic,
  QuestionRiskRewardPercentageDescription.classic,
  QuestionLossReactionDescription.classic,
];

export const CLASSIC_QUESTIONS_REORDERED: Array<Question> = [
  QuestionProjectDescription.classic,
  QuestionEsgPreferenceDescription.classic,
  QuestionChildrenNumberDescription.classic,
  QuestionSavingsProductsDescription.classic,
  QuestionFinancialLossesDescription.classic,
  QuestionLossReactionDescription.classic,
  QuestionHorizonDescription.classic,
  QuestionWithdrawalTwoYearsDescription.classic,
  QuestionWithdrawalHalfDescription.classic,
  QuestionEarningsPotentialDescription.classic,
  QuestionDelegationDescription.classic,
  QuestionEtfKnowledgeDescription.classic,
  QuestionRiskRewardDescription.classic,
  QuestionRiskRewardPercentageDescription.classic,
  QuestionBirthdayDescription.classic,
  QuestionPrimaryResidenceDescription.classic,
  QuestionRentMortgageDescription.classic,
  QuestionAnnualIncomeDescription.classic,
  QuestionInitialAmountDescription.classic,
  QuestionRecurringPaymentsDescription.classic,
  QuestionRealRestatePatrimonyDescription.classic,
  QuestionFinancialPatrimonyDescription.classic,
  QuestionSavingsCapacityDescription.classic,
  QuestionTaxResidencyDescription.classic,
];

export const QUESTIONS_TO_HIDE_IF_PREFERRED_FISCAL_PACKAGE: Record<
  FiscalPackage,
  Array<Question>
> = {
  [FiscalPackage.LIFE_INSURANCE]: [QuestionProjectDescription.classic],
  [FiscalPackage.STOCK_SAVINGS_PLAN]: [
    QuestionProjectDescription.classic,
    QuestionRecurringPaymentsDescription.classic,
    QuestionTaxResidencyDescription.classic,
  ],
  [FiscalPackage.RETIREMENT_SAVING_PLAN]: [QuestionProjectDescription.classic],
  [FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE]: [QuestionProjectDescription.classic],
  [FiscalPackage.TRADING_ACCOUNT]: [QuestionProjectDescription.classic],
};

export const QUESTIONS_TO_HIDE_IF_TRANSFER_PEA: Array<Question> = [
  QuestionSavingsProductsDescription.classic,
];

export const KIDS_LIFE_INSURANCE_QUESTIONS: Array<Question> = [
  QuestionProjectDescription.kid,
  QuestionInitialAmountDescription.kid,
  QuestionRecurringPaymentsDescription.kid,
  QuestionHorizonDescription.kid,
  QuestionBirthdayChildrenDescription.kid,
  QuestionTaxResidencyDescription.kid,
  QuestionEsgPreferenceDescription.kid,
  QuestionChildrenNumberDescription.kid,
  QuestionAnnualIncomeDescription.kid,
  QuestionPrimaryResidenceDescription.kid,
  QuestionRentMortgageDescription.kid,
  QuestionRealRestatePatrimonyDescription.kid,
  QuestionFinancialPatrimonyDescription.kid,
  QuestionSavingsCapacityDescription.kid,
  QuestionWithdrawalTwoYearsDescription.kid,
  QuestionWithdrawalHalfDescription.kid,
  QuestionSavingsProductsDescription.kid,
  QuestionEarningsPotentialDescription.kid,
  QuestionEtfKnowledgeDescription.kid,
  QuestionDelegationDescription.kid,
  QuestionFinancialLossesDescription.kid,
  QuestionRiskRewardDescription.kid,
  QuestionRiskRewardPercentageDescription.kid,
  QuestionLossReactionDescription.kid,
];

export const KIDS_REAL_ESTATE_QUESTIONS: Array<Question> = [
  QuestionProjectDescription.kid,
  QuestionInitialAmountDescription.kid,
  QuestionRecurringPaymentsDescription.kid,
  QuestionHorizonDescription.kid,
  QuestionBirthdayChildrenDescription.kid,
  QuestionTaxResidencyDescription.kid,
  QuestionChildrenNumberDescription.kid,
  QuestionAnnualIncomeDescription.kid,
  QuestionPrimaryResidenceDescription.kid,
  QuestionRentMortgageDescription.kid,
  QuestionRealRestatePatrimonyDescription.kid,
  QuestionFinancialPatrimonyDescription.kid,
  QuestionSavingsCapacityDescription.kid,
  QuestionWithdrawalTwoYearsDescription.kid,
  QuestionWithdrawalHalfDescription.kid,
  QuestionSavingsProductsDescription.kid,
  QuestionEarningsPotentialDescription.kid,
  QuestionRealEstateInvestmentDescription.realEstate,
  QuestionRealEstateValueLossDescription.realEstate,
  QuestionFinancialLossesDescription.kid,
  QuestionRiskRewardDescription.kid,
  QuestionRiskRewardPercentageDescription.kid,
  QuestionLossReactionDescription.kid,
];

export const REAL_ESTATE_QUESTIONS: Array<Question> = [
  QuestionProjectDescription.realEstate,
  QuestionInitialAmountDescription.realEstate,
  QuestionRecurringPaymentsDescription.realEstate,
  QuestionHorizonDescription.realEstate,
  QuestionBirthdayDescription.realEstate,
  QuestionTaxResidencyDescription.realEstate,
  QuestionChildrenNumberDescription.realEstate,
  QuestionAnnualIncomeDescription.realEstate,
  QuestionPrimaryResidenceDescription.realEstate,
  QuestionRentMortgageDescription.realEstate,
  QuestionRealRestatePatrimonyDescription.realEstate,
  QuestionFinancialPatrimonyDescription.realEstate,
  QuestionSavingsCapacityDescription.realEstate,
  QuestionWithdrawalTwoYearsDescription.realEstate,
  QuestionWithdrawalHalfDescription.realEstate,
  QuestionSavingsProductsDescription.realEstate,
  QuestionEarningsPotentialDescription.realEstate,
  QuestionRealEstateInvestmentDescription.realEstate,
  QuestionRealEstateValueLossDescription.realEstate,
  QuestionFinancialLossesDescription.realEstate,
  QuestionRiskRewardDescription.realEstate,
  QuestionRiskRewardPercentageDescription.realEstate,
  QuestionLossReactionDescription.realEstate,
];

export const LEGAL_ENTITY_QUESTIONS: Array<Question> = [
  QuestionProjectDescription.legalEntity,
  QuestionInitialAmountDescription.legalEntity,
  QuestionRecurringPaymentsDescription.legalEntity,
  QuestionHorizonDescription.legalEntity,
  QuestionBirthdayDescription.legalEntity,
  QuestionTaxResidencyDescription.legalEntity,
  QuestionRealRestatePatrimonyDescription.legalEntity,
  QuestionFinancialPatrimonyDescription.legalEntity,
  QuestionWithdrawalTwoYearsDescription.legalEntity,
  QuestionWithdrawalHalfDescription.legalEntity,
  QuestionSavingsProductsDescription.legalEntity,
  QuestionEarningsPotentialDescription.legalEntity,
  QuestionEtfKnowledgeDescription.legalEntity,
  QuestionDelegationDescription.legalEntity,
  QuestionFinancialLossesDescription.legalEntity,
  QuestionRiskRewardDescription.legalEntity,
  QuestionRiskRewardPercentageDescription.legalEntity,
  QuestionLossReactionDescription.legalEntity,
];
