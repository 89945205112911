import { Box } from '@mui/system';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as BankIcon } from '@assets/icons/bank.svg';
import { ReactComponent as PersonIcon } from '@assets/icons/person-outline.svg';
import { ReactComponent as StrollerIcon } from '@assets/icons/project/stroller.svg';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import ROUTES, { PRODUCT_FUNNEL_ROUTES, QUERY_PARAMS } from '@constants/routes.const';
import { KIDS_LIFE_INSURANCE_QUESTIONS } from '@features/project/questionList';
import useTracking from '@hooks/tracking/useTracking';
import useNavigateToProjectFunnelWithPreferredChoice from '@hooks/useNavigateToProjectFunnelWithPreferredChoice';
import { CdpCategory, CdpEventName, FiscalPackage, ProjectType } from '@shared/types';
import { projectTypeQuestionState } from '@state';

export default function QuestionRecipient({
  handleNext,
}: {
  handleNext: (callback: () => void) => void;
}) {
  const navigate = useNavigate();
  const { sendEvent } = useTracking();
  const setProjectType = useSetRecoilState(projectTypeQuestionState);
  const navigateToProjectFunnelWithPreferredChoice =
    useNavigateToProjectFunnelWithPreferredChoice();

  const buttons = [
    {
      value: 'perso',
      StartIcon: PersonIcon,
      titleId: 'productFunnel.question.recipient.button.me',
      onClick: () =>
        navigate({
          pathname: `/${ROUTES.PRODUCT_FUNNEL}/${PRODUCT_FUNNEL_ROUTES.FISCAL_PACKAGE}`,
          search: createSearchParams({
            [QUERY_PARAMS.RECIPIENT.key]: QUERY_PARAMS.RECIPIENT.values.PERSONAL,
          }).toString(),
        }),
    },
    {
      value: 'mon-enfant',
      StartIcon: StrollerIcon,
      titleId: 'productFunnel.question.recipient.button.my-child',
      onClick: () => {
        setProjectType(ProjectType.CHILDREN);
        navigateToProjectFunnelWithPreferredChoice({
          route: `/${ROUTES.PROJECT}/${KIDS_LIFE_INSURANCE_QUESTIONS[1].path}`,
          preferredEnvelope: FiscalPackage.LIFE_INSURANCE,
        });
      },
    },
    {
      value: 'pro',
      StartIcon: BankIcon,
      titleId: 'productFunnel.question.recipient.button.my-company',
      onClick: () =>
        navigate({
          pathname: `/${ROUTES.PRODUCT_FUNNEL}/${PRODUCT_FUNNEL_ROUTES.FISCAL_PACKAGE}`,
          search: createSearchParams({
            [QUERY_PARAMS.RECIPIENT.key]: QUERY_PARAMS.RECIPIENT.values.BUSINESS,
          }).toString(),
        }),
    },
  ];

  const onClick = (value: string) => {
    handleNext(() => {
      sendEvent({
        event: CdpEventName.PRODUCT_FUNNEL_QUESTION_RECIPIENT_SELECTION,
        properties: {
          category: CdpCategory.QUESTION,
          productFunnelRecipient: value,
        },
      });
      buttons.find((b) => b.value === value)?.onClick();
    });
  };

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={buttons}
        onChange={onClick}
        containerProps={{ sx: { maxWidth: '490px' } }}
        itemProps={{ xs: 12, display: 'flex' }}
        iconAboveText
        withoutBorderOnSelected
      />
    </Box>
  );
}
