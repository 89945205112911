import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionEsgPreference from '@features/project/components/questions/QuestionEsgPreference/QuestionEsgPreference';
import checkRulesQuestionEsgPreference from '@features/project/components/questions/QuestionEsgPreference/QuestionEsgPreference.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.ESG_PREFERENCE,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.thematic.title',
  cdpEvent: CdpEventName.QUESTION_RESPONSIBLE_INVESTMENT,
  Component: QuestionEsgPreference,
  payloadKeys: [FunnelAnswersKeys.RESPONSIBLE_INVESTMENT],
  rulesFn: checkRulesQuestionEsgPreference,
};

export default {
  classic: baseDescription,
  kid: baseDescription,
};
