import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionPrimaryResidence from '@features/project/components/questions/QuestionPrimaryResidence/QuestionPrimaryResidence';
import checkRulesQuestionPrimaryResidency from '@features/project/components/questions/QuestionPrimaryResidence/QuestionPrimaryResidence.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.PRIMARY_RESIDENCE,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.primary-residence.title',
  cdpEvent: CdpEventName.QUESTION_OWNER_MAIN_RESIDENCE,
  Component: QuestionPrimaryResidence,
  payloadKeys: [FunnelAnswersKeys.OWNER_MAIN_RESIDENCE],
  rulesFn: checkRulesQuestionPrimaryResidency,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: { ...baseDescription, questionSubtitle: 'step1.question.primary-residence.subtitle.kid' },
};
