import { Box, Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import Image from '@components/Image/Image';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import RadioButtonCard from '@components/RadioButtonCard/RadioButtonCard';
import ROUTES from '@constants/routes.const';
import UserClient from '@services/clients/user';
import { CAMEL_LIGHT, CARD_BORDER, METHOD_BOX_GRAY } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

export default function Newsletter() {
  const intl = useIntl();
  const navigate = useNavigate();
  const isMobile = isMobileDimensions('md');
  const [newsletter, setNewsletter] = useState(false);
  const [commercial, setCommercial] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { mutate: putUserConsent, isLoading } = useMutation(
    () =>
      UserClient.putUserConsent({
        advice: true,
        newsletter,
        commercial,
      }),
    {
      onSuccess: () => {
        navigate(ROUTES.RECOMMANDATION);
      },
    },
  );

  const onclick = async () => {
    putUserConsent();
  };

  return (
    <Box
      sx={{
        backgroundColor: METHOD_BOX_GRAY,
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-evenly',
        px: { xs: 3, lg: 0 },
        py: 6,
      }}>
      <Stack
        direction="column"
        alignItems={isMobile ? 'stretch' : 'center'}
        sx={{ maxWidth: '996px', width: '100%' }}>
        <Typography variant="xl" fontWeight="fontWeightBold" textAlign="center">
          {intl.formatMessage({ id: 'newsletter.title' })}
        </Typography>
        <Card
          sx={{
            my: 4,
            mx: 0,
            p: 4,
            borderRadius: '12px',
            boxShadow: {
              xs: 'none',
            },
            border: CARD_BORDER,
          }}>
          <Stack direction="row" gap={4}>
            {!isMobile && (
              <Box sx={{ backgroundColor: CAMEL_LIGHT, borderRadius: '12px', p: 4 }}>
                <Image
                  alt="newsletter"
                  src="/images/newsletter.png"
                  src2x="/images/newsletter@2x.png"
                  width="100%"
                  height="auto"
                />
              </Box>
            )}
            <Stack direction="column" gap={2}>
              <Stack gap={1}>
                <Typography variant="lg" fontWeight="fontWeightBold">
                  {intl.formatMessage({ id: 'newsletter.keep-contact' })}
                </Typography>
                <Typography variant="md" fontWeight="fontWeightLight">
                  {intl.formatMessage({ id: 'newsletter.tick-boxes' })}
                </Typography>
              </Stack>

              <Stack direction="column" gap={1}>
                <RadioButtonCard
                  disabled={isLoading}
                  aria-label="radio-button-card-newsletter"
                  titleId="newsletter.checkbox.newsletter.title"
                  checked={newsletter}
                  round={false}
                  onClick={() => setNewsletter((newsletterState) => !newsletterState)}
                  gap={1}
                  sx={{ borderRadius: '8px' }}>
                  <Typography variant="md" fontWeight="fontWeightLight" color="primary.light">
                    {intl.formatMessage({
                      id: `newsletter.checkbox.newsletter.description`,
                    })}
                  </Typography>
                </RadioButtonCard>

                <RadioButtonCard
                  disabled={isLoading}
                  aria-label="radio-button-card-commercial"
                  titleId="newsletter.checkbox.commercial.title"
                  checked={commercial}
                  round={false}
                  onClick={() => setCommercial((commercialState) => !commercialState)}
                  gap={1}
                  sx={{ borderRadius: '8px' }}>
                  <Typography variant="md" fontWeight="fontWeightLight" color="primary.light">
                    {intl.formatMessage({
                      id: `newsletter.checkbox.commercial.description`,
                    })}
                  </Typography>
                </RadioButtonCard>
              </Stack>
              <Box display="flex" justifyContent={isMobile ? 'center' : 'right'}>
                <PrimaryButton
                  onClick={onclick}
                  titleId="newsletter.submit"
                  loading={isLoading}
                  disabled={isLoading}
                />
              </Box>
            </Stack>
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}
