import { Stack } from '@mui/material';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { InputText } from '@components/InputText/InputText';
import checkRulesQuestionFinancialPatrimony from '@features/project/components/questions/QuestionFinancialPatrimony/QuestionFinancialPatrimony.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import useTryNewWording from '@hooks/useTryNewWording';
import { FunnelAnswersKeys } from '@shared/types';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

export default function QuestionFinancialPatrimony() {
  const { isMobile } = useDimensions();
  const intl = useIntl();
  const { isValid } = useContext(QuestionContext);
  const tryNewWording = useTryNewWording();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.FINANCIAL_PATRIMONY,
    checkRulesQuestionFinancialPatrimony,
  );

  const onChangeInputText = (newFinancialPatrimony: number) => {
    update(newFinancialPatrimony, { debounceError: true });
  };

  return (
    <Stack alignItems="center" gap={3}>
      <InputText
        name="financial-patrimony"
        debounceDuration={DEBOUNCE_DURATION}
        aria-label="input-text-financial-patrimony"
        maskProps={{
          mask: Number,
          scale: 0,
          thousandsSeparator: ' ',
          onAccept: (value) => onChangeInputText(parseInt(removeWhitespaces(value), 10)),
        }}
        suffix="€"
        value={answer !== undefined ? String(answer) : ''}
        placeholder={intl.formatMessage({
          id: tryNewWording
            ? 'step1.question.financial-patrimony.input.placeholder.abtest'
            : 'step1.question.financial-patrimony.input.placeholder',
        })}
        error={isNumberDefined(answer) && !isValid}
        valid={isValid}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            sx: { textAlign: 'right', paddingRight: '0 !important' },
          },
        }}
        sx={{ width: isMobile ? '100%' : '344px' }}
      />
    </Stack>
  );
}
