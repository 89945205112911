import yomoniClient from '@services/api';
import { PrepareNewProjectResponse, ResumeResponse } from '@shared/types';

async function getResume(): Promise<ResumeResponse> {
  return yomoniClient.get<ResumeResponse, any>('/project/resume').then((response) => response.data);
}

async function prepareNewProject(): Promise<PrepareNewProjectResponse> {
  return yomoniClient
    .get<PrepareNewProjectResponse, any>('/project/prepareNewProject')
    .then((response) => response.data);
}

const ResumeClient = {
  getResume,
  prepareNewProject,
};

export default ResumeClient;
