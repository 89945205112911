import { InvestmentSupport } from './investment-support.enum';
import { FiscalPackage } from './fiscal-package.enum';
import { Thematic } from './thematic.enum';

export enum ProfileId {
  PER_PRUDENT = 'PER_PRUDENT',
  PER_EQUILIBRE = 'PER_EQUILIBRE',
  PER_DYNAMIQUE = 'PER_DYNAMIQUE',
  SPK_PER_ETF_DYNAMIQUE = 'SPK_PER_ETF_DYNAMIQUE',
  SPK_PER_ETF_EQUILIBRE = 'SPK_PER_ETF_EQUILIBRE',
  SPK_PER_ETF_PRUDENT = 'SPK_PER_ETF_PRUDENT',
  SPK_PER_MULTI_ACTIFS_EQUILIBRE = 'SPK_PER_MULTI_ACTIFS_EQUILIBRE',
  SPK_PER_MULTI_ACTIFS_DYNAMIQUE = 'SPK_PER_MULTI_ACTIFS_DYNAMIQUE',
  SPK_PER_MULTI_ACTIFS_PRUDENT = 'SPK_PER_MULTI_ACTIFS_PRUDENT',
}

/**
 * @Deprecated replace usage with thematic state when fully available
 */
export function getThematic(profileId: string): Thematic | undefined {
  if (
    profileId === ProfileId.SPK_PER_MULTI_ACTIFS_PRUDENT ||
    profileId === ProfileId.SPK_PER_MULTI_ACTIFS_EQUILIBRE ||
    profileId === ProfileId.SPK_PER_MULTI_ACTIFS_DYNAMIQUE
  ) {
    return Thematic.MULTI_ASSETS;
  }
  return Thematic.CLASSIC;
}

export const PerBankingProfiles: ProfileId[] = [
  ProfileId.PER_PRUDENT,
  ProfileId.PER_EQUILIBRE,
  ProfileId.PER_DYNAMIQUE,
];

export const PerInsuranceEtfOnlyProfiles: ProfileId[] = [
  ProfileId.SPK_PER_ETF_PRUDENT,
  ProfileId.SPK_PER_ETF_EQUILIBRE,
  ProfileId.SPK_PER_ETF_DYNAMIQUE,
];

export const PerInsuranceMultiInvestmentSupportProfiles: ProfileId[] = [
  ProfileId.SPK_PER_MULTI_ACTIFS_PRUDENT,
  ProfileId.SPK_PER_MULTI_ACTIFS_EQUILIBRE,
  ProfileId.SPK_PER_MULTI_ACTIFS_DYNAMIQUE,
];

const PerProfileEquivalenceTable = [
  [
    {
      profileId: ProfileId.PER_PRUDENT,
      investmentSupport: InvestmentSupport.BANKING,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN,
    },
    {
      profileId: ProfileId.SPK_PER_ETF_PRUDENT,
      investmentSupport: InvestmentSupport.ETF_ONLY,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
    {
      profileId: ProfileId.SPK_PER_MULTI_ACTIFS_PRUDENT,
      investmentSupport: InvestmentSupport.MULTI_ASSET,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
  ],
  [
    {
      profileId: ProfileId.PER_EQUILIBRE,
      investmentSupport: InvestmentSupport.BANKING,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN,
    },
    {
      profileId: ProfileId.SPK_PER_ETF_EQUILIBRE,
      investmentSupport: InvestmentSupport.ETF_ONLY,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
    {
      profileId: ProfileId.SPK_PER_MULTI_ACTIFS_EQUILIBRE,
      investmentSupport: InvestmentSupport.MULTI_ASSET,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
  ],
  [
    {
      profileId: ProfileId.PER_DYNAMIQUE,
      investmentSupport: InvestmentSupport.BANKING,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN,
    },
    {
      profileId: ProfileId.SPK_PER_ETF_DYNAMIQUE,
      investmentSupport: InvestmentSupport.ETF_ONLY,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
    {
      profileId: ProfileId.SPK_PER_MULTI_ACTIFS_DYNAMIQUE,
      investmentSupport: InvestmentSupport.MULTI_ASSET,
      fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE,
    },
  ],
];

export function getPerEquivalentProfile(
  profileId: ProfileId,
  targetSupport: InvestmentSupport,
): { profileId: ProfileId; fiscalPackage: FiscalPackage } {
  const equivalentProfile = PerProfileEquivalenceTable.find((allProfiles) =>
    allProfiles.some((equivalentProfiles) => equivalentProfiles.profileId === profileId),
  )?.find((equivalentProfiles) => equivalentProfiles.investmentSupport === targetSupport);

  if (equivalentProfile) {
    return {
      profileId: equivalentProfile.profileId,
      fiscalPackage: equivalentProfile.fiscalPackage,
    };
  } else {
    throw new Error(`No equivalent profile found for ${profileId} and ${targetSupport}`);
  }
}
