import { Box, IconButton, styled } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import muiTheme, { WARNING_DARK, WARNING_LIGHT } from '@theme';

export const StyledCloseIcon = styled(CloseIcon)(() => ({
  fill: WARNING_DARK,
}));

interface YomoniBigSnackProps extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
}

const YomoniBigSnack = forwardRef<HTMLDivElement, PropsWithChildren<YomoniBigSnackProps>>(
  (
    { children, id, ...rest }: PropsWithChildren<YomoniBigSnackProps>,
    ref: ForwardedRef<HTMLDivElement>,
  ) => {
    const { closeSnackbar } = useSnackbar();

    return (
      <Box
        ref={ref}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: muiTheme.spacing(2.5),
          py: muiTheme.spacing(1.5),
          gap: muiTheme.spacing(2),
          borderRadius: muiTheme.spacing(1.5),
          width: '740px',
          backgroundColor: WARNING_LIGHT,
          border: '1px solid rgba(214, 158, 46, 0.16)',
        }}
        {...rest}>
        <Box>{children}</Box>
        <IconButton onClick={() => closeSnackbar(id)}>
          <StyledCloseIcon />
        </IconButton>
      </Box>
    );
  },
);

YomoniBigSnack.defaultProps = {};

export default YomoniBigSnack;
