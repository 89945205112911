import { Card, CardContent, Radio, RadioProps, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';

import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';
import Bubble from '@components/Bubble/Bubble';
import TextButton from '@components/TextButton/TextButton';
import { GRAY_MAIN_BORDER_COLOR, PRIMARY_MAIN, SUCCESS_MAIN, WHITE } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

interface RadioButtonCardProps extends RadioProps {
  titleId: string;
  checked?: boolean;
  badge?: JSX.Element;
  onClick?: () => void;
  onEdit?: () => void;
  round?: boolean;
  gap?: number;
}

function RadioButtonCard({
  titleId,
  checked,
  sx,
  badge,
  children,
  onClick,
  onEdit,
  round,
  'aria-label': ariaLabel,
  gap,
  disabled,
  ...rest
}: PropsWithChildren<RadioButtonCardProps>) {
  const intl = useIntl();
  const isMobile = isMobileDimensions('md');

  return (
    <Card
      aria-label={ariaLabel}
      className="root-card"
      sx={{
        margin: 0,
        mt: 1,
        cursor: disabled ? 'not-allowed' : 'pointer',
        padding: '20px',
        borderRadius: round ? '12px' : '4px',
        boxShadow: 'none !important',
        transition: 'border 0.3s ease',
        border: checked ? `1px solid ${SUCCESS_MAIN}` : `1px solid ${GRAY_MAIN_BORDER_COLOR}`,
        '&: hover': !isMobile && !checked && !disabled ? { borderColor: PRIMARY_MAIN } : null,
        '&: hover .bubble':
          !isMobile && !checked && !disabled ? { borderColor: PRIMARY_MAIN } : null,
        ...sx,
      }}>
      <CardContent onClick={disabled ? () => {} : onClick}>
        <Stack gap={gap}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Radio
                disableRipple
                icon={
                  <Bubble
                    className="bubble"
                    aria-label="unchecked-icon"
                    sx={{
                      borderColor: GRAY_MAIN_BORDER_COLOR,
                      backgroundColor: 'white',
                      marginLeft: '-9px',
                      borderRadius: round ? '100%' : '4px',
                    }}
                  />
                }
                checked={checked}
                checkedIcon={
                  <Bubble
                    aria-label="checked-icon"
                    sx={{
                      borderColor: SUCCESS_MAIN,
                      backgroundColor: SUCCESS_MAIN,
                      marginLeft: '-9px',
                      borderRadius: round ? '100%' : '4px',
                    }}>
                    <CheckIcon fill={WHITE} width="12px" height="12px" />
                  </Bubble>
                }
                disabled={disabled}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
              />
              <Typography variant="lg" fontWeight="fontWeightBold">
                {intl.formatMessage({ id: titleId })}
              </Typography>
            </Stack>
            {onEdit && <TextButton onClick={onEdit} titleId="edit" />}
          </Stack>
          {children}
          {badge}
        </Stack>
      </CardContent>
    </Card>
  );
}

RadioButtonCard.defaultProps = {
  badge: undefined,
  onClick: undefined,
  onEdit: undefined,
  checked: false,
  round: true,
  gap: 2,
};

export default RadioButtonCard;
