import { Box, SxProps } from '@mui/material';
import { ReactElement } from 'react';

import { WHITE } from '@theme';

interface IconBadgeProps {
  children: ReactElement;
  sx?: SxProps;
}

export default function IconBadge({ children, sx }: IconBadgeProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '3rem',
        width: '3rem',
        borderRadius: '50%',
        backgroundColor: WHITE,
        ...sx,
      }}>
      {children}
    </Box>
  );
}

IconBadge.defaultProps = {
  sx: {},
};
