import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getUser } from '@services/clients/user';
import { UserDetail } from '@shared/types';
import { PRIMARY_DARK } from '@theme';

interface ZendeskProps {
  onClose?: () => void;
  onOpen?: () => void;
  open?: boolean;
}

const settings = {
  webWidget: {
    position: {
      horizontal: 'right',
      vertical: 'bottom',
    },
    offset: {
      vertical: '85px',
    },
    color: {
      header: PRIMARY_DARK,
    },
    answerBot: {
      suppress: true,
    },
    chat: {
      suppress: false,
    },
    contactForm: {
      suppress: true,
    },
    helpCenter: {
      suppress: true,
    },
    navigation: {
      popoutButton: {
        enabled: false,
      },
    },
    talk: {
      suppress: true,
    },
  },
};

export default function Zendesk({ onClose, onOpen, open }: ZendeskProps) {
  const [loaded, setLoaded] = useState(false);
  const { refetch: getUserDetail } = useQuery<UserDetail, Error>(
    'userDetail',
    async () => getUser(),
    { enabled: false },
  );

  const onLoad = async () => {
    const { data: userDetail } = await getUserDetail();
    if (userDetail) {
      window.zE('webWidget', 'identify', {
        email: userDetail.email,
        ...(userDetail.firstname && { name: `${userDetail.firstname} ${userDetail.lastname}` }),
      });
    }
    setLoaded(true);
  };

  const loadChat = () => {
    if (window && !window.zE && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env
        .REACT_APP_ZENDESK_API_KEY!}`;
      script.onload = onLoad;
      body.appendChild(script);
    }
  };

  const openChat = () => {
    if (onOpen) {
      onOpen();
    }

    window.zE('webWidget', 'show');
    window.zE('webWidget', 'open');
    window.zE('webWidget:on', 'close', () => {
      window.zE('webWidget', 'hide');
      if (onClose) {
        onClose();
      }
    });
  };

  useEffect(() => {
    window.zESettings = settings;
  }, [settings]);

  useEffect(() => {
    if (open) {
      if (loaded) {
        openChat();
      } else {
        loadChat();
      }
    }
  }, [loaded, open]);

  return null;
}

Zendesk.defaultProps = {
  onClose: undefined,
  onOpen: undefined,
  open: false,
};
