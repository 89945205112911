import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionEtfKnowledge from '@features/project/components/questions/QuestionEtfKnowledge/QuestionEtfKnowledge.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { BUTTONS_TERNARY_ANSWER } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { TernaryAnswer } from '@shared/types';
import { etfKnowledgeQuizQuestionState, questionsSelector } from '@state';

export default function QuestionEtfKnowledge() {
  const { isMobile } = useDimensions();
  const { updateContext } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const setEtfKnowledgeQuizStateValue = useSetRecoilState(etfKnowledgeQuizQuestionState);

  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.etf-knowledge',
  });

  useEffect(() => {
    updateContext({
      ...checkRulesQuestionEtfKnowledge({ questionState }),
    });
  }, []);

  const onClick = (newTernaryAnswer: TernaryAnswer) => {
    setEtfKnowledgeQuizStateValue(newTernaryAnswer);
    updateContext(
      checkRulesQuestionEtfKnowledge({
        questionState: {
          ...questionState,
          etfKnowledgeQuiz: newTernaryAnswer,
        },
      }),
    );
  };

  return (
    <ToggleButtonGroup
      disabled={questionState.etfKnowledgeQuiz !== undefined}
      buttons={BUTTONS_TERNARY_ANSWER}
      selectedValue={questionState.etfKnowledgeQuiz}
      onChange={onClick}
      itemProps={{ xs: isMobile ? 12 : undefined }}
    />
  );
}
