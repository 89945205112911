import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRiskReward from '@features/project/components/questions/QuestionRiskReward/QuestionRiskReward';
import checkRulesQuestionRiskReward from '@features/project/components/questions/QuestionRiskReward/QuestionRiskReward.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.RISK_REWARD,
  headerTitle: 'step1.question.layout.header.risk-relation.title',
  questionTitle: 'step1.question.risk-reward.title',
  questionSubtitle: 'step1.question.risk-reward.subtitle',
  cdpEvent: CdpEventName.QUESTION_AMOUNT_WIN_LOSS_RATIO_QUIZ,
  Component: QuestionRiskReward,
  payloadKeys: [FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ],
  rulesFn: checkRulesQuestionRiskReward,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.risk-reward.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
