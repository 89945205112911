import { Container, Modal } from '@mui/material';
import { SxProps, Theme } from '@mui/system';
import { ReactNode } from 'react';

import { ReactComponent as CrossIcon } from '@assets/icons/cross.svg';
import ModalButtons from '@components/StyledModal/components/ModalButtons';
import ModalTitle, { ModalTitleProps } from '@components/StyledModal/components/ModalTitle';
import StyledContainer, {
  StyledContainerProps,
} from '@components/StyledModal/components/StyledContainer';
import StyledCrossIconButton from '@components/StyledModal/components/StyledCrossIconButton';
import StyledMobileNavbar from '@components/StyledModal/components/StyledMobileNavbar';
import { CdpEvent } from '@shared/types';
import { PRIMARY_MAIN } from '@theme';

interface StyledModalProps extends StyledContainerProps {
  outlinedButtonTitleId?: string;
  primaryButtonTitleId?: string;
  children: ReactNode;
  isOpen: boolean;
  primaryButtonDisabled?: boolean;
  outlinedButtonDisabled?: boolean;
  hideModalButtons?: boolean;
  shouldGrowPrimaryButton?: boolean;
  handleClose?: () => void;
  handleOutlinedButtonClick?: () => void;
  handlePrimaryButtonClick?: () => void;
  primaryButtonLoading?: boolean;
  buttonsSx?: SxProps<Theme>;
  ariaLabel?: string;
  primaryButtonEvent?: CdpEvent;
  outlinedButtonEvent?: CdpEvent;
  titleProps?: ModalTitleProps;
}

export default function StyledModal({
  ariaLabel,
  children,
  isOpen,
  outlinedButtonTitleId,
  primaryButtonTitleId,
  primaryButtonDisabled = false,
  primaryButtonEvent,
  outlinedButtonEvent,
  outlinedButtonDisabled = false,
  hideModalButtons = false,
  shouldGrowPrimaryButton = false,
  handleClose,
  handleOutlinedButtonClick,
  handlePrimaryButtonClick,
  primaryButtonLoading = false,
  isMobile = false,
  width,
  height,
  buttonsSx,
  sx,
  titleProps,
}: StyledModalProps) {
  return (
    <Modal open={isOpen} onClose={handleClose} aria-label={ariaLabel}>
      <StyledContainer isMobile={isMobile} width={width} height={height} sx={sx}>
        {isMobile && <StyledMobileNavbar data-testid="mobile-navbar" />}

        <Container
          disableGutters
          sx={{
            height: isMobile ? '100%' : 'fit-content',
            overflow: 'auto',
            marginTop: isMobile ? '56px' : 0,
          }}>
          <ModalTitle isMobile={isMobile} {...titleProps} />
          {children}
        </Container>

        {!hideModalButtons && handlePrimaryButtonClick && primaryButtonTitleId && (
          <ModalButtons
            data-testid="bottom-modal-buttons"
            handleOutlinedButtonClick={handleOutlinedButtonClick}
            outlinedButtonTitleId={outlinedButtonTitleId}
            handlePrimaryButtonClick={handlePrimaryButtonClick}
            primaryButtonTitleId={primaryButtonTitleId}
            primaryButtonDisabled={primaryButtonDisabled}
            shouldGrowPrimaryButton={shouldGrowPrimaryButton}
            outlinedButtonDisabled={outlinedButtonDisabled}
            primaryButtonLoading={primaryButtonLoading}
            primaryButtonEvent={primaryButtonEvent}
            outlinedButtonEvent={outlinedButtonEvent}
            sx={buttonsSx}
          />
        )}

        {handleClose && (
          <StyledCrossIconButton
            isMobile={isMobile}
            onClick={handleClose}
            data-testid="cross-modal-button">
            <CrossIcon fill={isMobile ? 'white' : PRIMARY_MAIN} />
          </StyledCrossIconButton>
        )}
      </StyledContainer>
    </Modal>
  );
}

StyledModal.defaultProps = {
  handleClose: undefined,
  handleOutlinedButtonClick: undefined,
  handlePrimaryButtonClick: undefined,
  hideModalButtons: false,
  primaryButtonDisabled: false,
  outlinedButtonDisabled: false,
  outlinedButtonTitleId: undefined,
  primaryButtonLoading: false,
  primaryButtonTitleId: undefined,
  shouldGrowPrimaryButton: false,
  buttonsSx: {},
  ariaLabel: undefined,
  primaryButtonEvent: undefined,
  outlinedButtonEvent: undefined,
  titleProps: {},
};
