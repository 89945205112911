import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRealEstatePatrimony from '@features/project/components/questions/QuestionRealEstatePatrimony/QuestionRealEstatePatrimony';
import checkRulesRealEstatePatrimony from '@features/project/components/questions/QuestionRealEstatePatrimony/QuestionRealEstatePatrimony.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.REAL_ESTATE_PATRIMONY,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.realEstatePatrimony.title',
  questionSubtitle: 'step1.question.realEstatePatrimony.subtitle',
  cdpEvent: CdpEventName.QUESTION_NET_PROPERTY_PATRIMONY,
  Component: QuestionRealEstatePatrimony,
  payloadKeys: [FunnelAnswersKeys.NET_PROPERTY_PATRIMONY],
  rulesFn: checkRulesRealEstatePatrimony,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  legalEntity: {
    ...baseDescription,
    questionTitle: 'step1.question.realEstatePatrimony.title.legalPerson',
    questionSubtitle: 'step1.question.realEstatePatrimony.subtitle.legalPerson',
  },
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.realEstatePatrimony.subtitle.children',
  },
};
