import NumberFormat from 'react-number-format';

import PackageTypeIcon from '@components/PackageTypeIcon/PackageTypeIcon';
import { ButtonEntry } from '@components/ToggleButtonGroup/ToggleButtonGroup';
import { QUERY_PARAMS } from '@constants/routes.const';
import {
  BusinessReferral,
  FiscalPackage,
  PreferredFiscalPackage,
  ProjectType,
} from '@shared/types';
import getMinimumInitialInvestment from '@utils/minimumInitialInvestment';

type ButtonConfig = ButtonEntry<string> & {
  hasExternalLink?: boolean;
  subtitleValues?: Record<string, string | number | JSX.Element>;
  onClick: (
    callback: ({
      preferredEnvelope,
      preferredTransfer,
      project,
      additionalSearchParams,
    }: {
      preferredEnvelope?: PreferredFiscalPackage;
      preferredTransfer?: boolean;
      project?: ProjectType;
      additionalSearchParams?: Record<string, string>;
    }) => void,
  ) => void;
};

type GetButtonsList = (obj: {
  hasActiveLifeInsurance?: boolean;
  businessReferral?: BusinessReferral;
}) => Record<string, ButtonConfig[]>;

export const getButtonsList: GetButtonsList = ({ hasActiveLifeInsurance, businessReferral }) => {
  const BUTTONS_CONFIG: Record<string, ButtonConfig> = {
    [FiscalPackage.LIFE_INSURANCE]: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.LIFE_INSURANCE} />,
      value: FiscalPackage.LIFE_INSURANCE,
      titleId: 'productFunnel.question.fiscalPackage.button.assurance-vie',
      subtitleId: 'productFunnel.question.fiscalPackage.subtitle',
      subtitleValues: (() => {
        const res = getMinimumInitialInvestment({
          fiscalPackage: FiscalPackage.LIFE_INSURANCE,
          projectType: ProjectType.SAVINGS,
          hasActiveLifeInsurance,
          businessReferral,
        });

        return {
          amount: <NumberFormat displayType="text" thousandSeparator=" " value={res.value} />,
        };
      })(),
      onClick: (callback) =>
        callback({ preferredEnvelope: FiscalPackage.LIFE_INSURANCE, project: ProjectType.SAVINGS }),
    },
    [FiscalPackage.STOCK_SAVINGS_PLAN]: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.STOCK_SAVINGS_PLAN} />,
      value: FiscalPackage.STOCK_SAVINGS_PLAN,
      titleId: 'productFunnel.question.fiscalPackage.button.pea',
      subtitleId: 'productFunnel.question.fiscalPackage.subtitle',
      subtitleValues: (() => {
        const res = getMinimumInitialInvestment({
          fiscalPackage: FiscalPackage.STOCK_SAVINGS_PLAN,
          hasActiveLifeInsurance,
          businessReferral,
        });

        return {
          amount: <NumberFormat displayType="text" thousandSeparator=" " value={res.value} />,
        };
      })(),
      onClick: (callback) => callback({ preferredEnvelope: FiscalPackage.STOCK_SAVINGS_PLAN }),
    },
    [FiscalPackage.TRADING_ACCOUNT]: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.TRADING_ACCOUNT} />,
      value: FiscalPackage.TRADING_ACCOUNT,
      titleId: 'productFunnel.question.fiscalPackage.button.compte-titres',
      subtitleId: 'productFunnel.question.fiscalPackage.subtitle',
      subtitleValues: (() => {
        const res = getMinimumInitialInvestment({
          fiscalPackage: FiscalPackage.STOCK_SAVINGS_PLAN,
          hasActiveLifeInsurance,
          businessReferral,
        });

        return {
          amount: <NumberFormat displayType="text" thousandSeparator=" " value={res.value} />,
        };
      })(),
      onClick: (callback) =>
        callback({
          preferredEnvelope: FiscalPackage.TRADING_ACCOUNT,
          project: ProjectType.SAVINGS,
        }),
    },
    [FiscalPackage.RETIREMENT_SAVING_PLAN]: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.RETIREMENT_SAVING_PLAN} />,
      value: FiscalPackage.RETIREMENT_SAVING_PLAN,
      titleId: 'productFunnel.question.fiscalPackage.button.per',
      subtitleId: 'productFunnel.question.fiscalPackage.subtitle',
      subtitleValues: (() => {
        const res = getMinimumInitialInvestment({
          fiscalPackage: FiscalPackage.RETIREMENT_SAVING_PLAN,
          hasActiveLifeInsurance,
          businessReferral,
        });

        return {
          amount: <NumberFormat displayType="text" thousandSeparator=" " value={res.value} />,
        };
      })(),
      hasExternalLink: true,
      onClick: (callback) => {
        callback({});
      },
    },
    TRADING_ACCOUNT_BUSINESS: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.TRADING_ACCOUNT} />,
      value: 'TRADING_ACCOUNT_BUSINESS',
      titleId: 'productFunnel.question.fiscalPackage.button.cto-pm',
      onClick: (callback) =>
        callback({
          preferredEnvelope: FiscalPackage.TRADING_ACCOUNT,
          project: ProjectType.LEGAL_ENTITY,
          additionalSearchParams: {
            [QUERY_PARAMS.IS_PROFESSIONAL.key]: QUERY_PARAMS.IS_PROFESSIONAL.values.TRUE,
          },
        }),
    },
    RETIREMENT_SAVING_PLAN_BUSINESS: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.RETIREMENT_SAVING_PLAN} />,
      value: 'RETIREMENT_SAVING_PLAN_BUSINESS',
      titleId: 'productFunnel.question.fiscalPackage.button.per-pm',
      hasExternalLink: true,
      onClick: (callback) => {
        callback({ additionalSearchParams: { estProfessionnel: 'true' } });
      },
    },
    RETIREMENT_SAVING_PLAN_PREFON: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.RETIREMENT_SAVING_PLAN} />,
      value: 'RETIREMENT_SAVING_PLAN_PREFON',
      titleId: 'productFunnel.question.fiscalPackage.button.per-prefon',
      hasExternalLink: true,
      onClick: (callback) => {
        callback({});
      },
    },
    RETIREMENT_SAVING_PLAN_POPULAR: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.RETIREMENT_SAVING_PLAN} />,
      value: 'RETIREMENT_SAVING_PLAN_POPULAR',
      titleId: 'productFunnel.question.fiscalPackage.button.per-populaire',
      hasExternalLink: true,
      onClick: (callback) => {
        callback({});
      },
    },
    RETIREMENT_SAVING_PLAN_MADELIN: {
      StartIcon: () => <PackageTypeIcon fiscalPackage={FiscalPackage.RETIREMENT_SAVING_PLAN} />,
      value: 'RETIREMENT_SAVING_PLAN_MADELIN',
      titleId: 'productFunnel.question.fiscalPackage.button.per-madelin',
      hasExternalLink: true,
      onClick: (callback) => {
        callback({});
      },
    },
  };

  const buttonsConfig = {
    [QUERY_PARAMS.RECIPIENT.values.PERSONAL]: [
      BUTTONS_CONFIG[FiscalPackage.LIFE_INSURANCE],
      BUTTONS_CONFIG[FiscalPackage.STOCK_SAVINGS_PLAN],
      BUTTONS_CONFIG[FiscalPackage.TRADING_ACCOUNT],
      BUTTONS_CONFIG[FiscalPackage.RETIREMENT_SAVING_PLAN],
    ],
    [QUERY_PARAMS.RECIPIENT.values.BUSINESS]: [
      BUTTONS_CONFIG.TRADING_ACCOUNT_BUSINESS,
      BUTTONS_CONFIG.RETIREMENT_SAVING_PLAN_BUSINESS,
    ],
    TRANSFER: [
      BUTTONS_CONFIG[FiscalPackage.STOCK_SAVINGS_PLAN],
      BUTTONS_CONFIG[FiscalPackage.RETIREMENT_SAVING_PLAN],
      BUTTONS_CONFIG.RETIREMENT_SAVING_PLAN_PREFON,
      BUTTONS_CONFIG.RETIREMENT_SAVING_PLAN_POPULAR,
      BUTTONS_CONFIG.RETIREMENT_SAVING_PLAN_MADELIN,
    ],
  };

  return buttonsConfig;
};

export default { getButtonsList };
