import { AtomEffect, DefaultValue, RecoilState, SetRecoilState, atom, selector } from 'recoil';

import {
  AnnualIncome,
  ChildrenNumber,
  EsgPreference,
  FinancialLosses,
  FunnelAnswersKeys,
  FunnelAnswers,
  LossReaction,
  PrimaryResidence,
  ProjectType,
  RiskReward,
  RiskRewardPercentage,
  SavingsCapacity,
  SavingsProducts,
  TaxResidency,
  TernaryAnswer,
  Thematic,
  WithdrawBefore2Years,
  WithdrawHalfProbability,
} from '@shared/types';

const localStorageEffect =
  (key: string): AtomEffect<any> =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: any) => {
      if (isReset) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

const datalayerEffect =
  (key: string): AtomEffect<any> =>
  ({ onSet }) => {
    onSet((newValue: any) => {
      window.dataLayer.push({ [key]: newValue });
    });
  };

export const projectTypeQuestionState = atom<ProjectType>({
  key: 'projectTypeQuestionState',
  default: undefined,
  effects: [localStorageEffect('projectTypeQuestionState')],
});
export const initialPaymentQuestionState = atom<number>({
  key: 'initialPaymentQuestionState',
  default: undefined,
  effects: [localStorageEffect('initialPaymentQuestionState')],
});
export const recurringPaymentQuestionState = atom<number>({
  key: 'recurringPaymentQuestionState',
  default: undefined,
  effects: [localStorageEffect('recurringPaymentQuestionState')],
});
export const horizonQuestionState = atom<number>({
  key: 'horizonQuestionState',
  default: undefined,
  effects: [localStorageEffect('horizonQuestionState')],
});
export const birthdateQuestionState = atom<string>({
  key: 'birthdateQuestionState',
  default: undefined,
  effects: [localStorageEffect('birthdateQuestionState')],
});
export const birthdateChildrenQuestionState = atom<string>({
  key: 'birthdateChildrenQuestionState',
  default: undefined,
  effects: [localStorageEffect('birthdateChildrenQuestionState')],
});
export const taxResidenceQuestionState = atom<TaxResidency>({
  key: 'taxResidenceQuestionState',
  default: undefined,
  effects: [localStorageEffect('taxResidenceQuestionState')],
});
export const responsibleInvestmentQuestionState = atom<EsgPreference>({
  key: 'responsibleInvestmentQuestionState',
  default: undefined,
  effects: [localStorageEffect('responsibleInvestmentQuestionState')],
});
export const nbChildrenQuestionState = atom<ChildrenNumber>({
  key: 'nbChildrenQuestionState',
  default: undefined,
  effects: [localStorageEffect('nbChildrenQuestionState')],
});
export const grossAnnualIncomeRangeQuestionState = atom<AnnualIncome>({
  key: 'grossAnnualIncomeRangeQuestionState',
  default: undefined,
  effects: [localStorageEffect('grossAnnualIncomeRangeQuestionState')],
});
export const grossAnnualIncomeQuestionState = atom<number>({
  key: 'grossAnnualIncomeQuestionState',
  default: undefined,
  effects: [localStorageEffect('grossAnnualIncomeQuestionState')],
});
export const ownerMainResidenceQuestionState = atom<PrimaryResidence>({
  key: 'ownerMainResidenceQuestionState',
  default: undefined,
  effects: [localStorageEffect('ownerMainResidenceQuestionState')],
});
export const creditOrRentMainResidenceQuestionState = atom<number | undefined>({
  key: 'creditOrRentMainResidenceQuestionState',
  default: undefined,
  effects: [localStorageEffect('creditOrRentMainResidenceQuestionState')],
});
export const netPropertyPatrimonyQuestionState = atom<number>({
  key: 'netPropertyPatrimonyQuestionState',
  default: undefined,
  effects: [localStorageEffect('netPropertyPatrimonyQuestionState')],
});
export const financialPatrimonyQuestionState = atom<number>({
  key: 'financialPatrimonyQuestionState',
  default: undefined,
  effects: [
    localStorageEffect('financialPatrimonyQuestionState'),
    datalayerEffect('financialAssets'),
  ],
});
export const savingsCapacityQuestionState = atom<SavingsCapacity>({
  key: 'savingsCapacityQuestionState',
  default: undefined,
  effects: [localStorageEffect('savingsCapacityQuestionState')],
});
export const probabilityOfWithdrawalWithinTwoYearsQuestionState = atom<WithdrawBefore2Years>({
  key: 'probabilityOfWithdrawalWithinTwoYearsQuestionState',
  default: undefined,
  effects: [localStorageEffect('probabilityOfWithdrawalWithinTwoYearsQuestionState')],
});
export const probabilityOfWithdrawalHalfQuestionState = atom<WithdrawHalfProbability>({
  key: 'probabilityOfWithdrawalHalfQuestionState',
  default: undefined,
  effects: [localStorageEffect('probabilityOfWithdrawalHalfQuestionState')],
});
export const savingsProductsQuestionState = atom<SavingsProducts>({
  key: 'savingsProductsQuestionState',
  default: undefined,
  effects: [localStorageEffect('savingsProductsQuestionState')],
});
export const earningsPotentialQuizQuestionState = atom<TernaryAnswer>({
  key: 'earningsPotentialQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('earningsPotentialQuizQuestionState')],
});
export const etfKnowledgeQuizQuestionState = atom<TernaryAnswer>({
  key: 'etfKnowledgeQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('etfKnowledgeQuizQuestionState')],
});
export const companyDelegationQuizQuestionState = atom<TernaryAnswer>({
  key: 'companyDelegationQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('companyDelegationQuizQuestionState')],
});
export const realEstateInvestmentQuizQuestionState = atom<TernaryAnswer>({
  key: 'realEstateInvestmentQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('realEstateInvestmentQuizQuestionState')],
});
export const lossOfRealEstateValueQuizQuestionState = atom<TernaryAnswer>({
  key: 'lossOfRealEstateValueQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('lossOfRealEstateValueQuizQuestionState')],
});
export const lossOnFinancialInvestmentsQuizQuestionState = atom<FinancialLosses>({
  key: 'lossOnFinancialInvestmentsQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('lossOnFinancialInvestmentsQuizQuestionState')],
});
export const amountWinLossRatioQuizQuestionState = atom<RiskReward>({
  key: 'amountWinLossRatioQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('amountWinLossRatioQuizQuestionState')],
});
export const percentageWinLossRatioQuizQuestionState = atom<RiskRewardPercentage>({
  key: 'percentageWinLossRatioQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('percentageWinLossRatioQuizQuestionState')],
});
export const tenPercentLossQuizQuestionState = atom<LossReaction>({
  key: 'tenPercentLossQuizQuestionState',
  default: undefined,
  effects: [localStorageEffect('tenPercentLossQuizQuestionState')],
});
export const thematicQuestionState = atom<Thematic>({
  key: 'thematicQuestionState',
  default: undefined,
  effects: [localStorageEffect('thematicQuestionState')],
});

function customSetWrapper(set: SetRecoilState) {
  return function customSet<T>(
    recoilStatevalue: RecoilState<T>,
    value?: T | DefaultValue | ((prevValue: T) => T | DefaultValue),
  ) {
    if (value !== undefined) {
      set(recoilStatevalue, value);
    }
  };
}

export const questionsSelector = selector<Partial<FunnelAnswers>>({
  key: 'questionsSelector',
  get: ({ get }) => ({
    DefaultValue,
    [FunnelAnswersKeys.PROJECT_TYPE]: get(projectTypeQuestionState),
    [FunnelAnswersKeys.INITIAL_PAYMENT]: get(initialPaymentQuestionState),
    [FunnelAnswersKeys.RECURRING_PAYMENT]: get(recurringPaymentQuestionState),
    [FunnelAnswersKeys.HORIZON]: get(horizonQuestionState),
    [FunnelAnswersKeys.BIRTHDATE]: get(birthdateQuestionState),
    [FunnelAnswersKeys.BIRTHDATE_KID]: get(birthdateChildrenQuestionState),
    [FunnelAnswersKeys.TAX_RESIDENCE]: get(taxResidenceQuestionState),
    [FunnelAnswersKeys.RESPONSIBLE_INVESTMENT]: get(responsibleInvestmentQuestionState),
    [FunnelAnswersKeys.NB_CHILDREN]: get(nbChildrenQuestionState),
    [FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE]: get(grossAnnualIncomeRangeQuestionState),
    [FunnelAnswersKeys.GROSS_ANNUAL_INCOME]: get(grossAnnualIncomeQuestionState),
    [FunnelAnswersKeys.OWNER_MAIN_RESIDENCE]: get(ownerMainResidenceQuestionState),
    [FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE]: get(creditOrRentMainResidenceQuestionState),
    [FunnelAnswersKeys.NET_PROPERTY_PATRIMONY]: get(netPropertyPatrimonyQuestionState),
    [FunnelAnswersKeys.FINANCIAL_PATRIMONY]: get(financialPatrimonyQuestionState),
    [FunnelAnswersKeys.SAVINGS_CAPACITY]: get(savingsCapacityQuestionState),
    [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS]: get(
      probabilityOfWithdrawalWithinTwoYearsQuestionState,
    ),
    [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF]: get(
      probabilityOfWithdrawalHalfQuestionState,
    ),
    [FunnelAnswersKeys.SAVINGS_PRODUCTS]: get(savingsProductsQuestionState),
    [FunnelAnswersKeys.EARNINGS_POTENTIAL_QUIZ]: get(earningsPotentialQuizQuestionState),
    [FunnelAnswersKeys.ETF_KNOWLEDGE_QUIZ]: get(etfKnowledgeQuizQuestionState),
    [FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ]: get(companyDelegationQuizQuestionState),
    [FunnelAnswersKeys.REAL_ESTATE_INVESTMENT_QUIZ]: get(realEstateInvestmentQuizQuestionState),
    [FunnelAnswersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ]: get(lossOfRealEstateValueQuizQuestionState),
    [FunnelAnswersKeys.LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ]: get(
      lossOnFinancialInvestmentsQuizQuestionState,
    ),
    [FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ]: get(amountWinLossRatioQuizQuestionState),
    [FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ]: get(
      percentageWinLossRatioQuizQuestionState,
    ),
    [FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ]: get(tenPercentLossQuizQuestionState),
    [FunnelAnswersKeys.THEMATIC]: get(thematicQuestionState),
  }),
  set: ({ reset, set }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(projectTypeQuestionState);
      reset(initialPaymentQuestionState);
      reset(recurringPaymentQuestionState);
      reset(horizonQuestionState);
      reset(birthdateQuestionState);
      reset(birthdateChildrenQuestionState);
      reset(taxResidenceQuestionState);
      reset(responsibleInvestmentQuestionState);
      reset(nbChildrenQuestionState);
      reset(grossAnnualIncomeRangeQuestionState);
      reset(grossAnnualIncomeQuestionState);
      reset(ownerMainResidenceQuestionState);
      reset(creditOrRentMainResidenceQuestionState);
      reset(netPropertyPatrimonyQuestionState);
      reset(financialPatrimonyQuestionState);
      reset(savingsCapacityQuestionState);
      reset(probabilityOfWithdrawalWithinTwoYearsQuestionState);
      reset(probabilityOfWithdrawalHalfQuestionState);
      reset(savingsProductsQuestionState);
      reset(earningsPotentialQuizQuestionState);
      reset(etfKnowledgeQuizQuestionState);
      reset(companyDelegationQuizQuestionState);
      reset(realEstateInvestmentQuizQuestionState);
      reset(lossOfRealEstateValueQuizQuestionState);
      reset(lossOnFinancialInvestmentsQuizQuestionState);
      reset(amountWinLossRatioQuizQuestionState);
      reset(percentageWinLossRatioQuizQuestionState);
      reset(tenPercentLossQuizQuestionState);
      reset(thematicQuestionState);
    } else {
      const customSet = customSetWrapper(set);

      customSet(projectTypeQuestionState, newValue[FunnelAnswersKeys.PROJECT_TYPE]);
      customSet(initialPaymentQuestionState, newValue[FunnelAnswersKeys.INITIAL_PAYMENT]);
      customSet(recurringPaymentQuestionState, newValue[FunnelAnswersKeys.RECURRING_PAYMENT]);
      customSet(horizonQuestionState, newValue[FunnelAnswersKeys.HORIZON]);
      customSet(birthdateQuestionState, newValue[FunnelAnswersKeys.BIRTHDATE]);
      customSet(birthdateChildrenQuestionState, newValue[FunnelAnswersKeys.BIRTHDATE_KID]);
      customSet(taxResidenceQuestionState, newValue[FunnelAnswersKeys.TAX_RESIDENCE]);
      customSet(
        responsibleInvestmentQuestionState,
        newValue[FunnelAnswersKeys.RESPONSIBLE_INVESTMENT],
      );
      customSet(nbChildrenQuestionState, newValue[FunnelAnswersKeys.NB_CHILDREN]);
      customSet(
        grossAnnualIncomeRangeQuestionState,
        newValue[FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE],
      );
      customSet(grossAnnualIncomeQuestionState, newValue[FunnelAnswersKeys.GROSS_ANNUAL_INCOME]);
      customSet(ownerMainResidenceQuestionState, newValue[FunnelAnswersKeys.OWNER_MAIN_RESIDENCE]);
      customSet(
        creditOrRentMainResidenceQuestionState,
        newValue[FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE],
      );
      customSet(
        netPropertyPatrimonyQuestionState,
        newValue[FunnelAnswersKeys.NET_PROPERTY_PATRIMONY],
      );
      customSet(financialPatrimonyQuestionState, newValue[FunnelAnswersKeys.FINANCIAL_PATRIMONY]);
      customSet(savingsCapacityQuestionState, newValue[FunnelAnswersKeys.SAVINGS_CAPACITY]);
      customSet(
        probabilityOfWithdrawalWithinTwoYearsQuestionState,
        newValue[FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS],
      );
      customSet(
        probabilityOfWithdrawalHalfQuestionState,
        newValue[FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF],
      );
      customSet(savingsProductsQuestionState, newValue[FunnelAnswersKeys.SAVINGS_PRODUCTS]);
      customSet(
        earningsPotentialQuizQuestionState,
        newValue[FunnelAnswersKeys.EARNINGS_POTENTIAL_QUIZ],
      );
      customSet(etfKnowledgeQuizQuestionState, newValue[FunnelAnswersKeys.ETF_KNOWLEDGE_QUIZ]);
      customSet(
        companyDelegationQuizQuestionState,
        newValue[FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ],
      );
      customSet(
        realEstateInvestmentQuizQuestionState,
        newValue[FunnelAnswersKeys.REAL_ESTATE_INVESTMENT_QUIZ],
      );
      customSet(
        lossOfRealEstateValueQuizQuestionState,
        newValue[FunnelAnswersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ],
      );
      customSet(
        lossOnFinancialInvestmentsQuizQuestionState,
        newValue[FunnelAnswersKeys.LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ],
      );
      customSet(
        amountWinLossRatioQuizQuestionState,
        newValue[FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ],
      );
      customSet(
        percentageWinLossRatioQuizQuestionState,
        newValue[FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ],
      );
      customSet(tenPercentLossQuizQuestionState, newValue[FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ]);
      customSet(thematicQuestionState, newValue[FunnelAnswersKeys.THEMATIC]);
    }
  },
});

// LAYOUT STATES

export const questionLayoutTitleState = atom<string | undefined>({
  key: 'questionLayoutTitleState',
  default: undefined,
});

export const questionLayoutSubtitleState = atom<string | undefined>({
  key: 'questionLayoutSubtitleState',
  default: undefined,
});
