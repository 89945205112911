import { Avatar, Box, SxProps, Theme, Typography, useTheme } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PrimitiveType } from 'intl-messageformat';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as WarningTriangle } from '@assets/icons/error_triangle.svg';
import { ReactComponent as Information } from '@assets/icons/information.svg';
import IconBadge from '@components/IconBadge/IconBadge';
import useDimensions from '@hooks/useDimensions';
import {
  ERROR_DARK,
  ERROR_LIGHT,
  GRAY_LIGHT,
  INFO_SUCCESS_DARK,
  INFO_SUCCESS_LIGHT,
  PRIMARY_MAIN,
  WARNING_DARK,
  WARNING_LIGHT,
  WHITE,
} from '@theme';
import { boldText, externalLink, externalLinkInSameTab } from '@utils/formatted-message.utils';

export enum InfoBoxLevel {
  INFO,
  WARNING,
  ERROR,
  SUCCESS,
}

interface InfoBoxProps {
  title?: string;
  description: string;
  type: InfoBoxLevel;
  imageSrc?: string | undefined;
  sx?: SxProps<Theme>;
  onClickLink?: string;
  openLinkInNewTab?: boolean;
  messageValues?: Record<string, PrimitiveType>;
}

function getColors(level: InfoBoxLevel): { backgroundColor: string; textColor: string } {
  const { isMobile } = useDimensions();

  switch (level) {
    case InfoBoxLevel.WARNING:
      return {
        backgroundColor: WARNING_LIGHT,
        textColor: WARNING_DARK,
      };
    case InfoBoxLevel.ERROR:
      return {
        backgroundColor: ERROR_LIGHT,
        textColor: ERROR_DARK,
      };
    case InfoBoxLevel.SUCCESS:
      return {
        backgroundColor: INFO_SUCCESS_LIGHT,
        textColor: INFO_SUCCESS_DARK,
      };
    default:
      return {
        backgroundColor: isMobile ? WHITE : GRAY_LIGHT,
        textColor: PRIMARY_MAIN,
      };
  }
}

const renderIcon = (type: InfoBoxLevel, imageSrc?: string) => {
  if (type === InfoBoxLevel.ERROR) {
    return (
      <IconBadge sx={{ height: '2.5rem', minWidth: '2.5rem', maxWidth: '2.5rem' }}>
        <WarningTriangle stroke={ERROR_DARK} width="1.5rem" height="1.5rem" />
      </IconBadge>
    );
  }

  if (type === InfoBoxLevel.WARNING) {
    return (
      <IconBadge sx={{ height: '2.5rem', minWidth: '2.5rem', maxWidth: '2.5rem' }}>
        <Information stroke={WARNING_DARK} width="1.5rem" height="1.5rem" />
      </IconBadge>
    );
  }

  if (imageSrc) {
    return <Avatar alt="avatar" src={imageSrc} />;
  }

  return null;
};

export default function InfoBox({
  type,
  title,
  description,
  imageSrc,
  sx,
  onClickLink,
  openLinkInNewTab,
  messageValues: baseMessageValues,
}: InfoBoxProps) {
  const theme = useTheme();
  const colors = getColors(type);
  const intl = useIntl();

  // Format numbers with spaces
  const updatedMessageValues = { ...baseMessageValues };
  if (baseMessageValues) {
    Object.keys(baseMessageValues).forEach((key) => {
      if (typeof baseMessageValues[key] === 'number') {
        const value = updatedMessageValues[key];
        updatedMessageValues[key] = intl.formatNumber(value as number);
      }
    });
  }

  const messageValues = {
    link: onClickLink
      ? (text: string) =>
          openLinkInNewTab
            ? externalLink(text, onClickLink, '_blank')
            : externalLinkInSameTab(text, onClickLink)
      : undefined,
    br: <br />,
    b: (text: string) => boldText(text),
    ...updatedMessageValues,
  };

  return (
    <Box
      id={title}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: theme.spacing(1),
        padding: theme.spacing(2),
        gap: theme.spacing(2),
        backgroundColor: colors.backgroundColor,
        ...sx,
      }}>
      {renderIcon(type, imageSrc)}
      <Box>
        {title && (
          <Box mb={1}>
            <Typography variant="subtitle1" color={colors.textColor}>
              <FormattedMessage values={messageValues} id={title} />
            </Typography>
          </Box>
        )}
        <Typography variant="md" color={colors.textColor}>
          <FormattedMessage values={messageValues} id={description} />
        </Typography>
      </Box>
    </Box>
  );
}

InfoBox.defaultProps = {
  title: undefined,
  imageSrc: undefined,
  sx: undefined,
  onClickLink: undefined,
  openLinkInNewTab: false,
  messageValues: {},
};
