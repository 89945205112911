import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionRealEstateValueLoss from '@features/project/components/questions/QuestionRealEstateValueLoss/QuestionRealEstateValueLoss.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import { BUTTONS_TERNARY_ANSWER } from '@features/project/question.utils';
import { TernaryAnswer } from '@shared/types';
import { lossOfRealEstateValueQuizQuestionState, questionsSelector } from '@state';

export default function QuestionRealEstateValueLoss() {
  const { updateContext } = useContext(QuestionContext);

  const questionState = useRecoilValue(questionsSelector);
  const setLossOfRealEstateValueQuizQuestionState = useSetRecoilState(
    lossOfRealEstateValueQuizQuestionState,
  );

  useEffect(() => {
    updateContext({
      ...checkRulesQuestionRealEstateValueLoss({ questionState }),
    });
  }, []);

  const onClick = (newTernaryAnswer: TernaryAnswer) => {
    setLossOfRealEstateValueQuizQuestionState(newTernaryAnswer);
    updateContext(
      checkRulesQuestionRealEstateValueLoss({
        questionState: { ...questionState, lossOfRealEstateValueQuiz: newTernaryAnswer },
      }),
    );
  };

  return (
    <ToggleButtonGroup
      disabled={questionState.lossOfRealEstateValueQuiz !== undefined}
      buttons={BUTTONS_TERNARY_ANSWER}
      selectedValue={questionState.lossOfRealEstateValueQuiz}
      onChange={onClick}
    />
  );
}
