import { Box, styled } from '@mui/system';

const Bubble = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '21px',
  height: '21px',
  borderWidth: '1px',
  borderRadius: '100%',
  borderStyle: 'solid',
  transition: 'border 0.3s ease, background-color 0.3s ease',
}));

export default Bubble;
