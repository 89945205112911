import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionBirthday from '@features/project/components/questions/QuestionBirthday/QuestionBirthday';
import checkRulesQuestionBirthdate from '@features/project/components/questions/QuestionBirthday/QuestionBirthday.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.BIRTH_DATE,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.birthday.title',
  questionSubtitle: 'step1.question.birthday.subtitle',
  cdpEvent: CdpEventName.QUESTION_BIRTHDATE,
  Component: QuestionBirthday,
  payloadKeys: [FunnelAnswersKeys.BIRTHDATE],
  rulesFn: checkRulesQuestionBirthdate,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  legalEntity: { ...baseDescription, questionSubtitle: undefined },
};
