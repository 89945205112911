import { Box } from '@mui/material';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionRiskRewardPercentage from '@features/project/components/questions/QuestionRiskRewardPercentage/QuestionRiskRewardPercentage.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import useTryNewWording from '@hooks/useTryNewWording';
import { FunnelAnswersKeys, RiskRewardPercentage } from '@shared/types';

const buttons = [
  {
    value: RiskRewardPercentage.GAIN_20_PERTE_5,
    titleId: 'step1.question.risk-reward-percentage.button.20-percent-gain-5-percent-loss',
  },
  {
    value: RiskRewardPercentage.GAIN_30_PERTE_10,
    titleId: 'step1.question.risk-reward-percentage.button.30-percent-gain-10-percent-loss',
  },
  {
    value: RiskRewardPercentage.GAIN_50_PERTE_15,
    titleId: 'step1.question.risk-reward-percentage.button.50-percent-gain-15-percent-loss',
  },
  {
    value: RiskRewardPercentage.GAIN_70_PERTE_15,
    titleId: 'step1.question.risk-reward-percentage.button.70-percent-gain-more-15-percent-loss',
  },
];

const newWordingButtons = [
  {
    value: RiskRewardPercentage.GAIN_20_PERTE_5,
    titleId: 'step1.question.risk-reward-percentage.button.20-percent-gain-5-percent-loss.abtest',
  },
  {
    value: RiskRewardPercentage.GAIN_30_PERTE_10,
    titleId: 'step1.question.risk-reward-percentage.button.30-percent-gain-10-percent-loss.abtest',
  },
  {
    value: RiskRewardPercentage.GAIN_50_PERTE_15,
    titleId: 'step1.question.risk-reward-percentage.button.50-percent-gain-15-percent-loss.abtest',
  },
  {
    value: RiskRewardPercentage.GAIN_70_PERTE_15,
    titleId:
      'step1.question.risk-reward-percentage.button.70-percent-gain-more-15-percent-loss.abtest',
  },
];

export default function QuestionRiskRewardPercentage() {
  const tryNewWording = useTryNewWording();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ,
    checkRulesQuestionRiskRewardPercentage,
  );
  usePreferredChoice({ translationQuestionPrefix: 'step1.question.risk-reward-percentage' });

  const onChange = (newRiskRewardPercentage: RiskRewardPercentage) =>
    update(newRiskRewardPercentage, { autoValidate: true });

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={tryNewWording ? newWordingButtons : buttons}
        selectedValue={answer}
        onChange={onChange}
        containerProps={{ sx: { maxWidth: '640px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
