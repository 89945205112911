import { Box } from '@mui/material';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionLossReaction from '@features/project/components/questions/QuestionLossReaction/QuestionLossReaction.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { FunnelAnswersKeys, LossReaction } from '@shared/types';

export default function QuestionLossReaction() {
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ,
    checkRulesQuestionLossReaction,
  );
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.loss-reaction',
  });

  const buttons = [
    {
      value: LossReaction.REINVESTIS,
      titleId: 'step1.question.loss-reaction.button.invest',
    },
    {
      value: LossReaction.ATTENTE,
      titleId: 'step1.question.loss-reaction.button.wait',
    },
    {
      value: LossReaction.VENTE_PARTIELLE,
      titleId: 'step1.question.loss-reaction.button.partial-sell',
    },
    {
      value: LossReaction.VENTE_TOTALE,
      titleId: 'step1.question.loss-reaction.button.sell-all',
    },
    {
      value: LossReaction.INCERTAIN,
      titleId: 'step1.question.loss-reaction.button.do-not-know',
    },
  ];

  const onChange = (newLossReaction: LossReaction) =>
    update(newLossReaction, { autoValidate: true });

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={answer}
        onChange={onChange}
        containerProps={{ sx: { maxWidth: '490px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
