import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { AnnualIncome, SavingsCapacity, getSavingsCapacityValue } from '@shared/types';

export default function checkRulesQuestionSavingsCapacity(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.savingsCapacity) {
    isValid = false;
  }

  if (displayMonthlyInvestmentWarn(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.savings-capacity.warn.monthly-investment',
      infoBoxLevel: InfoBoxLevel.WARNING,
    });
  }

  if (displayMonthlyIncomeWarn(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.savings-capacity.warn.monthly-income',
      infoBoxLevel: InfoBoxLevel.WARNING,
    });
  }

  if (displayFinancialPatrimonyWarn(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.savings-capacity.warn.financial-patrimony',
      infoBoxLevel: InfoBoxLevel.WARNING,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

function getAnnualIncomeValue(annualIncomeRange: AnnualIncome, annualIncome?: number) {
  // eslint-disable-next-line default-case
  switch (annualIncomeRange) {
    case AnnualIncome.BETWEEN_30_000_AND_45_000:
      return 30000;
    case AnnualIncome.BETWEEN_45_000_AND_60_000:
      return 45000;
    case AnnualIncome.BETWEEN_60_000_AND_100_000:
      return 60000;
    case AnnualIncome.BETWEEN_100_000_AND_150_000:
      return 100000;
  }

  if (annualIncome) {
    return annualIncome;
  }

  return undefined;
}

function getSavingsCapacityValueTopRange(savingsCapacity?: SavingsCapacity) {
  // eslint-disable-next-line default-case
  switch (savingsCapacity) {
    case SavingsCapacity.LESS_THAN_250:
      return 250;
    case SavingsCapacity.BETWEEN_250_AND_500:
      return 500;
    case SavingsCapacity.BETWEEN_500_AND_1000:
      return 1000;
    case SavingsCapacity.BETWEEN_1000_AND_2500:
      return 2500;
    case SavingsCapacity.MORE_THAN_2500:
      return 100000000;
  }
  return undefined;
}

/**
 * Q13-RG2
 */
function displayMonthlyInvestmentWarn(ruleParams: QuestionRuleParams) {
  const savingsCapacityValue = getSavingsCapacityValueTopRange(
    ruleParams.questionState.savingsCapacity,
  );
  const { recurringPayment } = ruleParams.questionState;

  return savingsCapacityValue && recurringPayment! >= savingsCapacityValue;
}

/**
 * Q13-RG4
 */
function displayMonthlyIncomeWarn(ruleParams: QuestionRuleParams) {
  const savingsCapacityValue = getSavingsCapacityValue(ruleParams.questionState.savingsCapacity);
  const annualIncomeValue = getAnnualIncomeValue(
    ruleParams.questionState.grossAnnualIncomeRange!,
    ruleParams.questionState.grossAnnualIncome,
  );
  const { creditOrRentMainResidence } = ruleParams.questionState;

  return (
    savingsCapacityValue &&
    annualIncomeValue &&
    savingsCapacityValue > annualIncomeValue / 12 - creditOrRentMainResidence!
  );
}

/**
 * Q13-RG5
 */
function displayFinancialPatrimonyWarn(ruleParams: QuestionRuleParams) {
  const { financialPatrimony, savingsCapacity } = ruleParams.questionState;
  const savingsCapacityValue = getSavingsCapacityValue(savingsCapacity);

  return savingsCapacityValue && savingsCapacityValue > financialPatrimony!;
}
