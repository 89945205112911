import { atom } from 'recoil';

export const emailState = atom<string>({
  key: 'emailState',
  default: undefined,
});

export const sponsorCodeState = atom<string>({
  key: 'sponsorCodeState',
  default: undefined,
});

export const externalTokenState = atom<string>({
  key: 'externalTokenState',
  default: undefined,
});
