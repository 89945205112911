import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import StyledModal from '@components/StyledModal/StyledModal';

interface LoadingModalProps {
  isOpen: boolean;
  titleId: string;
  messageId: string;
}

export default function LoadingModal({ isOpen, titleId, messageId }: LoadingModalProps) {
  return (
    <StyledModal isOpen={isOpen}>
      <Box display="flex" flexDirection="column">
        <Typography variant="md" align="center" mb={3}>
          <FormattedMessage id={titleId} />
        </Typography>
        <Typography variant="md" fontWeight="fontWeightLight" align="center">
          <FormattedMessage id={messageId} />
        </Typography>
      </Box>
    </StyledModal>
  );
}
