import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FunnelAnswersKeys, ProjectType, SavingsProducts } from '@shared/types';

export default function checkRulesQuestionSavingsProducts(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.savingsProducts) {
    isValid = false;
  }

  if (hasInconsistentlyAnswered(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.savings-products.inconsistentAnswer',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (shouldDisplayInfoPea(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.savings-products.info.pea',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q17-RG1
 */
function shouldDisplayInfoPea(params: QuestionRuleParams) {
  return params.questionState.projectType !== ProjectType.LEGAL_ENTITY;
}

/**
 * Q17-RG2
 */
function hasInconsistentlyAnswered(params: QuestionRuleParams) {
  return (
    params.preferredTransfer === true &&
    params.questionState[FunnelAnswersKeys.SAVINGS_PRODUCTS] === SavingsProducts.NOT_OWNER
  );
}
