import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';

import ButtonAsLink from '@components/links/ButtonAsLink/ButtonAsLink';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import { YOMONI_JWT_TOKEN_NAME } from '@constants/app.const';
import OtpField from '@features/emailsubmission/components/otpField/OtpField';
import useTracking from '@hooks/tracking/useTracking';
import useSubmitStep1 from '@hooks/useSubmitStep1';
import AuthClient from '@services/clients/auth';
import ProjectClient from '@services/clients/project';
import { CdpEventName, OtpType } from '@shared/types';
import { ERROR_DARK } from '@theme';

const link = (text: string, onClick: () => void) => (
  <ButtonAsLink variant="md" fontWeight="fontWeightBold" color={ERROR_DARK} onClick={onClick}>
    {text}
  </ButtonAsLink>
);

interface OtpInputCardProps {
  email: string;
  onSuccess: () => void;
  onConflict: () => void;
}
export default function OtpInput({ email, onSuccess, onConflict }: OtpInputCardProps) {
  const [isValid, setIsValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const { createPayload } = useSubmitStep1();
  const [isFirstSubmitting, setIsFirstSubmitting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [otpType, setOtpType] = useState(OtpType.SMS);
  const [otpPhoneNumberSuffix, setOtpPhoneNumberSuffix] = useState('');
  const otpLength = 6;
  const [otpValues, setOtpValues] = useState(Array(otpLength).fill(''));
  const { sendEvent } = useTracking();

  const resetOtpValues = () => {
    setOtpValues(Array(otpLength).fill(''));
    setIsValid(false);
  };

  const { mutate: passwordLessStart } = useMutation(
    'passwordLessStart',
    async () =>
      AuthClient.postPasswordLessStart({
        otpType,
        email,
      }),
    {
      retry: 0,
      onSuccess: (result) => {
        setIsSubmitting(false);
        setIsFirstSubmitting(false);
        if (result.phoneNumberSuffix) {
          setOtpPhoneNumberSuffix(result.phoneNumberSuffix.replace(/(.{2})/g, '$1 '));
        }
      },
    },
  );

  const { mutate: createNewProject } = useMutation(
    'createNewProject',
    ProjectClient.postCreateNewProject,
    {
      onSuccess: () => {
        onSuccess();
      },
      retry: (failureCount, error) => {
        if (error instanceof AxiosError && error.response?.status === 409) {
          onConflict();
          return false;
        }
        return true;
      },
    },
  );

  const { mutate: passwordLessLogin } = useMutation(
    'passwordLessLogin',
    async () =>
      AuthClient.postPasswordLessLogin({
        email,
        code: otpValues.join(''),
      }),
    {
      retry: 0,
      onSuccess: (result) => {
        sendEvent({ event: CdpEventName.OTP_SUCCESS });
        localStorage.setItem(YOMONI_JWT_TOKEN_NAME, result.apiToken);
        createNewProject(createPayload());
      },
      onError: (error) => {
        if (error instanceof AxiosError && error.response?.status === 400) {
          sendEvent({ event: CdpEventName.OTP_INVALID });
          setShowError(true);
          setIsSubmitting(false);
          setIsValid(false);
        } else {
          sendEvent({ event: CdpEventName.OTP_ERROR });
        }
      },
    },
  );

  const initPasswordLess = () => {
    resetOtpValues();
    setShowError(false);
    setIsSubmitting(true);
    setIsFirstSubmitting(true);

    sendEvent({ event: CdpEventName.OTP_INIT, properties: { otp_type: otpType } });
    passwordLessStart();
  };

  const changeOtpType = async () => {
    resetOtpValues();
    setOtpType(otpType === OtpType.SMS ? OtpType.EMAIL : OtpType.SMS);
  };

  const checkOtpValidity = (values: string[]) => {
    if (values.length === otpLength && values.filter((value) => value === '').length === 0) {
      setOtpValues(values);
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  const handleSubmitOtp = () => {
    setShowError(false);
    setIsSubmitting(true);

    passwordLessLogin();
  };

  useEffect(() => {
    initPasswordLess();
  }, [otpType]);

  return (
    <Stack gap={3} role="region" aria-label="otp-input">
      <Typography variant="lg" fontWeight="fontWeightBold">
        <FormattedMessage id={`emailSubmission.OtpInputCard.${otpType}.title`} />
      </Typography>
      {!isFirstSubmitting && otpType === OtpType.SMS && (
        <Typography variant="md">
          <FormattedMessage
            id="emailSubmission.OtpInputCard.SMS.subtitle"
            values={{ otpPhoneNumberSuffix }}
          />
        </Typography>
      )}
      {!isFirstSubmitting && otpType === OtpType.EMAIL && (
        <Typography variant="md">
          <FormattedMessage id="emailSubmission.OtpInputCard.EMAIL.subtitle" values={{ email }} />
        </Typography>
      )}
      <Stack gap={3}>
        {!isFirstSubmitting && (
          <OtpField
            autoFocus
            disabled={isSubmitting}
            error={showError}
            onChange={checkOtpValidity}
            otpLength={otpLength}
            value={otpValues}
          />
        )}
        {showError && (
          <Typography variant="md" color={ERROR_DARK}>
            <FormattedMessage
              id="emailSubmission.OtpInputCard.error"
              values={{
                link: (text: string) => link(text, initPasswordLess),
              }}
            />
          </Typography>
        )}
        <PrimaryButton
          type="submit"
          titleId="emailSubmission.OtpInputCard.confirm"
          onClick={handleSubmitOtp}
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
        />
      </Stack>
      {!isFirstSubmitting && (
        <ButtonAsLink variant="md" fontWeight="fontWeightBold" onClick={changeOtpType}>
          <FormattedMessage id={`emailSubmission.OtpInputCard.${otpType}.changeType`} />
        </ButtonAsLink>
      )}
    </Stack>
  );
}
