import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionWithdrawalHalf from '@features/project/components/questions/QuestionWithdrawalHalf/QuestionWithdrawalHalf';
import checkRulesQuestionWithdrawalHalf from '@features/project/components/questions/QuestionWithdrawalHalf/QuestionWithdrawalHalf.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.WITHDRAWAL_HALF_AMOUNT,
  headerTitle: 'step1.question.layout.header.title',
  cdpEvent: CdpEventName.QUESTION_PROBABILITY_OF_WITHDRAWAL_HALF,
  Component: QuestionWithdrawalHalf,
  payloadKeys: [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF],
  rulesFn: checkRulesQuestionWithdrawalHalf,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.withdrawal-half.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
