import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionSavingsProducts from '@features/project/components/questions/QuestionSavingsProducts/QuestionSavingsProducts';
import checkRulesQuestionSavingsProducts from '@features/project/components/questions/QuestionSavingsProducts/QuestionSavingsProducts.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.SAVINGS_PRODUCTS,
  headerTitle: 'step1.question.layout.header.knowledge.title',
  questionTitle: 'step1.question.savings-products.title',
  questionSubtitle: 'step1.question.savings-products.subtitle',
  cdpEvent: CdpEventName.QUESTION_SAVINGS_PRODUCTS,
  Component: QuestionSavingsProducts,
  payloadKeys: [FunnelAnswersKeys.SAVINGS_PRODUCTS],
  rulesFn: checkRulesQuestionSavingsProducts,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.savings-products.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
