import yomoniClient from '@services/api';
import { FrontRegisterPayload, PatchUserPayload, UserConsentDto, UserDetail } from '@shared/types';

export const getUser = (): Promise<UserDetail> => {
  return yomoniClient.get<UserDetail, any>('/user').then((response) => response.data);
};

export interface CreateUserResponse {
  jwtToken: string;
}

export const createUser = (payload: FrontRegisterPayload): Promise<CreateUserResponse> => {
  return yomoniClient({
    withCredentials: true,
    method: 'post',
    url: '/user/register',
    data: payload,
  }).then((response) => response.data);
};

export const putUserConsent = (userConsentDto: UserConsentDto): Promise<UserConsentDto> => {
  return yomoniClient
    .put<UserConsentDto>('/user/consent', userConsentDto)
    .then((response) => response.data);
};

export const patchUser = (patchUserDto: PatchUserPayload): Promise<PatchUserPayload> => {
  return yomoniClient
    .patch<PatchUserPayload>('/user', patchUserDto)
    .then((response) => response.data);
};

const UserClient = { getUser, createUser, putUserConsent, patchUser };

export default UserClient;
