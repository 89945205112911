const URLS = {
  SCHEDULE: 'https://calendly.com/d/dsw-sfj-rq8?utm_source=tunnel&utm_medium=onsite',
  MANAGEMENT_METHODS: 'https://www.yomoni.fr/apprendre/gestion/gestion-sous-mandat#Definition',
  SURVEY_AMF: 'https://static.yomoni.fr/documents/etude_AMF.pdf',
  EVOLUTION_DISTRIBUTION:
    'https://static.yomoni.fr/documents/yomoni-frais-et-allocations-per-assurantiel.pdf',
  PER_REPORT: 'https://www.ccsfin.fr/sites/default/files/medias/documents/rapport_per-2021.pdf',
  PER_DETAILED_STUDY: 'http://static.yomoni.fr/documents/analyse_tarifaire.pdf',
  TERMS_OF_USE_AND_PRIVACY_POLICY: 'https://www.yomoni.fr/legal/politique-confidentialite',
};

export default URLS;
