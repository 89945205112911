import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRentMortgage from '@features/project/components/questions/QuestionRentMortgage/QuestionRentMortgage';
import checkRulesQuestionRentMortage from '@features/project/components/questions/QuestionRentMortgage/QuestionRentMortgage.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.PRIMARY_RESIDENCE_CHARGES,
  headerTitle: 'step1.question.layout.header.title',
  cdpEvent: CdpEventName.QUESTION_CREDIT_OR_RENT_MAIN_RESIDENCE,
  Component: QuestionRentMortgage,
  payloadKeys: [FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE],
  rulesFn: checkRulesQuestionRentMortage,
};

export default {
  classic: baseDescription,
  kid: { ...baseDescription, questionSubtitle: 'step1.question.rent-mortgage.subtitle.kid' },
  realEstate: baseDescription,
};
