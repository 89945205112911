import yomoniClient from '@services/api';
import {
  OtpCheckDto,
  OtpCheckResponse,
  OtpStartDto,
  OtpStartResponse,
  TokenResponse,
} from '@shared/types';

async function getRefreshToken(): Promise<TokenResponse> {
  return yomoniClient.get<TokenResponse, any>('/auth/refresh').then((response) => response.data);
}
async function postPasswordLessStart(otpStartDto: OtpStartDto): Promise<OtpStartResponse> {
  return yomoniClient
    .post<OtpStartResponse, any>('/auth/passwordLessStart', otpStartDto)
    .then((response) => response.data);
}
async function postPasswordLessLogin(otpCheckDto: OtpCheckDto): Promise<OtpCheckResponse> {
  return yomoniClient
    .post<OtpCheckResponse, any>('/auth/passwordLessLogin', otpCheckDto)
    .then((response) => response.data);
}

const AuthClient = {
  getRefreshToken,
  postPasswordLessStart,
  postPasswordLessLogin,
};

export default AuthClient;
