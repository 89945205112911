export enum OtpType {
  SMS='SMS',
  EMAIL='EMAIL',
}

export interface OtpStartDto {
  email: string;
  otpType: OtpType;
}

export interface OtpStartResponse {
  otptype: OtpType;
  phoneNumberSuffix: string;
}

export interface OtpCheckDto {
  email: string;
  code: string;
}

export interface OtpCheckResponse {
  userId: string;
  apiToken: string;
}