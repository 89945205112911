import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';

/* eslint-disable no-relative-import-paths/no-relative-import-paths */
import App from './App';
import './index.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_ENDPOINT,
  enableTracing: false,
  debug: process.env.REACT_APP_ENV_NAME !== 'production',
  environment: process.env.REACT_APP_ENV_NAME,
  enabled: !['development', 'test'].includes(process.env.REACT_APP_ENV_NAME || 'development'),
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
