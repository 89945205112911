import { Box, SxProps, Theme, useTheme } from '@mui/system';

import OutlinedButton from '@components/OutlinedButton/OutlinedButton';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import { CdpEvent } from '@shared/types';

interface ModalButtonsProps {
  handleOutlinedButtonClick?: () => void;
  outlinedButtonTitleId?: string;
  handlePrimaryButtonClick: () => void;
  primaryButtonTitleId: string;
  primaryButtonDisabled: boolean;
  primaryButtonEvent?: CdpEvent;
  outlinedButtonEvent?: CdpEvent;
  outlinedButtonDisabled: boolean;
  shouldGrowPrimaryButton?: boolean;
  primaryButtonLoading?: boolean;
  sx?: SxProps<Theme>;
}

export default function ModalButtons({
  handleOutlinedButtonClick,
  handlePrimaryButtonClick,
  outlinedButtonTitleId,
  primaryButtonTitleId,
  primaryButtonDisabled,
  outlinedButtonDisabled,
  shouldGrowPrimaryButton = false,
  primaryButtonLoading = false,
  primaryButtonEvent,
  outlinedButtonEvent,
  sx,
}: ModalButtonsProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        gap: 3,
        marginTop: 3,
        width: shouldGrowPrimaryButton ? '100%' : 'default',
        '& > button': {
          flexGrow: shouldGrowPrimaryButton ? 1 : 'default',
        },
        ...sx,
      }}>
      {outlinedButtonTitleId && handleOutlinedButtonClick && (
        <OutlinedButton
          onClick={handleOutlinedButtonClick}
          titleId={outlinedButtonTitleId}
          disabled={outlinedButtonDisabled}
          cdpEvent={outlinedButtonEvent}
        />
      )}

      <PrimaryButton
        type="submit"
        titleId={primaryButtonTitleId}
        onClick={handlePrimaryButtonClick}
        loading={primaryButtonLoading}
        disabled={primaryButtonDisabled}
        cdpEvent={primaryButtonEvent}
        sx={{ minWidth: theme.spacing(15) }}
      />
    </Box>
  );
}

ModalButtons.defaultProps = {
  handleOutlinedButtonClick: undefined,
  outlinedButtonTitleId: undefined,
  shouldGrowPrimaryButton: false,
  primaryButtonLoading: false,
  sx: {},
  primaryButtonEvent: undefined,
  outlinedButtonEvent: undefined,
};
