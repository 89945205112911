import { useContext, useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import { QuestionRuleParams } from '@features/project/question.utils';
import useTryNewWording from '@hooks/useTryNewWording';
import { UserContext } from '@providers/UserProvider';
import {
  businessReferralState,
  preferredEnvelopeState,
  preferredTransferState,
  questionsSelector,
} from '@state';

export default function useRulesParameters(): QuestionRuleParams {
  const { user } = useContext(UserContext);
  const resume = useResumeFunnelAnswers();
  const businessReferral = useRecoilValue(businessReferralState);
  const questionState = useRecoilValue(questionsSelector);
  const preferredEnvelope = useRecoilValue(preferredEnvelopeState);
  const preferredTransfer = useRecoilValue(preferredTransferState);
  const tryNewWording = useTryNewWording();
  const isAlreadyClient = resume.data?.secondaryProject;
  const hasActiveLifeInsurance = resume.data?.hasActiveLifeInsurance;

  return useMemo(
    () => ({
      questionState,
      user,
      isAlreadyClient,
      hasActiveLifeInsurance,
      businessReferral,
      preferredEnvelope,
      preferredTransfer,
      tryNewWording,
    }),
    [
      questionState,
      user,
      isAlreadyClient,
      hasActiveLifeInsurance,
      businessReferral,
      preferredEnvelope,
      preferredTransfer,
      tryNewWording,
    ],
  );
}
