import { FiscalPackage } from '@shared/types';

export const ROUTES = {
  HOME: '/',
  FUNNEL_SELECTION: 'choix-entree',
  PRODUCT_FUNNEL: 'produit',
  PROJECT: 'projet',
  RECOMMANDATION: '/recommandation',
  SSO: '/sso',
};

export const PRODUCT_FUNNEL_ROUTES = {
  RECIPIENT: 'choix-destinataire',
  FISCAL_PACKAGE: 'choix-produit',
};

export const PROJECT_ROUTES = {
  INTRO: 'intro',

  PROJECT_SELECTION: 'choix-projet',
  INITIAL_AMOUNT: 'versement-initial',
  RECURRING_AMOUNT: 'versement-programme',
  HORIZON: 'horizon',
  BIRTH_DATE: 'date-de-naissance',
  FISCAL_RESIDENCY: 'residence-fiscale',
  ESG_PREFERENCE: 'esg',
  CHILDREN: 'enfants',
  ANNUAL_INCOME: 'revenus-annuels',
  PRIMARY_RESIDENCE: 'residence-principale',
  PRIMARY_RESIDENCE_CHARGES: 'residence-principale-charges',
  REAL_ESTATE_PATRIMONY: 'patrimoine-immobilier',
  FINANCIAL_PATRIMONY: 'patrimoine-financier',
  SAVING_CAPACITY: 'capacite-epargne',
  WITHDRAWAL_BEFORE_2_YEARS: 'retrait-deux-ans',
  WITHDRAWAL_HALF_AMOUNT: 'retrait-moitie-investissement',
  SAVINGS_PRODUCTS: 'experience-produits-epargne',
  EARNINGS_POTENTIAL: 'perspective-gains',
  ETF_KNOWLEDGE: 'connaissance-etf',
  REAL_ESTATE_INVESTMENT: 'connaissance-immo',
  DELEGATION: 'delegation-gestion',
  REAL_ESTATE_INVESTMENT_VALUE: 'valeur-investissement-immobilier',
  FINANCIAL_LOSSES: 'perte-placements-financiers',
  RISK_REWARD: 'rapport-gains-pertes-montant',
  RISK_REWARD_PERCENTAGE: 'rapport-gains-pertes-pourcentage',
  LOSS_REACTION: 'pertes-dix-pourcent',

  ACCOUNT_CREATION: 'creation-compte',
  NEWSLETTER: 'newsletter',
};

export const QUERY_PARAMS = {
  THEMATIQUE: {
    key: 'thematique',
    values: {
      IMMOBILIER: 'immobilier',
    },
  },
  ACCES: {
    key: 'acces',
    values: {
      PROSPECT: 'prospect',
    },
  },
  BUSINESS_REFERRAL: {
    key: 'partenaire',
    values: {
      BANKIN: 'bankin',
      DECATHLON: 'armoni_decathlon',
    },
  },
  PROJECT_TYPE: {
    key: 'type',
    values: {
      SIGNIFICANT_PURCHASE: 'achat_important',
      CHILDREN: 'enfant',
      SAVINGS: 'epargne',
      EMERGENCY_SAVINGS: 'epargne_securite',
      INHERITANCE: 'transmission',
      RETIREMENT: 'retraite',
      LEGAL_ENTITY: 'epargne_personne_morale',
    },
  },
  EMAIL: {
    key: 'email',
  },
  SPONSOR: {
    key: 'parrain',
  },
  TOKEN: {
    key: 'token',
  },
  RECIPIENT: {
    key: 'typeProduit',
    values: {
      PERSONAL: 'perso',
      CHILDREN: 'mon-enfant',
      BUSINESS: 'pro',
    },
  },
  PREFERRED_ENVELOPE: {
    key: 'souhaitEnveloppe',
    values: {
      [FiscalPackage.LIFE_INSURANCE]: 'assurance-vie',
      [FiscalPackage.TRADING_ACCOUNT]: 'compte-titres',
      [FiscalPackage.STOCK_SAVINGS_PLAN]: 'pea',
    },
  },
  PREFERRED_TRANSFER: {
    key: 'souhaitTransfert',
    values: {
      TRUE: 'true',
      FALSE: 'false',
    },
  },
  IS_PROFESSIONAL: {
    key: 'estProfessionnel',
    values: {
      TRUE: 'true',
    },
  },
};

export const coreBaseUrl = `${process.env.REACT_APP_YOMONI_CORE_FRONT_BASE_URL}`;
const perBaseUrl = `${process.env.REACT_APP_YOMONI_PER_FRONT_BASE_URL}`;
const ecBaseUrl = `${process.env.REACT_APP_YOMONI_EC_FRONT_BASE_URL}`;

export enum ExternalRoutesEnum {
  PER = 'PER',
  CORE = 'CORE',
  EC = 'EC',
}

export const EXTERNAL_ROUTES = {
  [ExternalRoutesEnum.PER]: {
    STEP_1: `${perBaseUrl}/souscription/per/projet/projet-investissement`,
    SSO: (url: string) => `${perBaseUrl}/sso?state=${btoa(url)}`,
  },
  [ExternalRoutesEnum.CORE]: {
    LOGIN: `${coreBaseUrl}/#/login`,
    RESUME: `${coreBaseUrl}/#/login/recuperation`,
    STEP_1: `${coreBaseUrl}/#/souscription/analyse`,
    RESUME_STEP: (step?: string) => `${coreBaseUrl}/#/souscription/resume?step=${step}`,
    SSO: (url: string) => `${coreBaseUrl}/sso?urlRedirect=${encodeURIComponent(url)}`,
  },
  [ExternalRoutesEnum.EC]: {
    ACCOUNT: `${ecBaseUrl}/compte/informations`,
    HOME: ecBaseUrl,
    SSO: (url: string) => {
      return `${ecBaseUrl}/sso?state=${btoa(`${url}`)}`;
    },
  },
};

export default ROUTES;
