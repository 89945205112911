import {
  InputAdornment,
  InputProps as InputPropsType,
  Stack,
  StandardTextFieldProps,
  SvgIcon,
  TextField,
  Typography,
  debounce,
  useTheme,
} from '@mui/material';
import type { ComponentProps } from 'react';
import { ForwardedRef, forwardRef, useEffect, useMemo, useState } from 'react';
import { IMaskInput } from 'react-imask';

import { GRAY_MAIN } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

export interface InputTextProps extends StandardTextFieldProps {
  suffix?: JSX.Element | string;
  valid?: boolean;
  maskProps?: ComponentProps<typeof IMaskInput>;
  debounceDuration?: number;
}

const MaskInput = forwardRef<HTMLInputElement>((props, ref) => (
  <IMaskInput {...props} inputRef={ref} />
));

export const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  (
    {
      suffix,
      valid,
      sx,
      maskProps,
      InputProps,
      debounceDuration,
      error,
      label,
      ...props
    }: InputTextProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const theme = useTheme();
    const isMobile = isMobileDimensions('md');

    const [errorState, setErrorState] = useState(false);
    const [validState, setValidState] = useState(false);

    const debouncedSetErrorState = useMemo(
      () => debounce((debouncedError) => setErrorState(debouncedError), debounceDuration),
      [],
    );

    const debouncedSetValidState = useMemo(
      () => debounce((debouncedValid) => setValidState(debouncedValid), debounceDuration),
      [],
    );

    useEffect(() => {
      if (error === true) {
        debouncedSetErrorState(true);
        debouncedSetValidState(false);
      } else if (error === false) {
        setErrorState(false);
        debouncedSetErrorState(false);
      }

      if (valid === true) {
        setValidState(true);
        debouncedSetValidState(true);
      }
      if (valid === false && !error) {
        setValidState(false);
        debouncedSetValidState(false);
      }
    }, [props.value, error, valid]);

    const inputComponentProps: InputPropsType = {
      ...InputProps,
      sx: { height: isMobile ? '48px' : '56px', ...InputProps?.sx },
      inputProps: {
        ...InputProps?.inputProps,
        sx: { textAlign: 'left', ...InputProps?.inputProps?.sx },
        ...maskProps,
      },
      inputComponent: MaskInput as any,
    };

    if (suffix) {
      inputComponentProps.endAdornment = (
        <InputAdornment position="end">
          {typeof suffix === 'string' ? (
            <Typography
              variant="lg"
              fontWeight="fontWeightBold"
              color={GRAY_MAIN}
              lineHeight="normal">
              {suffix}
            </Typography>
          ) : (
            <SvgIcon color={(valid && 'success') || (error && 'error') || undefined}>
              {suffix}
            </SvgIcon>
          )}
        </InputAdornment>
      );
    }

    return (
      <Stack gap={0.5}>
        {label && (
          <Typography variant="md" fontWeight="fontWeightBold">
            {label}
          </Typography>
        )}
        <TextField
          className={validState && !errorState ? 'validTextfield' : undefined}
          inputRef={ref}
          InputProps={inputComponentProps}
          sx={{
            width: '100%',
            '&.MuiTextField-root': {
              '& input': {
                fontSize: theme.typography.lg.fontSize,
                lineHeight: theme.typography.lg.lineHeight,
                fontWeight: theme.typography.lg.fontWeight,
                fontFamily: theme.typography.lg.fontFamily,
              },
              '& p': {
                fontSize: theme.typography.lg.fontSize,
                lineHeight: theme.typography.lg.lineHeight,
                fontWeight: theme.typography.fontWeightBold,
                fontFamily: theme.typography.lg.fontFamily,
              },
            },
            ...sx,
          }}
          {...props}
          error={errorState}
        />
      </Stack>
    );
  },
);

InputText.defaultProps = {
  valid: undefined,
  suffix: undefined,
  maskProps: undefined,
  debounceDuration: 0,
};

export default InputText;
