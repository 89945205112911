import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FiscalPackage, RiskReward } from '@shared/types';

export default function checkRulesQuestionRiskReward(ruleParams: QuestionRuleParams): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.amountWinLossRatioQuiz) {
    isValid = false;
  }

  if (wantStockSavingsPlanButDoesntLikeRisk(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.risk-reward.wants-stock-savings-plan-but-doesnt-like-risk',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q22-RG1
 */
function wantStockSavingsPlanButDoesntLikeRisk(params: QuestionRuleParams) {
  return (
    params.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN &&
    params.questionState.amountWinLossRatioQuiz &&
    [RiskReward.GAIN_1000_PERTE_400, RiskReward.GAIN_500_PERTE_0].includes(
      params.questionState.amountWinLossRatioQuiz,
    )
  );
}
