import { Link, SxProps, Typography, TypographyProps } from '@mui/material';
import { Theme } from '@mui/system';
import React, { PropsWithChildren, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';

import LoadingModal from '@components/LoadingModal/LoadingModal';
import { PRIMARY_MAIN } from '@theme';

interface ButtonAsLinkProps {
  onClick: (() => Promise<void>) | (() => void);
  to?: To;
  isMobile?: boolean;
  synchronous?: boolean;
  synchronousLoadingModalText?: string;
  synchronousLoadingModalSubText?: string;
  sx?: SxProps<Theme>;
  variant?: TypographyProps['variant'];
  color?: TypographyProps['color'];
  fontStyle?: TypographyProps['fontStyle'];
  fontWeight?: TypographyProps['fontWeight'];
}

export default function ButtonAsLink({
  onClick,
  to,
  children,
  isMobile,
  synchronous,
  synchronousLoadingModalText,
  synchronousLoadingModalSubText,
  sx,
  variant,
  color,
  fontStyle,
  fontWeight,
}: PropsWithChildren<ButtonAsLinkProps>) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isSynchronous = synchronous;

  const handleClickAsync = () => {
    onClick();
    if (to) {
      navigate(to);
    }
  };

  const handleClickSync = async () => {
    setIsLoading(true);
    await onClick();
    setIsLoading(false);
    if (to) {
      navigate(to);
    }
  };

  return (
    <>
      {isSynchronous && (
        <LoadingModal
          isOpen={isLoading}
          titleId={synchronousLoadingModalText!}
          messageId={synchronousLoadingModalSubText!}
        />
      )}
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <Link component="button" onClick={isSynchronous ? handleClickSync : handleClickAsync}>
        <Typography
          variant={variant || isMobile ? 'md' : 'lg'}
          fontStyle={fontStyle}
          fontWeight={fontWeight}
          color={color}
          sx={{ textDecoration: 'underline', ...sx }}>
          {children}
        </Typography>
      </Link>
    </>
  );
}

ButtonAsLink.defaultProps = {
  isMobile: false,
  synchronous: false,
  synchronousLoadingModalText: 'settingsLoadingModal.loading.primaryText.default',
  synchronousLoadingModalSubText: 'settingsLoadingModal.loading.secondaryText.default',
  to: null,
  sx: {},
  variant: null,
  color: PRIMARY_MAIN,
  fontStyle: 'normal',
  fontWeight: 'normal',
};
