import { ReactComponent as LifeInsurance } from '@assets/icons/package-type/life_insurance.svg';
import { ReactComponent as PackageDefault } from '@assets/icons/package-type/package_default.svg';
import { ReactComponent as RetirementSavingPlan } from '@assets/icons/package-type/retirement_saving_plan.svg';
import { ReactComponent as StockSavingsPlan } from '@assets/icons/package-type/stock_savings_plan.svg';
import { ReactComponent as TradingAccount } from '@assets/icons/package-type/trading_account.svg';
import { FiscalPackage } from '@shared/types';

interface PackageTypeIconProps {
  fiscalPackage: FiscalPackage;
}

const icons: Record<FiscalPackage, any> = {
  ASSURANCE_VIE: LifeInsurance,
  COMPTE_TITRE: TradingAccount,
  PEA: StockSavingsPlan,
  PER: RetirementSavingPlan,
  PER_ASSURANCE: RetirementSavingPlan,
};

export default function PackageTypeIcon({ fiscalPackage: packageType }: PackageTypeIconProps) {
  const PackageIcon = icons[packageType];
  if (PackageIcon == null) {
    return <PackageDefault />;
  }
  return <PackageIcon />;
}
