import { Button, CircularProgress, SxProps, Theme, Typography, useTheme } from '@mui/material';
import { useIntl } from 'react-intl';

import useTracking from '@hooks/tracking/useTracking';
import { CdpEvent } from '@shared/types';
import {
  BOX_SHADOW_MD,
  GRAY_MAIN,
  GRAY_MEDIUM,
  SECONDARY_MAIN,
  SECONDARY_MAIN_DARK,
  WHITE,
} from '@theme';

interface PrimaryButtonProps {
  titleId: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  cdpEvent?: CdpEvent;
}

export default function PrimaryButton({
  titleId,
  type,
  onClick,
  loading = false,
  disabled = false,
  cdpEvent,
  sx,
}: PrimaryButtonProps) {
  const intl = useIntl();
  const { spacing } = useTheme();
  const { sendEvent } = useTracking();

  const handleClick = () => {
    if (cdpEvent) {
      sendEvent(cdpEvent);
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      type={type}
      data-testid="primary-button"
      disabled={disabled}
      sx={{
        px: { xs: spacing(3), lg: spacing(3) },
        py: spacing(1.5),
        textTransform: 'none',
        backgroundColor: disabled ? GRAY_MEDIUM : SECONDARY_MAIN,
        borderRadius: 1,
        boxShadow: disabled ? 'none' : BOX_SHADOW_MD,
        border: disabled ? '1px solid transparent' : `1px solid ${SECONDARY_MAIN}`,
        '&:hover': {
          backgroundColor: disabled ? GRAY_MEDIUM : SECONDARY_MAIN_DARK,
        },
        ...sx,
      }}
      onClick={handleClick}>
      {loading && <CircularProgress variant="indeterminate" size={20} thickness={6} />}
      {!loading && (
        <Typography variant="md" fontWeight="fontWeightBold" color={disabled ? GRAY_MAIN : WHITE}>
          {intl.formatMessage({ id: titleId })}
        </Typography>
      )}
    </Button>
  );
}

PrimaryButton.defaultProps = {
  type: 'button',
  onClick: undefined,
  loading: false,
  disabled: false,
  sx: undefined,
  cdpEvent: undefined,
};
