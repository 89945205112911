import { lazy, Suspense, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';

import Loading from '@components/Loading/Loading';
import PhoneFirstModal from '@features/phoneFirst/PhoneFirstModal';
import {
  isHighPotentialUser,
  isUserAlreadyDefined,
} from '@features/recommendation/recommendation.utils';
import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggleWithOptions from '@hooks/toggle/useToggleWithOptions';
import useTracking from '@hooks/tracking/useTracking';
import useHandleSimulationAndRecommendationErrors from '@hooks/useHandleSimulationAndRecommendationErrors';
import useResetSimulation from '@hooks/useResetSimulation';
import useResume from '@hooks/useResume';
import useTryNewLifeInsurancePage from '@hooks/useTryNewLifeInsurancePage';
import useUserExpertise from '@hooks/useUserExpertise';
import { UserContext } from '@providers/UserProvider';
import {
  AbTestVersionName,
  CdpCategory,
  CdpEventName,
  isLifeInsurancePackage,
  isPeaPackage,
  isPerPackage,
  ResumeResponse,
  Thematic,
} from '@shared/types';
import { preferredEnvelopeState, profileSelector } from '@state';

const RecommendationClassic = lazy(
  () => import('@features/recommendation/pages/classic/RecommendationClassic'),
);
const RecommendationPer = lazy(
  () => import('@features/recommendation/pages/per/RecommendationPer'),
);
const RecommendationPea = lazy(
  () => import('@features/recommendation/pages/pea/RecommendationPea'),
);
const RecommendationAV = lazy(
  () => import('@features/recommendation/pages/RecommendationAV/RecommendationAV'),
);

export type RecommendationProps = {
  resume: ResumeResponse | undefined;
  thematic: Thematic | undefined;
};

export default function Recommendation() {
  const intl = useIntl();
  useUserExpertise();
  const resetSimulation = useResetSimulation();
  const { simulationBlockingErrors } = useHandleSimulationAndRecommendationErrors();
  const { resume } = useResume();
  const { thematic, fiscalPackage } = useRecoilValue(profileSelector);
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(true);
  const { sendEvent } = useTracking();
  const preferredFiscalPackage = useRecoilValue(preferredEnvelopeState);

  useEffect(() => {
    if (simulationBlockingErrors && simulationBlockingErrors.length > 0) {
      resetSimulation.reset();
    }
  }, [simulationBlockingErrors]);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'page.recommendation.title' });
    window.scrollTo(0, 0);

    if (window.dataLayer) {
      // Keep this pageview for marketing team
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: `/tunnel-proposition/intro`,
          title: 'Ma recommandation',
        },
      });
    }
  }, []);

  const closePhoneFirstModal = () => {
    setIsPhoneNumberModalOpen(false);
    sessionStorage.setItem('hasAlreadySeenPhoneFirstModal', 'true');
  };

  useEffect(() => {
    if (isPeaPackage(fiscalPackage)) {
      sendEvent({
        event: CdpEventName.SHOW_RECOMMENDATION,
        properties: {
          category: CdpCategory.SIMULATION,
        },
      });
    }
  }, [fiscalPackage]);

  // ABTEST-BEGIN | Toggle.REACT_APP_PHONE_FIRST_MODALE
  const { user } = useContext(UserContext);
  const toggleOption = useToggleWithOptions(Toggle.REACT_APP_PHONE_FIRST_MODALE);
  const shouldRenderPhoneFirstModal = useMemo(
    () =>
      Boolean(
        !isUserAlreadyDefined(user) &&
          isHighPotentialUser(resume) &&
          sessionStorage.getItem('hasAlreadySeenPhoneFirstModal') !== 'true',
      ),
    [user, resume],
  );
  useEffect(() => {
    if (shouldRenderPhoneFirstModal && toggleOption) {
      sendEvent({
        event: CdpEventName.PHONE_FIRST_MODAL_SHOWN,
        properties: {
          abTestVersion: toggleOption,
        },
      });
    }
  }, [shouldRenderPhoneFirstModal, toggleOption]);
  // ABTEST-END | Toggle.REACT_APP_PHONE_FIRST_MODALE

  // ABTEST-BEGIN | Toggle.NEW_LIFE_INSURANCE_PAGE
  const tryNewLifeInsurancePage = useTryNewLifeInsurancePage();
  useEffect(() => {
    if (preferredFiscalPackage && isLifeInsurancePackage(fiscalPackage)) {
      sendEvent({
        event: CdpEventName.SHOW_RECOMMENDATION,
        properties: {
          category: CdpCategory.SIMULATION,
          avPageVersion: tryNewLifeInsurancePage
            ? AbTestVersionName.NEW
            : AbTestVersionName.ORIGINAL,
        },
      });
    }
  }, [preferredFiscalPackage, fiscalPackage]);
  // ABTEST-END | Toggle.NEW_LIFE_INSURANCE_PAGE

  const page = useMemo(() => {
    if (resume) {
      if (isPerPackage(fiscalPackage)) {
        return <RecommendationPer />;
      }
      if (preferredFiscalPackage && isPeaPackage(preferredFiscalPackage)) {
        return <RecommendationPea />;
      }
      if (tryNewLifeInsurancePage) {
        return <RecommendationAV />;
      }
      if (fiscalPackage) {
        return <RecommendationClassic resume={resume} thematic={thematic} />;
      }
    }
    return <Loading />;
  }, [resume, fiscalPackage, preferredFiscalPackage, thematic, tryNewLifeInsurancePage]);

  return (
    <Suspense fallback={<Loading />}>
      {page}
      {shouldRenderPhoneFirstModal && toggleOption !== 'NO_MODALE' && (
        <PhoneFirstModal
          isOpen={!!resume && isPhoneNumberModalOpen}
          handleClose={closePhoneFirstModal}
        />
      )}
    </Suspense>
  );
}
