import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ROUTES from '@constants/routes.const';
import useTracking from '@hooks/tracking/useTracking';
import { CdpCategory, CdpType } from '@shared/types';

function TrackPageView({ children }: Readonly<PropsWithChildren<{}>>): JSX.Element {
  const { pathname } = useLocation();
  const { sendEvent } = useTracking();

  const excludePages = [`/${ROUTES.PROJECT}`];

  useEffect(() => {
    if (!excludePages.includes(pathname)) {
      sendEvent({
        type: CdpType.PAGE,
        name: pathname,
        properties: {
          category: CdpCategory.NAVIGATION,
        },
      });
    }
  }, [pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default TrackPageView;
