import { Stack } from '@mui/system';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import InputText from '@components/InputText/InputText';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionRecurringPayments from '@features/project/components/questions/QuestionReccuringPayments/questionRecurringPayments.rules';
import {
  InfoBoxContextValue,
  QuestionContext,
  QuestionContextValue,
} from '@features/project/components/Step1Questions';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { questionsSelector, recurringPaymentQuestionState } from '@state';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

const buttons = [
  {
    value: '100',
    titleId: 'step1.question.recurringPayments.button.100',
  },
  {
    value: '200',
    titleId: 'step1.question.recurringPayments.button.200',
  },
  {
    value: '500',
    titleId: 'step1.question.recurringPayments.button.500',
  },
  {
    value: '1000',
    titleId: 'step1.question.recurringPayments.button.1000',
  },
  {
    value: '1500',
    titleId: 'step1.question.recurringPayments.button.1500',
  },
];

const defaultInfoBoxMessages: InfoBoxContextValue[] = [
  {
    infoBoxLevel: InfoBoxLevel.INFO,
    infoBoxMessage: 'step1.question.recurringPayments.info.message',
  },
];

function QuestionRecurringPayments() {
  const intl = useIntl();
  const { isMobile } = useDimensions();
  const { updateContext, isValid } = useContext(QuestionContext);

  const questionState = useRecoilValue(questionsSelector);
  const setRecurringPaymentQuestionState = useSetRecoilState(recurringPaymentQuestionState);

  const rulesCheck = (userInput?: number, context?: Partial<QuestionContextValue>) => {
    const checkResult = checkRulesQuestionRecurringPayments({
      questionState: { ...questionState, recurringPayment: userInput },
    });
    checkResult.infoBoxMessages.push(...defaultInfoBoxMessages);
    updateContext({ ...checkResult, ...context });
  };

  useEffect(() => {
    rulesCheck(questionState.recurringPayment);
  }, []);

  const handleButtonChange = (value: string) => {
    const userInput = parseInt(value, 10);
    setRecurringPaymentQuestionState(userInput);
    rulesCheck(userInput, { debounceError: false, autoValidate: true });
  };

  const handleInputChange = (value: string) => {
    const userInput = parseInt(removeWhitespaces(value), 10);
    setRecurringPaymentQuestionState(userInput);
    rulesCheck(userInput, { debounceError: true, autoValidate: false });
  };

  return (
    <Stack direction="column" alignItems="center" gap={3}>
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={String(questionState.recurringPayment)}
        onChange={handleButtonChange}
      />
      <InputText
        name="recurring-payments-amount"
        debounceDuration={DEBOUNCE_DURATION}
        placeholder={intl.formatMessage({
          id: 'step1.question.recurringPayments.input.placeholder',
        })}
        error={isNumberDefined(questionState.recurringPayment) && !isValid}
        valid={isValid}
        suffix={intl.formatMessage({ id: 'step1.question.recurringPayments.input.suffix' })}
        maskProps={{
          mask: Number,
          scale: 0,
          thousandsSeparator: ' ',
          onAccept: handleInputChange,
        }}
        value={`${questionState.recurringPayment}`}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            sx: { textAlign: 'right', paddingRight: '0 !important' },
          },
        }}
        sx={{ width: isMobile ? '100%' : '344px' }}
      />
    </Stack>
  );
}

export default QuestionRecurringPayments;
