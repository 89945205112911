import { Navigate } from 'react-router-dom';

import ROUTES, { PROJECT_ROUTES } from '@constants/routes.const';
import EmailSubmission from '@features/emailsubmission/EmailSubmission';
import Newsletter from '@features/newsletter/Newsletter';
import SubscriptionIntro from '@features/project/pages/SubscriptionIntro';
import SubscriptionProject from '@features/project/pages/SubscriptionProject';
import SubscriptionQuestions from '@features/project/pages/SubscriptionQuestions';
import SsoForm from '@features/sso/pages/Sso';
import FunnelLayout from '@layouts/FunnelLayout';
import RequireAuth from '@services/auth/RequireAuth';
import { METHOD_BOX_GRAY } from '@theme';

function step1Routes() {
  return [
    {
      index: true,
      element: <Navigate to={ROUTES.PROJECT} />,
    },
    {
      path: ROUTES.PROJECT,
      element: <FunnelLayout backgroundColor={METHOD_BOX_GRAY} />,
      children: [
        {
          index: true,
          element: <SubscriptionProject />,
        },
        {
          path: PROJECT_ROUTES.INTRO,
          element: <SubscriptionIntro />,
        },
        {
          path: `:questionPath`,
          element: <SubscriptionQuestions />,
        },
        {
          children: [
            {
              path: PROJECT_ROUTES.NEWSLETTER,
              element: (
                <RequireAuth>
                  <Newsletter />
                </RequireAuth>
              ),
            },
          ],
        },
      ],
    },
    {
      path: `${ROUTES.PROJECT}/${PROJECT_ROUTES.ACCOUNT_CREATION}`,
      element: <FunnelLayout withLoginHeader={false} />,
      children: [
        {
          index: true,
          element: <EmailSubmission />,
        },
      ],
    },
    {
      path: ROUTES.SSO,
      element: <SsoForm />,
    },
  ];
}

export default step1Routes;
