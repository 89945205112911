import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionAnnualIncome from '@features/project/components/questions/QuestionAnnualIncome/QuestionAnnualIncome';
import checkRulesQuestionAnnualIncome from '@features/project/components/questions/QuestionAnnualIncome/QuestionAnnualIncome.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.ANNUAL_INCOME,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.annual-income.title',
  questionSubtitle: 'step1.question.annual-income.subtitle',
  cdpEvent: CdpEventName.QUESTION_ANNUAL_INCOME,
  Component: QuestionAnnualIncome,
  payloadKeys: [FunnelAnswersKeys.GROSS_ANNUAL_INCOME, FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE],
  rulesFn: checkRulesQuestionAnnualIncome,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.annual-income.subtitle.children-project',
  },
};
