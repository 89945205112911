import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Stack, Typography } from '@mui/material';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';

import InfoBox, { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import InputPhoneNumber from '@components/InputPhoneNumber/InputPhoneNumber';
import InputText from '@components/InputText/InputText';
import StyledModal from '@components/StyledModal/StyledModal';
import getSchema from '@features/phoneFirst/schemaValidation';
import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggleWithOptions from '@hooks/toggle/useToggleWithOptions';
import UserClient from '@services/clients/user';
import { CdpEventName, PatchUserPayload } from '@shared/types';
import { isMobileDimensions } from '@utils/global.utils';

interface PhoneFirstModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

type Inputs = {
  firstname: string;
  lastname: string;
  phoneNumber: string;
};

function PhoneFirstModal({ isOpen, handleClose }: PhoneFirstModalProps) {
  const intl = useIntl();
  const isMobile = isMobileDimensions('md');
  const queryClient = useQueryClient();
  // ABTEST-BEGIN | Toggle.REACT_APP_PHONE_FIRST_MODALE
  const toggleOption = useToggleWithOptions(Toggle.REACT_APP_PHONE_FIRST_MODALE);
  const modalIsMandatory = toggleOption === 'MANDATORY';
  // ABTEST-END | Toggle.REACT_APP_PHONE_FIRST_MODALE

  const {
    formState: { isValid },
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    mode: 'onTouched',
    resolver: yupResolver(getSchema(intl)),
  });

  const {
    mutate: patchUser,
    isLoading,
    isError,
  } = useMutation('patchUser', async (data: PatchUserPayload) => UserClient.patchUser(data), {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [localStorage.getItem('jwt'), 'resume'] });
      handleClose();
    },
  });

  const formSubmit: SubmitHandler<Inputs> = (data) => {
    patchUser({
      phoneNumber: data.phoneNumber,
      firstName: data.firstname,
      birthName: data.lastname,
      wouldLikeAssistance: true,
    });
  };

  return (
    <StyledModal
      ariaLabel="phoneFirstModal"
      isOpen={isOpen}
      isMobile={isMobile}
      titleProps={{
        titleId: modalIsMandatory ? 'phonerNumberModal.title.abTest' : 'phonerNumberModal.title',
        textAlign: 'left',
      }}
      primaryButtonTitleId={
        modalIsMandatory
          ? 'phonerNumberModal.assistanceWithAdvisorButton.abTest'
          : 'phonerNumberModal.assistanceWithAdvisorButton'
      }
      primaryButtonEvent={{
        event: CdpEventName.CLICK_PHONE_FIRST_MODAL_ACCEPTED,
        properties: { abTestVersion: toggleOption || '' },
      }}
      primaryButtonDisabled={isLoading || !isValid}
      primaryButtonLoading={isLoading}
      handlePrimaryButtonClick={handleSubmit(formSubmit)}
      outlinedButtonTitleId={modalIsMandatory ? undefined : 'phonerNumberModal.noThanksButton'}
      outlinedButtonDisabled={isLoading}
      handleOutlinedButtonClick={handleClose}
      outlinedButtonEvent={{
        event: CdpEventName.CLICK_PHONE_FIRST_MODAL_DECLINED,
        properties: { abTestVersion: toggleOption || '' },
      }}
      buttonsSx={{
        justifyContent: 'flex-end',
        width: '100%',
        flexDirection: { xs: 'column-reverse', md: 'row' },
      }}
      sx={{ minWidth: { md: '720px' } }}>
      <form onSubmit={handleSubmit(formSubmit)}>
        <Stack gap={{ xs: 2, md: 3 }}>
          {!modalIsMandatory && (
            <Typography variant="md">
              <FormattedMessage id="phonerNumberModal.subText" />
            </Typography>
          )}
          <Stack
            flexDirection={{ xs: 'column', md: 'row' }}
            gap={{ xs: 2, md: 3 }}
            sx={{ '& > div': { md: { flexBasis: '50%' } } }}>
            <Box>
              <InputText
                {...register('firstname')}
                error={!!errors.firstname}
                helperText={errors?.firstname?.message}
                label={intl.formatMessage({ id: 'phonerNumberModal.firstname' })}
                placeholder="Camille"
                disabled={isLoading}
              />
            </Box>
            <Box>
              <InputText
                {...register('lastname')}
                error={!!errors.lastname}
                helperText={errors?.lastname?.message}
                label={intl.formatMessage({ id: 'phonerNumberModal.lastname' })}
                placeholder="Dupont"
                disabled={isLoading}
              />
            </Box>
          </Stack>
          <Stack gap={0.5}>
            <Typography variant="md" fontWeight="fontWeightBold">
              <FormattedMessage id="phonerNumberModal.phoneNumber" />
            </Typography>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field, fieldState }) => (
                <InputPhoneNumber {...field} {...fieldState} disabled={isLoading} />
              )}
            />
          </Stack>
          {isError && (
            <Typography variant="md" color="error">
              <FormattedMessage id="phonerNumberModal.errorOccured" />
            </Typography>
          )}
          {modalIsMandatory && (
            <InfoBox
              type={InfoBoxLevel.INFO}
              title="phonerNumberModal.infoBox.title"
              description="phonerNumberModal.infoBox.description"
              imageSrc="https://static.yomoni.fr/images/tunnel/chief_sales_officer.png"
              sx={{ mb: 3 }}
            />
          )}
        </Stack>
      </form>
    </StyledModal>
  );
}

PhoneFirstModal.defaultProps = {};

export default PhoneFirstModal;
