import { createTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    xxl: React.CSSProperties;
    xl: React.CSSProperties;
    lg: React.CSSProperties;
    md: React.CSSProperties;
    sm: React.CSSProperties;
    xs: React.CSSProperties;
    keyFigure: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    xxl: React.CSSProperties;
    xl: React.CSSProperties;
    lg: React.CSSProperties;
    md: React.CSSProperties;
    sm: React.CSSProperties;
    xs: React.CSSProperties;
    keyFigure: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xxl: true;
    xl: true;
    lg: true;
    md: true;
    sm: true;
    xs: true;
    keyFigure: true;
  }
}

export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const SUCCESS_DARK = '#38A169';
export const SUCCESS_MAIN = '#68D391';
export const SUCCESS_LIGHT = '#FAFFFC';
export const ERROR_DARK = '#E53E3E';
export const ERROR_LIGHT = '#FFF5F5';
export const ERROR_MAIN = '#FC8181';
export const PEA_BAR_COLOR = '#94B5D7';
export const GRAY_DARK = '#232634';
export const GRAY_LIGHT = '#F4F4F7';
export const GRAY_LIGHT_MID = '#E9E9F2';
export const GRAY_MAIN = '#727798';
export const GRAY_MAIN_BORDER_COLOR = 'rgba(114, 119, 152, 0.3)';
export const PRIMARY_MAIN = '#1F2663';
export const PRIMARY_MAIN_BORDER_COLOR = 'rgba(31, 38, 99, 0.3)';
export const PRIMARY_LIGHT = '#4B5182';
export const PRIMARY_DARK = '#101434';
export const SECONDARY_MAIN = '#F29133';
export const SECONDARY_MAIN_DARK = '#E4770E';
export const PROFILE_2_COLOR = '#BEE3F8';
export const PROFILE_3_COLOR = '#90CDF4';
export const PROFILE_4_COLOR = '#63B3ED';
export const PROFILE_5_COLOR = '#4299E1';
export const PROFILE_7_COLOR = '#2B6CB0';
export const FORECAST_AMOUNT_SQUARE = '#7BBFF1';
export const METHOD_BOX_GRAY = '#F5F7FB';
export const BOX_SHADOW_MD = '2px 2px 10px rgba(35, 38, 52, 0.08)';
export const BOX_SHADOW_LG = '2px 5px 32px rgba(35, 38, 52, 0.16)';
export const BOX_SHADOW_LOADER =
  '0px 0px 40px rgba(242,145,51,0.25), 0px 0px 20px rgba(242,145,51,0.25) inset';
export const WHITE_BORDER = '1px solid #FFFFFF';
export const NAVBAR_HEIGHT = '80px';
export const FOOTER_HEIGHT = '95px';
export const GUTTER_TOP_SIZE = '24px';
export const WARNING = '#F4A352';
export const WARNING_LIGHT = '#FFFFF0';
export const WARNING_DARK = '#D69E2E';
export const CARD_BORDER = '1px solid rgb(31, 38, 99, 0.16)';
export const INFO_SUCCESS_LIGHT = '#E6FFFA';
export const INFO_SUCCESS_DARK = '#319795';

export const GRAY_MEDIUM = '#DCDCE5';
export const GRAY_300 = '#e7e9ea';
export const GRAY_400 = grey[400];
export const BLUE_300 = '#4F5583';
export const GRAY_BACKGROUND = 'rgba(31, 38, 99, 0.04)';
export const BLUE_WORLDMAP = '#2B6CB0';

export const GREEN_ESG = '#38A169';
export const GREEN_ESG_BACKGROUND = '#38A1690A';
export const BLUE_ISR = '#1D3D91';
export const GREEN_FRANCE_RELANCE = '#169B62';
export const CAMEL_LIGHT = '#FAF3ED';

export const BACKDROP = 'rgba(31, 38, 99, 0.5)';

const customFontFamily = ['Fira Sans', 'Work sans', 'sans-serif', 'Roboto'].join(',');

let theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN,
      light: PRIMARY_LIGHT,
      dark: PRIMARY_DARK,
    },
    secondary: {
      main: SECONDARY_MAIN,
    },
    success: {
      main: SUCCESS_MAIN,
      dark: SUCCESS_DARK,
    },
    info: {
      main: GRAY_MAIN,
      dark: GRAY_DARK,
      light: GRAY_LIGHT,
    },
    error: {
      main: '#FC8181',
      light: '#fff5f5',
      dark: '#E53E3E',
    },
    warning: {
      main: WARNING,
    },
    divider: '#F4F4F7',
  },
});

theme = createTheme({
  ...theme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundColor: PRIMARY_DARK,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          margin: '12px auto',
          boxShadow: BOX_SHADOW_MD,
          borderRadius: '4px',
          padding: '24px',
          overflow: 'visible',
          [theme.breakpoints.down('md')]: {
            padding: '24px 16px',
            boxShadow: BOX_SHADOW_LG,
          },
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '0px',
          marginBottom: '0px',
          '&:last-child': {
            paddingBottom: 0,
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        regular: {
          height: NAVBAR_HEIGHT,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        light: {
          borderColor: '#F4F4F7',
          opacity: 1,
        },
        root: {
          borderColor: GRAY_MAIN,
          opacity: '0.3',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: '100%',
          boxShadow: 'none',
          '&:before': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '& .MuiAccordionSummary-expandIconWrapper': {
            marginRight: theme.spacing(1),
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: PRIMARY_MAIN,
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: BACKDROP,
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            border: '1px solid transparent',
            backgroundColor: GRAY_LIGHT,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...theme.typography.xs,
          fontWeight: '400 !important',
          fontSize: '12px !important',
          lineHeight: '1.4 !important',
          margin: '4px 0 0 0 !important',
          '&.Mui-error': {
            color: ERROR_DARK,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
          },
        },
        input: {
          padding: '6px 8px',
          textAlign: 'center',
          // MD Regular (to be replaced by variant after migration)
          fontFamily: 'Fira Sans',
          fontSize: '14px',
          lineHeight: '22.4px',
          fontWeight: '400',
          color: PRIMARY_MAIN,
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          '&.MuiAlert-standardError': {
            backgroundColor: theme.palette.error.light,
            alignItems: 'center',
            borderRadius: '8px',
            color: ERROR_MAIN,
            fontSize: '14px',
          },
          '&.MuiAlert-standardError .MuiAlert-icon': {
            color: ERROR_MAIN,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            borderRadius: '6px',
          },
          '&.MuiTextField-root': {
            backgroundColor: WHITE,
            '& input': {
              color: PRIMARY_MAIN,
              '&:-webkit-autofill': {
                '-webkit-background-clip': 'text',
              },
              '&::placeholder': {
                fontStyle: 'italic',
                opacity: 1,
                color: GRAY_MAIN,
              },
            },
            '& .MuiInputBase-input': {
              padding: '16px 24px 16px 24px',
            },
            '& fieldset': {
              borderColor: GRAY_MEDIUM,
            },
            '&:hover fieldset': {
              borderColor: PRIMARY_MAIN,
            },
            '& .Mui-focused fieldset': {
              borderColor: PRIMARY_MAIN,
            },
            '& ::placeholder': {
              textAlign: 'left',
            },
            '&.validTextfield': {
              '& input': {
                color: `${SUCCESS_MAIN} !important`,
              },
              '& ::placeholder': {
                color: `${SUCCESS_MAIN} !important`,
              },
              backgroundColor: SUCCESS_LIGHT,
              '& fieldset': {
                borderColor: SUCCESS_MAIN,
              },
              '&:hover fieldset': {
                borderColor: SUCCESS_DARK,
              },
              '& .Mui-focused fieldset': {
                borderColor: SUCCESS_DARK,
              },
              '& .MuiInputAdornment-root': {
                '& .MuiTypography-root': {
                  color: `${SUCCESS_MAIN} !important`,
                },
              },
            },
            '& .Mui-error': {
              '&.MuiFormHelperText-root': {
                backgroundColor: WHITE,
                margin: '5px 0px 0px 3px',
                fontWeight: 400,
              },
              '& input': {
                color: `${ERROR_MAIN}`,
              },
              backgroundColor: `${ERROR_LIGHT}`,
              '& fieldset': {
                borderColor: `${ERROR_MAIN}`,
              },
              '&:hover fieldset': {
                borderColor: `${ERROR_DARK}`,
              },
              '& .Mui-focused fieldset': {
                borderColor: `${ERROR_DARK}`,
              },
              '& .MuiInputAdornment-root': {
                '& .MuiTypography-root': {
                  color: `${ERROR_MAIN} !important`,
                },
              },
            },
          },
        },
      },
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: customFontFamily,
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    xxl: {
      fontSize: '24px',
      lineHeight: '33.6px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    xl: {
      fontSize: '20px',
      lineHeight: '28px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    lg: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    md: {
      fontSize: '14px',
      lineHeight: '19.6px',
      fontWeight: '400',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    sm: {
      fontSize: '12px',
      lineHeight: '16.8px',
      fontWeight: '400',
      letterSpacing: '0.25px',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    xs: {
      fontSize: '10px',
      lineHeight: '14px',
      fontWeight: '400',
      letterSpacing: '0.25px',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
    h1: {
      fontStyle: 'normal',
      fontSize: '22px',
      lineHeight: '25.8px',
      fontWeight: '700',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '23.5px',
      fontWeight: '600',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    h3: {
      fontSize: '18px',
      lineHeight: '21.1px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    h4: {
      fontSize: '16px',
      lineHeight: '22.4px',
      fontWeight: '300',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    h5: {
      fontSize: '15px',
      lineHeight: '18px',
      fontWeight: '500',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    h6: {
      fontSize: '15px',
      lineHeight: '21px',
      fontWeight: '300',
      color: theme.palette.primary.main,
      fontFamily: 'Work Sans, sans-serif',
    },
    keyFigure: {
      fontSize: '32px',
      lineHeight: '44.8px',
      fontWeight: '600',
      color: theme.palette.primary.main,
      fontFamily: 'Fira Sans, sans-serif',
    },
  },
});

const yomoniTheme = theme;

export default yomoniTheme;
