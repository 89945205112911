import { createSearchParams, NavigateFunction } from 'react-router-dom';

import ROUTES, { EXTERNAL_ROUTES } from '@constants/routes.const';
import { FiscalPackage, isPerPackage } from '@shared/types';
import { createUrlWithAdditionalParams } from '@utils/url.utils';

export function navigateToRecommendation(navigate: NavigateFunction) {
  navigate({
    pathname: ROUTES.RECOMMANDATION,
  });
}

export function getStep1NavigationProps() {
  return {
    pathname: `/${ROUTES.PROJECT}`,
  };
}

export function getPerStep1NavigationProps(additionalSearchParams?: Record<string, string>) {
  return {
    pathname: ROUTES.SSO,
    search: createSearchParams({
      url: EXTERNAL_ROUTES.PER.SSO(
        createUrlWithAdditionalParams(EXTERNAL_ROUTES.PER.STEP_1, additionalSearchParams),
      ),
    }).toString(),
  };
}

export function getGoToStep1NavigationPropsGivenFiscalPackage(fiscalPackage?: FiscalPackage) {
  if (fiscalPackage && isPerPackage(fiscalPackage)) {
    return getPerStep1NavigationProps();
  }

  return getStep1NavigationProps();
}

export function navigateToStep1GivenFiscalPackage(
  navigate: NavigateFunction,
  fiscalPackageStateValue: FiscalPackage,
) {
  navigate(getGoToStep1NavigationPropsGivenFiscalPackage(fiscalPackageStateValue));
}

export function navigateToStep3(navigate: NavigateFunction) {
  navigate({
    pathname: ROUTES.SSO,
    search: createSearchParams({
      url: EXTERNAL_ROUTES.CORE.SSO(EXTERNAL_ROUTES.CORE.RESUME_STEP('3')),
    }).toString(),
  });
}

export function navigateToECHomepage(navigate: NavigateFunction) {
  navigate({
    pathname: ROUTES.SSO,
    search: createSearchParams({ url: EXTERNAL_ROUTES.EC.SSO(EXTERNAL_ROUTES.EC.HOME) }).toString(),
  });
}
