import { Box, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState, useRecoilValue } from 'recoil';

import { InputText } from '@components/InputText/InputText';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionHorizon from '@features/project/components/questions/QuestionHorizon/QuestionHorizon.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import useRulesParameters from '@features/project/hooks/useRulesParameters';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { horizonQuestionState, questionsSelector } from '@state';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

export default function QuestionHorizon() {
  const intl = useIntl();
  const { isMobile } = useDimensions();
  const { updateContext, isValid } = useContext(QuestionContext);
  const rulesParameters = useRulesParameters();
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.horizon',
  });

  const questionState = useRecoilValue(questionsSelector);
  const [horizonStateValue, setHorizonStateValue] = useRecoilState(horizonQuestionState);

  const buttons = [
    {
      value: '2',
      titleId: 'step1.question.horizon.button.two-year',
    },
    {
      value: '5',
      titleId: 'step1.question.horizon.button.five-year',
    },
    {
      value: '8',
      titleId: 'step1.question.horizon.button.eight-year',
    },
    {
      value: '10',
      titleId: 'step1.question.horizon.button.ten-year',
    },
    {
      value: '15',
      titleId: 'step1.question.horizon.button.fifteen-year',
    },
    {
      value: '20',
      titleId: 'step1.question.horizon.button.twenty-year',
    },
  ];

  useEffect(() => {
    updateContext(checkRulesQuestionHorizon(rulesParameters));
  }, [horizonStateValue]);

  const handleButtonChange = (newHorizon: string) => {
    const newHorizonNumber = parseInt(newHorizon, 10);
    setHorizonStateValue(newHorizonNumber);
    updateContext({ debounceError: true, autoValidate: false });
  };
  const handleInputChange = (newHorizon: number) => {
    setHorizonStateValue(newHorizon);
    updateContext({ debounceError: true, autoValidate: false });
  };

  return (
    <Stack gap={3} justifyContent="center">
      {isMobile && (
        <ToggleButtonGroup
          buttons={buttons}
          selectedValue={String(questionState.horizon)}
          onChange={handleButtonChange}
          itemProps={{ xs: 5.8 }}
        />
      )}

      <Box sx={{ textAlign: 'center', m: 'auto' }}>
        <InputText
          name="horizon"
          debounceDuration={DEBOUNCE_DURATION}
          placeholder={intl.formatMessage({ id: 'step1.question.horizon.placeholder' })}
          valid={isValid}
          error={isNumberDefined(horizonStateValue) && !isValid}
          suffix={intl.formatMessage({ id: 'symbol.years' })}
          maskProps={{
            mask: Number,
            scale: 0,
            thousandsSeparator: ' ',
            onAccept: (value) => handleInputChange(parseInt(removeWhitespaces(value), 10)),
          }}
          value={String(horizonStateValue)}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              sx: { textAlign: 'right', paddingRight: '0 !important' },
            },
          }}
          sx={{ width: isMobile ? '210px' : '326px' }}
        />
      </Box>
    </Stack>
  );
}
