import { LinearProgress, LinearProgressProps, linearProgressClasses, styled } from '@mui/material';

import { GRAY_MEDIUM, SECONDARY_MAIN, WHITE } from '@theme';

export interface ProgressBarProps extends LinearProgressProps {
  barcolor?: string;
  backgroundcolor?: string;
  noborder?: boolean;
  height?: number;
}

const StyledLinearProgress = styled(LinearProgress)<ProgressBarProps>(
  (props: ProgressBarProps) => ({
    height: props.height,
    border: props.noborder ? undefined : `1px solid ${GRAY_MEDIUM}`,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: props.backgroundcolor,
    },
    [`& .${linearProgressClasses.bar}`]: {
      marginTop: props.noborder ? 0 : 1,
      height: props.noborder ? props.height : props.height! - 2,
      borderRadius: 2,
      backgroundColor: props.barcolor,
    },
  }),
);

export default function ProgressBar({ value, ...props }: ProgressBarProps) {
  return (
    <StyledLinearProgress
      value={value}
      aria-label={`progressBar-${value ? value.toString() : ''}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      noborder={props.noborder ? props.noborder : undefined}
    />
  );
}

ProgressBar.defaultProps = {
  barcolor: SECONDARY_MAIN,
  backgroundcolor: WHITE,
  noborder: false,
  height: 6,
};
