import { yupResolver } from '@hookform/resolvers/yup';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { AxiosError } from 'axios';
import { useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import { InputText } from '@components/InputText/InputText';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import {
  YOMONI_BUSINESS_REFERRAL_COOKIE_NAME,
  YOMONI_JWT_TOKEN_NAME,
  YOMONI_UTM_COOKIE_NAME,
} from '@constants/app.const';
import URLS from '@constants/url.const';
import { getSchema } from '@features/emailsubmission/components/schemaValidation';
import useSubmitStep1 from '@hooks/useSubmitStep1';
import useTryNewWording from '@hooks/useTryNewWording';
import { UserContext } from '@providers/UserProvider';
import UserClient from '@services/clients/user';
import { UserDetail } from '@shared/types';
import { emailState, externalTokenState, sponsorCodeState } from '@state';
import { externalLink } from '@utils/formatted-message.utils';

type Inputs = {
  email: string;
};

interface EmailInputCardProps {
  onSuccess: (user: UserDetail) => void;
  onConflict: (email: string) => void;
}

export default function EmailInput({ onSuccess, onConflict }: EmailInputCardProps) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { refreshUser } = useContext(UserContext);
  const emailStateValue = useRecoilValue(emailState);
  const sponsorCode = useRecoilValue(sponsorCodeState);
  const externalToken = useRecoilValue(externalTokenState);
  const tryNewWording = useTryNewWording();
  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    defaultValues: {
      email: emailStateValue,
    },
    mode: 'onTouched',
    resolver: yupResolver(getSchema()),
  });
  const email = watch('email');
  const { createPayload } = useSubmitStep1();
  const removeCookie = useCookies([
    YOMONI_UTM_COOKIE_NAME,
    YOMONI_BUSINESS_REFERRAL_COOKIE_NAME,
  ])[2];

  const { mutate: registerUser, isLoading } = useMutation(
    () => UserClient.createUser({ ...createPayload(), email, sponsorCode, externalToken }),
    {
      onSuccess: async ({ jwtToken }) => {
        localStorage.setItem(YOMONI_JWT_TOKEN_NAME, jwtToken);
        await queryClient.invalidateQueries('userDetail');
        const userDetail = await refreshUser();

        removeCookie(YOMONI_UTM_COOKIE_NAME, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });
        removeCookie(YOMONI_BUSINESS_REFERRAL_COOKIE_NAME, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
        });

        onSuccess(userDetail!);
      },
      retry: (failureCount, error) => {
        if (error instanceof AxiosError && error.response?.status === 409) {
          onConflict(email);
          return false;
        }
        return true;
      },
    },
  );

  return (
    <Stack gap={3} role="region" aria-label="email-input">
      <Typography variant="lg" fontWeight="fontWeightBold">
        {intl.formatMessage(
          {
            id: tryNewWording
              ? 'emailSubmission.inputCard.title.abtest'
              : 'emailSubmission.inputCard.title',
          },
          { br: <br /> },
        )}
      </Typography>
      <form onSubmit={handleSubmit(() => registerUser())}>
        <Stack gap={3}>
          <InputText
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            placeholder={intl.formatMessage({ id: 'emailSubmission.inputCard.yourEmail' })}
            suffix={<MailOutlineIcon />}
            disabled={isLoading}
            autoComplete="email"
            InputProps={{
              inputProps: {
                inputMode: 'email',
                autoCapitalize: 'off',
              },
            }}
          />
          <PrimaryButton
            type="submit"
            titleId={
              tryNewWording
                ? 'emailSubmission.inputCard.continue.abtest'
                : 'emailSubmission.inputCard.continue'
            }
            loading={isLoading}
            disabled={!isValid || isLoading}
          />
        </Stack>
      </form>

      <Typography variant="md" color="primary.light" fontWeight="fontWeightLight">
        {intl.formatMessage(
          { id: 'emailSubmission.inputCard.termsOfUse' },
          { link: (link) => externalLink(link, URLS.TERMS_OF_USE_AND_PRIVACY_POLICY) },
        )}
      </Typography>
    </Stack>
  );
}
