import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import ResumeClient from '@services/clients/resume';
import { PrepareNewProjectResponse, ResumeResponse } from '@shared/types';

interface QueryOptions<T>
  extends Omit<UseQueryOptions<T, Error, T, QueryKey>, 'queryKey' | 'queryFn'> {}

export const resumeQuery = (options?: QueryOptions<ResumeResponse>) => {
  return useQuery<ResumeResponse, Error>('resume', ResumeClient.getResume, options);
};

export const prepareNewProjectQuery = (options?: QueryOptions<PrepareNewProjectResponse>) => {
  return useQuery<PrepareNewProjectResponse, Error>(
    'prepare',
    ResumeClient.prepareNewProject,
    options,
  );
};

export default { resumeQuery };
