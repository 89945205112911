import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRiskRewardPercentage from '@features/project/components/questions/QuestionRiskRewardPercentage/QuestionRiskRewardPercentage';
import checkRulesQuestionRiskRewardPercentage from '@features/project/components/questions/QuestionRiskRewardPercentage/QuestionRiskRewardPercentage.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.RISK_REWARD_PERCENTAGE,
  headerTitle: 'step1.question.layout.header.risk-relation.title',
  questionTitle: 'step1.question.risk-reward-percentage.title',
  questionSubtitle: 'step1.question.risk-reward-percentage.subtitle',
  cdpEvent: CdpEventName.QUESTION_PERCENTAGE_WIN_LOSS_RATIO_QUIZ,
  Component: QuestionRiskRewardPercentage,
  payloadKeys: [FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ],
  rulesFn: checkRulesQuestionRiskRewardPercentage,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.risk-reward-percentage.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
