import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FiscalPackage, WithdrawHalfProbability } from '@shared/types';

export default function checkRulesQuestionWithdrawalHalf(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.probabilityOfWithdrawalHalf) {
    isValid = false;
  }

  if (wantsStockSavingsPlanAndWithdrawalHalfIsCertainlyOrProbably(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.withdrawal-half.shouldntWithdraw.preferredFiscalPackage.PEA',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q16-RG1
 */
function wantsStockSavingsPlanAndWithdrawalHalfIsCertainlyOrProbably(
  ruleParams: QuestionRuleParams,
) {
  return (
    ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN &&
    ruleParams.questionState.probabilityOfWithdrawalHalf &&
    [WithdrawHalfProbability.CERTAINLY, WithdrawHalfProbability.PROBABLY].includes(
      ruleParams.questionState.probabilityOfWithdrawalHalf,
    )
  );
}
