import dayjs from 'dayjs';

export const DATE_API_FORMAT_PATTERN = 'YYYY-MM-DD';
export const DATE_FORMAT_PATTERN = 'DD/MM/YYYY';

export function formatFromApiDate(date?: string): string {
  return date ? dayjs(date, DATE_API_FORMAT_PATTERN).format(DATE_FORMAT_PATTERN) : '';
}
export function formatToApiDate(date: string): string {
  return date ? dayjs(date, DATE_FORMAT_PATTERN).format(DATE_API_FORMAT_PATTERN) : '';
}
