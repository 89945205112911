import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionEtfKnowledge from '@features/project/components/questions/QuestionEtfKnowledge/QuestionEtfKnowledge';
import checkRulesQuestionEtfKnowledge from '@features/project/components/questions/QuestionEtfKnowledge/QuestionEtfKnowledge.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.ETF_KNOWLEDGE,
  headerTitle: 'step1.question.layout.header.knowledge.title',
  questionTitle: 'step1.question.etf-knowledge.title',
  questionSubtitle: 'step1.question.etf-knowledge.subtitle',
  cdpEvent: CdpEventName.QUESTION_ETF_KNOWLEDGE_QUIZ,
  Component: QuestionEtfKnowledge,
  payloadKeys: [FunnelAnswersKeys.ETF_KNOWLEDGE_QUIZ],
  rulesFn: checkRulesQuestionEtfKnowledge,
};

export default {
  classic: baseDescription,
  legalEntity: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.etf-knowledge.kid.subtitle',
  },
};
