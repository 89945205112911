import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage, useIntl } from 'react-intl';

import StyledModal from '@components/StyledModal/StyledModal';
import { useWelcomeModal } from '@features/project/components/WelcomeModal/useWelcomeModal';
import { CdpEventName, PreferredFiscalPackage } from '@shared/types';

export interface WelcomeModalProps {
  isOpen: boolean;
  isMobile: boolean;
  isTransfer: boolean;
  fiscalPackage: PreferredFiscalPackage;
  handleClose: () => void;
}

function WelcomeModal({
  isOpen,
  isMobile,
  fiscalPackage,
  handleClose,
  isTransfer,
}: WelcomeModalProps) {
  const intl = useIntl();

  const { titleId, textId } = useWelcomeModal({ fiscalPackage, isTransfer });

  if (!intl.messages[titleId] || !intl.messages[textId]) {
    return null;
  }

  return (
    <StyledModal
      ariaLabel="welcomeModal"
      isOpen={isOpen}
      titleProps={{
        titleId,
        variant: 'xl',
        fontWeight: 'fontWeightBold',
      }}
      primaryButtonTitleId="welcomeModal.button"
      handlePrimaryButtonClick={handleClose}
      primaryButtonEvent={{ event: CdpEventName.CLICK_BUTTON_START_WELCOME_MODAL_STEP_2 }}
      width={isMobile ? '80%' : 'fit-content'}
      sx={{ minWidth: { md: '715px' } }}>
      <Box sx={{ textAlign: 'center', px: 3 }}>
        <Typography variant="lg" color="primary.light">
          <FormattedMessage
            id={textId}
            values={{ fiscalPackage: intl.formatMessage({ id: `fiscalPackage.${fiscalPackage}` }) }}
          />
        </Typography>
      </Box>
    </StyledModal>
  );
}

export default WelcomeModal;
