import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionDelegation from '@features/project/components/questions/QuestionDelegation/QuestionDelegation';
import checkRulesQuestionDelegation from '@features/project/components/questions/QuestionDelegation/QuestionDelegation.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.DELEGATION,
  headerTitle: 'step1.question.layout.header.knowledge.title',
  questionTitle: 'step1.question.delegation.title',
  questionSubtitle: 'step1.question.delegation.subtitle',
  cdpEvent: CdpEventName.QUESTION_COMPANY_DELEGATION_QUIZ,
  Component: QuestionDelegation,
  payloadKeys: [FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ],
  rulesFn: checkRulesQuestionDelegation,
};

export default {
  classic: baseDescription,
  legalEntity: baseDescription,
  kid: { ...baseDescription, questionSubtitle: 'step1.question.delegation.subtitle.kid' },
};
