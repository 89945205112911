import { Navigate } from 'react-router-dom';

import ROUTES, { PRODUCT_FUNNEL_ROUTES } from '@constants/routes.const';
import ProductFunnelQuestions from '@features/productFunnel/components/ProductFunnelQuestions';
import FunnelLayout from '@layouts/FunnelLayout';
import { METHOD_BOX_GRAY } from '@theme';

export default function productFunnelRoutes() {
  return [
    {
      path: ROUTES.PRODUCT_FUNNEL,
      element: (
        <FunnelLayout
          backgroundColor={METHOD_BOX_GRAY}
          withLoginHeader={false}
          withStepper={false}
        />
      ),
      children: [
        {
          index: true,
          element: <Navigate to={PRODUCT_FUNNEL_ROUTES.FISCAL_PACKAGE} replace />,
        },
        {
          path: `:questionPath`,
          element: <ProductFunnelQuestions />,
        },
      ],
    },
  ];
}
