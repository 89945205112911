import ROUTES from '@constants/routes.const';
import QuestionFunnelSelection from '@features/funnelSelection/QuestionFunnelSelection/QuestionFunnelSelection';
import FunnelLayout from '@layouts/FunnelLayout';
import { METHOD_BOX_GRAY } from '@theme';

export default function funnelSelectionRoutes() {
  return [
    {
      path: ROUTES.FUNNEL_SELECTION,
      element: (
        <FunnelLayout
          backgroundColor={METHOD_BOX_GRAY}
          withLoginHeader={false}
          withStepper={false}
        />
      ),
      children: [
        {
          index: true,
          element: <QuestionFunnelSelection />,
        },
      ],
    },
  ];
}
