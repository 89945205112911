import { AppBar } from '@mui/material';
import styled from '@mui/styled-engine';
import { Container } from '@mui/system';
import { useLocation } from 'react-router-dom';

import ROUTES from '@constants/routes.const';
import FooterRecommendation from '@layouts/components/Footer/FooterRecommendation/FooterRecommendation';
import { BOX_SHADOW_MD, WHITE } from '@theme';

export const StyledAppBar = styled(AppBar)(() => ({
  position: 'fixed',
  bottom: 0,
  top: 'auto',
  backgroundColor: WHITE,
  boxShadow: BOX_SHADOW_MD,
}));

export default function Footer() {
  const location = useLocation();

  const isRecommendationPage = location.pathname === ROUTES.RECOMMANDATION;

  if (!isRecommendationPage) return null;

  return (
    <StyledAppBar role="region" aria-label="footer">
      <Container sx={{ p: 2 }}>{isRecommendationPage && <FooterRecommendation />}</Container>
    </StyledAppBar>
  );
}
