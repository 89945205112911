import { Stack, TextField } from '@mui/material';
import React, { createRef, useEffect } from 'react';

import useOtp from '@hooks/useOtp';
import theme from '@theme';

export interface OtpFieldProps {
  autoFocus?: boolean;
  disabled?: boolean;
  error: boolean;
  onChange: (values: string[]) => void;
  otpLength?: number;
  value?: string[];
}
export default function OtpField({
  autoFocus,
  disabled,
  error,
  onChange: onChangeProp,
  otpLength,
  value,
}: OtpFieldProps) {
  const { activeIndex, onChange, onKeyDown, onInput, onPaste, onFocus } = useOtp({
    autoFocus,
    onChange: onChangeProp,
    otpType: 'number',
    otpLength,
    value,
  });

  const inputRefs: React.RefObject<any>[] = Array(otpLength)
    .fill(0)
    .map(() => createRef());

  const inputs =
    otpLength &&
    [...Array(otpLength)]
      .map((_, i) => i + 1)
      .map((key, index) => (
        <TextField
          key={key}
          inputProps={{
            autoFocus: index === activeIndex,
            autoComplete: 'one-time-code', // Display AutoFill suggestion on iOS
            inputMode: 'numeric', // Display numeric keyboard on mobile browsers
            maxLength: 1,
            onFocus: (e) => onFocus(index, e),
            onInput,
            onKeyDown,
            onPaste,
            pattern: '[0-9]*', // Display numeric keyboard on iOS
            size: 1,
          }}
          sx={{
            width: '60px',
            height: '60px',
            '&.MuiTextField-root': {
              '.MuiInputBase-root': {
                height: '100%',
              },
              '& input': {
                fontSize: theme.typography.lg.fontSize,
                lineHeight: theme.typography.lg.lineHeight,
                fontWeight: theme.typography.lg.fontWeight,
                fontFamily: theme.typography.lg.fontFamily,
              },
            },
          }}
          disabled={disabled}
          error={error}
          inputRef={inputRefs[index]}
          onChange={onChange}
          value={value ? value[index] : ''}
        />
      ));

  // Handle focus changes (e.g. left and right arrow keys)
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRefs[activeIndex].current && inputRefs[activeIndex].current.focus();
  }, [activeIndex, inputRefs]);

  return (
    <Stack direction="row" justifyContent="center" gap={1}>
      {inputs}
    </Stack>
  );
}

OtpField.defaultProps = {
  autoFocus: true,
  disabled: false,
  otpLength: 6,
  value: [],
};
