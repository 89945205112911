import { Grid, SxProps } from '@mui/material';
import { ComponentProps, FunctionComponent, useEffect, useState } from 'react';

import ToggleButton from '@components/ToggleButton/ToggleButton';
import { isMobileDimensions } from '@utils/global.utils';

export interface ButtonEntry<T extends string> {
  value: T;
  titleId: string;
  subtitleId?: string;
  subtitleValues?: Record<string, string | number | JSX.Element>;
  StartIcon?: FunctionComponent;
  EndIcon?: FunctionComponent;
}

interface ToggleButtonGroupProps<T extends string> {
  buttons: ButtonEntry<T>[];
  selectedValue?: T;
  disabled?: boolean;
  onChange: (value: T) => void;
  addHiddenItem?: boolean;
  iconAboveText?: boolean;
  containerProps?: ComponentProps<typeof Grid>;
  itemProps?: ComponentProps<typeof Grid>;
  sxPropsButton?: SxProps;
  withoutBorderOnSelected?: boolean;
}

export default function ToggleButtonGroup<T extends string>({
  buttons,
  selectedValue,
  disabled,
  onChange,
  iconAboveText,
  containerProps,
  itemProps,
  sxPropsButton,
  addHiddenItem: addEmptyHiddenItem,
  withoutBorderOnSelected,
}: ToggleButtonGroupProps<T>) {
  const isMobile = isMobileDimensions('md');
  const [selectedButtonValue, setSelectedButtonValue] = useState<T | undefined>(selectedValue);

  useEffect(() => {
    setSelectedButtonValue(selectedValue);
  }, [selectedValue]);

  const onClick = (value: T) => {
    setSelectedButtonValue(value);
    onChange(value);
  };

  return (
    <Grid
      container
      spacing={isMobile ? 1 : 2}
      justifyContent="center"
      {...containerProps}
      sx={{ ...containerProps?.sx }}>
      {buttons.map(({ value, titleId, StartIcon, EndIcon, subtitleId, subtitleValues }) => {
        return (
          <Grid item key={value} {...itemProps} sx={{ ...itemProps?.sx }}>
            <ToggleButton
              titleId={titleId}
              subtitleId={subtitleId}
              subtitleValues={subtitleValues}
              value={value}
              toggled={!withoutBorderOnSelected && value === selectedButtonValue}
              disabled={disabled}
              onClick={() => onClick(value)}
              startIcon={StartIcon && <StartIcon />}
              endIcon={EndIcon && <EndIcon />}
              sx={{
                width: '100%',
                ...(iconAboveText && {
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'start',
                  '& .MuiButton-startIcon': {
                    marginRight: isMobile ? 0 : 1,
                    marginBottom: isMobile ? 1 : 0,
                  },
                }),
                ...sxPropsButton,
              }}
            />
          </Grid>
        );
      })}
      {addEmptyHiddenItem && (
        <Grid item {...itemProps} sx={{ ...itemProps?.sx, visibility: 'hidden' }} />
      )}
    </Grid>
  );
}

ToggleButtonGroup.defaultProps = {
  selectedValue: undefined,
  disabled: false,
  iconAboveText: false,
  containerProps: {},
  itemProps: {},
  sxPropsButton: {},
  addHiddenItem: false,
  withoutBorderOnSelected: false,
};
