import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import useRulesParameters from '@features/project/hooks/useRulesParameters';
import { Question } from '@features/project/question.utils';
import useQuestion from '@hooks/useQuestion';
import { questionsSelector } from '@state';

export default function useQuestionsGuard(questions: Question[], currentQuestionIndex?: number) {
  const rulesParameters = useRulesParameters();
  const questionState = useRecoilValue(questionsSelector);

  const [invalidQuestions, setInvalidQuestions] = useState<Question[]>([]);

  const { questionNumber: firstErrorQuestionIndex } = useQuestion(
    questions,
    invalidQuestions[0]?.path,
  );

  useEffect(() => {
    const tmpInvalidQuestions: Question[] = [];

    questions.forEach((question) => {
      let isValid;
      try {
        isValid = question.rulesFn(rulesParameters).isValid;
      } catch (error) {
        isValid = false;
      }

      if (!isValid) {
        tmpInvalidQuestions.push(question);
      }
    });

    setInvalidQuestions(tmpInvalidQuestions);
  }, [questions, questionState, currentQuestionIndex]);

  let isErrorBeforeCurrentQuestion;

  if (currentQuestionIndex === undefined) {
    isErrorBeforeCurrentQuestion = undefined;
  } else if (invalidQuestions.length > 0) {
    isErrorBeforeCurrentQuestion = firstErrorQuestionIndex < currentQuestionIndex;
  } else if (invalidQuestions.length === 0) {
    isErrorBeforeCurrentQuestion = false;
  }

  return {
    invalidQuestions,
    isErrorBeforeCurrentQuestion,
  };
}
