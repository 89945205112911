import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import InputDate from '@components/InputDate/InputDate';
import checkRulesQuestionBirthdateChildren from '@features/project/components/questions/QuestionBirthdayChildren/QuestionBirthdayChildren.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import { birthdateChildrenQuestionState, questionsSelector } from '@state';
import { formatFromApiDate, formatToApiDate } from '@utils/date.utils';
import { isStringDefined } from '@utils/input.utils';

export default function QuestionBirthdayChildren() {
  const { updateContext, isValid } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const setBirthdateChildren = useSetRecoilState(birthdateChildrenQuestionState);

  useEffect(() => {
    updateContext(checkRulesQuestionBirthdateChildren({ questionState }));
  }, []);

  const onChange = (newBirthdate: string) => {
    const newBirthdateFormatted = formatToApiDate(newBirthdate);
    setBirthdateChildren(newBirthdateFormatted);
    updateContext({
      ...checkRulesQuestionBirthdateChildren({
        questionState: { ...questionState, birthdateKid: newBirthdateFormatted },
      }),
      debounceError: true,
    });
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <InputDate
        name="children-birthdate"
        onChange={onChange}
        date={formatFromApiDate(questionState.birthdateKid)}
        valid={isValid}
        error={isStringDefined(questionState.birthdateKid) && !isValid}
        debounceDuration={DEBOUNCE_DURATION}
      />
    </Box>
  );
}
