import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import parsePhoneNumber, { CountryCode, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
import { ForwardedRef, forwardRef, useMemo, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { useIntl } from 'react-intl';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import fr from 'react-phone-input-2/lang/fr.json';

import {
  countries,
  fromInternationalPhoneNumber,
  isCountryData,
} from '@components/InputPhoneNumber/utils';
import theme, {
  BOX_SHADOW_MD,
  ERROR_DARK,
  ERROR_LIGHT,
  ERROR_MAIN,
  GRAY_MEDIUM,
  PRIMARY_MAIN,
} from '@theme';

import 'react-phone-input-2/lib/material.css';

const useStyles = makeStyles(() => ({
  dropdown: {
    boxShadow: `${BOX_SHADOW_MD} !important`,
  },
  input: {
    width: '100% ! important',
    borderColor: (error) => `${error ? ERROR_MAIN : GRAY_MEDIUM} !important`,
    backgroundColor: (error) => `${error ? ERROR_LIGHT : 'inherit'} !important`,
    paddingLeft: '80px !important',
    ...theme.typography.lg,
    '&:hover': {
      borderColor: (error) => `${error ? ERROR_DARK : PRIMARY_MAIN} !important`,
    },
    '&:focus': {
      borderColor: `${PRIMARY_MAIN} !important`,
      boxShadow: 'none !important',
    },
  },
  flag: {
    '& .selected-flag': {
      paddingLeft: theme.spacing(3),
      '&:focus .arrow': {
        border: 'none',
        left: '25px',
        top: '1px',
      },
      '& .arrow': {
        '&.up': {
          borderBottom: 'none',
          left: '23px',
          top: '8%',
        },
        width: '24px',
        height: '24px',
        borderColor: 'transparent',
        left: '22px',
        top: '-11%',
        backgroundRepeat: 'no-repeat',
        backgroundImage: `url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M15.25 10.75L12 14.25L8.75 10.75" stroke="%231F2663" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" /></svg>')`,
      },
      '& .flag': {
        borderRadius: '11px',
        height: '24px',
        width: '24px',
      },
    },
  },
}));

interface InputPhoneNumberProps {
  onChange: (phoneNumber: string) => void;
  disabled?: boolean | undefined;
  name: string;
  invalid?: boolean;
  error?: FieldError;
  value?: string;
}

const InputPhoneNumber = forwardRef<HTMLInputElement, InputPhoneNumberProps>(
  (
    { onChange, name, disabled, error, invalid, value, ...rest }: InputPhoneNumberProps,
    ref?: ForwardedRef<HTMLInputElement>,
  ) => {
    const intl = useIntl();
    const classes = useStyles(Boolean(error));
    const [country, setCountry] = useState<CountryCode>('FR');

    const handleChange: PhoneInputProps['onChange'] = (incomingValue, data) => {
      let customValue = incomingValue;

      if (isCountryData(data)) {
        const selectedCountry = countries.find((code) => code === data.countryCode.toUpperCase());
        if (selectedCountry) {
          setCountry(selectedCountry);

          const phoneNumberInstance = parsePhoneNumber(incomingValue, selectedCountry);
          if (phoneNumberInstance) {
            customValue = phoneNumberInstance.formatInternational();
          }
        }
      }

      if (onChange) {
        onChange(customValue);
      }
    };

    const defaultMask = useMemo(
      () => getExampleNumber(country, examples)?.formatNational().replace(/\d/g, '.') || '',
      [country],
    );

    return (
      <>
        <PhoneInput
          autoFormat={false}
          disableCountryGuess
          disableCountryCode
          defaultMask={defaultMask}
          placeholder={intl.formatMessage({ id: 'phonerNumberModal.input.placeholder' })}
          country={country.toLowerCase()}
          localization={fr}
          inputClass={classes.input}
          buttonClass={classes.flag}
          dropdownClass={classes.dropdown}
          specialLabel=""
          onChange={handleChange}
          disabled={disabled}
          inputProps={{
            name,
            ref: ref || undefined,
          }}
          value={fromInternationalPhoneNumber(value)}
          {...rest}
        />
        {invalid && (
          <Typography variant="sm" color={ERROR_DARK}>
            {error?.message}
          </Typography>
        )}
      </>
    );
  },
);

InputPhoneNumber.defaultProps = {
  disabled: false,
  error: undefined,
  invalid: false,
  value: undefined,
};

export default InputPhoneNumber;
