/* eslint-disable no-use-before-define */
import dayjs from 'dayjs';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { DATE_API_FORMAT_PATTERN } from '@utils/date.utils';
import { isStringDefined } from '@utils/input.utils';

export default function checkRulesQuestionBirthdate(ruleParams: QuestionRuleParams): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { birthdate } = ruleParams.questionState;

  if (
    !birthdate ||
    !isStringDefined(birthdate) ||
    birthdate.length < DATE_API_FORMAT_PATTERN.length
  ) {
    isValid = false;
  } else if (isNotAValidDate(birthdate)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.birthday.incorrect',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  } else if (birthdateIsMoreThan120Years(birthdate)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.birthday.too-old',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  } else if (isNotAdultBirthdate(birthdate)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.birthday.not-adult',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 *
 * Q_BIRTHDAY_RG1
 */
function isNotAValidDate(birthdate: string) {
  return (
    !dayjs(birthdate, DATE_API_FORMAT_PATTERN, true).isValid() ||
    dayjs(birthdate, DATE_API_FORMAT_PATTERN, true).isAfter(dayjs())
  );
}

/**
 *
 * Q_BIRTHDAY_RG2
 */
function birthdateIsMoreThan120Years(birthdate: string) {
  return dayjs(birthdate, DATE_API_FORMAT_PATTERN, true).isBefore(
    dayjs().subtract(120, 'year'),
    'day',
  );
}

/**
 *
 * Q_BIRTHDAY_RG3
 */
function isNotAdultBirthdate(birthdate: string) {
  return dayjs(birthdate, DATE_API_FORMAT_PATTERN, true).isAfter(
    dayjs().subtract(18, 'year'),
    'day',
  );
}
