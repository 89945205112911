import { Toggle, rescaleTo100 } from '@hooks/toggle/toggle.utils';
import useYomoniCdpCookie from '@hooks/useYomoniCdpCookie';

export default function useToggle(toggle: Toggle): boolean {
  const segmentId = useYomoniCdpCookie()?.yomoniCdpCookie.anonymousId;
  const toggleEnvValue = process.env[toggle];

  if (toggleEnvValue) {
    const isActive = toggleEnvValue === 'true';
    const isRollout = toggleEnvValue.startsWith('rollout'); // format: rollout:50

    if (segmentId) {
      const hashValue = rescaleTo100(segmentId);
      if (isRollout) {
        const rollout = parseInt(toggleEnvValue.split(':')[1] || '0', 10);
        return hashValue <= rollout;
      }
    }

    return isActive;
  }

  return false;
}
