import { useContext, useEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionPrimaryResidency from '@features/project/components/questions/QuestionPrimaryResidence/QuestionPrimaryResidence.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import { PrimaryResidence as PrimaryResidenceOwnership } from '@shared/types';
import {
  creditOrRentMainResidenceQuestionState,
  ownerMainResidenceQuestionState,
  questionsSelector,
} from '@state';

export default function QuestionPrimaryResidence() {
  const { updateContext } = useContext(QuestionContext);

  const questionState = useRecoilValue(questionsSelector);
  const setOwnerMainResidenceStateValue = useSetRecoilState(ownerMainResidenceQuestionState);
  const setMonthlyRentOrMortageStateValue = useSetRecoilState(
    creditOrRentMainResidenceQuestionState,
  );
  const isInitialownerMainResidenceStateValue = useRef(questionState.ownerMainResidence);

  const buttons = [
    {
      value: PrimaryResidenceOwnership.OWNER,
      titleId: 'step1.question.primary-residence.button.OWNER',
    },
    {
      value: PrimaryResidenceOwnership.NOT_OWNER,
      titleId: 'step1.question.primary-residence.button.NOT_OWNER',
    },
  ];

  useEffect(() => {
    // Q9-RG2
    // Q9-RG3
    if (isInitialownerMainResidenceStateValue.current !== questionState.ownerMainResidence) {
      setMonthlyRentOrMortageStateValue(undefined);
    }
  }, [questionState.ownerMainResidence]);

  useEffect(() => {
    updateContext(checkRulesQuestionPrimaryResidency({ questionState }));
  }, []);

  const onClick = (newPrimaryResidenceOwnership: PrimaryResidenceOwnership) => {
    setOwnerMainResidenceStateValue(newPrimaryResidenceOwnership);
    updateContext({
      ...checkRulesQuestionPrimaryResidency({
        questionState: {
          ...questionState,
          ownerMainResidence: newPrimaryResidenceOwnership,
        },
      }),
      autoValidate: true,
    });
  };

  return (
    <ToggleButtonGroup
      buttons={buttons}
      selectedValue={questionState.ownerMainResidence}
      onChange={onClick}
      itemProps={{ xs: 6, sm: 2 }}
    />
  );
}
