import { Box } from '@mui/system';
// eslint-disable-next-line import/no-extraneous-dependencies
import { PrimitiveType } from 'intl-messageformat';
import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import QuestionLayout from '@components/QuestionLayout/QuestionLayout';
import Tooltip from '@components/Tooltip/Tooltip';
import ROUTES, { PROJECT_ROUTES } from '@constants/routes.const';
import SkipStep1Button from '@features/project/components/SkipStep1Button/SkipStep1Button';
import useProjectParams from '@features/project/hooks/useProjectParams';
import useQuestionsGuard from '@features/project/hooks/useQuestionsGuard';
import useShowSkipStep1Button from '@features/project/hooks/useShowSkipStep1Button';
import QUESTION_TRACKING_STATE_MAP from '@features/project/question-tracking.const';
import { Question } from '@features/project/question.utils';
import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggle from '@hooks/toggle/useToggle';
import useTracking from '@hooks/tracking/useTracking';
import useQuestion from '@hooks/useQuestion';
import useSubmitStep1 from '@hooks/useSubmitStep1';
import { UserContext } from '@providers/UserProvider';
import ProjectClient from '@services/clients/project';
import { AbTestVersionName, Action, CdpCategory, CdpEvent } from '@shared/types';
import {
  lastProductFunnelRouteState,
  preferredEnvelopeState,
  preferredTransferState,
  questionLayoutSubtitleState,
  questionLayoutTitleState,
  questionsSelector,
} from '@state';

export type InfoBoxContextValue = {
  infoBoxMessage: string;
  infoBoxLevel: InfoBoxLevel;
  infoBoxLink?: string;
  openLinkInNewTab?: boolean;
  infoBoxMessageValues?: Record<string, PrimitiveType>;
  infoBoxImageSrc?: string;
};

export type QuestionContextValue = {
  isValid: boolean;
  autoValidate: boolean;
  infoBoxMessages: InfoBoxContextValue[];
  debounceError?: boolean;
  updateContext: (context: Partial<QuestionContextValue>) => void;
};

export type RulesResult = Pick<QuestionContextValue, 'isValid' | 'infoBoxMessages'>;

const defaultContext: QuestionContextValue = {
  isValid: false,
  autoValidate: false,
  infoBoxMessages: [],
  debounceError: false,
  updateContext: () => {},
};
export const QuestionContext = createContext<QuestionContextValue & typeof defaultContext>(
  defaultContext,
);

export interface Step1QuestionsProps {
  questions: Question[];
  questionPath: string;
}

export default memo(function Step1Questions({ questions, questionPath }: Step1QuestionsProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { createPayload } = useSubmitStep1();
  const { queryParamsStr, isProfessionalParam } = useProjectParams();
  const [transitionning, setTransitionning] = useState(false);

  const [contextState, setContextState] = useState<QuestionContextValue & typeof defaultContext>(
    defaultContext,
  );
  const contextValue = useMemo(
    () => ({
      ...contextState,
      updateContext: (partialContext: Partial<QuestionContextValue>) => {
        setContextState((currentContextState) => ({
          ...currentContextState,
          ...partialContext,
        }));
      },
    }),
    [contextState],
  );
  const [questionLayoutTitle, setQuestionLayoutTitle] = useRecoilState(questionLayoutTitleState);
  const [questionLayoutSubtitle, setQuestionLayoutSubtitle] = useRecoilState(
    questionLayoutSubtitleState,
  );

  const { sendEvent } = useTracking();
  const questionState = useRecoilValue(questionsSelector);
  const preferredFiscalPackage = useRecoilValue(preferredEnvelopeState);
  const preferredTransfer = useRecoilValue(preferredTransferState);
  const [lastProductFunnelRoute, setLastProductFunnelRoute] = useRecoilState(
    lastProductFunnelRouteState,
  );
  const queryClient = useQueryClient();
  const { question, questionNumber, previousQuestion, nextQuestion } = useQuestion(
    questions,
    questionPath,
  );
  const canGoBackToProductFunnel = lastProductFunnelRoute !== undefined && questionNumber === 1;
  const tryQuestionsWithNewOrder = useToggle(Toggle.QUESTIONS_NEW_ORDER);

  const { Component } = question;
  const { invalidQuestions, isErrorBeforeCurrentQuestion } = useQuestionsGuard(
    questions,
    questionNumber,
  );

  const showSkipStep1Button = useShowSkipStep1Button(invalidQuestions);

  const clearContext = () => {
    setQuestionLayoutTitle(undefined);
    setQuestionLayoutSubtitle(undefined);
    setContextState((currentContextState) => ({
      ...currentContextState,
      autoValidate: false,
      infoBoxMessages: [],
      isValid: false,
      debounceError: false,
    }));
  };

  useEffect(() => {
    // if accessing a question directly, we need to check if the all the previous questions are valid
    if (invalidQuestions.length > 0 && isErrorBeforeCurrentQuestion === true) {
      clearContext();
      navigate(`/${ROUTES.PROJECT}/${invalidQuestions[0].path}${queryParamsStr}`);
    }
  }, [invalidQuestions, question]);

  const switchQuestion = (newQuestion: Question) => {
    setTransitionning(true);
    setTimeout(() => {
      clearContext();
      navigate(`/${ROUTES.PROJECT}/${newQuestion.path}${queryParamsStr}`);
      setTransitionning(false);
    }, 300);
  };

  const handleBack = () => {
    if (canGoBackToProductFunnel) {
      setLastProductFunnelRoute(undefined);
      navigate(lastProductFunnelRoute);
    } else if (previousQuestion) {
      switchQuestion(previousQuestion);
    }
  };

  const onSubmitSuccess = async () => {
    clearContext();
    queryClient.removeQueries('resume');
    navigate(ROUTES.RECOMMANDATION);
  };
  const { mutate: createNewProject, isLoading: isCreatingNewProject } = useMutation(
    () => ProjectClient.postCreateNewProject(createPayload()),
    {
      onSuccess: onSubmitSuccess,
    },
  );
  const { mutate: patchProject, isLoading: isPatching } = useMutation(
    () => ProjectClient.patchProject(createPayload()),
    {
      onSuccess: onSubmitSuccess,
    },
  );

  const sendEventQuestionProject = () => {
    const questionsOrderVersion = tryQuestionsWithNewOrder
      ? AbTestVersionName.NEW
      : AbTestVersionName.ORIGINAL;
    const event: CdpEvent = {
      event: question!.cdpEvent,
      properties: {
        category: CdpCategory.QUESTION,
        isProfessional: isProfessionalParam,
        preferredFiscalPackage,
        preferredTransfer,
        questionsOrderVersion,
      },
    };
    question!.payloadKeys?.forEach((param) => {
      const paramKey = QUESTION_TRACKING_STATE_MAP.get(param);
      event.properties![paramKey!] = questionState[param];
    });
    sendEvent(event);
  };

  const handleNext = () => {
    if (!contextState.isValid) {
      return;
    }

    if (!transitionning && nextQuestion) {
      sendEventQuestionProject();
      switchQuestion(nextQuestion);
    } else if (!(transitionning || isCreatingNewProject || isPatching)) {
      if (!user) {
        sendEventQuestionProject();
        navigate(`/${ROUTES.PROJECT}/${PROJECT_ROUTES.ACCOUNT_CREATION}`);
      } else if (user?.actions.includes(Action.CREATE_PROJECT)) {
        createNewProject();
      } else {
        patchProject();
      }
    }
  };

  useEffect(() => {
    const canAutoValidate = () => {
      const hasErrorMessage = contextState.infoBoxMessages.some(
        (infoBoxMessage) => infoBoxMessage.infoBoxLevel === InfoBoxLevel.ERROR,
      );
      const hasWarningMessage = contextState.infoBoxMessages.some(
        (infoBoxMessage) => infoBoxMessage.infoBoxLevel === InfoBoxLevel.WARNING,
      );
      return (
        contextState.isValid && contextState.autoValidate && !hasWarningMessage && !hasErrorMessage
      );
    };

    if (canAutoValidate()) {
      setTimeout(() => {
        handleNext();
      }, 300);
    }
  }, [contextState.isValid, contextState.autoValidate]);

  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleNext();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => window.removeEventListener('keydown', onKeyDown);
  }, [onKeyDown]);

  const isLoading = isCreatingNewProject || isPatching;

  return (
    <QuestionLayout
      headerTitle={question.headerTitle}
      title={question && questionLayoutTitle ? questionLayoutTitle : question.questionTitle}
      subtitle={
        question && questionLayoutSubtitle ? questionLayoutSubtitle : question.questionSubtitle
      }
      questionNumber={questionNumber}
      totalQuestionNumber={questions.length}
      infoBoxMessages={contextState.infoBoxMessages}
      debounceError={contextState.debounceError}
      fade={!transitionning}
      handleBack={previousQuestion || canGoBackToProductFunnel ? handleBack : undefined}
      isBackDisabled={
        (!previousQuestion && !canGoBackToProductFunnel) ||
        transitionning ||
        isCreatingNewProject ||
        isPatching
      }
      handleNext={handleNext}
      isNextDisabled={!contextState.isValid || transitionning || isLoading}
      isNextLoading={isLoading}
      ctaChildren={
        showSkipStep1Button
          ? {
              mobile: (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginTop: 1,
                  }}>
                  <SkipStep1Button onClick={patchProject} disabled={isLoading} />
                </Box>
              ),
              desktop: (
                <Tooltip
                  title={intl.formatMessage({ id: 'step1.question.button-skipStep1.tooltip' })}
                  placement="top">
                  <SkipStep1Button onClick={patchProject} disabled={isLoading} />
                </Tooltip>
              ),
            }
          : undefined
      }>
      <QuestionContext.Provider value={contextValue}>
        <Component />
      </QuestionContext.Provider>
    </QuestionLayout>
  );
});
