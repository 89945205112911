import { createContext, useMemo, useState } from 'react';

export type ProductFunnelQuestionsContextType = {
  questionTitleId?: string;
  specificPreviousRoute?: string;
  update: (context: Partial<ProductFunnelQuestionsContextType>) => void;
  reset: () => void;
};
const defaultContext: ProductFunnelQuestionsContextType = {
  questionTitleId: undefined,
  specificPreviousRoute: undefined,
  update: () => {},
  reset: () => {},
};
export const ProductFunnelQuestionsContext =
  createContext<ProductFunnelQuestionsContextType>(defaultContext);

const useProductfunnelQuestionsContext = () => {
  const [contextState, setContextState] =
    useState<ProductFunnelQuestionsContextType>(defaultContext);

  const context: ProductFunnelQuestionsContextType = useMemo(
    () => ({
      ...contextState,
      update: (partialContext) => {
        setContextState((currentContextState) => ({
          ...currentContextState,
          ...partialContext,
        }));
      },
      reset: () => {
        setContextState(defaultContext);
      },
    }),
    [contextState],
  );

  return context;
};

export default useProductfunnelQuestionsContext;
