import { Stack } from '@mui/material';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue } from 'recoil';

import InputText from '@components/InputText/InputText';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionInitialAmount from '@features/project/components/questions/QuestionInitialAmount/QuestionInitialAmount.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { FunnelAnswersKeys, ProjectType } from '@shared/types';
import { questionsSelector } from '@state';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

export default function QuestionInitialAmount() {
  const { isMobile } = useDimensions();
  const intl = useIntl();

  const { isValid } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.INITIAL_PAYMENT,
    checkRulesQuestionInitialAmount,
  );
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.initial-amount',
  });

  const classicButtons = [
    {
      value: '5000',
      titleId: 'step1.question.initial-amount.button.5000',
    },
    {
      value: '10000',
      titleId: 'step1.question.initial-amount.button.10000',
    },
    {
      value: '30000',
      titleId: 'step1.question.initial-amount.button.30000',
    },
    {
      value: '50000',
      titleId: 'step1.question.initial-amount.button.50000',
    },
    {
      value: '150000',
      titleId: 'step1.question.initial-amount.button.150000',
    },
  ];

  const legalEntityButtons = [
    {
      value: '20000',
      titleId: 'step1.question.initial-amount.button.20000',
    },
    {
      value: '35000',
      titleId: 'step1.question.initial-amount.button.35000',
    },
    {
      value: '50000',
      titleId: 'step1.question.initial-amount.button.50000',
    },
    {
      value: '150000',
      titleId: 'step1.question.initial-amount.button.150000',
    },
    {
      value: '500000',
      titleId: 'step1.question.initial-amount.button.500000',
    },
  ];

  const onChangeButtons = (newInitialAmount: number) => {
    update(newInitialAmount, { debounceError: false, autoValidate: true });
  };

  const onChangeInputText = (newInitialAmount: number) => {
    update(newInitialAmount, { debounceError: true, autoValidate: false });
  };

  return (
    <Stack alignItems="center" gap={3}>
      <ToggleButtonGroup
        buttons={
          questionState.projectType === ProjectType.LEGAL_ENTITY
            ? legalEntityButtons
            : classicButtons
        }
        selectedValue={String(answer)}
        onChange={(value) => onChangeButtons(parseInt(value, 10))}
        itemProps={{ xs: isMobile ? 6 : undefined }}
      />

      <InputText
        name="initial-amount"
        debounceDuration={DEBOUNCE_DURATION}
        aria-label="input-text-initial-amount"
        maskProps={{
          mask: Number,
          scale: 0,
          thousandsSeparator: ' ',
          onAccept: (value) => onChangeInputText(parseInt(removeWhitespaces(value), 10)),
        }}
        suffix="€"
        value={String(answer)}
        placeholder={intl.formatMessage({ id: 'step1.question.initial-amount.input.placeholder' })}
        error={isNumberDefined(answer) && !isValid}
        valid={isValid}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            sx: { textAlign: 'right', paddingRight: '0 !important' },
          },
        }}
        sx={{ width: isMobile ? '100%' : '344px' }}
      />
    </Stack>
  );
}
