import { createSearchParams } from 'react-router-dom';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import ROUTES, { EXTERNAL_ROUTES } from '@constants/routes.const';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FiscalPackage, FunnelAnswersKeys, ProjectType, TaxResidency } from '@shared/types';

export default function checkRulesQuestionTaxResidency(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.taxResidence) {
    isValid = false;
  }

  if (isLegalEntityNotFrenchResident(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.tax-residency.legal-entity.info.OTHER',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (isKidProjectAndNotFrenchResident(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.tax-residency.kid.info.OTHER',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (isInitialAmountNotEnoughForNonFrenchResident(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.tax-residency.amount.info.OTHER',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (isKidProjectAndFrenchResident(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.tax-residency.kid.info.FRANCE',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  if (
    isNotFrenchResidentAndNotPreferredTransferAndLifeInsuranceOrStockSavingsPlanPreferredEnvelope(
      ruleParams,
    )
  ) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: `step1.question.tax-residency.preferredEnvelope.${ruleParams.preferredEnvelope}`,
      infoBoxLevel: InfoBoxLevel.ERROR,
      infoBoxLink: 'https://www.yomoni.fr/investir/compte-titres-cto',
      openLinkInNewTab: true,
    });
  }

  if (ruleParams.isAlreadyClient) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.tax-residency.auto-filled',
      infoBoxLevel: InfoBoxLevel.INFO,
      infoBoxLink: `${ROUTES.SSO}?${createSearchParams({
        url: EXTERNAL_ROUTES.EC.SSO(EXTERNAL_ROUTES.EC.ACCOUNT),
      }).toString()}`,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q6-RG1
 */
function isKidProjectAndFrenchResident(ruleParams: QuestionRuleParams) {
  const { projectType, taxResidence } = ruleParams.questionState;

  return projectType === ProjectType.CHILDREN && taxResidence === TaxResidency.FRANCE;
}

/**
 * Q6-RG2
 */
function isKidProjectAndNotFrenchResident(ruleParams: QuestionRuleParams) {
  const { projectType, taxResidence } = ruleParams.questionState;

  return projectType === ProjectType.CHILDREN && taxResidence === TaxResidency.OTHER;
}

/**
 * Q6-RG3
 */
function isInitialAmountNotEnoughForNonFrenchResident(ruleParams: QuestionRuleParams) {
  const { taxResidence, initialPayment } = ruleParams.questionState;

  return taxResidence === TaxResidency.OTHER && initialPayment! < 5000;
}

/**
 * Q6-RG5
 */
function isLegalEntityNotFrenchResident(ruleParams: QuestionRuleParams) {
  const { projectType, taxResidence } = ruleParams.questionState;

  return taxResidence === TaxResidency.OTHER && projectType === ProjectType.LEGAL_ENTITY;
}

/**
 * Q6-RG6
 */
function isNotFrenchResidentAndNotPreferredTransferAndLifeInsuranceOrStockSavingsPlanPreferredEnvelope(
  ruleParams: QuestionRuleParams,
) {
  return (
    ruleParams.questionState[FunnelAnswersKeys.TAX_RESIDENCE] === TaxResidency.OTHER &&
    ruleParams.preferredTransfer !== true &&
    ruleParams.preferredEnvelope &&
    [FiscalPackage.LIFE_INSURANCE, FiscalPackage.STOCK_SAVINGS_PLAN].includes(
      ruleParams.preferredEnvelope,
    )
  );
}
