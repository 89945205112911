import { OtpType } from '@shared/types/otp.interface';
import { PreferredFiscalPackage } from './preferred-fiscal-package.type';

export enum CdpCategory {
  NAVIGATION = 'navigation',
  HELP = 'aide',
  SIMULATION = 'simulation',
  QUESTION = 'question',
}

export enum CdpType {
  TRACK = 'track',
  PAGE = 'page',
}

export enum CdpEventName {
  PAGE_NOT_FOUND = 'page_not_found',

  REGISTER_SUCCESS = 'register_success',
  REGISTER_EMAIL_ALREADY_USED = 'register_email_already_used',
  REGISTER_OTP_SUCCESS = 'register_otp_success',
  REGISTER_CONTRACT_ALREADY_OPENED = 'register_contract_already_opened',

  OTP_INIT = 'otp_init',
  OTP_SUCCESS = 'otp_success',
  OTP_INVALID = 'otp_invalid',
  OTP_ERROR = 'otp_error',

  CLICK_CLOSE_PACKAGE_MODAL = 'close_modify_enveloppe_modal',
  CLICK_VALIDATE_PACKAGE_MODAL = 'clic_valider_cette_enveloppe_button',
  CLICK_SELECT_PER_PACKAGE = 'clic_per_text_link',
  CLICK_TOGGLE_ESG_INVESTMENT = 'clic_investissement_responsable_toggle',
  CLICK_OPEN_RISK_PROFILE = 'clic_ouverture_profil_de_risque',
  CLICK_APPLY_RISK_PROFILE = 'clic_profil_de_risque_appliquer',
  CLICK_VALIDATE_SIMULATION = 'clic_valider_final_button',
  CLICK_APPOINTMENT = 'clic_prendre_rdv_link',
  CLICK_ADVISOR_BUTTON = 'clic_conseiller_button',
  CLICK_PERFORMANCES_SELECT = 'clic_performances',
  CLICK_BUTTON_SKIP_STEP1 = 'clic_passer_etape1_button',
  CLICK_BUTTON_START_WELCOME_MODAL_STEP_2 = 'clic_commencer_modale_bienvenue_etape_2_button',

  CLICK_FUNNEL_SELECTION = 'click_choix_action',

  PRODUCT_FUNNEL_QUESTION_FISCAL_PACKAGE_SELECTION = 'product_funnel_question_fiscal_package_selection',
  PRODUCT_FUNNEL_QUESTION_RECIPIENT_SELECTION = 'product_funnel_question_recipient_selection',

  QUESTION_PROJECT_TYPE = 'Quest-choix-projet',
  QUESTION_INITIAL_PAYMENT = 'Quest-versement-initial',
  QUESTION_RECURRING_PAYMENT = 'Quest-versement-programme',
  QUESTION_HORIZON = 'Quest-horizon',
  QUESTION_BIRTHDATE = 'Quest-date-de-naissance',
  QUESTION_BIRTHDATE_CHILDREN = 'Quest-date-de-naissance',
  QUESTION_TAX_RESIDENCE = 'Quest-residence-fiscale',
  QUESTION_RESPONSIBLE_INVESTMENT = 'Quest-esg',
  QUESTION_CHILDREN_NUMBER = 'Quest-enfants',
  QUESTION_ANNUAL_INCOME = 'Quest-revenus-annuels',
  QUESTION_OWNER_MAIN_RESIDENCE = 'Quest-residence-principale',
  QUESTION_CREDIT_OR_RENT_MAIN_RESIDENCE = 'Quest-residence-principale-charges',
  QUESTION_NET_PROPERTY_PATRIMONY = 'Quest-patrimoine-immobilier',
  QUESTION_FINANCIAL_PATRIMONY = 'Quest-patrimoine-financier',
  QUESTION_SAVINGS_CAPACITY = 'Quest-capacite-epargne',
  QUESTION_PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS = 'Quest-retrait-deux-ans',
  QUESTION_PROBABILITY_OF_WITHDRAWAL_HALF = 'Quest-retrait-moitie-investissement',
  QUESTION_SAVINGS_PRODUCTS = 'Quest-experience-produits-epargne',
  QUESTION_EARNINGS_POTENTIAL_QUIZ = 'Quest-perspective-gains',
  QUESTION_ETF_KNOWLEDGE_QUIZ = 'Quest-connaissance-etf',
  QUESTION_COMPANY_DELEGATION_QUIZ = 'Quest-delegation-gestion',
  QUESTION_REAL_ESTATE_INVESTMENT_QUIZ = 'Quest-connaissance-immo',
  QUESTION_LOSS_OF_REAL_ESTATE_VALUE_QUIZ = 'Quest-valeur-investissement-immobilier',
  QUESTION_LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ = 'Quest-perte-placements-financiers',
  QUESTION_AMOUNT_WIN_LOSS_RATIO_QUIZ = 'Quest-rapport-gains-pertes-montant',
  QUESTION_PERCENTAGE_WIN_LOSS_RATIO_QUIZ = 'Quest-rapport-gains-pertes-pourcentage',
  QUESTION_TEN_PERCENT_LOSS_QUIZ = 'Quest-pertes-dix-pour-cent',

  SHOW_RECOMMENDATION = 'recommendation',

  PHONE_FIRST_MODAL_SHOWN = 'Accompagnement proposé',
  CLICK_PHONE_FIRST_MODAL_DECLINED = 'Accompagnement refusé',
  CLICK_PHONE_FIRST_MODAL_ACCEPTED = 'Accompagnement accepté',
}

export enum ParametersKeys {
  PROJECT_TYPE = 'typeProjet',
  INITIAL_PAYMENT = 'initialAmount',
  RECURRING_PAYMENT = 'recurrentAmount',
  HORIZON = 'timeframe',
  BIRTHDATE = 'birthdate',
  BIRTHDATE_CHILDREN = 'birthdate',
  TAX_RESIDENCE = 'residentFiscal',
  RESPONSIBLE_INVESTMENT = 'esgInvestment',
  CHILDREN_NUMBER = 'nbEnfants',
  GROSS_ANNUAL_INCOME = 'revenusAnnuelsExacts',
  GROSS_ANNUAL_INCOME_RANGE = 'revenusAnnuels',
  OWNER_MAIN_RESIDENCE = 'proprietaireResidencePrincipale',
  CREDIT_OR_RENT_MAIN_RESIDENCE = 'loaning',
  NET_PROPERTY_ASSETS = 'patrimoineImmobilier',
  FINANCIAL_PATRIMONY = 'patrimoineFinancier',
  SAVINGS_CAPACITY = 'capaciteEpargne',
  PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS = 'probabiliteRetraitAvant2ans',
  PROBABILITY_OF_WITHDRAWAL_HALF = 'probabiliteRetrait50PourcentEpargne',
  SAVINGS_PRODUCTS = 'reponseDetentionProduitFinancier',
  EARNINGS_POTENTIAL_QUIZ = 'reponseRisqueGain',
  ETF_KNOWLEDGE_QUIZ = 'reponseETF',
  COMPANY_DELEGATION_QUIZ = 'reponseMandatGestion',
  REAL_ESTATE_INVESTMENT_QUIZ = 'realEstateInvestmentOwnerAnswer',
  LOSS_OF_REAL_ESTATE_VALUE_QUIZ = 'realEstateInvestmentRiskAnswer',
  LOSS_OF_FINANCIAL_INVESTMENTS_VALUE_QUIZ = 'reponsePerteFinanciere',
  AMOUNT_WIN_LOSS_RATIO_QUIZ = 'reponseResistanceRisque',
  PERCENTAGE_WIN_LOSS_RATIO_QUIZ = 'reponseInvestissement',
  TEN_PERCENT_LOSS_QUIZ = 'reponsePerte10Pourcent',

  RECOMMENDED_PROFILE = 'recommendedProfile',
  RECOMMENDED_PACKAGE = 'recommendedPackage',
  RECOMMENDED_RISK = 'recommendedRisk',
  PREVIOUS_PACKAGE = 'previousPackage',
  PREVIOUS_RISK = 'previousRisk',
  NEW_PACKAGE = 'newPackage',
  NEW_RISK = 'newRisk',
  RECOMMENDED_ESG = 'recommendedEsg',

  FUNNEL_SELECTION = 'funnelSelection',
  PRODUCT_FUNNEL_RECIPIENT = 'productFunnelRecipient',
  PRODUCT_FUNNEL_FISCAL_PACKAGE = 'productFunnelFiscalPackage',
}

export enum AbTestVersionName {
  ORIGINAL = 'version_originale',
  NEW = 'version_1',
}

export type EventParameters = {
  [key in ParametersKeys]?: any;
};

export interface EventMetadata {
  path?: string;
  isMobile: boolean;
  action: string;
  category: CdpCategory;
  questionsOrderVersion?: string;
  displayAbTestPeaWording?: boolean;
  displayAbTestNewProfileDisplay?: boolean;
  avPageVersion: string;
  abTestVersion: string;
}

interface EventProperties {
  otp_type: OtpType;
  isProfessional: boolean;
  preferredFiscalPackage: PreferredFiscalPackage;
  preferredTransfer: boolean;
}

export type CdpProperties = Partial<EventParameters> &
  Partial<EventMetadata> &
  Partial<EventProperties>;

export interface CdpEvent {
  type?: CdpType;
  event?: CdpEventName | string;
  name?: string;
  userId?: string;
  anonymousId?: string;
  environment?: string;
  properties?: CdpProperties;
}
