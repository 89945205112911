import * as Sentry from '@sentry/react';
import { createContext, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import {
  APP_REFRESH_INTERVAL,
  APP_REFRESH_MARGIN,
  YOMONI_JWT_TOKEN_NAME,
} from '@constants/app.const';
import useTracking from '@hooks/tracking/useTracking';
import AuthClient from '@services/clients/auth';
import * as UserClient from '@services/clients/user';
import { UserDetail } from '@shared/types';

export function parseJwt(token?: string | null) {
  try {
    if (!token) return null;
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
}

export function decodeToken(token: string): {
  header: Record<string, any>;
  payload: Record<string, any>;
} {
  const parts = token.split('.');
  const header = JSON.parse(atob(parts[0]));
  const payload = JSON.parse(atob(parts[1]));

  return {
    header,
    payload,
  };
}

export function isTokenExpiring(token: string): boolean {
  const { payload } = decodeToken(token);
  const expirationDate: number = payload.exp;
  const now: number = Math.floor(Date.now() / 1e3);
  const margin = APP_REFRESH_MARGIN;

  return expirationDate - now < margin;
}

export async function refreshToken(): Promise<void> {
  try {
    const jwt = localStorage.getItem(YOMONI_JWT_TOKEN_NAME);
    if (jwt) {
      const isExpired: boolean = isTokenExpiring(jwt);
      if (isExpired) {
        const tokenResponse = await AuthClient.getRefreshToken();
        localStorage.setItem(YOMONI_JWT_TOKEN_NAME, tokenResponse.token);
      }
    }
  } catch (error) {
    localStorage.removeItem(YOMONI_JWT_TOKEN_NAME);
  }
}

export function logoutUser(): void {
  localStorage.removeItem(YOMONI_JWT_TOKEN_NAME);
}

interface UserContextValue {
  user?: UserDetail;
  isLoading?: boolean;
  hasJwtToken?: boolean;
  refreshUser: () => Promise<UserDetail | undefined>;
}

export const UserContext = createContext<UserContextValue>({
  refreshUser: async () => {
    return undefined;
  },
});

export function UserProvider({ children, propUser }: PropsWithChildren<{ propUser?: UserDetail }>) {
  const [user, setUser] = useState<UserDetail | undefined>(propUser);
  const [isLoading, setIsLoading] = useState(true);
  const jwtToken = localStorage.getItem(YOMONI_JWT_TOKEN_NAME);

  const { refetch: refetchUserDetail } = useQuery<UserDetail | undefined, Error>(
    jwtToken || 'userDetail',
    async () => {
      if (jwtToken) {
        setIsLoading(true);
        const userDetail = await UserClient.getUser();
        setUser(userDetail);
        return userDetail;
      }
      return undefined;
    },
    {
      onSuccess: () => {
        setIsLoading(false);
      },
      onError: () => {
        setIsLoading(false);
      },
    },
  );
  const memoisedContext = useMemo<UserContextValue>(() => {
    return {
      user,
      isLoading,
      hasJwtToken: !!jwtToken,
      refreshUser: async () => {
        const userDetailQueryResult = await refetchUserDetail();
        return userDetailQueryResult.data;
      },
    };
  }, [user, isLoading, jwtToken]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { yomoniCdpCookie } = useTracking();

  useEffect(() => {
    const paramToken = searchParams.get('token');
    if (!paramToken) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
      localStorage.setItem(YOMONI_JWT_TOKEN_NAME, paramToken);
      setSearchParams((currentSearchParams) => {
        currentSearchParams.delete('token');
        return new URLSearchParams(currentSearchParams);
      });
    }
  }, []);

  useQuery<void, Error>('refreshToken', refreshToken, {
    refetchInterval: APP_REFRESH_INTERVAL,
    refetchIntervalInBackground: true,
  });

  useEffect(() => {
    const parsedJwt = parseJwt(jwtToken);
    if (!jwtToken || !parsedJwt) {
      const anonymousUser: Sentry.User = { id: `anonymousId:${yomoniCdpCookie?.anonymousId}` };
      Sentry.setUser(anonymousUser);
      return;
    }

    Sentry.setUser(parsedJwt.sub);
  }, [jwtToken]);

  return <UserContext.Provider value={memoisedContext}>{children}</UserContext.Provider>;
}

UserProvider.defaultProps = {
  propUser: undefined,
};
