import { Box, Card, IconButton, Typography, useTheme } from '@mui/material';
import { styled } from '@mui/system';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { ForwardedRef, forwardRef, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as CloseIcon } from '@assets/icons/close.svg';
import { ReactComponent as ErrorStatusIcon } from '@assets/icons/ko_status.svg';
import { ReactComponent as OkStatusIcon } from '@assets/icons/ok_status.svg';
import { ReactComponent as WarnStatusIcon } from '@assets/icons/warn_status.svg';
import { GRAY_LIGHT } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

const StyledCloseIcon = styled(CloseIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
}));

function TitleContainer({
  isError,
  isWarning,
  title,
}: {
  isError: boolean;
  isWarning: boolean;
  title: string;
}) {
  const { spacing } = useTheme();
  const isSuccess = !isError && !isWarning;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {isError && <ErrorStatusIcon style={{ marginRight: spacing(1.5) }} />}
      {isWarning && <WarnStatusIcon style={{ marginRight: spacing(1.5) }} />}
      {isSuccess && <OkStatusIcon style={{ marginRight: spacing(1.5) }} />}
      <Typography variant="md" fontWeight="fontWeightBold">
        <FormattedMessage id={title} />
      </Typography>
    </Box>
  );
}

interface YomoniSnackProps {
  id: string;
  message: string;
  title?: string;
  isError?: boolean;
  isWarning?: boolean;
}

const YomoniSnack = forwardRef<HTMLDivElement, YomoniSnackProps>(
  (props: PropsWithChildren<YomoniSnackProps>, ref: ForwardedRef<HTMLDivElement>) => {
    const { id, message, title, isError, isWarning } = props;
    const { palette } = useTheme();
    const { closeSnackbar } = useSnackbar();
    const isMobile = isMobileDimensions('md');

    const statusColor =
      (isError && palette.error.main) ||
      (isWarning && palette.warning.main) ||
      palette.success.main;

    const notificationTitle =
      title ||
      (isError && 'notification.title.error') ||
      (isWarning && 'notification.title.warning') ||
      'notification.title.success';

    return (
      <Card
        ref={ref}
        sx={{
          padding: 2,
          borderLeft: `2px solid ${statusColor}`,
          width: isMobile ? 'auto' : '403px',
          outline: `1px solid ${GRAY_LIGHT}`,
          margin: 1,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 1,
          }}>
          <TitleContainer isError={!!isError} isWarning={!!isWarning} title={notificationTitle} />
          <IconButton
            onClick={() => closeSnackbar(id)}
            sx={{
              padding: 0,
              marginRight: 1,
            }}>
            <StyledCloseIcon />
          </IconButton>
        </Box>
        <Typography variant="sm" fontWeight="fontWeightLight">
          <FormattedMessage id={`notification.${message}`} />
        </Typography>
      </Card>
    );
  },
);

YomoniSnack.defaultProps = {
  title: '',
  isError: false,
  isWarning: false,
};

export default YomoniSnack;
