import { CircularProgress, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

import { GRAY_MAIN } from '@theme';

interface LoadingProps {
  messageId?: string;
}

export default function Loading({ messageId }: LoadingProps) {
  return (
    <Stack
      direction="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      height="100%"
      minHeight="371px">
      <CircularProgress size={20} sx={{ color: GRAY_MAIN, boxShadow: 'none', display: 'block' }} />
      {messageId && (
        <Typography variant="sm">
          <FormattedMessage id={messageId} />
        </Typography>
      )}
    </Stack>
  );
}

Loading.defaultProps = {
  messageId: undefined,
};
