import { ProjectType } from '@constants/project-type.const';
import {
  CHILDREN_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  FIRST_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  HORIZON_MIN_VALUE_PEA,
  LIFE_INSURANCE_MAX_MONTHLY_DEPOSIT,
  LIFE_INSURANCE_MIN_INITIAL_INVESTMENT,
  MAX_RISK_RANGE,
  MAX_RISK_WITHDRAW_BEFORE_2_YEARS,
  MIN_RECOMMENDED_RISK_PEA,
  MONTHLY_DEPOSIT_AUTHORIZE_NONE,
  MONTHLY_DEPOSIT_MIN_VALUE,
  STOCK_SAVINGS_PLAN_MAX_INITIAL_INVESTMENT,
  STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT,
  TRADING_ACCOUNT_LEGAL_ENTITY_MIN_INITIAL_INVESTMENT,
  TRADING_ACCOUNT_MIN_INITIAL_INVESTMENT,
} from '@constants/simulation-parameters-edge.const';
import { BusinessReferral, FiscalPackage } from '@shared/types';

export const isProjectWithHighRisk = ({
  riskStateValue,
  projectType,
}: {
  riskStateValue: number;
  projectType: ProjectType | undefined;
}): boolean => {
  return projectType === ProjectType.EMERGENCY_SAVINGS && riskStateValue > 3;
};

export const isRiskLevelOutOfRange = ({
  selectedRiskLevel,
  recommendedRisk,
}: {
  selectedRiskLevel: number;
  recommendedRisk?: number;
}): boolean => {
  return Boolean(recommendedRisk && selectedRiskLevel - recommendedRisk > MAX_RISK_RANGE);
};

export const isTooRiskyProject = ({
  selectedRiskLevel,
  mayWithdrawBefore2Years,
}: {
  selectedRiskLevel: number;
  mayWithdrawBefore2Years: boolean | undefined;
}): boolean => {
  return <boolean>(
    (selectedRiskLevel === MAX_RISK_WITHDRAW_BEFORE_2_YEARS && mayWithdrawBefore2Years)
  );
};

const checkInitialInvestmentChildrenLifeInsurance = (
  initialInvestmentStateValue: number,
): string[] => {
  const errors = [];

  if (initialInvestmentStateValue < CHILDREN_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT) {
    errors.push('ERR_INITIAL_INVESTMENT_CHILDREN_LIFE_INSURANCE_TOO_LOW');
  }

  return errors;
};

export const checkInitialInvestmentLifeInsurance = (
  initialInvestmentStateValue: number,
  projectType: ProjectType | undefined,
  hasActiveLifeInsurance: boolean | undefined,
  businessReferral: string | undefined,
): string[] => {
  if (projectType === ProjectType.CHILDREN) {
    return checkInitialInvestmentChildrenLifeInsurance(initialInvestmentStateValue);
  }

  const errors = [];
  const isBusinessReferralPartner =
    businessReferral &&
    [BusinessReferral.DECATHLON.toString(), BusinessReferral.BANKIN.toString()].includes(
      businessReferral,
    );

  if (
    isBusinessReferralPartner &&
    initialInvestmentStateValue < LIFE_INSURANCE_MIN_INITIAL_INVESTMENT
  ) {
    errors.push('ERR_INITIAL_INVESTMENT_LIFE_INSURANCE_TOO_LOW');
  }

  if (
    hasActiveLifeInsurance &&
    initialInvestmentStateValue < LIFE_INSURANCE_MIN_INITIAL_INVESTMENT &&
    !isBusinessReferralPartner
  ) {
    errors.push('ERR_INITIAL_INVESTMENT_LIFE_INSURANCE_TOO_LOW');
  }

  if (
    !hasActiveLifeInsurance &&
    initialInvestmentStateValue < FIRST_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT &&
    !isBusinessReferralPartner
  ) {
    errors.push('ERR_INITIAL_INVESTMENT_FIRST_LIFE_INSURANCE_TOO_LOW');
  }

  return errors;
};

export const checkInitialInvestmentTradingAccount = (
  initialInvestmentStateValue: number,
  projectType: ProjectType | undefined,
): string[] => {
  const tradingAccountErrors = [];
  if (
    projectType === ProjectType.LEGAL_ENTITY &&
    initialInvestmentStateValue < TRADING_ACCOUNT_LEGAL_ENTITY_MIN_INITIAL_INVESTMENT
  ) {
    tradingAccountErrors.push('ERR_INITIAL_INVESTMENT_TRADING_ACCOUNT_LEGAL_ENTITY_TOO_LOW');
  }

  if (
    projectType !== ProjectType.LEGAL_ENTITY &&
    initialInvestmentStateValue < TRADING_ACCOUNT_MIN_INITIAL_INVESTMENT
  ) {
    tradingAccountErrors.push('ERR_INITIAL_INVESTMENT_TRADING_ACCOUNT_TOO_LOW');
  }
  return tradingAccountErrors;
};

export const checkInitialInvestmentStocksSavingPlan = (
  initialInvestmentStateValue: number,
  isTransfer: boolean | undefined,
): string[] => {
  const stocksSavingPlanErrors = [];
  if (initialInvestmentStateValue < STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT) {
    stocksSavingPlanErrors.push('ERR_INITIAL_INVESTMENT_STOCKS_SAVING_PLAN_TOO_LOW');
  }
  if (initialInvestmentStateValue > STOCK_SAVINGS_PLAN_MAX_INITIAL_INVESTMENT && !isTransfer) {
    stocksSavingPlanErrors.push('ERR_INITIAL_INVESTMENT_STOCKS_SAVING_PLAN_TOO_HIGH');
  }
  return stocksSavingPlanErrors;
};

export const checkInitialInvestment = ({
  initialInvestmentStateValue,
  packageStateValue,
  projectType,
  hasActiveLifeInsurance,
  businessReferral,
  isTransfer,
}: {
  initialInvestmentStateValue: number;
  packageStateValue: FiscalPackage;
  projectType: ProjectType | undefined;
  hasActiveLifeInsurance: boolean | undefined;
  businessReferral: string | undefined;
  isTransfer: boolean | undefined;
}): string[] => {
  const errorsMap: Record<string, () => string[]> = {
    [FiscalPackage.LIFE_INSURANCE]: () =>
      checkInitialInvestmentLifeInsurance(
        initialInvestmentStateValue,
        projectType,
        hasActiveLifeInsurance,
        businessReferral,
      ),
    [FiscalPackage.STOCK_SAVINGS_PLAN]: () =>
      checkInitialInvestmentStocksSavingPlan(initialInvestmentStateValue, isTransfer),
    [FiscalPackage.TRADING_ACCOUNT]: () =>
      checkInitialInvestmentTradingAccount(initialInvestmentStateValue, projectType),
  };

  return errorsMap[packageStateValue] ? errorsMap[packageStateValue]() : [];
};

export const checkMonthlyDepositLifeInsurance = (monthlyInvestmentStateValue: number): string[] => {
  const monthlyDepositErrors = [];

  if (monthlyInvestmentStateValue > LIFE_INSURANCE_MAX_MONTHLY_DEPOSIT) {
    monthlyDepositErrors.push('ERR_MONTHLY_DEPOSIT_LIFE_INSURANCE_TOO_HIGH');
  }

  return monthlyDepositErrors;
};

export const checkMonthlyDeposit = ({
  monthlyInvestmentStateValue,
  fiscalPackageStateValue,
}: {
  monthlyInvestmentStateValue: number;
  fiscalPackageStateValue: FiscalPackage;
}): string[] => {
  const monthlyDepositErrors = [];

  if (monthlyInvestmentStateValue >= 1 && monthlyInvestmentStateValue < MONTHLY_DEPOSIT_MIN_VALUE) {
    monthlyDepositErrors.push('ERR_MONTHLY_DEPOSIT_TOO_LOW');
  }

  if (monthlyInvestmentStateValue === 0 && !MONTHLY_DEPOSIT_AUTHORIZE_NONE) {
    monthlyDepositErrors.push('ERR_MONTHLY_DEPOSIT_TOO_LOW');
  }

  const errorsMapGivenPackage: Record<string, (monthlyInvestmentStateValue: number) => string[]> = {
    [FiscalPackage.LIFE_INSURANCE]: checkMonthlyDepositLifeInsurance,
  };

  const errorsGivenPackage = errorsMapGivenPackage[fiscalPackageStateValue]
    ? errorsMapGivenPackage[fiscalPackageStateValue](monthlyInvestmentStateValue)
    : [];

  return monthlyDepositErrors.concat(errorsGivenPackage);
};

export const checkHorizon = ({
  fiscalPackage,
  horizon,
}: {
  fiscalPackage: FiscalPackage;
  horizon: number;
}): string[] => {
  const errors = [];

  if (fiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN && horizon < HORIZON_MIN_VALUE_PEA) {
    errors.push('ERR_HORIZON_TOO_LOW_PEA');
  }

  return errors;
};

export const checkRiskProfileError = ({
  selectedRiskLevel,
  recommendedRisk,
  projectType,
  mayWithdrawBefore2Years,
}: {
  selectedRiskLevel: number;
  recommendedRisk: number | undefined;
  projectType: ProjectType | undefined;
  mayWithdrawBefore2Years: boolean | undefined;
}): string | undefined => {
  if (isProjectWithHighRisk({ riskStateValue: selectedRiskLevel, projectType })) {
    return 'ERR_HIGH_RISK_FOR_SAVINGS';
  }
  if (isTooRiskyProject({ selectedRiskLevel, mayWithdrawBefore2Years })) {
    return 'ERR_MAY_WITHDRAW_BEFORE_2_YEARS';
  }
  if (isRiskLevelOutOfRange({ selectedRiskLevel, recommendedRisk })) {
    return 'ERR_RISK_LEVEL_RANGE_TOO_HIGH';
  }
  return undefined;
};

export const checkError = ({
  initialInvestmentStateValue,
  packageStateValue,
  monthlyInvestmentStateValue,
  projectType,
  riskStateValue,
  hasActiveLifeInsurance,
  horizonStateValue,
  mayWithdrawBefore2Years,
  recommendedRisk,
  businessReferral,
  isTransfer,
}: {
  initialInvestmentStateValue: number;
  packageStateValue: FiscalPackage;
  monthlyInvestmentStateValue: number;
  horizonStateValue: number;
  projectType: ProjectType | undefined;
  riskStateValue: number;
  hasActiveLifeInsurance: boolean | undefined;
  mayWithdrawBefore2Years: boolean | undefined;
  recommendedRisk: number | undefined;
  businessReferral: string | undefined;
  isTransfer: boolean | undefined;
}): string[] => {
  const errors = [];

  errors.push(
    checkInitialInvestment({
      initialInvestmentStateValue,
      packageStateValue,
      projectType,
      hasActiveLifeInsurance,
      businessReferral,
      isTransfer,
    }),
  );
  errors.push(
    checkMonthlyDeposit({
      monthlyInvestmentStateValue,
      fiscalPackageStateValue: packageStateValue,
    }),
  );
  errors.push(checkHorizon({ fiscalPackage: packageStateValue, horizon: horizonStateValue }));
  const errorRiskProfile = checkRiskProfileError({
    selectedRiskLevel: riskStateValue,
    recommendedRisk,
    projectType,
    mayWithdrawBefore2Years,
  });
  if (errorRiskProfile) {
    errors.push(errorRiskProfile);
  }

  return errors.flat();
};

export const checkPEAError = ({
  recommendedRisk,
  projectType,
  mayWithdrawBefore2Years,
  isFrenchTaxResident,
  initialInvestment,
}: {
  recommendedRisk: number;
  projectType: ProjectType | undefined;
  mayWithdrawBefore2Years: boolean | undefined;
  isFrenchTaxResident: boolean | undefined;
  initialInvestment: number;
}): string | undefined => {
  if (projectType === ProjectType.EMERGENCY_SAVINGS) {
    return 'ERR_PEA_HIGH_RISK_FOR_SAVINGS';
  }
  if (
    initialInvestment >= STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT &&
    initialInvestment <= STOCK_SAVINGS_PLAN_MAX_INITIAL_INVESTMENT &&
    projectType !== ProjectType.CHILDREN &&
    projectType !== ProjectType.LEGAL_ENTITY &&
    isFrenchTaxResident
  ) {
    if (mayWithdrawBefore2Years) {
      return 'ERR_PEA_MAY_WITHDRAW_BEFORE_2_YEARS';
    }
    if (recommendedRisk < MIN_RECOMMENDED_RISK_PEA) {
      return 'ERR_PEA_RISK_LEVEL_RANGE_TOO_HIGH';
    }
  }
  return undefined;
};
