import { atom, DefaultValue, selector } from 'recoil';

import { BusinessReferral, PreferredFiscalPackage } from '@shared/types';
import { InvestmentStateType } from '@typings/investment-state-type.interface';
import { ProjectSelectorType } from '@typings/project-selector-type.interface';

export const monthlyInvestmentState = atom({
  key: 'monthlyInvestmentState',
  default: 0 as number,
});
export const investmentHorizonState = atom({
  key: 'investmentHorizonState',
  default: 0 as number,
});
export const retirementAgeState = atom({
  key: 'retirementAgeState',
  default: 64 as number,
});
export const initialPaymentState = atom({
  key: 'initialPaymentState',
  default: 0 as number,
});
export const transferAmountState = atom({
  key: 'transferAmountState',
  default: 0 as number,
});
export const isTransferState = atom<boolean | undefined>({
  key: 'isTransferState',
  default: undefined,
});
export const deductibilityState = atom({
  key: 'deductibilityState',
  default: true,
});
export const desensitizationState = atom({
  key: 'desensitizationState',
  default: true,
});
export const businessReferralState = atom<BusinessReferral | undefined>({
  key: 'businessReferral',
  default: undefined,
});
export const preferredEnvelopeState = atom<PreferredFiscalPackage | undefined>({
  key: 'preferredEnvelope',
  default: undefined,
});
export const preferredTransferState = atom<boolean | undefined>({
  key: 'preferredTransfer',
  default: undefined,
});
export const lastProductFunnelRouteState = atom<string | undefined>({
  key: 'productFunnel',
  default: undefined,
});
export const comeFromProductFunnelState = atom<boolean | undefined>({
  key: 'comeFromProductFunnel',
  default: undefined,
});

export const startingCapitalState = selector({
  key: 'startingCapitalState',
  get: ({ get }) => get(initialPaymentState) + get(transferAmountState),
});
export const investmentSelector = selector<InvestmentStateType>({
  key: 'investmentSelector',
  get: ({ get }) => ({
    monthlyInvestmentStateValue: get(monthlyInvestmentState),
    investmentHorizonStateValue: get(investmentHorizonState),
    retirementAgeStateValue: get(retirementAgeState),
    initialPaymentStateValue: get(initialPaymentState),
    transferAmountStateValue: get(transferAmountState),
    startingCapitalStateValue: get(startingCapitalState),
  }),
});

export const projectSelector = selector<ProjectSelectorType>({
  key: 'projectSelector',
  get: ({ get }) => ({
    isTransfer: get(isTransferState),
    deductibility: get(deductibilityState),
    desensitization: get(desensitizationState),
    monthlyInvestment: get(monthlyInvestmentState),
    horizon: get(investmentHorizonState),
    retirementAge: get(retirementAgeState),
    initialPayment: get(initialPaymentState),
    transferAmount: get(transferAmountState),
    startingCapital: get(startingCapitalState),
  }),
  set: ({ reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(isTransferState);
      reset(deductibilityState);
      reset(desensitizationState);
      reset(monthlyInvestmentState);
      reset(investmentHorizonState);
      reset(retirementAgeState);
      reset(initialPaymentState);
      reset(transferAmountState);
    }
  },
});

export const wasUserExpertiseAlreadySentState = atom<boolean>({
  key: 'wasUserExpertiseAlreadySent',
  default: false,
});
export default wasUserExpertiseAlreadySentState;
