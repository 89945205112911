import {
  MIN_FINANCIAL_PATRIMONY,
  MIN_GROSS_INCOME_RANGE as MIN_GROSS_INCOME_RANGE_LIST,
  MIN_STARTING_CAPITAL,
} from '@constants/phone-first-modal-const';
import { AnnualIncome, ResumeResponse, UserDetail } from '@shared/types';

export const isUserAlreadyDefined = (user?: UserDetail) => {
  return user?.phoneNumber || user?.firstname || user?.lastname;
};
export const isGrossAnnualIncomeRangeEnough = (grossAnnualIncomeRange?: AnnualIncome) => {
  return grossAnnualIncomeRange && MIN_GROSS_INCOME_RANGE_LIST.includes(grossAnnualIncomeRange);
};
export const isHighPotentialUser = (resume?: ResumeResponse) => {
  if (!resume) return false;

  return (
    (resume.step1.financialPatrimony &&
      resume.step1.financialPatrimony >= MIN_FINANCIAL_PATRIMONY) ||
    resume.step0.startingCapital >= MIN_STARTING_CAPITAL ||
    isGrossAnnualIncomeRangeEnough(resume.step1.grossAnnualIncomeRange)
  );
};
