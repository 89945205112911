import { useEffect, useRef } from 'react';
import { Portal } from 'react-portal';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import { EXTERNAL_ROUTES } from '@constants/routes.const';
import { logoutUser } from '@providers/UserProvider';
import SsoTokenClient from '@services/clients/ssoToken';

/**
 * Send a fake form to simulate a POST request
 */
export default function SsoForm() {
  const formRef = useRef<HTMLFormElement>(null);
  const [searchParams] = useSearchParams();
  const urlParam = searchParams.get('url') || EXTERNAL_ROUTES.CORE.SSO(EXTERNAL_ROUTES.CORE.STEP_1);

  const { data: ssoToken } = useQuery('ssoToken', SsoTokenClient.getSsoToken, {
    onSuccess: () => {
      logoutUser();
    },
  });

  useEffect(() => {
    if (ssoToken && formRef.current) {
      formRef.current.submit();
    }
  }, [ssoToken]);

  return (
    <Portal>
      <form
        action={urlParam}
        encType="application/x-www-form-urlencoded"
        method="POST"
        ref={formRef}>
        <input name="token" type="hidden" value={ssoToken || 'temporaryToken'} />
      </form>
    </Portal>
  );
}
