import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useTracking from '@hooks/tracking/useTracking';
import { CdpCategory, CdpEventName, CdpType } from '@shared/types';

export default function Error404() {
  const { pathname } = useLocation();
  const { sendEvent } = useTracking();

  useEffect(() => {
    sendEvent({
      type: CdpType.PAGE,
      event: CdpEventName.PAGE_NOT_FOUND,
      properties: {
        category: CdpCategory.NAVIGATION,
        path: pathname,
      },
    });
  }, [pathname]);

  return (
    <div>
      <h1>404</h1>
      <p>Page not found</p>
    </div>
  );
}
