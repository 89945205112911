import { Box, debounce, Fade, Grid, Typography, useTheme } from '@mui/material';
import { BoxProps, Stack } from '@mui/system';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import InfoBox, { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import OutlinedButton from '@components/OutlinedButton/OutlinedButton';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import ProgressBar from '@components/ProgressBar/ProgressBar';
import { InfoBoxContextValue } from '@features/project/components/Step1Questions';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import { GRAY_LIGHT, GRAY_LIGHT_MID, GRAY_MAIN, PRIMARY_LIGHT, WHITE } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

interface QuestionLayoutProps extends BoxProps {
  children: React.ReactNode;
  headerTitle: string;
  title?: string;
  subtitle?: string;
  questionNumber?: number;
  totalQuestionNumber?: number;
  infoBoxMessages?: InfoBoxContextValue[];
  fade: boolean;
  debounceError?: boolean;
  handleBack?: () => void;
  isBackDisabled?: boolean;
  handleNext?: () => void;
  isNextDisabled?: boolean;
  isNextLoading?: boolean;
  ctaChildren?: {
    mobile: React.ReactNode;
    desktop: React.ReactNode;
  };
}

export default function QuestionLayout({
  children,
  headerTitle,
  title,
  subtitle,
  questionNumber,
  totalQuestionNumber,
  infoBoxMessages,
  fade,
  debounceError,
  handleBack,
  isBackDisabled,
  handleNext,
  isNextDisabled,
  isNextLoading,
  ctaChildren,
  ...props
}: QuestionLayoutProps) {
  const intl = useIntl();
  const isMobile = isMobileDimensions('md');
  const theme = useTheme();

  const [infoBoxMessagesState, setInfoBoxMessagesState] = useState(infoBoxMessages);
  const containErrorMessage = infoBoxMessages?.some(
    (msg) => msg.infoBoxLevel === InfoBoxLevel.ERROR,
  );

  const debouncedSetInputError = useMemo(
    () =>
      debounce(
        (debouncedInfoBoxMessages) => setInfoBoxMessagesState(debouncedInfoBoxMessages),
        DEBOUNCE_DURATION,
      ),
    [],
  );

  useEffect(() => {
    if (containErrorMessage && debounceError) {
      debouncedSetInputError(infoBoxMessages);
    } else {
      setInfoBoxMessagesState(infoBoxMessages);
      debouncedSetInputError(infoBoxMessages);
    }
  }, [infoBoxMessages]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingBottom={isMobile ? '96px' : 'none'}
      {...props}>
      <Box width="100%" maxWidth={isMobile ? '100%' : '740px'}>
        <Box
          width="100%"
          sx={{
            backgroundColor: isMobile ? 'transparent' : WHITE,
            borderRadius: isMobile ? undefined : '12px',
            border: isMobile ? undefined : `1px solid ${GRAY_LIGHT_MID}`,
          }}>
          {questionNumber && totalQuestionNumber && isMobile && (
            <ProgressBar
              variant="determinate"
              backgroundcolor="transparent"
              noborder
              height={8}
              value={(questionNumber / totalQuestionNumber) * 100}
            />
          )}
          {!isMobile && (
            <Grid
              container
              item
              xs={12}
              sx={{
                height: '40px',
                borderRadius: '11px 11px 0 0',
                backgroundColor: GRAY_LIGHT,
                px: 4,
                alignItems: 'center',
              }}>
              <Grid item xs={12} sm={5}>
                <Typography variant="sm" color={GRAY_MAIN}>
                  {intl.formatMessage({ id: headerTitle })}
                </Typography>
              </Grid>
              {questionNumber && totalQuestionNumber && (
                <Grid
                  container
                  item
                  xs={12}
                  sm={7}
                  direction="row"
                  justifyContent="flex-end"
                  gap={1}
                  alignItems="center">
                  <Grid item display="flex" alignItems="center">
                    <Typography variant="sm" color={GRAY_MAIN}>
                      {intl.formatMessage(
                        { id: 'step1.question.layout.header.status' },
                        { currentQuestionNumber: questionNumber, totalQuestionNumber },
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ProgressBar
                      variant="determinate"
                      value={(questionNumber / totalQuestionNumber) * 100}
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}

          <Fade in={fade}>
            <Grid item>
              <Box display="flex" flexDirection="column" padding={4}>
                {title && (
                  <Typography
                    variant="xl"
                    fontWeight="fontWeightBold"
                    textAlign={isMobile ? 'left' : 'center'}
                    padding={isMobile ? undefined : theme.spacing(0, 5)}>
                    {intl.messages[title] ? (
                      <FormattedMessage
                        id={title}
                        values={{
                          br: <br />,
                        }}
                      />
                    ) : (
                      title
                    )}
                  </Typography>
                )}
                {subtitle && (
                  <Typography
                    variant="md"
                    fontWeight="fontWeightLight"
                    textAlign="center"
                    color={PRIMARY_LIGHT}
                    mt={2}>
                    {intl.messages[subtitle] ? (
                      <FormattedMessage
                        id={subtitle}
                        values={{
                          br: <br />,
                        }}
                      />
                    ) : (
                      subtitle
                    )}
                  </Typography>
                )}

                <Box mt={3}>{children}</Box>

                <Box mt={3} mb={-3}>
                  {infoBoxMessagesState?.map((infoBoxMessage) => (
                    <InfoBox
                      key={infoBoxMessage.infoBoxMessage}
                      type={infoBoxMessage.infoBoxLevel}
                      description={infoBoxMessage.infoBoxMessage}
                      imageSrc={
                        infoBoxMessage.infoBoxImageSrc ??
                        'https://static.yomoni.fr/images/tunnel/chief_sales_officer.png'
                      }
                      onClickLink={infoBoxMessage.infoBoxLink}
                      openLinkInNewTab={infoBoxMessage.openLinkInNewTab}
                      messageValues={infoBoxMessage.infoBoxMessageValues}
                      sx={{ mb: 3 }}
                    />
                  ))}
                </Box>
              </Box>
            </Grid>
          </Fade>
        </Box>
        {isMobile && (
          <Box
            sx={{
              width: '100%',
              boxSizing: 'border-box',
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              padding: '16px 24px',
              backgroundColor: 'rgba(255, 255, 255, 0.48)',
              backdropFilter: 'blur(8px)',
              boxShadow: '2px 4px 15px rgba(35, 38, 52, 0.12)',
            }}>
            <Stack direction="row" justifyContent="space-between" gap={1}>
              {handleBack && (
                <OutlinedButton
                  disabled={isBackDisabled}
                  onClick={handleBack}
                  titleId="step1.question.button-back"
                />
              )}
              {handleNext && (
                <PrimaryButton
                  loading={isNextLoading}
                  disabled={isNextDisabled}
                  onClick={handleNext}
                  titleId="step1.question.button-next"
                  sx={{ marginLeft: 'auto', marginRight: 0, flex: 1 }}
                />
              )}
            </Stack>
            {ctaChildren?.mobile}
          </Box>
        )}
        {!isMobile && (
          <Stack direction="row" justifyContent="space-between" mt={3} mb={3} gap={1}>
            {handleBack && (
              <OutlinedButton
                disabled={isBackDisabled}
                onClick={handleBack}
                titleId="step1.question.button-back"
              />
            )}
            <Stack direction="row" gap={1} ml="auto" mr={0}>
              {ctaChildren?.desktop}
              {handleNext && (
                <PrimaryButton
                  loading={isNextLoading}
                  disabled={isNextDisabled}
                  onClick={handleNext}
                  titleId="step1.question.button-next"
                />
              )}
            </Stack>
          </Stack>
        )}
      </Box>
    </Box>
  );
}

QuestionLayout.defaultProps = {
  infoBoxMessages: [],
  debounceError: false,
  title: undefined,
  subtitle: undefined,
  questionNumber: undefined,
  totalQuestionNumber: undefined,
  handleBack: undefined,
  isBackDisabled: false,
  handleNext: undefined,
  isNextDisabled: false,
  isNextLoading: false,
  ctaChildren: undefined,
};
