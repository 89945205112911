import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionRealEstateInvestment from '@features/project/components/questions/QuestionRealEstateInvestment/QuestionRealEstateInvestment.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import { BUTTONS_TERNARY_ANSWER } from '@features/project/question.utils';
import { TernaryAnswer } from '@shared/types';
import { questionsSelector, realEstateInvestmentQuizQuestionState } from '@state';

export default function QuestionRealEstateInvestment() {
  const { updateContext } = useContext(QuestionContext);

  const questionState = useRecoilValue(questionsSelector);
  const setRealEstateInvestmentQuizStateValue = useSetRecoilState(
    realEstateInvestmentQuizQuestionState,
  );

  useEffect(() => {
    updateContext({
      ...checkRulesQuestionRealEstateInvestment({ questionState }),
    });
  }, []);

  const onClick = (newTernaryAnswer: TernaryAnswer) => {
    setRealEstateInvestmentQuizStateValue(newTernaryAnswer);
    updateContext(
      checkRulesQuestionRealEstateInvestment({
        questionState: {
          ...questionState,
          realEstateInvestmentQuiz: newTernaryAnswer,
        },
      }),
    );
  };

  return (
    <ToggleButtonGroup
      disabled={questionState.realEstateInvestmentQuiz !== undefined}
      buttons={BUTTONS_TERNARY_ANSWER}
      selectedValue={questionState.realEstateInvestmentQuiz}
      onChange={onClick}
    />
  );
}
