import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { PrimaryResidence, ProjectType } from '@shared/types';

export default function checkRulesQuestionRentMortage(ruleParams: QuestionRuleParams): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { creditOrRentMainResidence } = ruleParams.questionState;

  if (creditOrRentMainResidence === undefined || Number.isNaN(creditOrRentMainResidence)) {
    isValid = false;
  }

  if (isErrorMortgage(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.rent-mortgage.error.mortgage',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (isErrorRent(ruleParams)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.rent-mortgage.error.rent',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  if (showInfoMortage(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.rent-mortgage.info.mortgage.kid',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  if (showInfoRent(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.rent-mortgage.info.rent.kid',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q10-RG1
 */
function isErrorMortgage(rulesParams: QuestionRuleParams) {
  const { creditOrRentMainResidence, ownerMainResidence } = rulesParams.questionState;

  return (
    ownerMainResidence === PrimaryResidence.OWNER &&
    creditOrRentMainResidence !== undefined &&
    (creditOrRentMainResidence < 0 || creditOrRentMainResidence > 5000)
  );
}

/**
 * Q10-RG2
 */
function isErrorRent(rulesParams: QuestionRuleParams) {
  const { creditOrRentMainResidence, ownerMainResidence } = rulesParams.questionState;

  return (
    ownerMainResidence === PrimaryResidence.NOT_OWNER &&
    creditOrRentMainResidence !== undefined &&
    (creditOrRentMainResidence < 0 || creditOrRentMainResidence > 5000)
  );
}

/**
 * Q10-RG3
 */
function showInfoMortage(rulesParams: QuestionRuleParams) {
  const { projectType, ownerMainResidence } = rulesParams.questionState;

  return projectType === ProjectType.CHILDREN && ownerMainResidence === PrimaryResidence.OWNER;
}

/**
 * Q10-RG4
 */
function showInfoRent(rulesParams: QuestionRuleParams) {
  const { projectType, ownerMainResidence } = rulesParams.questionState;

  return projectType === ProjectType.CHILDREN && ownerMainResidence === PrimaryResidence.NOT_OWNER;
}
