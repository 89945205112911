import { Button, styled } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { forwardRef } from 'react';
import { useIntl } from 'react-intl';

import { GRAY_MAIN } from '@theme';

interface TextButtonProps extends ButtonProps {
  onClick: () => void;
  titleId: string;
  type?: 'button' | 'submit' | 'reset';
  icon?: JSX.Element;
  href?: string;
  disabled?: boolean;
  stretch?: boolean;
  underline?: boolean;
}

const StyledTextButton = styled('span')(() => ({
  color: GRAY_MAIN,
  fontFamily: 'Fira Sans, sans-serif',
  fontSize: '12px',
  lineHeight: '17px',
  fontWeight: 400,
  textDecoration: 'underline',
}));

const TextButton = forwardRef<HTMLButtonElement, TextButtonProps>(
  ({ onClick, titleId, type, icon, href, disabled, stretch, underline, sx, ...props }, ref) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        data-testid="text-button"
        type={type}
        variant="text"
        disabled={disabled}
        sx={{
          flex: stretch ? 'auto' : 'none',
          textTransform: 'none',
          ...sx,
        }}
        onClick={onClick}
        href={href}
        endIcon={icon}
        {...props}>
        <StyledTextButton style={{ textDecoration: underline ? 'underline' : 'none' }}>
          {intl.formatMessage({ id: titleId })}
        </StyledTextButton>
      </Button>
    );
  },
);

TextButton.defaultProps = {
  type: 'button',
  icon: undefined,
  href: undefined,
  disabled: false,
  stretch: false,
  underline: true,
};

export default TextButton;
