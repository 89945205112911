import axios from 'axios';

import ROUTES from '@constants/routes.const';

const YOMONI_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
  },
  baseURL: process.env.REACT_APP_BFF_URL,
};

const yomoniClient = axios.create(YOMONI_CONFIG);

yomoniClient.interceptors.response.use(
  (response) => {
    if (response.headers.authorization) {
      localStorage.setItem('jwt', response.headers.authorization.replace('Bearer ', ''));
    }
    return response;
  },
  (error) => {
    const { status } = error.response;
    const { pathname } = window.location;

    if (status === 401) {
      localStorage.removeItem('jwt');
    }

    // edge case : accessing step1 page (/projet/*) as prospect with expired token should not redirect to login
    if (
      status === 401 &&
      !(pathname.endsWith(`/${ROUTES.PROJECT}`) || pathname.includes(`/${ROUTES.PROJECT}/`))
    ) {
      window.location.href = `${process.env.REACT_APP_YOMONI_CORE_FRONT_BASE_URL}/login`;
    }

    if (status === 403 || status === 500) {
      window.location.href = `${process.env.REACT_APP_YOMONI_CORE_FRONT_BASE_URL}/login`;
    }
    return Promise.reject(error);
  },
);

yomoniClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwt');
    if (token && config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export default yomoniClient;
