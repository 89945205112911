import { useContext, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionChildrenNumber from '@features/project/components/questions/QuestionChildrenNumber/QuestionChildrenNumber.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useRulesParameters from '@features/project/hooks/useRulesParameters';
import useDimensions from '@hooks/useDimensions';
import { ChildrenNumber } from '@shared/types';
import { nbChildrenQuestionState } from '@state';

export default function QuestionChildrenNumber() {
  const { isMobile } = useDimensions();
  const { updateContext } = useContext(QuestionContext);
  const rulesParameters = useRulesParameters();
  const setChildrenNumberStateValue = useSetRecoilState(nbChildrenQuestionState);

  const buttons = [
    { value: ChildrenNumber.NONE, titleId: 'step1.question.children.button.none' },
    { value: ChildrenNumber.ONE, titleId: 'step1.question.children.button.one' },
    { value: ChildrenNumber.TWO, titleId: 'step1.question.children.button.two' },
    { value: ChildrenNumber.THREE_OR_MORE, titleId: 'step1.question.children.button.threeOrMore' },
  ];

  useEffect(() => {
    updateContext(checkRulesQuestionChildrenNumber(rulesParameters));
  }, []);

  const onChange = (newChildrenNumber: ChildrenNumber) => {
    setChildrenNumberStateValue(newChildrenNumber);
    updateContext({
      ...checkRulesQuestionChildrenNumber({
        ...rulesParameters,
        questionState: { ...rulesParameters.questionState, nbChildren: newChildrenNumber },
      }),
      autoValidate: true,
    });
  };

  return (
    <ToggleButtonGroup
      buttons={buttons}
      selectedValue={rulesParameters.questionState.nbChildren}
      onChange={onChange}
      itemProps={{ xs: isMobile ? 12 : undefined }}
    />
  );
}
