import { Container, styled } from '@mui/material';

import { NAVBAR_HEIGHT, PRIMARY_DARK } from '@theme';

export const StyledMobileNavbar = styled(Container)(() => ({
  backgroundColor: PRIMARY_DARK,
  position: 'absolute',
  top: 0,
  left: 0,
  height: NAVBAR_HEIGHT,
  width: '100%',
  zIndex: 2,
}));

export default StyledMobileNavbar;
