import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionBirthdayChildren from '@features/project/components/questions/QuestionBirthdayChildren/QuestionBirthdayChildren';
import checkRulesQuestionBirthdateChildren from '@features/project/components/questions/QuestionBirthdayChildren/QuestionBirthdayChildren.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.BIRTH_DATE,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.birthday-children.title',
  cdpEvent: CdpEventName.QUESTION_BIRTHDATE_CHILDREN,
  Component: QuestionBirthdayChildren,
  payloadKeys: [FunnelAnswersKeys.BIRTHDATE_KID],
  rulesFn: checkRulesQuestionBirthdateChildren,
};

export default {
  kid: baseDescription,
};
