import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useTryNewWording from '@hooks/useTryNewWording';
import {
  preferredEnvelopeState,
  preferredTransferState,
  questionLayoutSubtitleState,
  questionLayoutTitleState,
} from '@state';

const useTitle = ({
  translationQuestionPrefix,
  titleValues,
}: {
  translationQuestionPrefix: string;
  titleValues?: Record<string, string | number>;
}) => {
  const intl = useIntl();
  const setQuestionLayoutTitle = useSetRecoilState(questionLayoutTitleState);
  const setQuestionLayoutSubtitle = useSetRecoilState(questionLayoutSubtitleState);
  const preferredTransfer = useRecoilValue(preferredTransferState);
  const preferredEnvelope = useRecoilValue(preferredEnvelopeState);
  const tryNewWording = useTryNewWording();

  const getSuffix = (path: string) => {
    if (!preferredEnvelope) {
      return null;
    }

    if (tryNewWording && !!intl.messages[`${translationQuestionPrefix}${path}.abtest`]) {
      return `${path}.abtest`;
    }

    if (preferredTransfer && !!intl.messages[`${translationQuestionPrefix}${path}.transfering`]) {
      return `${path}.transfering`;
    }

    if (intl.messages[`${translationQuestionPrefix}${path}.opening`]) {
      return `${path}.opening`;
    }

    return null;
  };

  const titleSuffix = useMemo(() => {
    const suffix = getSuffix('.title');
    if (suffix) {
      return suffix;
    }

    return '.title';
  }, [tryNewWording, preferredTransfer, preferredEnvelope]);

  const subTitleSuffix = useMemo(() => {
    return getSuffix('.subtitle');
  }, [tryNewWording, preferredTransfer, preferredEnvelope]);

  useEffect(() => {
    if (!translationQuestionPrefix) {
      return;
    }

    setQuestionLayoutTitle(
      intl.formatMessage(
        {
          id: `${translationQuestionPrefix}${titleSuffix}`,
        },
        {
          fiscalPackage: preferredEnvelope
            ? intl.formatMessage({ id: `fiscalPackage.${preferredEnvelope}` })
            : undefined,
          ...titleValues,
        },
      ),
    );

    if (subTitleSuffix) {
      setQuestionLayoutSubtitle(
        intl.formatMessage({
          id: `${translationQuestionPrefix}${subTitleSuffix}`,
        }),
      );
    }
  }, [preferredEnvelope, titleValues, translationQuestionPrefix, titleSuffix, subTitleSuffix]);
};

export default useTitle;
