import { ReactComponent as Bank } from '@assets/icons/project/bank.svg';
import { ReactComponent as Home } from '@assets/icons/project/home.svg';
import { ReactComponent as PiggyBank } from '@assets/icons/project/piggybank.svg';
import { ReactComponent as Stroller } from '@assets/icons/project/stroller.svg';
import { ReactComponent as Substitute } from '@assets/icons/project/substitute.svg';
import { ReactComponent as Sunrise } from '@assets/icons/project/sunrise.svg';
import { ReactComponent as Umbrella } from '@assets/icons/project/umbrella.svg';
import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { ButtonEntry } from '@components/ToggleButtonGroup/ToggleButtonGroup';
import { EXTERNAL_ROUTES } from '@constants/routes.const';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { ProjectType, Thematic, FiscalPackage } from '@shared/types';
import { getPerStep1NavigationProps } from '@utils/navigation.utils';

export function checkRulesQuestionProject(ruleParams: QuestionRuleParams): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.projectType) {
    isValid = false;
  }

  if (!checkRuleInfoRetirementProject(infoBoxMessages, ruleParams)) {
    isValid = false;
  }

  if (!checkRuleInfoChildrenProject(infoBoxMessages, ruleParams)) {
    isValid = false;
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q1-RG1
 */
function checkRuleInfoChildrenProject(
  infoBoxMessages: InfoBoxContextValue[],
  rulesParam?: QuestionRuleParams,
) {
  if (rulesParam?.questionState.projectType === ProjectType.CHILDREN) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.project.CHILDREN.info',
      infoBoxLevel: InfoBoxLevel.WARNING,
    });
  }
  return true;
}

/**
 * Q1-RG2
 */
function checkRuleInfoRetirementProject(
  infoBoxMessages: InfoBoxContextValue[],
  rulesParam: QuestionRuleParams,
) {
  if (
    rulesParam.questionState.projectType === ProjectType.RETIREMENT &&
    rulesParam.questionState.thematic !== Thematic.REAL_ESTATE
  ) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.project.RETIREMENT.info',
      infoBoxLevel: InfoBoxLevel.WARNING,
      infoBoxLink: rulesParam.user
        ? `${getPerStep1NavigationProps().pathname}?${getPerStep1NavigationProps().search}`
        : EXTERNAL_ROUTES.PER.STEP_1,
    });
  }
  return true;
}

const CLASSIC_BUTTONS: ButtonEntry<ProjectType>[] = [
  {
    value: ProjectType.SAVINGS,
    titleId: 'step1.question.project.SAVINGS.title',
    StartIcon: PiggyBank,
  },
  {
    value: ProjectType.EMERGENCY_SAVINGS,
    titleId: 'step1.question.project.EMERGENCY_SAVINGS.title',
    StartIcon: Umbrella,
  },
  {
    value: ProjectType.SIGNIFICANT_PURCHASE,
    titleId: 'step1.question.project.SIGNIFICANT_PURCHASE.title',
    StartIcon: Home,
  },
  {
    value: ProjectType.RETIREMENT,
    titleId: 'step1.question.project.RETIREMENT.title',
    StartIcon: Sunrise,
  },
  {
    value: ProjectType.INHERITANCE,
    titleId: 'step1.question.project.INHERITANCE.title',
    StartIcon: Substitute,
  },
  {
    value: ProjectType.CHILDREN,
    titleId: 'step1.question.project.CHILDREN.title',
    StartIcon: Stroller,
  },
  {
    value: ProjectType.LEGAL_ENTITY,
    titleId: 'step1.question.project.LEGAL_ENTITY.title',
    StartIcon: Bank,
  },
] as ButtonEntry<ProjectType>[];

const REAL_ESTATE_BUTTONS: ButtonEntry<ProjectType>[] = [
  {
    value: ProjectType.SAVINGS,
    titleId: 'step1.question.project.SAVINGS.title',
    StartIcon: PiggyBank,
  },

  {
    value: ProjectType.SIGNIFICANT_PURCHASE,
    titleId: 'step1.question.project.SIGNIFICANT_PURCHASE.title',
    StartIcon: Home,
  },
  {
    value: ProjectType.RETIREMENT,
    titleId: 'step1.question.project.RETIREMENT.title',
    StartIcon: Sunrise,
  },
  {
    value: ProjectType.INHERITANCE,
    titleId: 'step1.question.project.INHERITANCE.title',
    StartIcon: Substitute,
  },
  {
    value: ProjectType.CHILDREN,
    titleId: 'step1.question.project.CHILDREN.title',
    StartIcon: Stroller,
  },
];

const LIFE_INSURANCE_PREFERENCE_BUTTONS: ButtonEntry<ProjectType>[] = [
  {
    value: ProjectType.SAVINGS,
    titleId: 'step1.question.project.SAVINGS.title',
    StartIcon: PiggyBank,
  },
  {
    value: ProjectType.EMERGENCY_SAVINGS,
    titleId: 'step1.question.project.EMERGENCY_SAVINGS.title',
    StartIcon: Umbrella,
  },
  {
    value: ProjectType.SIGNIFICANT_PURCHASE,
    titleId: 'step1.question.project.SIGNIFICANT_PURCHASE.title',
    StartIcon: Home,
  },
  {
    value: ProjectType.RETIREMENT,
    titleId: 'step1.question.project.RETIREMENT.title',
    StartIcon: Sunrise,
  },
  {
    value: ProjectType.INHERITANCE,
    titleId: 'step1.question.project.INHERITANCE.title',
    StartIcon: Substitute,
  },
  {
    value: ProjectType.CHILDREN,
    titleId: 'step1.question.project.CHILDREN.title',
    StartIcon: Stroller,
  },
] as ButtonEntry<ProjectType>[];

const STOCK_SAVINGS_PLAN_PREFERENCE_BUTTONS: ButtonEntry<ProjectType>[] = [
  {
    value: ProjectType.SAVINGS,
    titleId: 'step1.question.project.SAVINGS.title',
    StartIcon: PiggyBank,
  },

  {
    value: ProjectType.SIGNIFICANT_PURCHASE,
    titleId: 'step1.question.project.SIGNIFICANT_PURCHASE.title',
    StartIcon: Home,
  },
  {
    value: ProjectType.RETIREMENT,
    titleId: 'step1.question.project.RETIREMENT.title',
    StartIcon: Sunrise,
  },
] as ButtonEntry<ProjectType>[];

const TRADING_ACCOUNT_PREFERENCE_BUTTONS: ButtonEntry<ProjectType>[] = [
  {
    value: ProjectType.SAVINGS,
    titleId: 'step1.question.project.SAVINGS.title',
    StartIcon: PiggyBank,
  },
  {
    value: ProjectType.EMERGENCY_SAVINGS,
    titleId: 'step1.question.project.EMERGENCY_SAVINGS.title',
    StartIcon: Umbrella,
  },
  {
    value: ProjectType.SIGNIFICANT_PURCHASE,
    titleId: 'step1.question.project.SIGNIFICANT_PURCHASE.title',
    StartIcon: Home,
  },
  {
    value: ProjectType.RETIREMENT,
    titleId: 'step1.question.project.RETIREMENT.title',
    StartIcon: Sunrise,
  },
  {
    value: ProjectType.LEGAL_ENTITY,
    titleId: 'step1.question.project.LEGAL_ENTITY.title',
    StartIcon: Bank,
  },
] as ButtonEntry<ProjectType>[];

/**
 * Q1-RG3
 */
export function getButtonsForThematic(thematic?: Thematic, preferredEnvelope?: FiscalPackage) {
  switch (preferredEnvelope) {
    case FiscalPackage.LIFE_INSURANCE:
      return LIFE_INSURANCE_PREFERENCE_BUTTONS;
    case FiscalPackage.STOCK_SAVINGS_PLAN:
      return STOCK_SAVINGS_PLAN_PREFERENCE_BUTTONS;
    case FiscalPackage.TRADING_ACCOUNT:
      return TRADING_ACCOUNT_PREFERENCE_BUTTONS;
    default:
      break;
  }

  if (thematic === Thematic.REAL_ESTATE) {
    return REAL_ESTATE_BUTTONS;
  }

  return CLASSIC_BUTTONS;
}
