import yomoniClient from '@services/api';
import {
  AnnualPerformances,
  FunnelAnswers,
  PatchPerSubscription,
  RescoringResponse,
  ResumeResponse,
  SendSimulationParamRequest,
  SendSimulationParamResponse,
  SimulationParams,
} from '@shared/types';

async function sendSimulationParam(
  simulationParams: SendSimulationParamRequest,
): Promise<SendSimulationParamResponse> {
  return yomoniClient
    .post<AnnualPerformances, any>('/project/step2', simulationParams)
    .then((response) => response.data);
}

async function updateProject(simulationParams: SimulationParams): Promise<RescoringResponse> {
  return yomoniClient
    .post<RescoringResponse, any>('/project/rescoring', simulationParams)
    .then((response) => response.data);
}

async function cancelProject(): Promise<{}> {
  return yomoniClient.post<{}, any>('/project/cancel').then((response) => response.data);
}

async function postCreateNewProject(payload: FunnelAnswers): Promise<ResumeResponse> {
  return yomoniClient
    .post<FunnelAnswers, any>('/project/createNewProject', payload)
    .then((response) => response.data);
}

async function patchProject(payload: FunnelAnswers): Promise<ResumeResponse> {
  return yomoniClient
    .post<FunnelAnswers, any>('/project/step1', payload)
    .then((response) => response.data);
}

async function patchPerSubscription(
  contractId: string,
  selectedProfileId: string,
  desensitization: boolean,
  deductibility: boolean,
  initialPayment: number,
  transferAmount: number,
): Promise<{}> {
  const updatePerSubscription: PatchPerSubscription = {
    selectedProfileId,
    desensitization,
    deductibility,
    initialPayment,
    transferAmount,
  };

  return yomoniClient
    .patch<{}, any>(`/project/per/subscription/${contractId}`, updatePerSubscription)
    .then((response) => response.data);
}

async function resetPerProject(): Promise<{}> {
  return yomoniClient.put<{}, any>(`/project/per/subscription/reset`);
}

const ProjectClient = {
  sendSimulationParam,
  updateProject,
  cancelProject,
  patchPerSubscription,
  resetPerProject,
  postCreateNewProject,
  patchProject,
};

export default ProjectClient;
