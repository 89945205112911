// eslint-disable-next-line import/no-extraneous-dependencies
import { isValidPhoneNumber } from 'libphonenumber-js';
import { IntlShape } from 'react-intl';
import { object, string } from 'yup';

export const getSchema = (intl: IntlShape) =>
  object({
    firstname: string()
      .matches(/^[^<>{}[\]]*$/)
      .max(32)
      .required(),
    lastname: string()
      .matches(/^[^<>{}[\]]*$/)
      .max(32)
      .required(),
    phoneNumber: string()
      .test(
        'isPhoneNumber',
        () => intl.formatMessage({ id: 'validation.error.phoneNumber' }),
        (value?: string) => {
          if (!value) {
            return false;
          }

          return isValidPhoneNumber(value);
        },
      )
      .required(),
  }).required();

export default getSchema;
