import { To } from 'react-router-dom';

import LinkWithRouter from '@components/links/LinkWithRouter/LinkWithRouter';

export function lightBoldText(value: string) {
  return <span style={{ fontWeight: '500' }}>{value}</span>;
}

export function boldText(value: string) {
  return <b>{value}</b>;
}

export function underlineText(value: string) {
  return <u>{value}</u>;
}

export function externalLink(value: string | string[], href: string, target: string = '_blank') {
  return (
    <a href={href} target={target} rel="noreferrer" style={{ color: 'inherit' }}>
      {value}
    </a>
  );
}

export function externalLinkInSameTab(text: string, href: string) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href="#"
      rel="noreferrer"
      style={{ color: 'inherit' }}
      onClick={() => {
        window.location.href = href;
      }}>
      {text}
    </a>
  );
}

export function internalLink(text: string | string[], to: To) {
  return (
    <LinkWithRouter color="inherit" to={to}>
      {text}
    </LinkWithRouter>
  );
}
