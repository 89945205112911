import { useContext } from 'react';
import { useRecoilValue } from 'recoil';

import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import { Question } from '@features/project/question.utils';
import { UserContext } from '@providers/UserProvider';
import { FunnelAnswers, FunnelAnswersKeys, ProjectType } from '@shared/types';
import { questionsSelector } from '@state';

type ShowSkipStep1ButtonRules = {
  key: FunnelAnswersKeys;
  specificRule?: (
    apiAnswers: Partial<FunnelAnswers> | undefined,
    funnelAnswers: Partial<FunnelAnswers> | undefined,
  ) => boolean;
};
const rules: ShowSkipStep1ButtonRules[] = [
  {
    key: FunnelAnswersKeys.PROJECT_TYPE,
    specificRule: (apiAnswers, funnelAnswers) => {
      const projectTypeUnaccepted = [
        ProjectType.CHILDREN,
        ProjectType.RETIREMENT,
        ProjectType.LEGAL_ENTITY,
      ];
      if (!apiAnswers?.projectType || !funnelAnswers?.projectType) return false;
      // Pour afficher le bouton, les deux types de projets doivent être identiques ou alors ne pas être dans la liste des projets non acceptés
      return (
        apiAnswers.projectType === funnelAnswers.projectType ||
        (!projectTypeUnaccepted.includes(apiAnswers.projectType) &&
          !projectTypeUnaccepted.includes(funnelAnswers.projectType))
      );
    },
  },
  {
    key: FunnelAnswersKeys.OWNER_MAIN_RESIDENCE,
  },
];

const useShowSkipStep1ButtonButton = (invalidQuestions: Question[]) => {
  const { user } = useContext(UserContext);
  const { data: apiFunnelAnswersData } = useResumeFunnelAnswers();
  const questionsState = useRecoilValue(questionsSelector);

  if (
    invalidQuestions.length > 0 ||
    !user ||
    !apiFunnelAnswersData?.funnelAnswers ||
    !questionsState
  )
    return false;

  return rules.every((rule) => {
    if (rule.specificRule) {
      return rule.specificRule(apiFunnelAnswersData.funnelAnswers, questionsState);
    }
    return apiFunnelAnswersData.funnelAnswers?.[rule.key] === questionsState[rule.key];
  });
};

export default useShowSkipStep1ButtonButton;
