import { Box, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import ContactButton from '@components/ContactButton/ContactButton';
import OutlinedButton from '@components/OutlinedButton/OutlinedButton';
import ValidateSimulationButton from '@components/ValidateSimulationButton/ValidateSimulationButton';
import useTryNewLifeInsurancePage from '@hooks/useTryNewLifeInsurancePage';
import { isPeaPackage } from '@shared/types';
import { fiscalPackageState, preferredTransferState } from '@state';
import { isMobileDimensions } from '@utils/global.utils';
import { navigateToStep1GivenFiscalPackage } from '@utils/navigation.utils';

export default function FooterRecommendation() {
  const { spacing } = useTheme();
  const isMobile = isMobileDimensions('md');
  const navigate = useNavigate();
  const fiscalPackage = useRecoilValue(fiscalPackageState);
  const preferredTransfer = useRecoilValue(preferredTransferState);

  // ABTEST-BEGIN | Toggle.NEW_LIFE_INSURANCE_PAGE
  const tryNewLifeInsurancePage = useTryNewLifeInsurancePage();
  // ABTEST-END | Toggle.NEW_LIFE_INSURANCE_PAGE

  const buildTitle = (): string => {
    if (isPeaPackage(fiscalPackage)) {
      if (preferredTransfer) {
        return 'buttons.validateProjectPea.transfer';
      }
      return 'buttons.validateProjectPea';
    }
    // ABTEST-BEGIN | Toggle.NEW_LIFE_INSURANCE_PAGE
    if (tryNewLifeInsurancePage) {
      return 'buttons.validateProjectAV';
    }
    // ABTEST-END | Toggle.NEW_LIFE_INSURANCE_PAGE
    return 'buttons.validateProject';
  };
  const buttonTitle = useMemo(buildTitle, [fiscalPackage, preferredTransfer]);

  const handleSimulationValidation = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'eventTracking',
        category: 'navigation',
        action: 'clic-cta',
        label: 'tunnelv2-etape2-1-validation',
      });
    }
  };

  const handleRecommandationBackButtonClick = async () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'eventTracking',
        category: 'navigation',
        action: 'clic-cta',
        label: 'tunnelv2-etape2-1-retour',
      });
    }

    navigateToStep1GivenFiscalPackage(navigate, fiscalPackage);
  };

  return (
    <div
      data-testid="footer-recommendation"
      style={{
        display: 'flex',
        justifyContent: isMobile ? 'center' : 'space-between',
        width: '100%',
        gap: isMobile ? spacing(2) : 0,
      }}>
      {!isMobile && (
        <OutlinedButton
          onClick={handleRecommandationBackButtonClick}
          titleId="footer.recommendationPageBackButton"
        />
      )}
      <Box
        sx={{
          display: 'flex',
          width: isMobile ? '100%' : 'auto',
          justifyContent: { xs: 'center', lg: 'flex-end' },
          gap: { xs: spacing(2), lg: spacing(5) },
        }}>
        {!isMobile && <ContactButton />}
        <ValidateSimulationButton titleId={buttonTitle} onClick={handleSimulationValidation} />
      </Box>
    </div>
  );
}
