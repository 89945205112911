import { useEffect } from 'react';

import { SmartPushOptions } from '@typings/smart-push.interface';

interface SmartPushProps {
  onContactClick: () => void;
}

const options: SmartPushOptions = {
  analyticsUA: process.env.REACT_APP_GA_ID!,
  cookieOptin: true,
  filters: {
    tags: ['souscription-proposition-2575'],
    thematics: [],
  },
  kbId: Number(process.env.REACT_APP_SMART_PUSH_KB_ID),
  locale: 'fr',
};

export function observeAddedChildNode(parent: any, childIdOrFunction: any, callback: any) {
  const observer = new MutationObserver(
    (mutationsList: MutationRecord[], mutationObserver: MutationObserver) => {
      const child = mutationsList
        .filter((mutation) => mutation.addedNodes.length)
        .flatMap((mutation) => Array.from(mutation.addedNodes))
        .find((node: any) => {
          if (typeof childIdOrFunction === 'string') {
            return node.id === childIdOrFunction;
          }
          if (typeof childIdOrFunction === 'function') {
            return childIdOrFunction(node);
          }
          throw new TypeError('childIdOrFunction is not a string or a function');
        });
      if (!child) {
        return;
      }

      callback(mutationObserver, child);
    },
  );
  observer.observe(parent, { childList: true, subtree: true });
}

export function handleContactButtonClick(buttonId: string, onClick: any) {
  // Observe body until smart push has been added
  observeAddedChildNode(document.body, 'st-push-root', (bodyObserver: any, stPushRoot: string) => {
    bodyObserver.disconnect();
    // Observe smart push until contact button has been added
    observeAddedChildNode(
      stPushRoot,
      (node: any) =>
        Array.from(node.getElementsByTagName('*')).find((nodeIt: any) => nodeIt.id === buttonId),
      () => {
        // Handle click on contact button
        const contactButton = document.getElementById(buttonId);
        if (contactButton) {
          contactButton.addEventListener('click', () => {
            onClick();
          });
        }
      },
    );
  });
}

export default function SmartPush({ onContactClick }: SmartPushProps) {
  useEffect(() => {
    window.addEventListener('STPUSHLoaded', (event: CustomEvent) => {
      window.stPush = event.detail;
      window.stPush.init(options);
    });
    if (window && !window.stPush && document) {
      const script = document.createElement('script');
      const body = document.getElementsByTagName('body')[0];
      script.src = process.env.REACT_APP_SMART_PUSH_URL!;
      script.async = true;
      body.appendChild(script);

      if (typeof onContactClick === 'function') {
        handleContactButtonClick('st-push-contact-button', onContactClick);
      }
    }
  }, []);

  return null;
}
