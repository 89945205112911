export enum Toggle {
  QUESTIONS_NEW_ORDER = 'REACT_APP_QUESTIONS_NEW_ORDER',
  NEW_LIFE_INSURANCE_PAGE = 'REACT_APP_NEW_LIFE_INSURANCE_PAGE',
  REACT_APP_NEW_PEA_WORDING = 'REACT_APP_NEW_PEA_WORDING',
  REACT_APP_PHONE_FIRST_MODALE = 'REACT_APP_PHONE_FIRST_MODALE',
}

/* eslint-disable no-bitwise */
const HASH_MIN = -2148000000;
const HASH_MAX = 2148000000;

const hashString = (str: string) => {
  let hash = 0;
  if (str.length === 0) {
    return hash;
  }
  let ch;
  for (let x = 0; x < str.length; x += 1) {
    ch = str.charCodeAt(x);
    hash = (hash << 5) - hash + ch;
    hash &= hash;
  }

  return hash;
};

export function rescaleTo100(str: string): number {
  const hash = hashString(str);
  return Math.max(1, Math.min(100, Math.round((hash - HASH_MIN) / ((HASH_MAX - HASH_MIN) / 100))));
}
