import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';

export default function checkRulesQuestionRealEstateValueLoss(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { lossOfRealEstateValueQuiz } = ruleParams.questionState;

  if (!lossOfRealEstateValueQuiz) {
    isValid = false;
  } else {
    isValid = true;
    infoBoxMessages.push({
      infoBoxMessage: `step1.question.realEstateValueLoss.info.${lossOfRealEstateValueQuiz}`,
      infoBoxLevel: InfoBoxLevel.INFO,
      infoBoxImageSrc: '/images/thomas@2x.png',
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}
