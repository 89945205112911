export function isDefined(value?: Object) {
  return value !== null && value !== undefined;
}

export function isNumberDefined(num?: number) {
  return isDefined(num) && !Number.isNaN(num);
}

export function isStringDefined(str?: string) {
  return isDefined(str) && str !== '';
}

export function removeWhitespaces(str: string) {
  return str.replace(/\s/g, '');
}
