import { useState } from 'react';

const useTransition = (isTransitioningDuration: number = 300) => {
  const [isTransitioning, setIsTransitioning] = useState(false);

  const transition = (callback: () => void) => {
    setIsTransitioning(true);
    setTimeout(() => {
      callback();
      setIsTransitioning(false);
    }, isTransitioningDuration);
  };

  return { isTransitioning, transition };
};

export default useTransition;
