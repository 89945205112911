import dayjs from 'dayjs';
import fr from 'dayjs/locale/fr';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';

import SmartPush from '@features/smartpush/SmartPush';
import Yup from '@features/yup/Yup';
import Zendesk from '@features/zendesk/Zendesk';
import Providers from '@providers/Providers';
import AppRoutes from '@routes/AppRoutes';

/* messages externalizing */
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.locale(fr);

function App() {
  const [chatOpened, setChatOpened] = useState(false);

  useEffect(() => {
    if (process.env.REACT_APP_GTM_ID) {
      const tagManagerArgs = {
        gtmId: process.env.REACT_APP_GTM_ID,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  const handleChatOpen = () => {
    window.stPush.hide(); // hide SmartPush popup
    window.stPush.off(); // hide SmartPush button
  };

  const handleChatClose = () => {
    window.stPush.on(); // show SmartPush button
    setChatOpened(false);
  };

  return (
    <Providers>
      <AppRoutes />
      <Yup />
      <SmartPush onContactClick={() => setChatOpened(true)} />
      <Zendesk onClose={handleChatClose} onOpen={handleChatOpen} open={chatOpened} />
    </Providers>
  );
}

export default App;
