import { ClickAwayListener, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MaterialToolTip, {
  TooltipProps as MaterialToolTipProps,
  tooltipClasses,
} from '@mui/material/Tooltip';
import { ReactNode } from 'react';

import useModale from '@hooks/useModale';
import { GRAY_DARK } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

interface TooltipProps extends MaterialToolTipProps {}

interface TooltipContentProps {
  title: ReactNode;
  onClickAway: () => void;
}

const StyledMaterialTooltip = styled(({ className, children, ...rest }: TooltipProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MaterialToolTip {...rest} classes={{ popper: className }}>
    {children}
  </MaterialToolTip>
))(() => {
  return {
    [`& .${tooltipClasses.arrow}`]: {
      color: GRAY_DARK,
      fontSize: '9px',
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: GRAY_DARK,
    },
  };
});

function TooltipContent({ title, onClickAway }: TooltipContentProps) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Typography variant="xs" fontWeight="fontWeightMedium" color="common.white">
        {title}
      </Typography>
    </ClickAwayListener>
  );
}

export default function Tooltip({ children, title, arrow = true, ...rest }: TooltipProps) {
  const isMobile = isMobileDimensions('md');

  const { isModalOpen, closeModal, openModal } = useModale(false);

  return (
    <StyledMaterialTooltip
      open={isMobile ? isModalOpen : undefined}
      disableHoverListener={isMobile}
      onClick={openModal}
      arrow={arrow}
      title={<TooltipContent title={title} onClickAway={closeModal} />}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}>
      {children}
    </StyledMaterialTooltip>
  );
}
