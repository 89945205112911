import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

function Yup() {
  const intl = useIntl();

  useEffect(() => {
    yup.setLocale({
      mixed: {
        required: intl.formatMessage({ id: 'validation.error.required' }),
      },
      string: {
        email: intl.formatMessage({ id: 'validation.error.email' }),
        max: ({ max }) => intl.formatMessage({ id: 'validation.error.max' }, { max }),
        matches: intl.formatMessage({ id: 'validation.error.matches' }),
      },
    });
  }, []);

  return null;
}

export default Yup;
