export const MONTHLY_DEPOSIT_MIN_VALUE = 50;
export const MONTHLY_DEPOSIT_MAX_VALUE = 150_000;
export const MONTHLY_DEPOSIT_AUTHORIZE_NONE = true;

export const DEFAULT_INITIAL_INVESTMENT_MIN_VALUE = 1_000;
export const DEFAULT_INITIAL_INVESTMENT_MAX_VALUE = 10_000_000;

export const CHILDREN_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT = 300;
export const FIRST_LIFE_INSURANCE_MIN_INITIAL_INVESTMENT = 1_000;
export const LIFE_INSURANCE_MIN_INITIAL_INVESTMENT = 300;

export const LIFE_INSURANCE_MAX_MONTHLY_DEPOSIT = 150_000;

export const TRADING_ACCOUNT_MIN_INITIAL_INVESTMENT = 5_000;
export const TRADING_ACCOUNT_LEGAL_ENTITY_MIN_INITIAL_INVESTMENT = 10_000;
export const TRADING_ACCOUNT_MIN_RISK = 3;

export const STOCK_SAVINGS_PLAN_MIN_INITIAL_INVESTMENT = 5_000;
export const STOCK_SAVINGS_PLAN_MAX_INITIAL_INVESTMENT = 150_000;
export const STOCK_SAVINGS_PLAN_MIN_RECOMMENDED_HORIZON = 5;
export const STOCK_SAVINGS_PLAN_RISK = 10;

export const HIGH_RISK_MIN_RECOMMENDED_HORIZON = 5;

export const HIGH_RISK_THRESHOLD = 5;
export const CAUTIOUS_HIGH_RISK_THRESHOLD = 3;
export const BALANCED_HIGH_RISK_THRESHOLD = 5;

export const MAX_RISK_WITHDRAW_BEFORE_2_YEARS = 10;

export const HORIZON_MIN_VALUE = 2;
export const HORIZON_MIN_VALUE_PEA = 4;
export const HORIZON_MAX_VALUE = 30;

export const MAX_RISK_RANGE = 3;
export const MIN_RECOMMENDED_RISK_PEA = 7;

export const RETIREMENT_AGE_MIN_VALUE = 50;
export const RETIREMENT_AGE_MAX_VALUE = 70;
export const TRANSFER_AMOUNT_MIN_VALUE = 0;
