import { Breakpoint, useMediaQuery } from '@mui/material';
import dayjs from 'dayjs';

import {
  FiscalPackage,
  MarginalTaxRate,
  PerformanceProfile,
  RiskLevelPerformances,
  Thematic,
} from '@shared/types';
import theme from '@theme';

/**
 * Format a raw barChart value to a percentage with comma
 * @param value
 * @example formatBarChartValue(12.5) => 12,5%
 */
export function formatPerformanceValue(value: number | string): string {
  return `${String(Number(value || 0).toFixed(1)).replace('.', ',')}%`;
}

/**
 * Returns the SRRI of the specified profile or an empty string if profile is not found
 * @param profileId
 * @param performances
 * @example getProfileSrri('P4', [{id: 'P4', profile: '4', edge: 'AV', srri: '4', …}]) => '4'
 */
export function getProfileSrri(profileId: string, performances: RiskLevelPerformances): string {
  return performances?.profiles?.find((profile) => profile.id === profileId)?.SRRI ?? '';
}

/**
 * Returns the SRI of the specified profile or an empty string if profile is not found
 * @param profileId
 * @param performances
 * @example getProfileSri('P4', [{id: 'P4', profile: '4', edge: 'AV', sri: '4', …}]) => '4'
 */
export function getProfileSri(profileId: string, performances: RiskLevelPerformances): string {
  return performances?.profiles?.find((profile) => profile.id === profileId)?.SRI ?? '';
}

/**
 * Returns the perfValue of the specified profile or an empty string if profile is not found
 * @param profileId
 * @param performances
 * @example getProfilePerformance('P4', [{id: 'P4', profile: '4', edge: 'AV', begin: '01/09/2015', end: '29/04/2022', …}]) => '6.9'
 */
export function getProfilePerformance(
  profileId: string,
  performances: RiskLevelPerformances,
): string {
  return performances.profiles.find((profile) => profile.id === profileId)?.perfValue ?? '';
}

/**
 * Returns the begin date of the specified profile or an empty string if profile is not found
 * @param profileId
 * @param performances
 * @example getProfileStartDate('P4', [{id: 'P4', profile: '4', edge: 'AV', begin: '01/09/2015', end: '29/04/2022', …}]) => '01/09/2015'
 */
export function getProfileStartDate(
  profileId: string,
  performances: RiskLevelPerformances,
): string {
  return performances.profiles.find((profile) => profile.id === profileId)?.begin ?? '';
}

/**
 * Returns the begin date of the specified profile or an empty string if profile is not found
 * @param profileId
 * @param performances
 * @example getProfileEndDate('P4', [{id: 'P4', profile: '4', edge: 'AV', begin: '01/09/2015', end: '29/04/2022', …}]) => '29/04/2022'
 */
export function getProfileEndDate(profileId: string, performances: RiskLevelPerformances): string {
  return performances.profiles.find((profile) => profile.id === profileId)?.end ?? '';
}

/**
 * Returns performance for the provided profileId
 * @param profileId
 * @param performances
 * @example getProfileEndDate('P4', [{id: 'P4', profile: '4', edge: 'AV', begin: '01/09/2015', end: '29/04/2022', …}]) => '29/04/2022'
 */
export function getProfile(
  profileId: string,
  performances?: RiskLevelPerformances,
): PerformanceProfile {
  if (!performances) {
    return {} as PerformanceProfile;
  }
  return (
    performances.profiles.find((profile) => profile.id === profileId) || ({} as PerformanceProfile)
  );
}

/**
 * Returns true if breakpoints is below breakpoint parameter
 */
export function isMobileDimensions(breakpoint: Breakpoint): boolean {
  return useMediaQuery(theme.breakpoints.down(breakpoint));
}

/**
 * Returns the corresponding profileId from fiscalPackageStateValue
 * @param profileId
 * @example getFiscalPackageFromProfileId('P10') => FiscalPackage.LIFE_INSURANCE
 */
export const getFiscalPackageFromProfileId = (profileId: string) => {
  const STOCK_SAVINGS_PLAN_REGEXP = /^(PEA)$/;
  const TRADING_ACCOUNT_REGEXP = /^(CT)([3-9]|10)$/;
  const LIFE_INSURANCE_REGEXP = /(^P([1-9]|10)$)|(^P([2-9]|10)(ESG|IMMO)$)|(^P2_2021$)/;
  const PER_REGEXP = /^(PER).*/;
  const PER_INSURANCE = /^(SPK_PER).*/;

  switch (true) {
    case STOCK_SAVINGS_PLAN_REGEXP.test(profileId):
      return FiscalPackage.STOCK_SAVINGS_PLAN;

    case TRADING_ACCOUNT_REGEXP.test(profileId):
      return FiscalPackage.TRADING_ACCOUNT;

    case LIFE_INSURANCE_REGEXP.test(profileId):
      return FiscalPackage.LIFE_INSURANCE;

    case PER_REGEXP.test(profileId):
      return FiscalPackage.RETIREMENT_SAVING_PLAN;

    case PER_INSURANCE.test(profileId):
      return FiscalPackage.RETIREMENT_SAVING_PLAN_INSURANCE;

    default:
      throw new Error('Unknown profileId');
  }
};

/**
 * Returns the number of years since startDate
 * @param startDate - format must be DD/MM/YYYY
 * @example getYearsSinceStartDate('01/09/2015') => 6
 */
export const getNumberOfYearsSinceStartDate = (startDate: string) => {
  const startDateMoment = dayjs(startDate);
  return dayjs().diff(startDateMoment, 'year');
};

export function validateAndGetThematic(
  thematic?: Thematic,
  packageValue?: FiscalPackage,
): Thematic {
  if (!thematic || !packageValue) {
    return Thematic.CLASSIC;
  }

  if (thematic === Thematic.ESG) {
    return packageValue === FiscalPackage.LIFE_INSURANCE ? Thematic.ESG : Thematic.CLASSIC;
  }

  return thematic;
}

export function uuidToHash(stringToHash: string) {
  let hash = 0;
  if (stringToHash.length === 0) {
    return hash;
  }
  let ch;
  for (let x = 0; x < stringToHash.length; x += 1) {
    ch = stringToHash.charCodeAt(x);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + ch;
    // eslint-disable-next-line no-bitwise
    hash &= hash;
  }
  return hash;
}

export function rescaleHashTo100(hash: number) {
  const HASH_MIN = -2148000000;
  const HASH_MAX = 2148000000;
  return Math.max(1, Math.min(100, Math.round((hash - HASH_MIN) / ((HASH_MAX - HASH_MIN) / 100))));
}

export function decodeToken(token: string): {
  header: Record<string, any>;
  payload: Record<string, any>;
} {
  const parts = token.split('.');
  const header = JSON.parse(atob(parts[0]));
  const payload = JSON.parse(atob(parts[1]));

  return {
    header,
    payload,
  };
}

export function getRiskLevelLabel(riskLevel: number): string {
  if (riskLevel >= 7) {
    return 'high';
  }
  if (riskLevel >= 4) {
    return 'medium';
  }
  return 'low';
}

export function getAnnualTaxSavingFromMarginalTaxRate(
  initialAmount: number,
  marginalTaxRate: MarginalTaxRate,
) {
  switch (marginalTaxRate) {
    case MarginalTaxRate.TAUX_0:
      return 0;
    case MarginalTaxRate.TAUX_11:
      return (initialAmount * 11) / 100;
    case MarginalTaxRate.TAUX_30:
      return (initialAmount * 30) / 100;
    case MarginalTaxRate.TAUX_41:
      return (initialAmount * 41) / 100;
    case MarginalTaxRate.TAUX_45:
    default:
      return (initialAmount * 45) / 100;
  }
}
