import { useState } from 'react';

const KeyCode = {
  UpperA: 65,
  LowerZ: 122,
  Zero: 48,
  Nine: 57,
};

/**
 * A customizable one-time password input.
 *
 * Options:
 * - autoFocus: auto focuses input on mount
 * - onChange: a function called on input change that expects the OTP code as parameter
 * - otpLength: number of input boxes (default: 6)
 * - otpType: default: any values, "number": allow only numbers, "alpha": allows only a-zA-Z, "alphanumeric": allows 0-9a-zA-z
 * - value: the OTP code as array
 *
 * Props:
 * - activeIndex: the active index
 * - onFocus: select the input text on focus
 * - onInput: move focus to the next input
 * - onChange: change the input at active index and move focus on next input
 * - onFocus: change index to the focused input and select text in input
 * - onInput: change index to the next input if the current input is filled
 * - onKeyDown: handle Backspace, Delete, ArrowLeft and ArrowRight keys
 * - onPaste: handle data from clipboard
 *
 * @param {Object} options - The options
 * @return The props
 */
export interface UseOtpProps {
  autoFocus?: boolean;
  onChange: (otpValue: string[]) => void;
  otpLength?: number;
  otpType: string;
  value?: string[];
}

export default function useOtp({
  autoFocus,
  onChange: onChangeProp,
  otpLength,
  otpType,
  value: otp,
}: UseOtpProps) {
  const [activeIndex, setActiveIndex] = useState(autoFocus ? 0 : -1);

  // Helper to return OTP from input
  const handleOtpChange = (otpValue: string[]) => {
    if (onChangeProp) {
      onChangeProp(otpValue);
    }
  };

  // Focus on input by index
  const focusInput = (input: number) => {
    const nextActiveInput = Math.max(Math.min(otpLength! - 1, input), 0);
    setActiveIndex(nextActiveInput);
  };

  const focusInputByDirection = (direction = 'next') => {
    focusInput(direction === 'next' ? activeIndex + 1 : activeIndex - 1);
  };

  // Change OTP value at focused input
  const changeActiveInputValue = (nextValue: string) => {
    if (otp) {
      // eslint-disable-next-line no-param-reassign
      otp[activeIndex] = nextValue;
      handleOtpChange(otp);
    }
  };

  const isValidateChar = (char: string) => {
    switch (otpType) {
      case 'number':
        return !(char.charCodeAt(0) > KeyCode.Nine || char.charCodeAt(0) < KeyCode.Zero);
      case 'alpha':
        return !(char.charCodeAt(0) > KeyCode.LowerZ || char.charCodeAt(0) < KeyCode.UpperA);
      case 'alphanumeric':
        return !(char.charCodeAt(0) > KeyCode.LowerZ || char.charCodeAt(0) < KeyCode.Zero);
      default:
        return true;
    }
  };

  // Handle pasted OTP
  const onPaste = (e: any) => {
    e.preventDefault();

    // Get pastedData in an array of max size (num of inputs - current position)
    const clipboardData = e.clipboardData
      .getData('text/plain')
      .slice(0, otpLength! - activeIndex)
      .split('');

    // Pass copied value through onChange rules
    const filteredOtpValue: string[] = Array(otpLength).fill('');
    let validCharIndex = 0;
    // eslint-disable-next-line no-plusplus
    for (let charIndex = 0; charIndex < otpLength!; charIndex++) {
      if (isValidateChar(clipboardData[charIndex])) {
        filteredOtpValue[validCharIndex] = clipboardData[charIndex];
        validCharIndex += 1;
      }
    }

    handleOtpChange(filteredOtpValue);
  };

  const onChange = (e: any) => {
    if (isValidateChar(e.target.value)) {
      changeActiveInputValue(e.target.value);
      focusInputByDirection('next');
    }
  };

  const onKeyDown = (e: any) => {
    switch (e.key) {
      case 'Backspace':
        e.preventDefault();
        changeActiveInputValue('');
        focusInputByDirection('prev');
        break;
      case 'Delete':
        e.preventDefault();
        changeActiveInputValue('');
        break;
      case 'ArrowLeft':
        e.preventDefault();
        focusInputByDirection('prev');
        break;
      case 'ArrowRight':
        e.preventDefault();
        focusInputByDirection('next');
        break;
      default:
        break;
    }
  };

  const onInput = (e: any) => {
    if (e.target.value.length > 1) {
      e.preventDefault();
      focusInputByDirection('next');
    }
  };

  const onFocus = (index: number, event: any) => {
    setActiveIndex(index);
    event.target.select();
  };

  return {
    activeIndex,
    onChange,
    onFocus,
    onInput,
    onKeyDown,
    onPaste,
  };
}

useOtp.defaultProps = {
  autoFocus: true,
  otpLength: 6,
  value: [],
};
