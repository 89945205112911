import { Box } from '@mui/system';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionWithdrawalHalf from '@features/project/components/questions/QuestionWithdrawalHalf/QuestionWithdrawalHalf.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import useDimensions from '@hooks/useDimensions';
import { FunnelAnswersKeys, WithdrawHalfProbability } from '@shared/types';
import { horizonQuestionState } from '@state';

export default function QuestionWithdrawalHalf() {
  const { isMobile } = useDimensions();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF,
    checkRulesQuestionWithdrawalHalf,
  );
  const horizon = useRecoilValue(horizonQuestionState);
  const titleValues = useMemo(() => ({ horizon }), [horizon]);
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.withdrawal-half',
    titleValues,
  });

  const buttons = [
    {
      value: WithdrawHalfProbability.CERTAINLY_NOT,
      titleId: 'step1.question.withdrawal-half.CERTAINLY_NOT',
    },
    {
      value: WithdrawHalfProbability.PROBABLY_NOT,
      titleId: 'step1.question.withdrawal-half.PROBABLY_NOT',
    },
    {
      value: WithdrawHalfProbability.PROBABLY,
      titleId: 'step1.question.withdrawal-half.PROBABLY',
    },
    {
      value: WithdrawHalfProbability.CERTAINLY,
      titleId: 'step1.question.withdrawal-half.CERTAINLY',
    },
  ];

  const onChange = (newWithdrawHalfProbability: WithdrawHalfProbability) =>
    update(newWithdrawHalfProbability, { autoValidate: true });

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={answer}
        onChange={onChange}
        containerProps={{ sx: { maxWidth: isMobile ? '100%' : '360px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
