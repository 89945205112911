import dayjs from 'dayjs';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { DATE_API_FORMAT_PATTERN } from '@utils/date.utils';

export default function checkRulesQuestionBirthdateChildren(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const birthdateChildren = ruleParams.questionState.birthdateKid;

  if (birthdateChildren && birthdateChildren.length === DATE_API_FORMAT_PATTERN.length) {
    if (wasNotBorn(birthdateChildren)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.birthday-children.was-not-born',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    } else if (isNotAValidDate(birthdateChildren)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.birthday.incorrect',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    } else if (birthdateIsMoreThan120Years(birthdateChildren)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.birthday.too-old',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    } else if (isAnAdultBirthdate(birthdateChildren)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.birthday-children.not-a-children',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    }
  } else {
    isValid = false;
  }

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 *
 * Q5-RG1
 */
function isNotAValidDate(birthdateChildren: string) {
  return !dayjs(birthdateChildren, DATE_API_FORMAT_PATTERN, true).isValid();
}

/**
 *
 * Q5-RG2
 */
function wasNotBorn(birthdateChildren: string) {
  return dayjs(birthdateChildren, DATE_API_FORMAT_PATTERN, true).isAfter(dayjs());
}

/**
 *
 * Q5-RG5
 */
function birthdateIsMoreThan120Years(birthdateChildren: string) {
  return dayjs(birthdateChildren, DATE_API_FORMAT_PATTERN, true).isBefore(
    dayjs().subtract(120, 'year'),
    'day',
  );
}

/**
 *
 * Q5-RG4
 */
function isAnAdultBirthdate(birthdateChildren: string) {
  return dayjs(birthdateChildren, DATE_API_FORMAT_PATTERN, true).isBefore(
    dayjs().subtract(18, 'year').add(1, 'day'),
    'day',
  );
}
