import { useContext } from 'react';

import Loading from '@components/Loading/Loading';
import { UserContext } from '@providers/UserProvider';

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { user, isLoading, hasJwtToken } = useContext(UserContext);

  if (isLoading || (hasJwtToken && !user)) {
    return <Loading />;
  }

  if (!user) {
    window.location.href = `${process.env.REACT_APP_YOMONI_CORE_FRONT_BASE_URL}/login`;
    return null;
  }

  return children;
}
