import { useCookies } from 'react-cookie';

import { YOMONI_CDP_COOKIE_NAME } from '@constants/app.const';
import { YomoniCdpCookie } from '@hooks/tracking/useTracking';

export default function useYomoniCdpCookie() {
  const [cookies, setCookie] = useCookies([YOMONI_CDP_COOKIE_NAME]);

  const createYomoniCdpCookie = (newAnonymousId: string) => {
    const newCdpCookie: YomoniCdpCookie = { anonymousId: newAnonymousId };

    setCookie(YOMONI_CDP_COOKIE_NAME, JSON.stringify(newCdpCookie), {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      path: '/',
      sameSite: 'lax',
    });

    return newCdpCookie;
  };

  const getOrCreateYomoniCdpCookie = (): YomoniCdpCookie => {
    let yomoniCdpCookie: YomoniCdpCookie = {};

    try {
      yomoniCdpCookie = cookies[YOMONI_CDP_COOKIE_NAME];
    } catch (e) {
      // Could not parse cookie
    }

    if (!yomoniCdpCookie?.anonymousId) {
      const newAnonymousId = crypto.randomUUID().toString();
      yomoniCdpCookie = createYomoniCdpCookie(newAnonymousId);
    }

    return yomoniCdpCookie;
  };

  return { yomoniCdpCookie: getOrCreateYomoniCdpCookie() };
}
