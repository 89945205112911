import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { isNumberDefined } from '@utils/input.utils';

export default function checkRulesQuestionRecurringPayments(
  ruleParams: QuestionRuleParams,
): RulesResult {
  const returnResponse: RulesResult = {
    isValid: true,
    infoBoxMessages: [],
  };

  const { recurringPayment } = ruleParams.questionState;

  if (!isNumberDefined(recurringPayment)) {
    returnResponse.isValid = false;
    return returnResponse;
  }

  if (
    recurringPayment &&
    recurringPayment !== 0 &&
    (recurringPayment < 50 || recurringPayment > 150000)
  ) {
    returnResponse.infoBoxMessages.push({
      infoBoxMessage: 'step1.question.recurringPayments.error.rangeNotRespected',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
    returnResponse.isValid = false;
  }

  return returnResponse;
}
