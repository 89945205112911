import { useMemo } from 'react';

import { ProductQuestion, Question } from '@features/project/question.utils';

export default function useQuestion<T extends Question | ProductQuestion>(
  questions: Array<T>,
  path?: string,
) {
  const question = useMemo(() => {
    return questions.find((q) => q.path === path) ?? questions[0];
  }, [questions, path]);
  const questionIndex = questions.findIndex((q) => q.path === question.path);
  const questionNumber = questionIndex + 1;
  const previousQuestion = questionIndex > 0 ? questions[questionIndex - 1] : undefined;
  const nextQuestion =
    questionIndex < questions.length - 1 ? questions[questionIndex + 1] : undefined;

  return {
    question,
    questionIndex,
    questionNumber,
    previousQuestion,
    nextQuestion,
  };
}
