import { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import CenteredColumnContainer from '@components/CenteredColumnContainer/CenteredColumnContainer';
import Loading from '@components/Loading/Loading';
import Step1Questions from '@features/project/components/Step1Questions';
import WelcomeModal from '@features/project/components/WelcomeModal/WelcomeModal';
import useProjectParams from '@features/project/hooks/useProjectParams';
import useQuestionLists from '@features/project/hooks/useQuestionLists';
import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import useDimensions from '@hooks/useDimensions';
import useModale from '@hooks/useModale';
import { UserContext } from '@providers/UserProvider';
import { METHOD_BOX_GRAY } from '@theme';
import { extractPreferredEnvelope, extractPreferredTransfer } from '@utils/url.utils';

export default function SubscriptionQuestions() {
  const { isMobile } = useDimensions();
  const {
    refetch: fetchAndSetFunnelAnswers,
    data: funnelAnswersData,
    isRefetching: isRefetchingFunnelAnswers,
  } = useResumeFunnelAnswers();
  const navigate = useNavigate();
  const params = useParams<{ questionPath: string }>();
  const intl = useIntl();
  const { user, isLoading: isLoadingUser } = useContext(UserContext);
  const { queryParamsStr } = useProjectParams();
  const { questions } = useQuestionLists();
  const { isModalOpen, closeModal } = useModale(true);
  const [searchParams] = useSearchParams();
  const preferredEnvelopeFromUrl = extractPreferredEnvelope(searchParams);
  const preferredTransferFromUrl = extractPreferredTransfer(searchParams);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'page.project.title' });
    window.scrollTo(0, 0);
  }, [params.questionPath]);

  useEffect(() => {
    if (params.questionPath === undefined) {
      navigate(`${questions.toDisplay.at(0)!.path}${queryParamsStr}`, { replace: true });
    }
  }, []);

  useEffect(() => {
    if (user) {
      fetchAndSetFunnelAnswers();
    }
  }, [user]);

  const component = useMemo(
    () =>
      isLoadingUser || isRefetchingFunnelAnswers || (user && !funnelAnswersData) ? (
        <Loading />
      ) : (
        <>
          {preferredEnvelopeFromUrl ? (
            <WelcomeModal
              handleClose={closeModal}
              isOpen={isModalOpen}
              isMobile={isMobile}
              fiscalPackage={preferredEnvelopeFromUrl}
              isTransfer={!!preferredTransferFromUrl}
            />
          ) : null}
          <Step1Questions questions={questions.toDisplay} questionPath={params.questionPath!} />
        </>
      ),
    [
      isLoadingUser,
      isRefetchingFunnelAnswers,
      user,
      funnelAnswersData,
      questions,
      params.questionPath,
      preferredEnvelopeFromUrl,
      preferredTransferFromUrl,
      isModalOpen,
      isMobile,
      closeModal,
    ],
  );

  return (
    <CenteredColumnContainer
      px={isMobile ? 0 : undefined}
      py={isMobile ? 0 : 4}
      alignItems="stretch"
      backgroundColor={METHOD_BOX_GRAY}
      maxWidth={isMobile ? '100%' : undefined}
      disableGutters={isMobile}>
      {component}
    </CenteredColumnContainer>
  );
}
