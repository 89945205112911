import { useMemo } from 'react';

import { WelcomeModalProps } from '@features/project/components/WelcomeModal/WelcomeModal';
import { FiscalPackage } from '@shared/types';

interface UseWelcomeModalProps extends Pick<WelcomeModalProps, 'fiscalPackage' | 'isTransfer'> {}

const getTitleId = ({ fiscalPackage, isTransfer }: UseWelcomeModalProps) => {
  let titleId = `welcomeModal.title.${fiscalPackage}`;
  if (fiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN && isTransfer) {
    titleId = 'welcomeModal.title.PEA.transfer';
  }

  return titleId;
};
const getTextId = ({ fiscalPackage, isTransfer }: UseWelcomeModalProps) => {
  let textId = 'welcomeModal.text';
  if (fiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN && isTransfer) {
    textId = 'welcomeModal.text.transfer';
  }

  return textId;
};

export const useWelcomeModal = (props: UseWelcomeModalProps) => {
  const titleId = useMemo(() => getTitleId(props), [props]);
  const textId = useMemo(() => getTextId(props), [props]);

  return { titleId, textId };
};

export default useWelcomeModal;
