import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { QUERY_PARAMS } from '@constants/routes.const';
import { getButtonsList } from '@features/productFunnel/components/questions/QuestionFiscalPackage/QuestionFiscalPackage.utils';
import useProjectParams from '@features/project/hooks/useProjectParams';
import { UserContext } from '@providers/UserProvider';
import { prepareNewProjectQuery, resumeQuery } from '@queries';
import { Action } from '@shared/types';
import { businessReferralState } from '@state';

const useResumeOrPrepareNewProjectQuery = () => {
  const { user } = useContext(UserContext);

  const resume = resumeQuery({ enabled: false });
  const prepareNewProject = prepareNewProjectQuery({ enabled: false });

  return user?.actions.includes(Action.CREATE_PROJECT) ? prepareNewProject : resume;
};

export default function useButtons({ wantsTransfer }: { wantsTransfer: boolean }) {
  useProjectParams();
  const { user, isLoading: isLoadingUser, hasJwtToken } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const businessReferral = useRecoilValue(businessReferralState);
  const resumeOrPrepareNewProject = useResumeOrPrepareNewProjectQuery();

  const isLoading =
    isLoadingUser ||
    (hasJwtToken && !user) ||
    resumeOrPrepareNewProject.isLoading ||
    (user && !resumeOrPrepareNewProject.data);

  useEffect(() => {
    if (user) {
      resumeOrPrepareNewProject.refetch();
    }
  }, [user]);

  const buttonList = getButtonsList({
    hasActiveLifeInsurance: resumeOrPrepareNewProject.data?.hasActiveLifeInsurance,
    businessReferral,
  });

  const buttons = wantsTransfer
    ? buttonList.TRANSFER
    : buttonList[searchParams.get(QUERY_PARAMS.RECIPIENT.key) as string] ??
      buttonList[QUERY_PARAMS.RECIPIENT.values.PERSONAL];

  return { buttons, isLoading };
}
