import parsePhoneNumber from 'libphonenumber-js';
import { getCountries } from 'libphonenumber-js/mobile';
import { CountryData } from 'react-phone-input-2';

export const isCountryData = (x: any): x is CountryData => true;

export const countries = getCountries();
export const fromInternationalPhoneNumber = (internationalPhoneNumber?: string | null) => {
  if (!internationalPhoneNumber) return undefined;

  const phoneNumberInstance = parsePhoneNumber(internationalPhoneNumber);

  if (phoneNumberInstance) {
    return phoneNumberInstance.formatNational();
  }
  return internationalPhoneNumber;
};
