import { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Navbar from '@components/Navbar/Navbar';
import LoginOrResumeHeader from '@features/project/components/LoginOrResumeHeader/LoginOrResumeHeader';
import useDimensions from '@hooks/useDimensions';
import Stepper from '@layouts/components/Stepper/Stepper';
import TrackPageView from '@providers/TrackPageView';
import { comeFromProductFunnelState } from '@state';
import { GRAY_LIGHT, METHOD_BOX_GRAY, WHITE } from '@theme';

export default function FunnelLayout({
  backgroundColor,
  withLoginHeader,
  withStepper,
  children,
}: PropsWithChildren<{
  backgroundColor?: typeof GRAY_LIGHT | typeof METHOD_BOX_GRAY | typeof WHITE;
  withLoginHeader?: boolean;
  withStepper?: boolean;
}>) {
  const { isMobile } = useDimensions();
  const comeFromProductFunnel = useRecoilValue(comeFromProductFunnelState);

  return (
    <TrackPageView>
      <div
        style={{
          backgroundColor,
          minHeight: '100vh',
        }}>
        <Navbar />
        {withLoginHeader && !comeFromProductFunnel && <LoginOrResumeHeader />}
        {withStepper && !isMobile && <Stepper />}
        {children || null}
        <Outlet />
      </div>
    </TrackPageView>
  );
}

FunnelLayout.defaultProps = {
  backgroundColor: WHITE,
  withLoginHeader: true,
  withStepper: true,
};
