import { Stack } from '@mui/system';
import { useContext, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import InputText from '@components/InputText/InputText';
import ToggleButton from '@components/ToggleButton/ToggleButton';
import checkRulesQuestionRentMortgage from '@features/project/components/questions/QuestionRentMortgage/QuestionRentMortgage.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import useTryNewWording from '@hooks/useTryNewWording';
import { FunnelAnswersKeys, PrimaryResidence as PrimaryResidenceOwnership } from '@shared/types';
import { questionLayoutTitleState, questionsSelector } from '@state';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

export default function QuestionRentMortgage() {
  const { isMobile } = useDimensions();
  const intl = useIntl();
  const { isValid } = useContext(QuestionContext);
  const setQuestionLayoutTitle = useSetRecoilState(questionLayoutTitleState);
  const questionState = useRecoilValue(questionsSelector);
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE,
    checkRulesQuestionRentMortgage,
  );
  const tryNewWording = useTryNewWording();

  const buttonTitleId = useMemo(
    () =>
      questionState.ownerMainResidence === PrimaryResidenceOwnership.OWNER
        ? 'step1.question.rent-mortgage.button.mortgage'
        : 'step1.question.rent-mortgage.button.rent',
    [questionState.ownerMainResidence],
  );

  useEffect(() => {
    setQuestionLayoutTitle(
      questionState.ownerMainResidence === PrimaryResidenceOwnership.OWNER
        ? 'step1.question.rent-mortgage.title.mortgage'
        : 'step1.question.rent-mortgage.title.rent',
    );
  }, []);

  const onClickButton = () => {
    update(0, { autoValidate: true, debounceError: false });
  };

  const onTextInput = (newRentMortgageValue: number) => {
    update(newRentMortgageValue, { autoValidate: false, debounceError: true });
  };

  return (
    <Stack alignItems="center" gap={3}>
      <InputText
        name="rent-mortgage-amount"
        debounceDuration={DEBOUNCE_DURATION}
        aria-label="input-text-rent-mortgage"
        placeholder={intl.formatMessage({
          id: tryNewWording
            ? 'step1.question.rent-mortgage.input.placeholder.abtest'
            : 'step1.question.rent-mortgage.input.placeholder',
        })}
        suffix={intl.formatMessage({ id: 'step1.question.rent-mortgage.input.suffix' })}
        maskProps={{
          mask: Number,
          scale: 0,
          thousandsSeparator: ' ',
          onAccept: (value) => onTextInput(parseInt(removeWhitespaces(value), 10)),
        }}
        value={String(answer)}
        InputProps={{
          inputProps: {
            inputMode: 'numeric',
            sx: { textAlign: 'right', paddingRight: '0 !important' },
          },
        }}
        sx={{ width: isMobile ? '100%' : '344px' }}
        valid={isValid}
        error={isNumberDefined(answer) && !isValid}
      />
      <ToggleButton titleId={buttonTitleId} onClick={onClickButton} toggled={answer === 0} />
    </Stack>
  );
}
