import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FiscalPackage } from '@shared/types';
import { isNumberDefined } from '@utils/input.utils';

export default function checkRulesQuestionHorizon(ruleParams: QuestionRuleParams): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const { horizon } = ruleParams.questionState;

  if (!isNumberDefined(horizon)) {
    isValid = false;
  }

  if (ruleParams.preferredEnvelope === FiscalPackage.STOCK_SAVINGS_PLAN) {
    if (checkHorizonRangeForPreferredEnvelopePEAMinRange(horizon)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.horizon.rules.preferredPea.minRange',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    } else if (checkHorizonRangeForPreferredEnvelopePEAMaxrange(horizon)) {
      isValid = false;
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.horizon.rules.preferredPea.maxRange',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    }
  } else if (checkHorizonRange(horizon)) {
    isValid = false;
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.horizon.rules.between2And30',
      infoBoxLevel: InfoBoxLevel.ERROR,
    });
  }

  infoBoxMessages.push({
    infoBoxLevel: InfoBoxLevel.INFO,
    infoBoxMessage: `step1.question.horizon.info.message${
      ruleParams?.tryNewWording ? '.abtest' : ''
    }`,
  });

  return {
    isValid,
    infoBoxMessages,
  };
}

/**
 * Q4-RG2
 */
function checkHorizonRange(horizon?: number) {
  return horizon !== undefined && (horizon < 2 || horizon > 30);
}

function checkHorizonRangeForPreferredEnvelopePEAMinRange(horizon?: number) {
  return horizon !== undefined && horizon < 8;
}
function checkHorizonRangeForPreferredEnvelopePEAMaxrange(horizon?: number) {
  return horizon !== undefined && horizon > 30;
}
