import { Stack, Typography, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import CenteredColumnContainer from '@components/CenteredColumnContainer/CenteredColumnContainer';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import ROUTES from '@constants/routes.const';
import useProjectParams from '@features/project/hooks/useProjectParams';
import useQuestionLists from '@features/project/hooks/useQuestionLists';
import { canSubscribeToRealEstate } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import { UserContext } from '@providers/UserProvider';
import { Thematic } from '@shared/types';
import { GRAY_LIGHT_MID, METHOD_BOX_GRAY, PRIMARY_LIGHT, WHITE } from '@theme';

export default function SubscriptionIntro() {
  const intl = useIntl();
  const theme = useTheme();
  const location = useLocation();
  const { isMobile } = useDimensions();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const { thematicParam, isBypassImmoProspect } = useProjectParams();
  const { questions } = useQuestionLists();

  const isRealEstateError = () => {
    return (
      Thematic.REAL_ESTATE === thematicParam &&
      !canSubscribeToRealEstate(!!user, isBypassImmoProspect)
    );
  };

  const onClick = () => {
    navigate(`/${ROUTES.PROJECT}/${questions.toDisplay.at(0)!.path}${location.search}`, {
      replace: true,
    });
  };

  return (
    <CenteredColumnContainer
      px={isMobile ? 0 : undefined}
      py={isMobile ? 0 : 4}
      alignItems="stretch"
      backgroundColor={METHOD_BOX_GRAY}
      maxWidth={isMobile ? '100%' : undefined}
      disableGutters={isMobile}>
      <Stack
        sx={{
          alignItems: 'center',
          gap: theme.spacing(3),
          padding: theme.spacing(4),
          backgroundColor: WHITE,
          border: `1px solid ${GRAY_LIGHT_MID}`,
          borderRadius: theme.spacing(1.5),
        }}>
        <Typography variant="xl" fontWeight="fontWeightBold">
          {intl.formatMessage({ id: 'step1.intro.title' })}
        </Typography>

        {!isRealEstateError() && (
          <Typography variant="lg" color={PRIMARY_LIGHT} align="center">
            {intl.formatMessage({ id: 'step1.intro.info' })}
          </Typography>
        )}

        {isRealEstateError() && (
          <Stack gap={2}>
            <Typography variant="lg" color={PRIMARY_LIGHT} align="center">
              {intl.formatMessage({ id: 'step1.intro.error.real-estate.1' })}
            </Typography>
            <Typography variant="lg" color={PRIMARY_LIGHT} align="center">
              {intl.formatMessage({ id: 'step1.intro.error.real-estate.2' })}
            </Typography>
            <Typography variant="lg" color={PRIMARY_LIGHT} align="center">
              {intl.formatMessage({ id: 'step1.intro.error.real-estate.3' })}
            </Typography>
          </Stack>
        )}

        <PrimaryButton
          titleId="step1.intro.button.next"
          onClick={onClick}
          sx={{ width: theme.spacing(16) }}
        />
      </Stack>
    </CenteredColumnContainer>
  );
}

SubscriptionIntro.defaultProps = {
  thematicFromUrl: undefined,
};
