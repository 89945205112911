import { PRODUCT_FUNNEL_ROUTES, QUERY_PARAMS } from '@constants/routes.const';
import QuestionFiscalPackage from '@features/productFunnel/components/questions/QuestionFiscalPackage/QuestionFiscalPackage';
import { ProductQuestion } from '@features/project/question.utils';
import { CdpEventName } from '@shared/types';

const description: ProductQuestion = {
  path: PRODUCT_FUNNEL_ROUTES.FISCAL_PACKAGE,
  headerTitle: 'productFunnel.question.layout.header.title',
  questionTitle: 'productFunnel.question.fiscalPackage.title',
  cdpEvent: CdpEventName.PRODUCT_FUNNEL_QUESTION_FISCAL_PACKAGE_SELECTION,
  Component: QuestionFiscalPackage,
};

export const PRODUCT_QUESTION_CHOICES = {
  [QUERY_PARAMS.RECIPIENT.values.BUSINESS]: [],
  [QUERY_PARAMS.RECIPIENT.values.PERSONAL]: [],
};

export default description;
