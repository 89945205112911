import { DefaultValue, atom, selector } from 'recoil';

import { FiscalPackage, Thematic } from '@shared/types';

export const riskLevelState = atom({
  key: 'riskProfileState',
  default: 0 as number,
});

export const thematicState = atom<Thematic | undefined>({
  key: 'thematicState',
  default: undefined,
});

export const profileIdState = atom({
  key: 'profileId',
  default: '',
});

export const profileIdSelector = selector({
  key: 'profileIdSelector',
  get: ({ get }) => {
    const profileId: string = get(profileIdState);
    if (profileId === 'P2') {
      return 'P2_2021';
    }
    return profileId;
  },
});

export const fiscalPackageState = atom({
  key: 'packageState',
  default: FiscalPackage.LIFE_INSURANCE as FiscalPackage,
});

export const profileSelector = selector({
  key: 'profileSelector',
  get: ({ get }) => ({
    profileId: get(profileIdSelector),
    riskProfile: get(riskLevelState),
    thematic: get(thematicState),
    fiscalPackage: get(fiscalPackageState),
  }),
  set: ({ reset }, newValue) => {
    if (newValue instanceof DefaultValue) {
      reset(profileIdState);
      reset(riskLevelState);
      reset(thematicState);
      reset(fiscalPackageState);
    }
  },
});
