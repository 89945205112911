import { useRecoilValue } from 'recoil';

import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggle from '@hooks/toggle/useToggle';
import { isLifeInsurancePackage } from '@shared/types';
import { preferredEnvelopeState } from '@state';

export default function useTryNewLifeInsurancePage() {
  const tryNewLifeInsurancePage = useToggle(Toggle.NEW_LIFE_INSURANCE_PAGE);
  const preferredFiscalPackage = useRecoilValue(preferredEnvelopeState);

  return (
    tryNewLifeInsurancePage &&
    preferredFiscalPackage !== undefined &&
    isLifeInsurancePackage(preferredFiscalPackage)
  );
}
