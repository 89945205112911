import { useContext, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {
  QuestionContext,
  QuestionContextValue,
  RulesResult,
} from '@features/project/components/Step1Questions';
import useRulesParameters from '@features/project/hooks/useRulesParameters';
import { QuestionRuleParams } from '@features/project/question.utils';
import { FunnelAnswers } from '@shared/types';
import { questionsSelector } from '@state';

export default function useFunnelAnswer<T extends keyof FunnelAnswers>(
  key: T,
  checkRules: (rule: QuestionRuleParams) => RulesResult,
) {
  const { updateContext } = useContext(QuestionContext);
  const [questions, setQuestions] = useRecoilState(questionsSelector);

  const rulesParameters = useRulesParameters();

  const update = (newAnswer: FunnelAnswers[T], newContext?: Partial<QuestionContextValue>) => {
    setQuestions({ [key]: newAnswer });
    updateContext({
      ...checkRules({
        ...rulesParameters,
        questionState: { ...rulesParameters.questionState, [key]: newAnswer },
      }),
      ...newContext,
    });
  };

  useEffect(() => {
    updateContext(checkRules(rulesParameters));
  }, [checkRules, rulesParameters]);

  return {
    answer: questions[key],
    update,
  };
}
