import {
  Box,
  Container,
  Link,
  LinkProps,
  Step,
  StepIconProps,
  StepLabel,
  Stepper as MuiStepper,
  Typography,
} from '@mui/material';
import { styled, useTheme } from '@mui/system';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import SeparatorIcon from '@assets/breadcrumb-separator.png';
import { ReactComponent as CheckIcon } from '@assets/icons/check.svg';
import Bubble from '@components/Bubble/Bubble';
import ROUTES from '@constants/routes.const';
import useDimensions from '@hooks/useDimensions';
import useTryNewLifeInsurancePage from '@hooks/useTryNewLifeInsurancePage';
import { isCtoPackage, isPeaPackage, isPerPackage, ProjectType } from '@shared/types';
import { fiscalPackageState, preferredEnvelopeState, projectTypeQuestionState } from '@state';
import { GRAY_300, GRAY_400, GRAY_MAIN, WHITE } from '@theme';
import {
  navigateToRecommendation,
  navigateToStep1GivenFiscalPackage,
} from '@utils/navigation.utils';

interface StepperStep {
  label: string;
  routes?: string[];
}

const StyledBreadcrumb = styled(Box)(() => ({
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: GRAY_300,
  background: WHITE,
}));

interface BreadcrumbLinkProps extends LinkProps {
  cursor?: 'default' | 'pointer';
}
const StyledBreadcrumbLink = styled(Link)<BreadcrumbLinkProps>(({ cursor }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: cursor || 'default',
}));

const StyledSeparator = styled(Box)(({ theme }) => ({
  width: '22px',
  height: '48px',
  marginLeft: theme.spacing(2),
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${SeparatorIcon})`,
}));

function StepIcon({ active, completed, classes }: StepIconProps) {
  const { palette } = useTheme();

  const greenColor = palette.success.main;
  const selectedColor = palette.primary.main;

  let backgroundColor = WHITE;
  let borderColor = GRAY_400;

  if (active) {
    borderColor = selectedColor;
    backgroundColor = selectedColor;
  } else if (completed) {
    backgroundColor = greenColor;
    borderColor = greenColor;
  }

  return (
    <Bubble
      sx={{
        borderColor,
        backgroundColor,
      }}>
      {completed && <CheckIcon fill={WHITE} width="12px" height="12px" />}
      {active && (
        <Typography variant="sm" color="white">
          {classes!.text}
        </Typography>
      )}
      {!completed && !active && (
        <Typography variant="sm" sx={{ color: GRAY_MAIN }}>
          {classes!.text}
        </Typography>
      )}
    </Bubble>
  );
}

export default function Stepper() {
  const location = useLocation();
  const navigate = useNavigate();
  const { isMobile } = useDimensions();
  const theme = useTheme();
  const fiscalPackage = useRecoilValue(fiscalPackageState);
  const projectType = useRecoilValue(projectTypeQuestionState);
  const preferredEnvelope = useRecoilValue(preferredEnvelopeState);
  const tryNewLifeInsurancePage = useTryNewLifeInsurancePage();

  let step1TextId = 'breadcrumb.projet';
  let step2TextId = 'breadcrumb.recommandation';

  const buildSteps = () => {
    if (preferredEnvelope && !isPerPackage(preferredEnvelope)) {
      if (projectType === ProjectType.LEGAL_ENTITY && isCtoPackage(preferredEnvelope)) {
        step1TextId += `.${preferredEnvelope}_PRO`;
      } else {
        step1TextId += `.${preferredEnvelope}`;
      }
      if (isPeaPackage(preferredEnvelope) || tryNewLifeInsurancePage) {
        step2TextId = 'breadcrumb.recommandation.adequation';
      }
    }
    return [
      {
        label: step1TextId,
        routes: [ROUTES.PROJECT],
      },
      {
        label: step2TextId,
        routes: [ROUTES.RECOMMANDATION],
      },
      {
        label: 'breadcrumb.souscription',
      },
      {
        label: 'breadcrumb.justificatifs',
      },
      {
        label: 'breadcrumb.signature',
      },
    ];
  };

  const steps: StepperStep[] = useMemo(buildSteps, [preferredEnvelope, projectType]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isStepNavigationEnabled = (index: number) => {
    return !location.pathname.includes(ROUTES.PROJECT);
  };

  const handleGoToStepIndex = (index: number) => {
    if (!isStepNavigationEnabled(index)) return;

    if (index === 0) {
      navigateToStep1GivenFiscalPackage(navigate, fiscalPackage);
    } else if (index === 1) {
      navigateToRecommendation(navigate);
    }
  };

  const activeStep = steps.findIndex((step) =>
    step.routes?.find((route) => location.pathname.includes(route)),
  );

  return (
    <StyledBreadcrumb role="region" aria-label="stepper" height="48px">
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: isMobile ? theme.spacing(1.5) : 0,
        }}>
        <MuiStepper activeStep={activeStep} connector={isMobile ? null : <StyledSeparator />}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StyledBreadcrumbLink
                onClick={() => handleGoToStepIndex(index)}
                underline="none"
                cursor={!isStepNavigationEnabled(index) ? 'default' : 'pointer'}>
                <StepLabel
                  StepIconComponent={StepIcon}
                  StepIconProps={{ classes: { text: `${index + 1}` } }}>
                  <Typography variant="md">
                    {isMobile && index !== activeStep ? '' : <FormattedMessage id={step.label} />}
                  </Typography>
                </StepLabel>
              </StyledBreadcrumbLink>
            </Step>
          ))}
        </MuiStepper>
      </Container>
    </StyledBreadcrumb>
  );
}
