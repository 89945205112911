import { Stack } from '@mui/material';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import InputText from '@components/InputText/InputText';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesRealEstatePatrimony from '@features/project/components/questions/QuestionRealEstatePatrimony/QuestionRealEstatePatrimony.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import { DEBOUNCE_DURATION } from '@features/project/question.utils';
import useDimensions from '@hooks/useDimensions';
import useTryNewWording from '@hooks/useTryNewWording';
import { FunnelAnswersKeys } from '@shared/types';
import { isNumberDefined, removeWhitespaces } from '@utils/input.utils';

const buttons = [
  {
    value: '0',
    titleId: 'step1.question.realEstatePatrimony.button.none',
  },
  {
    value: '25000',
    titleId: 'step1.question.realEstatePatrimony.button.lessThan50k',
  },
  {
    value: '50000',
    titleId: 'step1.question.realEstatePatrimony.button.between50kAnd100k',
  },
  {
    value: '100000',
    titleId: 'step1.question.realEstatePatrimony.button.between100kAnd250k',
  },
  {
    value: '250000',
    titleId: 'step1.question.realEstatePatrimony.button.between250kAnd500k',
  },
  {
    value: '500000',
    titleId: 'step1.question.realEstatePatrimony.button.moreThan500k',
  },
];

export default function QuestionRealEstatePatrimony() {
  const { isMobile } = useDimensions();
  const intl = useIntl();
  const { isValid } = useContext(QuestionContext);
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.NET_PROPERTY_PATRIMONY,
    checkRulesRealEstatePatrimony,
  );
  const tryNewWording = useTryNewWording();

  const handleButtonChange = (value: string) => {
    const userInput = parseInt(value, 10);
    update(userInput, { autoValidate: true, debounceError: false });
  };

  const handleInputChange = (value: string) => {
    const userInput = parseInt(removeWhitespaces(value), 10);
    update(userInput, { autoValidate: false, debounceError: true });
  };

  return (
    <Stack direction="column" alignItems="center">
      {tryNewWording || isMobile ? (
        <ToggleButtonGroup
          buttons={buttons}
          selectedValue={`${answer}`}
          onChange={handleButtonChange}
          itemProps={{ xs: 12, sm: 6 }}
        />
      ) : (
        <InputText
          name="real-estate-patrimony"
          debounceDuration={DEBOUNCE_DURATION}
          placeholder={intl.formatMessage({ id: 'placeholder.typeSomething' })}
          valid={isValid}
          error={isNumberDefined(answer) && !isValid}
          suffix={intl.formatMessage({ id: 'step1.question.realEstatePatrimony.input.suffix' })}
          maskProps={{
            mask: Number,
            scale: 0,
            thousandsSeparator: ' ',
            onAccept: handleInputChange,
          }}
          value={`${answer}`}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              sx: { textAlign: 'right', paddingRight: '0 !important' },
            },
          }}
          sx={{ width: isMobile ? '100%' : '344px' }}
        />
      )}
    </Stack>
  );
}
