import { ReactComponent as DoubleChevronRight } from '@assets/icons/double-chevron-right.svg';
import TextButton from '@components/TextButton/TextButton';
import useTracking from '@hooks/tracking/useTracking';
import { CdpEventName } from '@shared/types';

function SkipStep1Button({ onClick, disabled }: { onClick: () => void; disabled: boolean }) {
  const { sendEvent } = useTracking();
  return (
    <TextButton
      onClick={() => {
        sendEvent({
          event: CdpEventName.CLICK_BUTTON_SKIP_STEP1,
        });
        onClick();
      }}
      titleId="step1.question.button-skipStep1"
      disabled={disabled}
      underline={false}
      icon={<DoubleChevronRight />}
    />
  );
}

export default SkipStep1Button;
