/* eslint-disable react/destructuring-assignment */
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionTaxResidency from '@features/project/components/questions/QuestionTaxResidency/QuestionTaxResidency.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import useDimensions from '@hooks/useDimensions';
import { FunnelAnswersKeys, TaxResidency } from '@shared/types';

export default function QuestionTaxResidency() {
  const { isMobile } = useDimensions();
  const resume = useResumeFunnelAnswers();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.TAX_RESIDENCE,
    checkRulesQuestionTaxResidency,
  );

  const isAlreadyClient = resume.data?.secondaryProject;

  const buttons = [
    {
      value: TaxResidency.FRANCE,
      titleId: 'step1.question.tax-residency.button.yes',
    },
    {
      value: TaxResidency.OTHER,
      titleId: 'step1.question.tax-residency.button.no',
    },
  ];

  const onClick = (newTaxResidency: TaxResidency) => {
    update(newTaxResidency, { autoValidate: true });
  };

  return (
    <ToggleButtonGroup
      buttons={buttons}
      selectedValue={answer}
      onChange={onClick}
      disabled={isAlreadyClient}
      itemProps={{ xs: isMobile ? 6 : undefined }}
    />
  );
}
