import { Container } from '@mui/material';
import { CSS } from '@react-spring/web';
import React from 'react';

import { WHITE } from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

interface CenteredColumnContainerProps {
  children: React.ReactNode;
  flexDirection?: CSS.Property.FlexDirection;
  justifyContent?: CSS.Property.JustifyContent;
  alignItems?: CSS.Property.AlignItems;
  backgroundColor?: CSS.Property.BackgroundColor;
  ariaLabel?: string;
  px?: number | {};
  py?: number | {};
  maxWidth?: CSS.Property.MaxWidth;
  disableGutters?: boolean;
}

export default function CenteredColumnContainer({
  children,
  flexDirection,
  justifyContent,
  alignItems,
  backgroundColor,
  ariaLabel,
  px,
  py,
  maxWidth,
  disableGutters,
}: CenteredColumnContainerProps) {
  const isMobile = isMobileDimensions('md');

  return (
    <Container
      maxWidth={false}
      role="region"
      aria-label={ariaLabel}
      disableGutters={disableGutters}
      sx={{
        backgroundColor,
        px,
        py,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Container
        maxWidth={false}
        disableGutters={disableGutters || isMobile}
        sx={{
          display: 'flex',
          flexDirection,
          justifyContent,
          alignItems,
          maxWidth,
        }}>
        {children}
      </Container>
    </Container>
  );
}

CenteredColumnContainer.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  backgroundColor: WHITE,
  ariaLabel: undefined,
  px: { xs: 3, lg: 9 },
  py: { xs: 6, lg: 9 },
  maxWidth: '1024px',
  disableGutters: false,
};
