import { Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import { navigateToECHomepage } from '@utils/navigation.utils';

export default function ContractAlreadyOpen() {
  const navigate = useNavigate();

  const goToEc = () => {
    navigateToECHomepage(navigate);
  };

  return (
    <Stack gap={3} role="region" aria-label="contract-already-open">
      <Typography variant="lg" fontWeight="fontWeightBold">
        <FormattedMessage id="emailSubmission.contractAlreadyOpenCard.title" />
      </Typography>
      <Typography variant="md" fontWeight="fontWeightLight">
        <FormattedMessage id="emailSubmission.contractAlreadyOpenCard.desc" />
      </Typography>
      <PrimaryButton
        type="button"
        titleId="emailSubmission.contractAlreadyOpenCard.goToEc"
        onClick={goToEc}
      />
    </Stack>
  );
}
