export enum ClasseActif {
  FONDS_EURO_OU_MONETAIRE = "FONDS_EURO_OU_MONETAIRE",
  OBLIGATIONS = "OBLIGATIONS",
  ACTIONS = "ACTIONS",
  IMMOBILIER = "IMMOBILIER",
  ACTIFS_NON_COTES = "ACTIFS_NON_COTES",
  AUTRES = "AUTRES"
}

export interface RepartitionClasseActif {
  classeActif: ClasseActif;
  poids: number;
}

export interface RepartitionClassification {
  classeActif: string;
  classification: string;
  poids: number;
}

interface RepartitionGeographique {
  region: string;
  poids: number;
}

export interface RepartitionDesensibilisation {
  anneeMaximumAvantHorizon: number;
  anneeMinimumAvantHorizon: number;
  repartitionClasseActif?: RepartitionClasseActif[];
  repartitionClassification: RepartitionClassification[];
}

export interface Repartition {
  profil: string;
  repartitionClasseActif: RepartitionClasseActif[];
  repartitionClassification: RepartitionClassification[];
  repartitionGeographique: RepartitionGeographique[];
  repartitionDesensibilisation?: RepartitionDesensibilisation[];
}

export interface RepartitionResponse {
  repartitions: Repartition[];
}
