import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRealEstateValueLoss from '@features/project/components/questions/QuestionRealEstateValueLoss/QuestionRealEstateValueLoss';
import checkRulesQuestionRealEstateValueLoss from '@features/project/components/questions/QuestionRealEstateValueLoss/QuestionRealEstateValueLoss.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.REAL_ESTATE_INVESTMENT_VALUE,
  headerTitle: 'step1.question.layout.header.knowledge.title',
  questionTitle: 'step1.question.realEstateValueLoss.title',
  questionSubtitle: 'step1.question.realEstateValueLoss.subtitle',
  cdpEvent: CdpEventName.QUESTION_LOSS_OF_REAL_ESTATE_VALUE_QUIZ,
  Component: QuestionRealEstateValueLoss,
  payloadKeys: [FunnelAnswersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ],
  rulesFn: checkRulesQuestionRealEstateValueLoss,
};

export default {
  realEstate: baseDescription,
};
