import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionRealEstateInvestment from '@features/project/components/questions/QuestionRealEstateInvestment/QuestionRealEstateInvestment';
import checkRulesQuestionRealEstateInvestment from '@features/project/components/questions/QuestionRealEstateInvestment/QuestionRealEstateInvestment.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.REAL_ESTATE_INVESTMENT,
  headerTitle: 'step1.question.layout.header.knowledge.title',
  questionTitle: 'step1.question.real-estate-investment.title',
  questionSubtitle: 'step1.question.real-estate-investment.subtitle',
  cdpEvent: CdpEventName.QUESTION_REAL_ESTATE_INVESTMENT_QUIZ,
  Component: QuestionRealEstateInvestment,
  payloadKeys: [FunnelAnswersKeys.REAL_ESTATE_INVESTMENT_QUIZ],
  rulesFn: checkRulesQuestionRealEstateInvestment,
};

export default { realEstate: baseDescription };
