import { useSetRecoilState } from 'recoil';

import useResume from '@hooks/useResume';
import { fiscalPackageState, profileIdState, riskLevelState, thematicState } from '@state';

export default function useResetSimulation() {
  const { resume } = useResume();

  const setPackageStateValue = useSetRecoilState(fiscalPackageState);
  const setThematicStateValue = useSetRecoilState(thematicState);
  const setRiskProfileStateValue = useSetRecoilState(riskLevelState);
  const setProfileIdStateValue = useSetRecoilState(profileIdState);

  // Allows user choice to be reset to the default values
  // See https://smartdays.atlassian.net/browse/PDT-7379
  function reset() {
    setPackageStateValue(resume!.step1Response.package);
    setThematicStateValue(resume!.step1Response.thematic);
    setRiskProfileStateValue(resume!.step1Response.riskLevel);
    setProfileIdStateValue(resume!.step1Response.profileId);
  }

  return { reset };
}
