import { Collapse, IconButton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import CenteredColumnContainer from '@components/CenteredColumnContainer/CenteredColumnContainer';
import { StyledCloseIcon } from '@components/YomoniBigSnack/YomoniBigSnack';
import { EXTERNAL_ROUTES } from '@constants/routes.const';
import useQuestionLists from '@features/project/hooks/useQuestionLists';
import useDimensions from '@hooks/useDimensions';
import useQuestion from '@hooks/useQuestion';
import { UserContext } from '@providers/UserProvider';
import { WARNING_DARK, WARNING_LIGHT } from '@theme';
import { boldText, externalLinkInSameTab } from '@utils/formatted-message.utils';

export default function LoginOrResumeHeader() {
  const { isMobile } = useDimensions();
  const { user } = useContext(UserContext);
  const params = useParams<{ questionPath: string }>();
  const { questions } = useQuestionLists();
  const { questionNumber } = useQuestion(questions.toDisplay, params.questionPath!);

  const [show, setShow] = useState(questionNumber < 4 && !isMobile && !user);

  useEffect(() => {
    if (questionNumber >= 4 || isMobile || user) {
      setShow(false);
    }
  }, [questionNumber, isMobile, user]);

  return (
    <Collapse in={show}>
      <CenteredColumnContainer
        flexDirection="row"
        justifyContent="space-between"
        px={3}
        py={3}
        backgroundColor={WARNING_LIGHT}>
        <Box
          display="flex"
          flexDirection="column"
          gap={0.5}
          role="region"
          aria-label="loginOrResumeHeader">
          <Typography variant="sm" color={WARNING_DARK}>
            <FormattedMessage
              id="notification.resumePreviousProject"
              values={{
                link: (chunk: string) => externalLinkInSameTab(chunk, EXTERNAL_ROUTES.CORE.RESUME),
                b: (chunk: string) => boldText(chunk),
              }}
            />
          </Typography>
          <Typography variant="sm" color={WARNING_DARK}>
            <FormattedMessage
              id="notification.login"
              values={{
                link: (chunk: string) => externalLinkInSameTab(chunk, EXTERNAL_ROUTES.CORE.LOGIN),
                b: (chunk: string) => boldText(chunk),
              }}
            />
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => setShow(false)}>
            <StyledCloseIcon />
          </IconButton>
        </Box>
      </CenteredColumnContainer>
    </Collapse>
  );
}
