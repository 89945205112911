import { Box } from '@mui/material';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionRiskReward from '@features/project/components/questions/QuestionRiskReward/QuestionRiskReward.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { FunnelAnswersKeys, RiskReward } from '@shared/types';

const buttons = [
  {
    value: RiskReward.GAIN_5000_PERTE_2000,
    titleId: 'step1.question.risk-reward.button.5000-gain-2000-loss',
  },
  {
    value: RiskReward.GAIN_2000_PERTE_1000,
    titleId: 'step1.question.risk-reward.button.2000-gain-1000-loss',
  },
  {
    value: RiskReward.GAIN_1000_PERTE_400,
    titleId: 'step1.question.risk-reward.button.1000-gain-400-loss',
  },
  {
    value: RiskReward.GAIN_500_PERTE_0,
    titleId: 'step1.question.risk-reward.button.500-gain-0-loss',
  },
];

export default function QuestionRiskReward() {
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ,
    checkRulesQuestionRiskReward,
  );
  usePreferredChoice({ translationQuestionPrefix: 'step1.question.risk-reward' });

  const onChange = (newRiskReward: RiskReward) => update(newRiskReward, { autoValidate: true });

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={answer}
        onChange={onChange}
        containerProps={{ sx: { maxWidth: '430px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
