import { Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionFinancialLosses from '@features/project/components/questions/QuestionFinancialLosses/QuestionFinancialLosses.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import useTryNewWording from '@hooks/useTryNewWording';
import { FinancialLosses } from '@shared/types';
import { lossOnFinancialInvestmentsQuizQuestionState, questionsSelector } from '@state';

const buttons = [
  {
    value: FinancialLosses.NON,
    titleId: 'step1.question.financial-losses.button.no',
  },
  {
    value: FinancialLosses.OUI_10_MAX,
    titleId: 'step1.question.financial-losses.button.10-percent-max',
  },
  {
    value: FinancialLosses.OUI_20_MAX,
    titleId: 'step1.question.financial-losses.button.20-percent-max',
  },
  {
    value: FinancialLosses.OUI_PLUS_DE_20,
    titleId: 'step1.question.financial-losses.button.more-than-20-percent',
  },
];

const buttonsNewWording = [
  {
    value: FinancialLosses.NON,
    titleId: 'step1.question.financial-losses.button.no.abtest',
  },
  {
    value: FinancialLosses.OUI_10_MAX,
    titleId: 'step1.question.financial-losses.button.10-percent-max.abtest',
  },
  {
    value: FinancialLosses.OUI_20_MAX,
    titleId: 'step1.question.financial-losses.button.20-percent-max.abtest',
  },
  {
    value: FinancialLosses.OUI_PLUS_DE_20,
    titleId: 'step1.question.financial-losses.button.more-than-20-percent.abtest',
  },
];

export default function QuestionFinancialLosses() {
  const { updateContext } = useContext(QuestionContext);
  const tryNewWording = useTryNewWording();

  const questionState = useRecoilValue(questionsSelector);
  const setFinancialLossesStateValue = useSetRecoilState(
    lossOnFinancialInvestmentsQuizQuestionState,
  );

  const onClick = (newFinancialLosses: FinancialLosses) => {
    setFinancialLossesStateValue(newFinancialLosses);
    updateContext({
      ...checkRulesQuestionFinancialLosses({
        questionState: {
          ...questionState,
          lossOnFinancialInvestmentsQuiz: newFinancialLosses,
        },
      }),
      autoValidate: true,
    });
  };

  useEffect(() => {
    if (questionState.lossOnFinancialInvestmentsQuiz) {
      updateContext(checkRulesQuestionFinancialLosses({ questionState }));
    }
  }, []);

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={tryNewWording ? buttonsNewWording : buttons}
        selectedValue={questionState.lossOnFinancialInvestmentsQuiz}
        onChange={onClick}
        containerProps={{ sx: { maxWidth: '540px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
