import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionEsgPreference from '@features/project/components/questions/QuestionEsgPreference/QuestionEsgPreference.rules';
import { InfoBoxContextValue, QuestionContext } from '@features/project/components/Step1Questions';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import useDimensions from '@hooks/useDimensions';
import { EsgPreference, Thematic } from '@shared/types';
import {
  questionsSelector,
  responsibleInvestmentQuestionState,
  thematicQuestionState,
} from '@state';

export default function QuestionEsgPreference() {
  const { isMobile } = useDimensions();
  const { updateContext } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const setResponsibleInvestmentStateValue = useSetRecoilState(responsibleInvestmentQuestionState);
  const setThematicQuestionState = useSetRecoilState(thematicQuestionState);
  usePreferredChoice({ translationQuestionPrefix: 'step1.question.7' });

  const buttons = [
    {
      value: EsgPreference.PREFER_ESG,
      titleId: 'step1.question.thematic.button.ESG',
    },
    {
      value: EsgPreference.NO_PREFERENCE,
      titleId: 'step1.question.thematic.button.CLASSIC',
    },
  ];

  const infoBoxMessages = [
    { infoBoxLevel: InfoBoxLevel.INFO, infoBoxMessage: 'step1.question.thematic.info.message' },
  ] as InfoBoxContextValue[];

  useEffect(() => {
    const newContext = checkRulesQuestionEsgPreference({ questionState });
    newContext.infoBoxMessages.push(...infoBoxMessages);
    updateContext(newContext);
  }, []);

  const onClick = (newEsgPreference: EsgPreference) => {
    setResponsibleInvestmentStateValue(newEsgPreference);
    setThematicQuestionState(
      newEsgPreference === EsgPreference.PREFER_ESG ? Thematic.ESG : Thematic.CLASSIC,
    );
    const newContext = checkRulesQuestionEsgPreference({
      questionState: { ...questionState, responsibleInvestment: newEsgPreference },
    });
    newContext.infoBoxMessages.push(...infoBoxMessages);
    updateContext({ ...newContext, autoValidate: true });
  };

  return (
    <ToggleButtonGroup
      buttons={buttons}
      selectedValue={questionState.responsibleInvestment}
      onChange={onClick}
      itemProps={{ xs: isMobile ? 12 : undefined }}
    />
  );
}
