export enum AnswerInvestment {
  CAUTIOUS = 'PRUDENT',
  BALANCED = 'EQUILIBREE',
  DYNAMIC = 'DYNAMIQUE',
  BOLD = 'AUDACIEUX',
}

export enum WithdrawBefore2Years {
  PROBABLY = 'PROBABLEMENT',
  PROBABLY_NOT = 'PROBABLEMENT_PAS',
  CERTAINLY = 'CERTAINEMENT',
  CERTAINLY_NOT = 'CERTAINEMENT_PAS',
}
