import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';

export default function checkRulesQuestionFinancialLosses(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  if (!ruleParams.questionState.lossOnFinancialInvestmentsQuiz) {
    isValid = false;
  }

  return {
    isValid,
    infoBoxMessages,
  };
}
