import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionInitialAmount from '@features/project/components/questions/QuestionInitialAmount/QuestionInitialAmount';
import checkRulesQuestionInitialAmount from '@features/project/components/questions/QuestionInitialAmount/QuestionInitialAmount.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.INITIAL_AMOUNT,
  headerTitle: 'step1.question.layout.header.title.yourProject',
  questionTitle: 'step1.question.initial-amount.title',
  questionSubtitle: 'step1.question.initial-amount.subtitle',
  cdpEvent: CdpEventName.QUESTION_INITIAL_PAYMENT,
  Component: QuestionInitialAmount,
  payloadKeys: [FunnelAnswersKeys.INITIAL_PAYMENT],
  rulesFn: checkRulesQuestionInitialAmount,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: baseDescription,
  legalEntity: baseDescription,
};
