import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { PropsWithChildren, Suspense } from 'react';
import { CookiesProvider } from 'react-cookie';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import frMessages from '@locales/fr.json';
import { UserProvider } from '@providers/UserProvider';
import yomoniTheme from '@theme';
import { isMobileDimensions } from '@utils/global.utils';

const locale = 'fr';
const STALE_TIME = 10_000;
const NUMBER_OF_RETRIES = 6;
const MAX_RETRY_WAITING_TIME = 30_000;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      retry: NUMBER_OF_RETRIES,
      retryDelay: (attempt: number) =>
        Math.min(attempt > 1 ? 2 ** attempt * 1_000 : 1_000, MAX_RETRY_WAITING_TIME),
    },
  },
});

function Providers({ children }: PropsWithChildren<{}>) {
  const isMobile = isMobileDimensions('md');

  return (
    <BrowserRouter>
      <RecoilRoot>
        <IntlProvider locale={locale} messages={frMessages} textComponent="span">
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={yomoniTheme}>
              <SnackbarProvider
                dense
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: isMobile ? 'center' : 'right',
                }}
                autoHideDuration={null}>
                <UserProvider>
                  <CookiesProvider defaultSetOptions={{ path: '/' }}>
                    <Suspense fallback={null}>{children}</Suspense>
                  </CookiesProvider>
                </UserProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </IntlProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default Providers;
