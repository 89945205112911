/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionSavingsCapacity from '@features/project/components/questions/QuestionSavingsCapacity/QuestionSavingsCapacity.rules';
import { QuestionContext } from '@features/project/components/Step1Questions';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import { SavingsCapacity } from '@shared/types';
import { questionsSelector, savingsCapacityQuestionState } from '@state';

export default function QuestionSavingsCapacity() {
  const { updateContext } = useContext(QuestionContext);
  const questionState = useRecoilValue(questionsSelector);
  const setSavingsCapacityStateValue = useSetRecoilState(savingsCapacityQuestionState);
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.savings-capacity',
  });

  const buttons = [
    {
      value: SavingsCapacity.LESS_THAN_250,
      titleId: 'step1.question.savings-capacity.button.LESS_THAN_250',
    },
    {
      value: SavingsCapacity.BETWEEN_250_AND_500,
      titleId: 'step1.question.savings-capacity.button.BETWEEN_250_AND_500',
    },
    {
      value: SavingsCapacity.BETWEEN_500_AND_1000,
      titleId: 'step1.question.savings-capacity.button.BETWEEN_500_AND_1000',
    },
    {
      value: SavingsCapacity.BETWEEN_1000_AND_2500,
      titleId: 'step1.question.savings-capacity.button.BETWEEN_1000_AND_2500',
    },
    {
      value: SavingsCapacity.MORE_THAN_2500,
      titleId: 'step1.question.savings-capacity.button.MORE_THAN_2500',
    },
  ];

  useEffect(() => {
    updateContext(checkRulesQuestionSavingsCapacity({ questionState }));
  }, []);

  const onClickButton = (newSavingsCapacity: SavingsCapacity) => {
    setSavingsCapacityStateValue(newSavingsCapacity);
    updateContext({
      ...checkRulesQuestionSavingsCapacity({
        questionState: {
          ...questionState,
          savingsCapacity: newSavingsCapacity,
        },
      }),
      autoValidate: true,
    });
  };

  return (
    <ToggleButtonGroup
      buttons={buttons}
      onChange={onClickButton}
      selectedValue={questionState.savingsCapacity}
      itemProps={{ xs: 12, sm: 4 }}
    />
  );
}
