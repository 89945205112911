import { useContext } from 'react';
import { useQuery, UseQueryResult } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { UserContext } from '@providers/UserProvider';
import ResumeClient from '@services/clients/resume';
import { Action, FunnelAnswers, ProjectParameters } from '@shared/types';
import {
  businessReferralState,
  preferredEnvelopeState,
  preferredTransferState,
  questionsSelector,
} from '@state';

export type FunnelAnswersObject = {
  funnelAnswers?: Partial<FunnelAnswers>;
  secondaryProject: boolean;
  projectParameters?: ProjectParameters;
  hasActiveLifeInsurance: boolean;
};

const useResumeFunnelAnswers = (): UseQueryResult<FunnelAnswersObject, Error> => {
  const { user } = useContext(UserContext);
  const setQuestionSelector = useSetRecoilState(questionsSelector);
  const setBusinessReferralState = useSetRecoilState(businessReferralState);
  const [preferredEnvelopeStateValue, setPreferredEnvelopeState] =
    useRecoilState(preferredEnvelopeState);
  const setPreferredTransferState = useSetRecoilState(preferredTransferState);

  let queryParams: [string, () => Promise<FunnelAnswersObject>] = [
    'resume',
    ResumeClient.getResume,
  ];
  if (user?.actions.includes(Action.CREATE_PROJECT)) {
    queryParams = ['prepare', ResumeClient.prepareNewProject];
  }

  const query = useQuery<FunnelAnswersObject, Error>(queryParams[0], queryParams[1], {
    enabled: false,
    onSuccess(data) {
      if (data?.funnelAnswers) {
        setQuestionSelector(data.funnelAnswers);
      }
      setBusinessReferralState(data?.projectParameters?.businessReferral);
      if (preferredEnvelopeStateValue === undefined) {
        setPreferredEnvelopeState(data?.projectParameters?.preferredEnvelope);
        setPreferredTransferState(data?.projectParameters?.preferredTransfer);
      }
    },
  });

  return query;
};

export default useResumeFunnelAnswers;
