import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionHorizon from '@features/project/components/questions/QuestionHorizon/QuestionHorizon';
import checkRulesQuestionHorizon from '@features/project/components/questions/QuestionHorizon/QuestionHorizon.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.HORIZON,
  headerTitle: 'step1.question.layout.header.title.yourProject',
  questionTitle: 'step1.question.horizon.title',
  cdpEvent: CdpEventName.QUESTION_HORIZON,
  Component: QuestionHorizon,
  payloadKeys: [FunnelAnswersKeys.HORIZON],
  rulesFn: checkRulesQuestionHorizon,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: baseDescription,
  legalEntity: baseDescription,
};
