/* eslint-disable react/destructuring-assignment */
import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import {
  checkRulesQuestionProject,
  getButtonsForThematic as getButtons,
} from '@features/project/components/questions/QuestionProject/QuestionProject.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import useProjectParams from '@features/project/hooks/useProjectParams';
import { FunnelAnswersKeys, ProjectType } from '@shared/types';
import { preferredEnvelopeState, thematicQuestionState } from '@state';

export default function QuestionProject() {
  const { preSelectedProjectTypeParam } = useProjectParams();
  const thematic = useRecoilValue(thematicQuestionState);
  const preferredEnvelopeParam = useRecoilValue(preferredEnvelopeState);
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.PROJECT_TYPE,
    checkRulesQuestionProject,
  );

  useEffect(() => {
    if (preSelectedProjectTypeParam && answer === undefined) {
      update(preSelectedProjectTypeParam);
    }
  }, []);

  const onClick = (newProjectType: ProjectType) => {
    if (newProjectType !== answer) {
      update(newProjectType, { autoValidate: true });
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={getButtons(thematic, preferredEnvelopeParam)}
        selectedValue={answer}
        onChange={onClick}
        itemProps={{ xs: 6, sm: 6, display: 'flex' }}
        iconAboveText
        addHiddenItem
      />
    </Box>
  );
}
