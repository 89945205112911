import { Box } from '@mui/material';

import ToggleButtonGroup from '@components/ToggleButtonGroup/ToggleButtonGroup';
import checkRulesQuestionWithdrawalTwoYears from '@features/project/components/questions/QuestionWithdrawalTwoYears/QuestionWithdrawalTwoYears.rules';
import useFunnelAnswer from '@features/project/hooks/useFunnelAnswer';
import usePreferredChoice from '@features/project/hooks/usePreferredChoice/usePreferredChoice';
import useDimensions from '@hooks/useDimensions';
import { FunnelAnswersKeys, WithdrawBefore2Years } from '@shared/types';

export default function QuestionWithdrawalTwoYears() {
  const { isMobile } = useDimensions();
  const { answer, update } = useFunnelAnswer(
    FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS,
    checkRulesQuestionWithdrawalTwoYears,
  );
  usePreferredChoice({
    translationQuestionPrefix: 'step1.question.withdrawal-2-years',
  });

  const buttons = [
    {
      value: WithdrawBefore2Years.CERTAINLY_NOT,
      titleId: 'step1.question.withdrawal-2-years.CERTAINLY_NOT',
    },
    {
      value: WithdrawBefore2Years.PROBABLY_NOT,
      titleId: 'step1.question.withdrawal-2-years.PROBABLY_NOT',
    },
    {
      value: WithdrawBefore2Years.PROBABLY,
      titleId: 'step1.question.withdrawal-2-years.PROBABLY',
    },
    {
      value: WithdrawBefore2Years.CERTAINLY,
      titleId: 'step1.question.withdrawal-2-years.CERTAINLY',
    },
  ];

  const onClick = (newWithdrawalBefore2Years: WithdrawBefore2Years) => {
    update(newWithdrawalBefore2Years, {
      autoValidate: true,
    });
  };

  return (
    <Box display="flex" justifyContent="center">
      <ToggleButtonGroup
        buttons={buttons}
        selectedValue={answer}
        onChange={onClick}
        containerProps={{ sx: { maxWidth: isMobile ? '100%' : '360px' } }}
        itemProps={{ xs: 12 }}
      />
    </Box>
  );
}
