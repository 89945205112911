import { InfoBoxLevel } from '@components/InfoBox/InfoBox';
import { InfoBoxContextValue, RulesResult } from '@features/project/components/Step1Questions';
import { QuestionRuleParams } from '@features/project/question.utils';
import { AnnualIncome, ProjectType } from '@shared/types';
import { isNumberDefined } from '@utils/input.utils';

export default function checkRulesQuestionAnnualIncome(
  ruleParams: QuestionRuleParams,
): RulesResult {
  let isValid = true;
  const infoBoxMessages: InfoBoxContextValue[] = [];

  const annualIncome = ruleParams.questionState.grossAnnualIncome;
  const annualIncomeRange = ruleParams.questionState.grossAnnualIncomeRange;

  if (!annualIncomeRange) {
    isValid = false;
  }

  if (shouldSpecifyExactIncomeForLowerIncome(ruleParams)) {
    isValid = false;
    if (isNumberDefined(annualIncome)) {
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.annual-income.rules.error-range',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    }
  }

  if (shouldSpecifyExactIncomeForUpperIncome(ruleParams)) {
    isValid = false;
    if (isNumberDefined(annualIncome)) {
      infoBoxMessages.push({
        infoBoxMessage: 'step1.question.annual-income.rules.error-range',
        infoBoxLevel: InfoBoxLevel.ERROR,
      });
    }
  }

  if (shouldDisplayInfoMessageForChildrenProject(ruleParams)) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.annual-income.info',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  if (ruleParams.tryNewWording) {
    infoBoxMessages.push({
      infoBoxMessage: 'step1.question.annual-income.info.abtest',
      infoBoxLevel: InfoBoxLevel.INFO,
    });
  }

  return {
    isValid,
    infoBoxMessages,
  };
}
/**
 * Q9-RG1
 */
export function shouldDisplayExactInput(grossAnnualIncomeRange?: AnnualIncome) {
  return (
    grossAnnualIncomeRange &&
    (grossAnnualIncomeRange === AnnualIncome.MORE_THAN_150_000 ||
      grossAnnualIncomeRange === AnnualIncome.LESS_THAN_30_000)
  );
}

/**
 * Q9-RG2
 */
function shouldSpecifyExactIncomeForLowerIncome(ruleParams: QuestionRuleParams) {
  const { grossAnnualIncomeRange, grossAnnualIncome } = ruleParams.questionState;

  return (
    grossAnnualIncomeRange === AnnualIncome.LESS_THAN_30_000 &&
    (!isNumberDefined(grossAnnualIncome) || grossAnnualIncome! < 0 || grossAnnualIncome! >= 30000)
  );
}

/**
 * Q9-RG3
 */
function shouldSpecifyExactIncomeForUpperIncome(ruleParams: QuestionRuleParams) {
  const { grossAnnualIncomeRange, grossAnnualIncome } = ruleParams.questionState;

  return (
    grossAnnualIncomeRange === AnnualIncome.MORE_THAN_150_000 &&
    (!grossAnnualIncome || grossAnnualIncome <= 150000)
  );
}

/**
 * Q9-RG4
 */
function shouldDisplayInfoMessageForChildrenProject(ruleParams: QuestionRuleParams) {
  const { projectType } = ruleParams.questionState;

  return projectType === ProjectType.CHILDREN;
}
