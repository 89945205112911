import { BusinessReferral } from '@shared/types/business-referral.enum';
import { WithdrawBefore2Years } from '@shared/types/profile-form.const';
import { ProjectType } from '@shared/types/project-type.enum';
import {
  AnnualIncome,
  ChildrenNumber,
  EsgPreference,
  FinancialLosses,
  LossReaction,
  PrimaryResidence,
  RiskReward,
  RiskRewardPercentage,
  SavingsCapacity,
  SavingsProducts,
  TernaryAnswer,
  WithdrawHalfProbability,
} from '@shared/types/step1.enum';
import { TaxResidency } from '@shared/types/tax-residency.enum';
import { Thematic } from '@shared/types/thematic.enum';
import { PreferredFiscalPackage } from './preferred-fiscal-package.type';

export enum FunnelAnswersKeys {
  PROJECT_TYPE = 'projectType',
  INITIAL_PAYMENT = 'initialPayment',
  RECURRING_PAYMENT = 'recurringPayment',
  HORIZON = 'horizon',
  BIRTHDATE = 'birthdate',
  BIRTHDATE_KID = 'birthdateKid',
  TAX_RESIDENCE = 'taxResidence',
  RESPONSIBLE_INVESTMENT = 'responsibleInvestment',
  NB_CHILDREN = 'nbChildren',
  GROSS_ANNUAL_INCOME = 'grossAnnualIncome',
  GROSS_ANNUAL_INCOME_RANGE = 'grossAnnualIncomeRange',
  OWNER_MAIN_RESIDENCE = 'ownerMainResidence',
  CREDIT_OR_RENT_MAIN_RESIDENCE = 'creditOrRentMainResidence',
  NET_PROPERTY_PATRIMONY = 'netPropertyPatrimony',
  FINANCIAL_PATRIMONY = 'financialPatrimony',
  SAVINGS_CAPACITY = 'savingsCapacity',
  PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS = 'probabilityOfWithdrawalWithinTwoYears',
  PROBABILITY_OF_WITHDRAWAL_HALF = 'probabilityOfWithdrawalHalf',
  SAVINGS_PRODUCTS = 'savingsProducts',
  EARNINGS_POTENTIAL_QUIZ = 'earningsPotentialQuiz',
  ETF_KNOWLEDGE_QUIZ = 'etfKnowledgeQuiz',
  COMPANY_DELEGATION_QUIZ = 'companyDelegationQuiz',
  REAL_ESTATE_INVESTMENT_QUIZ = 'realEstateInvestmentQuiz',
  LOSS_OF_REAL_ESTATE_VALUE_QUIZ = 'lossOfRealEstateValueQuiz',
  LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ = 'lossOnFinancialInvestmentsQuiz',
  AMOUNT_WIN_LOSS_RATIO_QUIZ = 'amountWinLossRatioQuiz',
  PERCENTAGE_WIN_LOSS_RATIO_QUIZ = 'percentageWinLossRatioQuiz',
  TEN_PERCENT_LOSS_QUIZ = 'tenPercentLossQuiz',
  THEMATIC = 'thematic',
}

export interface FunnelAnswers {
  [FunnelAnswersKeys.PROJECT_TYPE]: ProjectType;
  [FunnelAnswersKeys.INITIAL_PAYMENT]: number;
  [FunnelAnswersKeys.RECURRING_PAYMENT]: number;
  [FunnelAnswersKeys.HORIZON]: number;
  [FunnelAnswersKeys.BIRTHDATE]?: string;
  [FunnelAnswersKeys.BIRTHDATE_KID]?: string;
  [FunnelAnswersKeys.TAX_RESIDENCE]: TaxResidency;
  [FunnelAnswersKeys.RESPONSIBLE_INVESTMENT]?: EsgPreference;
  [FunnelAnswersKeys.NB_CHILDREN]?: ChildrenNumber;
  [FunnelAnswersKeys.GROSS_ANNUAL_INCOME]: number;
  [FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE]: AnnualIncome;
  [FunnelAnswersKeys.OWNER_MAIN_RESIDENCE]?: PrimaryResidence;
  [FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE]?: number;
  [FunnelAnswersKeys.NET_PROPERTY_PATRIMONY]: number;
  [FunnelAnswersKeys.FINANCIAL_PATRIMONY]: number;
  [FunnelAnswersKeys.SAVINGS_CAPACITY]?: SavingsCapacity;
  [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS]: WithdrawBefore2Years;
  [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF]: WithdrawHalfProbability;
  [FunnelAnswersKeys.SAVINGS_PRODUCTS]: SavingsProducts;
  [FunnelAnswersKeys.EARNINGS_POTENTIAL_QUIZ]: TernaryAnswer;
  [FunnelAnswersKeys.ETF_KNOWLEDGE_QUIZ]?: TernaryAnswer;
  [FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ]: TernaryAnswer;
  [FunnelAnswersKeys.REAL_ESTATE_INVESTMENT_QUIZ]?: TernaryAnswer;
  [FunnelAnswersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ]?: TernaryAnswer;
  [FunnelAnswersKeys.LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ]: FinancialLosses;
  [FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ]: RiskReward;
  [FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ]: RiskRewardPercentage;
  [FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ]: LossReaction;
  [FunnelAnswersKeys.THEMATIC]: Thematic;
}

interface Utm {
  campaign: string;
  content: string;
  medium: string;
  source: string;
  term: string;
}

export type FrontStep1Payload = FunnelAnswers & {
  preferredEnvelope: PreferredFiscalPackage | undefined;
  preferredTransfer: boolean | undefined;
  businessReferral?: BusinessReferral;
  utm?: Utm;
};

export type FrontRegisterPayload = FrontStep1Payload & {
  email: string;
  sponsorCode?: string;
  externalToken?: string;
};
