import { useEffect, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { getQuestions } from '@features/project/question.utils';
import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggle from '@hooks/toggle/useToggle';
import { FiscalPackage, ProjectType, SavingsProducts, TaxResidency } from '@shared/types';
import {
  preferredEnvelopeState,
  preferredTransferState,
  projectTypeQuestionState,
  recurringPaymentQuestionState,
  savingsProductsQuestionState,
  taxResidenceQuestionState,
  thematicQuestionState,
} from '@state';

export default function useQuestionLists() {
  const thematicQuestionStateValue = useRecoilValue(thematicQuestionState);
  const projectTypeStateValue = useRecoilValue(projectTypeQuestionState);
  const preferredFiscalPackage = useRecoilValue(preferredEnvelopeState);
  const preferredTransfer = useRecoilValue(preferredTransferState);
  const tryQuestionsWithNewOrder = useToggle(Toggle.QUESTIONS_NEW_ORDER);
  const setProjectTypeStateValue = useSetRecoilState(projectTypeQuestionState);
  const setRecurringPaymentQuestionValue = useSetRecoilState(recurringPaymentQuestionState);
  const setTaxResidenceQuestionValue = useSetRecoilState(taxResidenceQuestionState);
  const setSavingsProductsQuestionStateValue = useSetRecoilState(savingsProductsQuestionState);

  const questionListsParams = {
    thematic: thematicQuestionStateValue,
    projectType: projectTypeStateValue,
    preferredEnvelope: preferredFiscalPackage,
    preferredTransfer,
    tryQuestionsWithNewOrder,
  };

  useEffect(() => {
    if (preferredFiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN) {
      setProjectTypeStateValue(ProjectType.SAVINGS);
      setRecurringPaymentQuestionValue(0);
      setTaxResidenceQuestionValue(TaxResidency.FRANCE);
    }
    if (preferredFiscalPackage === FiscalPackage.STOCK_SAVINGS_PLAN && preferredTransfer) {
      setSavingsProductsQuestionStateValue(SavingsProducts.OWNER);
    }
  }, [preferredFiscalPackage, preferredTransfer]);

  const questions = useMemo(
    () => getQuestions(questionListsParams),
    [
      thematicQuestionStateValue,
      projectTypeStateValue,
      tryQuestionsWithNewOrder,
      preferredFiscalPackage,
      preferredTransfer,
    ],
  );
  return {
    questions,
  };
}
