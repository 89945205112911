import { styled } from '@mui/material';
import { createSearchParams } from 'react-router-dom';

const StyledExternalLink = styled('a')`
  color: inherit;
`;

interface To {
  pathname: string;
  searchParameters: { [key: string]: string };
}

interface ExternalLinkProps {
  message: string;
  to: string | To;
  shouldTargetSelf?: boolean;
  sendEvent?: () => void;
}

export default function ExternalLink({
  message,
  to,
  shouldTargetSelf,
  sendEvent,
}: ExternalLinkProps) {
  const getHref = (toParam: ExternalLinkProps['to']) => {
    if (typeof toParam === 'string') {
      return toParam;
    }

    return `${toParam.pathname}?${createSearchParams(toParam.searchParameters).toString()}`;
  };

  return (
    <StyledExternalLink
      href={getHref(to)}
      target={shouldTargetSelf ? '_self' : '_blank'}
      rel="noreferrer"
      onClick={sendEvent}>
      {message}
    </StyledExternalLink>
  );
}

ExternalLink.defaultProps = {
  shouldTargetSelf: false,
  sendEvent: () => {},
};
