import { ImgHTMLAttributes } from 'react';

interface ImageSetProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'srcSet'> {
  src: string;
  src2x?: string;
  alt?: string;
}

export default function Image({ src, src2x, alt, ...rest }: ImageSetProps) {
  const srcSet = src2x ? `${src2x} 2x` : '';
  const imageNameRegex = /([a-zA-Z|_|-]*\.png)/g;
  const overridenAlt = alt || (imageNameRegex.test(src) ? src.match(imageNameRegex)!.pop() : '');

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <img src={src} srcSet={srcSet} alt={overridenAlt} {...rest} />;
}

Image.defaultProps = {
  src2x: undefined,
  alt: undefined,
};
