import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionProject from '@features/project/components/questions/QuestionProject/QuestionProject';
import { checkRulesQuestionProject } from '@features/project/components/questions/QuestionProject/QuestionProject.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.PROJECT_SELECTION,
  headerTitle: 'step1.question.layout.header.title.yourProject',
  questionTitle: 'step1.question.project.title',
  cdpEvent: CdpEventName.QUESTION_PROJECT_TYPE,
  Component: QuestionProject,
  payloadKeys: [FunnelAnswersKeys.PROJECT_TYPE, FunnelAnswersKeys.THEMATIC],
  rulesFn: checkRulesQuestionProject,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: baseDescription,
  legalEntity: baseDescription,
};
