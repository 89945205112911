import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionChildrenNumber from '@features/project/components/questions/QuestionChildrenNumber/QuestionChildrenNumber';
import checkRulesQuestionChildrenNumber from '@features/project/components/questions/QuestionChildrenNumber/QuestionChildrenNumber.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.CHILDREN,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.children.title',
  cdpEvent: CdpEventName.QUESTION_CHILDREN_NUMBER,
  Component: QuestionChildrenNumber,
  payloadKeys: [FunnelAnswersKeys.NB_CHILDREN],
  rulesFn: checkRulesQuestionChildrenNumber,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  kid: {
    ...baseDescription,
    questionTitle: 'step1.question.children.title',
    questionSubtitle: 'step1.question.children.subtitle',
  },
};
