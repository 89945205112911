import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionTaxResidency from '@features/project/components/questions/QuestionTaxResidency/QuestionTaxResidency';
import checkRulesQuestionTaxResidency from '@features/project/components/questions/QuestionTaxResidency/QuestionTaxResidency.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.FISCAL_RESIDENCY,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.tax-residency.title',
  questionSubtitle: 'step1.question.tax-residency.subtitle',
  cdpEvent: CdpEventName.QUESTION_TAX_RESIDENCE,
  Component: QuestionTaxResidency,
  payloadKeys: [FunnelAnswersKeys.TAX_RESIDENCE],
  rulesFn: checkRulesQuestionTaxResidency,
};

export default {
  classic: baseDescription,
  realEstate: baseDescription,
  legalEntity: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.tax-residency.subtitle.kid',
  },
};
