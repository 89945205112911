import { FunnelAnswersKeys, ParametersKeys } from '@shared/types';

const QUESTION_TRACKING_STATE_MAP = new Map<FunnelAnswersKeys, ParametersKeys>([
  [FunnelAnswersKeys.PROJECT_TYPE, ParametersKeys.PROJECT_TYPE],
  [FunnelAnswersKeys.INITIAL_PAYMENT, ParametersKeys.INITIAL_PAYMENT],
  [FunnelAnswersKeys.RECURRING_PAYMENT, ParametersKeys.RECURRING_PAYMENT],
  [FunnelAnswersKeys.HORIZON, ParametersKeys.HORIZON],
  [FunnelAnswersKeys.BIRTHDATE, ParametersKeys.BIRTHDATE],
  [FunnelAnswersKeys.BIRTHDATE_KID, ParametersKeys.BIRTHDATE_CHILDREN],
  [FunnelAnswersKeys.TAX_RESIDENCE, ParametersKeys.TAX_RESIDENCE],
  [FunnelAnswersKeys.RESPONSIBLE_INVESTMENT, ParametersKeys.RESPONSIBLE_INVESTMENT],
  [FunnelAnswersKeys.NB_CHILDREN, ParametersKeys.CHILDREN_NUMBER],
  [FunnelAnswersKeys.GROSS_ANNUAL_INCOME, ParametersKeys.GROSS_ANNUAL_INCOME],
  [FunnelAnswersKeys.GROSS_ANNUAL_INCOME_RANGE, ParametersKeys.GROSS_ANNUAL_INCOME_RANGE],
  [FunnelAnswersKeys.OWNER_MAIN_RESIDENCE, ParametersKeys.OWNER_MAIN_RESIDENCE],
  [FunnelAnswersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE, ParametersKeys.CREDIT_OR_RENT_MAIN_RESIDENCE],
  [FunnelAnswersKeys.NET_PROPERTY_PATRIMONY, ParametersKeys.NET_PROPERTY_ASSETS],
  [FunnelAnswersKeys.FINANCIAL_PATRIMONY, ParametersKeys.FINANCIAL_PATRIMONY],
  [FunnelAnswersKeys.SAVINGS_CAPACITY, ParametersKeys.SAVINGS_CAPACITY],
  [
    FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS,
    ParametersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS,
  ],
  [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_HALF, ParametersKeys.PROBABILITY_OF_WITHDRAWAL_HALF],
  [FunnelAnswersKeys.SAVINGS_PRODUCTS, ParametersKeys.SAVINGS_PRODUCTS],
  [FunnelAnswersKeys.EARNINGS_POTENTIAL_QUIZ, ParametersKeys.EARNINGS_POTENTIAL_QUIZ],
  [FunnelAnswersKeys.ETF_KNOWLEDGE_QUIZ, ParametersKeys.ETF_KNOWLEDGE_QUIZ],
  [FunnelAnswersKeys.COMPANY_DELEGATION_QUIZ, ParametersKeys.COMPANY_DELEGATION_QUIZ],
  [FunnelAnswersKeys.REAL_ESTATE_INVESTMENT_QUIZ, ParametersKeys.REAL_ESTATE_INVESTMENT_QUIZ],
  [FunnelAnswersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ, ParametersKeys.LOSS_OF_REAL_ESTATE_VALUE_QUIZ],
  [
    FunnelAnswersKeys.LOSS_ON_FINANCIAL_INVESTMENTS_QUIZ,
    ParametersKeys.LOSS_OF_FINANCIAL_INVESTMENTS_VALUE_QUIZ,
  ],
  [FunnelAnswersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ, ParametersKeys.AMOUNT_WIN_LOSS_RATIO_QUIZ],
  [FunnelAnswersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ, ParametersKeys.PERCENTAGE_WIN_LOSS_RATIO_QUIZ],
  [FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ, ParametersKeys.TEN_PERCENT_LOSS_QUIZ],
  [FunnelAnswersKeys.THEMATIC, ParametersKeys.RECOMMENDED_ESG],
]);

export default QUESTION_TRACKING_STATE_MAP;
