export enum Action {
  CREATE_PROJECT = 'CREER_PROJET',
}

export interface UserDetail {
  firstname?: string;
  lastname?: string;
  phoneNumber?: string;
  email: string;
  id: string;
  actions: Action[];
}
