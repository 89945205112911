import { Typography } from '@mui/material';
import { styled } from '@mui/system';
import { useIntl } from 'react-intl';

import { ReactComponent as CellphoneIcon } from '@assets/icons/cellphone.svg';
import OutlinedButton from '@components/OutlinedButton/OutlinedButton';
import useTracking from '@hooks/tracking/useTracking';
import useDimensions from '@hooks/useDimensions';
import { CdpCategory, CdpEventName } from '@shared/types';
import { GRAY_MAIN } from '@theme';

const StyledPhoneLink = styled('a')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  textDecoration: 'none',
}));

export default function ContactButton() {
  const intl = useIntl();
  const { isMobile } = useDimensions();
  const { sendEvent } = useTracking();

  const handleClick = () => {
    sendEvent({
      event: CdpEventName.CLICK_ADVISOR_BUTTON,
      properties: {
        category: CdpCategory.SIMULATION,
      },
    });
  };

  if (isMobile) {
    return (
      <OutlinedButton
        onClick={handleClick}
        titleId="footer.contactButton"
        href={intl.formatMessage({ id: 'footer.phoneNumberLink' })}
        icon={<CellphoneIcon />}
      />
    );
  }

  return (
    <div
      data-testid="contact-button"
      style={{ display: 'flex', flexDirection: 'column', textAlign: 'end' }}>
      <div>
        <Typography variant="md" color={GRAY_MAIN}>
          {intl.formatMessage({ id: 'footer.contactTitle' })}
        </Typography>
      </div>
      <StyledPhoneLink
        onClick={handleClick}
        href={intl.formatMessage({ id: 'footer.phoneNumberLink' })}>
        <CellphoneIcon />
        <Typography variant="lg" fontWeight="fontWeightBold">
          {intl.formatMessage({ id: 'footer.formattedPhoneNumber' })}
        </Typography>
      </StyledPhoneLink>
    </div>
  );
}
