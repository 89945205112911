import QuestionProductDescription from '@features/productFunnel/components/questions/QuestionFiscalPackage/QuestionFiscalPackage.description';
import QuestionRecipientDescription from '@features/productFunnel/components/questions/QuestionRecipient/QuestionRecipient.description';
import { ProductQuestion } from '@features/project/question.utils';

const QUESTIONS: Array<ProductQuestion> = [
  QuestionRecipientDescription,
  QuestionProductDescription,
];

export default QUESTIONS;
