import { AppBar, Box, Container, Toolbar } from '@mui/material';

import yomoniLogoWhite from '@assets/icons/logo/yomoni_horizontal_logo_white.svg';

export default function Navbar() {
  return (
    <>
      <AppBar sx={{ height: '60px' }}>
        <Container>
          <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
            <Box sx={{ mt: '10px', mb: 'auto' }}>
              <img src={yomoniLogoWhite} alt="Yomoni-logo" height="40px" />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar disableGutters sx={{ height: '60px', minHeight: '0px !important' }} />
    </>
  );
}
