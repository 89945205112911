export const PROJECT_TYPE_ICONS: {
  [key: string]: string;
} = {
  EPARGNE: 'yicon-piggy-bank',
  EPARGNE_SECURITE: 'yicon-umbrella',
  ACHAT_IMPORTANT: 'yicon-house',
  RETRAITE: 'yicon-retirement',
  TRANSMISSION: 'yicon-family',
  ENFANT: 'yicon-poussette',
  IMMOBILIER: 'yicon-house',
  ETUDES_ENFANTS: 'yicon-student',
  EPARGNE_PERSONNE_MORALE: 'yicon-piggy-bank',
};

export enum ProjectType {
  SIGNIFICANT_PURCHASE = 'ACHAT_IMPORTANT',
  CHILDREN = 'ENFANT',
  SAVINGS = 'EPARGNE',
  EMERGENCY_SAVINGS = 'EPARGNE_SECURITE',
  CHILDREN_STUDY = 'ETUDES_ENFANTS',
  REAL_ESTATE = 'IMMOBILIER',
  INHERITANCE = 'TRANSMISSION',
  RETIREMENT = 'RETRAITE',
  LEGAL_ENTITY = 'EPARGNE_PERSONNE_MORALE',
}
