import { useRecoilValue } from 'recoil';

import { Toggle } from '@hooks/toggle/toggle.utils';
import useToggle from '@hooks/toggle/useToggle';
import { FiscalPackage } from '@shared/types';
import { preferredEnvelopeState } from '@state';

export default function useTryNewWording() {
  const tryNewWording = useToggle(Toggle.REACT_APP_NEW_PEA_WORDING);
  const preferredEnveloppe = useRecoilValue(preferredEnvelopeState);

  return tryNewWording && preferredEnveloppe === FiscalPackage.STOCK_SAVINGS_PLAN;
}
