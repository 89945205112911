import { Typography, useTheme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { Stack } from '@mui/system';
import { useIntl } from 'react-intl';

import useDimensions from '@hooks/useDimensions';
import {
  GRAY_LIGHT,
  GRAY_MAIN,
  GRAY_MEDIUM,
  PRIMARY_MAIN,
  SUCCESS_LIGHT,
  SUCCESS_MAIN,
  WHITE,
} from '@theme';

export interface ButtonValueProps extends ButtonProps {
  titleId: string;
  toggled?: boolean;
  subtitleId?: string;
  subtitleValues?: Record<string, string | number | JSX.Element>;
}

export default function ToggleButton({
  titleId,
  toggled,
  sx,
  subtitleId,
  subtitleValues,
  ...props
}: ButtonValueProps) {
  const theme = useTheme();
  const intl = useIntl();
  const { isMobile } = useDimensions();

  return (
    <Button
      variant="outlined"
      id={titleId + (subtitleId ? `+${subtitleId}` : '')}
      key={titleId}
      sx={{
        minHeight: isMobile ? '48px' : '56px',
        backgroundColor: toggled ? SUCCESS_LIGHT : WHITE,
        borderRadius: '6px',
        border: '1px solid',
        borderColor: toggled ? SUCCESS_MAIN : GRAY_MEDIUM,
        padding: isMobile ? theme.spacing(1.5, 2.5, 1.5, 2.5) : theme.spacing(2, 3.5, 2, 3.5),
        ':hover': {
          // Disable hover effect on mobile
          '@media (hover: none)': {
            backgroundColor: `${toggled ? SUCCESS_LIGHT : WHITE} !important`,
            borderColor: `${toggled ? SUCCESS_MAIN : GRAY_MEDIUM} !important`,
          },
          backgroundColor: toggled ? SUCCESS_LIGHT : GRAY_LIGHT,
          borderColor: toggled ? SUCCESS_MAIN : PRIMARY_MAIN,
        },
        '&.Mui-disabled': {
          opacity: toggled ? 1 : 0.5,
          backgroundColor: toggled ? SUCCESS_LIGHT : GRAY_LIGHT,
          borderColor: `${toggled ? SUCCESS_MAIN : GRAY_MEDIUM} !important`,
          border: '1px solid',
        },
        '& .MuiButton-startIcon, & .MuiButton-endIcon': {
          '& svg': { stroke: toggled ? SUCCESS_MAIN : '' },
        },
        ...sx,
      }}
      {...props}>
      <Stack alignItems={{ xs: 'center', md: 'flex-start' }}>
        <Typography id={titleId} variant="lg" color={toggled ? SUCCESS_MAIN : PRIMARY_MAIN}>
          {intl.formatMessage({ id: titleId })}
        </Typography>
        {subtitleId && (
          <Typography id={subtitleId} variant="md" color={GRAY_MAIN}>
            {intl.formatMessage({ id: subtitleId }, subtitleValues)}
          </Typography>
        )}
      </Stack>
    </Button>
  );
}

ToggleButton.defaultProps = {
  toggled: false,
  subtitleId: undefined,
  subtitleValues: undefined,
};
