import { useRoutes } from 'react-router-dom';

import ROUTES from '@constants/routes.const';
import Error404 from '@features/misc/pages/404';
import funnelSelectionRoutes from '@routes/FunnelSelectionRoutes';
import productFunnelRoutes from '@routes/ProductFunnelRoutes';
import step1Routes from '@routes/Step1Routes';
import step2Routes from '@routes/Step2Routes';

function AppRoutes() {
  return useRoutes([
    {
      path: ROUTES.HOME,
      children: [
        ...funnelSelectionRoutes(),
        ...productFunnelRoutes(),
        ...step1Routes(),
        ...step2Routes(),
      ],
    },
    { path: '*', element: <Error404 /> },
  ]);
}

export default AppRoutes;
