import { Box, Card, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import ROUTES, { PROJECT_ROUTES } from '@constants/routes.const';
import ContractAlreadyOpen from '@features/emailsubmission/components/ContractAlreadyOpen';
import EmailInput from '@features/emailsubmission/components/EmailInput';
import OtpInput from '@features/emailsubmission/components/OtpInput';
import useTracking from '@hooks/tracking/useTracking';
import useTryNewWording from '@hooks/useTryNewWording';
import { CdpEventName, UserDetail } from '@shared/types';
import { CARD_BORDER, METHOD_BOX_GRAY } from '@theme';
import digestSha256 from '@utils/crypto.utils';

export enum ComponentsEnum {
  EMAIL_INPUT,
  OTP_INPUT,
  CONTRACT_ALREADY_OPENED,
}

export default function EmailSubmission() {
  const intl = useIntl();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [componentToShow, setComponentToShow] = useState(ComponentsEnum.EMAIL_INPUT);
  const queryClient = useQueryClient();
  const { sendEvent } = useTracking();
  const tryNewWording = useTryNewWording();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const goToConsentPage = () => {
    navigate(`/${ROUTES.PROJECT}/${PROJECT_ROUTES.NEWSLETTER}`);
  };

  const onRegisterSuccess = async (user: UserDetail) => {
    sendEvent({ event: CdpEventName.REGISTER_SUCCESS, userId: user.id });

    queryClient.removeQueries('resume');

    try {
      window.dataLayer.push({
        leadsUserData: {
          sha256_email_address: await digestSha256(user.email.trim().toLowerCase()),
        },
      });
    } catch (error) {
      /* empty */
    }
    goToConsentPage();
  };

  const onRegisterConflict = (emailSubmitted: string) => {
    sendEvent({ event: CdpEventName.REGISTER_EMAIL_ALREADY_USED });
    setEmail(emailSubmitted);
    setComponentToShow(ComponentsEnum.OTP_INPUT);
  };

  const onOtpSuccess = async () => {
    sendEvent({ event: CdpEventName.REGISTER_OTP_SUCCESS });
    queryClient.removeQueries('resume');
    goToConsentPage();
  };

  const onOtpConflict = () => {
    sendEvent({ event: CdpEventName.REGISTER_CONTRACT_ALREADY_OPENED });
    setComponentToShow(ComponentsEnum.CONTRACT_ALREADY_OPENED);
  };

  return (
    <Box
      sx={{
        backgroundColor: METHOD_BOX_GRAY,
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'space-evenly',
        px: { xs: 3, lg: 0 },
        py: 6,
      }}>
      <Stack direction="column" alignItems="center">
        <Typography variant="xl" fontWeight="fontWeightBold" textAlign="center">
          {intl.formatMessage(
            {
              id: tryNewWording ? 'emailSubmission.title.abtest' : 'emailSubmission.title',
            },
            { br: <br /> },
          )}
        </Typography>
        <Card
          sx={{
            mt: 4,
            mx: 0,
            mb: 0,
            px: 4,
            py: 4,
            borderRadius: '12px',
            boxShadow: {
              xs: 'none',
            },
            border: CARD_BORDER,
            width: { xs: 'auto', lg: '100%' },
            maxWidth: { xs: 'auto', lg: '410px' },
            height: 'fit-content',
          }}>
          {componentToShow === ComponentsEnum.EMAIL_INPUT && (
            <EmailInput onSuccess={onRegisterSuccess} onConflict={onRegisterConflict} />
          )}
          {componentToShow === ComponentsEnum.OTP_INPUT && (
            <OtpInput email={email} onSuccess={onOtpSuccess} onConflict={onOtpConflict} />
          )}
          {componentToShow === ComponentsEnum.CONTRACT_ALREADY_OPENED && <ContractAlreadyOpen />}
        </Card>
      </Stack>
    </Box>
  );
}
