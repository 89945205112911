import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionLossReaction from '@features/project/components/questions/QuestionLossReaction/QuestionLossReaction';
import checkRulesQuestionLossReaction from '@features/project/components/questions/QuestionLossReaction/QuestionLossReaction.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.LOSS_REACTION,
  headerTitle: 'step1.question.layout.header.risk-relation.title',
  questionTitle: 'step1.question.loss-reaction.title',
  questionSubtitle: 'step1.question.loss-reaction.subtitle',
  cdpEvent: CdpEventName.QUESTION_TEN_PERCENT_LOSS_QUIZ,
  Component: QuestionLossReaction,
  payloadKeys: [FunnelAnswersKeys.TEN_PERCENT_LOSS_QUIZ],
  rulesFn: checkRulesQuestionLossReaction,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.loss-reaction.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
