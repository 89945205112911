export enum TernaryAnswer {
  OUI = 'OUI',
  NON = 'NON',
  NE_SAIS_PAS = 'NE_SAIS_PAS',
}

export enum BinaryAnswer {
  OUI = 'OUI',
  NON = 'NON',
}

export enum AnnualIncome {
  MOINS_DE_25_000 = 'MOINS_DE_25_000',
  ENTRE_25_000_ET_50_000 = 'ENTRE_25_000_ET_50_000',
  ENTRE_50_000_ET_100_000 = 'ENTRE_50_000_ET_100_000',
  ENTRE_100_000_ET_150_000 = 'ENTRE_100_000_ET_150_000',
  PLUS_DE_150_000 = 'PLUS_DE_150_000',
  LESS_THAN_30_000 = 'LESS_THAN_30_000',
  BETWEEN_30_000_AND_45_000 = 'BETWEEN_30_000_AND_45_000',
  BETWEEN_45_000_AND_60_000 = 'BETWEEN_45_000_AND_60_000',
  BETWEEN_60_000_AND_100_000 = 'BETWEEN_60_000_AND_100_000',
  BETWEEN_100_000_AND_150_000 = 'BETWEEN_100_000_AND_150_000',
  MORE_THAN_150_000 = 'MORE_THAN_150_000',
}

export enum FinancialLosses {
  NON = 'NON',
  OUI_10_MAX = 'OUI_10_MAX',
  OUI_20_MAX = 'OUI_20_MAX',
  OUI_PLUS_DE_20 = 'OUI_PLUS_DE_20',
}

export enum RiskRewardPercentage {
  GAIN_20_PERTE_5 = 'GAIN_20_PERTE_5',
  GAIN_30_PERTE_10 = 'GAIN_30_PERTE_10',
  GAIN_50_PERTE_15 = 'GAIN_50_PERTE_15',
  GAIN_70_PERTE_15 = 'GAIN_70_PERTE_15',
}

export enum RiskReward {
  GAIN_5000_PERTE_2000 = 'GAIN_5000_PERTE_2000',
  GAIN_2000_PERTE_1000 = 'GAIN_2000_PERTE_1000',
  GAIN_1000_PERTE_400 = 'GAIN_1000_PERTE_400',
  GAIN_500_PERTE_0 = 'GAIN_500_PERTE_0',
}

export enum LegacyRiskReward {
  GAIN_100_PERTE_0 = 'GAIN_100_PERTE_0',
  GAIN_400_PERTE_100 = 'GAIN_400_PERTE_100',
  GAIN_1000_PERTE_400 = 'GAIN_1000_PERTE_400',
  GAIN_2000_PERTE_1000 = 'GAIN_2000_PERTE_1000',
}

export enum LossReaction {
  REINVESTIS = 'REINVESTIS',
  ATTENTE = 'ATTENTE',
  VENTE_PARTIELLE = 'VENTE_PARTIELLE',
  VENTE_TOTALE = 'VENTE_TOTALE',
  INCERTAIN = 'UNCERTAIN',
}

export enum MarginalTaxRate {
  TAUX_0 = 'TAUX_0',
  TAUX_11 = 'TAUX_11',
  TAUX_30 = 'TAUX_30',
  TAUX_41 = 'TAUX_41',
  TAUX_45 = 'TAUX_45',
}

export enum EsgPreference {
  PREFER_ESG = 'true',
  NO_PREFERENCE = 'false',
}

export enum ChildrenNumber {
  NONE = 'NONE',
  ONE = 'ONE',
  TWO = 'TWO',
  THREE_OR_MORE = 'THREE_OR_MORE',
}

export enum PrimaryResidence {
  OWNER = 'true',
  NOT_OWNER = 'false',
}

export enum SavingsCapacity {
  LESS_THAN_250 = 'LESS_THAN_250',
  BETWEEN_250_AND_500 = 'BETWEEN_250_AND_500',
  BETWEEN_500_AND_1000 = 'BETWEEN_500_AND_1000',
  BETWEEN_1000_AND_2500 = 'BETWEEN_1000_AND_2500',
  MORE_THAN_2500 = 'MORE_THAN_2500',
}

export function getSavingsCapacityValue(savingsCapacity?: SavingsCapacity) {
  switch (savingsCapacity) {
    case SavingsCapacity.LESS_THAN_250:
      return 125;
    case SavingsCapacity.BETWEEN_250_AND_500:
      return 250;
    case SavingsCapacity.BETWEEN_500_AND_1000:
      return 500;
    case SavingsCapacity.BETWEEN_1000_AND_2500:
      return 1000;
    case SavingsCapacity.MORE_THAN_2500:
      return 2500;
  }
  return undefined;
}
export function getSavingsCapacityRange(savingsCapacity?: number) {
  if (savingsCapacity === undefined || savingsCapacity === null) {
    return undefined;
  }
  if (savingsCapacity < 250) {
    return SavingsCapacity.LESS_THAN_250;
  }
  if (savingsCapacity < 500) {
    return SavingsCapacity.BETWEEN_250_AND_500;
  }
  if (savingsCapacity < 1000) {
    return SavingsCapacity.BETWEEN_500_AND_1000;
  }
  if (savingsCapacity < 2500) {
    return SavingsCapacity.BETWEEN_1000_AND_2500;
  }
  return SavingsCapacity.MORE_THAN_2500;
}

export enum WithdrawHalfProbability {
  PROBABLY = 'PROBABLEMENT',
  PROBABLY_NOT = 'PROBABLEMENT_PAS',
  CERTAINLY = 'CERTAINEMENT',
  CERTAINLY_NOT = 'CERTAINEMENT_PAS',
}

export enum SavingsProducts {
  OWNER = 'OUI',
  NOT_OWNER = 'NON',
}
