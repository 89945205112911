import { useContext } from 'react';

import useDimensions from '@hooks/useDimensions';
import useTryNewWording from '@hooks/useTryNewWording';
import useYomoniCdpCookie from '@hooks/useYomoniCdpCookie';
import { UserContext } from '@providers/UserProvider';
import yomoniClient from '@services/api';
import { CdpEvent, CdpType } from '@shared/types';

export type YomoniCdpCookie = {
  anonymousId?: string;
};

export default function useTracking() {
  const { user } = useContext(UserContext);
  const { isMobile } = useDimensions();
  const { yomoniCdpCookie } = useYomoniCdpCookie();
  const tryNewWording = useTryNewWording();

  const sendEvent = (event: CdpEvent): void => {
    const updatedEvent = event;

    updatedEvent.userId = event.userId ?? user?.id;
    updatedEvent.anonymousId = yomoniCdpCookie.anonymousId;
    updatedEvent.type = event.type ?? CdpType.TRACK;

    updatedEvent.properties = event.properties ?? {};
    updatedEvent.properties.isMobile = isMobile;
    updatedEvent.properties.displayAbTestPeaWording = tryNewWording;

    yomoniClient({
      withCredentials: true,
      method: 'post',
      url: '/cdp',
      data: updatedEvent,
    });
  };

  return { sendEvent, yomoniCdpCookie };
}
