import {
  HIGH_RISK_MIN_RECOMMENDED_HORIZON,
  HIGH_RISK_THRESHOLD,
  STOCK_SAVINGS_PLAN_MIN_RECOMMENDED_HORIZON,
} from '@constants/simulation-parameters-edge.const';
import { FiscalPackage } from '@shared/types';

export const checkHorizonHighRisk = (
  horizonStateValue: number,
  riskStateValue: number,
): string[] => {
  const warnings: string[] = [];

  if (
    horizonStateValue < HIGH_RISK_MIN_RECOMMENDED_HORIZON &&
    riskStateValue > HIGH_RISK_THRESHOLD
  ) {
    warnings.push('WARN_HORIZON_HIGH_RISK_PROFILE_TOO_LOW');
  }
  return warnings;
};

export const checkHorizonStocksSavingPlan = (
  horizonStateValue: number,
  riskStateValue: number,
): string[] => {
  const warnings: string[] = [];

  if (
    horizonStateValue < STOCK_SAVINGS_PLAN_MIN_RECOMMENDED_HORIZON &&
    riskStateValue > HIGH_RISK_THRESHOLD
  ) {
    warnings.push('WARN_HORIZON_STOCKS_SAVING_PLAN_TOO_LOW');
  }
  return warnings;
};

export const checkHorizon = ({
  packageStateValue,
  horizonStateValue,
  riskStateValue,
}: {
  packageStateValue: FiscalPackage;
  horizonStateValue: number;
  riskStateValue: number;
}): string[] => {
  const warningsMap: Record<
    string,
    (horizonStateValue: number, riskStateValue: number) => string[]
  > = {
    [FiscalPackage.LIFE_INSURANCE]: checkHorizonHighRisk,
    [FiscalPackage.STOCK_SAVINGS_PLAN]: checkHorizonStocksSavingPlan,
    [FiscalPackage.TRADING_ACCOUNT]: checkHorizonHighRisk,
  };

  return warningsMap[packageStateValue]
    ? warningsMap[packageStateValue](horizonStateValue, riskStateValue)
    : [];
};

export const checkWarning = ({
  packageStateValue,
  horizonStateValue,
  riskStateValue,
}: {
  packageStateValue: FiscalPackage;
  horizonStateValue: number;
  riskStateValue: number;
}): string[] => {
  const warnings = [];

  warnings.push(checkHorizon({ packageStateValue, horizonStateValue, riskStateValue }));

  return warnings.flat();
};
