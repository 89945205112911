import Link from '@mui/material/Link';
import { PropsWithChildren } from 'react';
import { LinkProps, Link as RouterLink, To } from 'react-router-dom';

export interface LinkWithRouterProps extends PropsWithChildren<LinkProps> {
  onClick?: () => void;
  to: To;
}

function LinkWithRouter({ children, to, onClick, ...rest }: LinkWithRouterProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Link component={RouterLink} to={to} onClick={onClick} {...rest}>
      {children}
    </Link>
  );
}

LinkWithRouter.defaultProps = {
  onClick: () => {},
};

export default LinkWithRouter;
