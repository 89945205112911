import { useContext, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import { YOMONI_BUSINESS_REFERRAL_COOKIE_NAME } from '@constants/app.const';
import { ProjectType } from '@constants/project-type.const';
import { QUERY_PARAMS } from '@constants/routes.const';
import useResumeFunnelAnswers from '@features/project/hooks/useResumeFunnelAnswers';
import { canSubscribeToRealEstate } from '@features/project/question.utils';
import { UserContext } from '@providers/UserProvider';
import { BusinessReferral, Thematic } from '@shared/types';
import {
  businessReferralState,
  emailState,
  externalTokenState,
  preferredEnvelopeState,
  preferredTransferState,
  sponsorCodeState,
  thematicQuestionState,
} from '@state';
import { extractPreferredEnvelope, extractPreferredTransfer } from '@utils/url.utils';

export default function useProjectParams() {
  const { user } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const resume = useResumeFunnelAnswers();
  const [cookies] = useCookies([YOMONI_BUSINESS_REFERRAL_COOKIE_NAME]);

  const setThematicQuestionState = useSetRecoilState(thematicQuestionState);
  const setBusinessReferralState = useSetRecoilState(businessReferralState);
  const setEmailState = useSetRecoilState(emailState);
  const setSponsorCodeState = useSetRecoilState(sponsorCodeState);
  const setExternalTokenState = useSetRecoilState(externalTokenState);
  const setPreferredEnvelopeState = useSetRecoilState(preferredEnvelopeState);
  const setPreferredTransferState = useSetRecoilState(preferredTransferState);

  const isBypassImmoProspect = hasProspectParam(searchParams);
  const thematicParam = extractThematic(searchParams);
  const businessReferralParam = extractBusinessReferral(cookies, searchParams);
  const preSelectedProjectTypeParam = extractPreSelectedProjectType(searchParams);
  const emailParam = extractEmail(searchParams);
  const sponsorCodeParam = extractSponsorCode(searchParams);
  const tokenParam = extractToken(searchParams);
  const preferredEnvelopeParam = extractPreferredEnvelope(searchParams);
  const preferredTransferParam = extractPreferredTransfer(searchParams);
  const isProfessionalParam =
    searchParams.get(QUERY_PARAMS.IS_PROFESSIONAL.key) === QUERY_PARAMS.IS_PROFESSIONAL.values.TRUE;

  const businessReferralFromResume = resume?.data?.projectParameters?.businessReferral;

  useEffect(() => {
    if (
      thematicParam === Thematic.REAL_ESTATE &&
      canSubscribeToRealEstate(!!user, isBypassImmoProspect)
    ) {
      setThematicQuestionState(Thematic.REAL_ESTATE);
    }
  }, [thematicParam]);

  useEffect(() => {
    if (businessReferralParam && !businessReferralFromResume) {
      setBusinessReferralState(businessReferralParam);
    }
  }, [businessReferralParam]);

  useEffect(() => {
    if (emailParam !== null) {
      setEmailState(emailParam);
    }
  }, [emailParam]);

  useEffect(() => {
    if (sponsorCodeParam !== null) {
      setSponsorCodeState(sponsorCodeParam);
    }
  }, [sponsorCodeParam]);

  useEffect(() => {
    if (tokenParam !== null) {
      setExternalTokenState(tokenParam);
    }
  }, [tokenParam]);

  useEffect(() => {
    if (preferredEnvelopeParam) {
      setPreferredEnvelopeState(preferredEnvelopeParam);
    }
  }, [preferredEnvelopeParam]);

  useEffect(() => {
    if (preferredEnvelopeParam) {
      setPreferredTransferState(preferredTransferParam);
    }
  }, [preferredEnvelopeParam, preferredTransferParam]);

  return {
    isProfessionalParam,
    isBypassImmoProspect,
    thematicParam,
    queryParamsStr: buildParamsString(searchParams),
    partnerParam: businessReferralParam,
    preSelectedProjectTypeParam,
  };
}

function buildParamsString(searchParams: URLSearchParams) {
  let queryParamsStr = '';
  if (searchParams && searchParams.size > 0) {
    queryParamsStr = `?${searchParams?.toString()}`;
  }
  return queryParamsStr;
}

function hasProspectParam(searchParams: URLSearchParams): boolean {
  return searchParams.get(QUERY_PARAMS.ACCES.key) === QUERY_PARAMS.ACCES.values.PROSPECT;
}

function extractThematic(searchParams: URLSearchParams): Thematic | undefined {
  return searchParams.get(QUERY_PARAMS.THEMATIQUE.key) === QUERY_PARAMS.THEMATIQUE.values.IMMOBILIER
    ? Thematic.REAL_ESTATE
    : undefined;
}

function extractEmail(searchParams: URLSearchParams): string | null {
  return searchParams.get(QUERY_PARAMS.EMAIL.key);
}

function extractSponsorCode(searchParams: URLSearchParams): string | null {
  return searchParams.get(QUERY_PARAMS.SPONSOR.key);
}

function extractToken(searchParams: URLSearchParams): string | null {
  return searchParams.get(QUERY_PARAMS.TOKEN.key);
}

function extractBusinessReferral(
  cookies: any,
  searchParams: URLSearchParams,
): BusinessReferral | undefined {
  const businessReferralParamStr =
    extractBusinessReferralFromCookie(cookies) ||
    (searchParams.get(QUERY_PARAMS.BUSINESS_REFERRAL.key) as BusinessReferral);

  let businessReferralParam;
  if (businessReferralParamStr != null) {
    switch (businessReferralParamStr) {
      case QUERY_PARAMS.BUSINESS_REFERRAL.values.BANKIN:
        businessReferralParam = BusinessReferral.BANKIN;
        break;
      case QUERY_PARAMS.BUSINESS_REFERRAL.values.DECATHLON:
        businessReferralParam = BusinessReferral.DECATHLON;
        break;
      default:
        businessReferralParam = businessReferralParamStr;
        break;
    }
  }

  return businessReferralParam;
}

function extractBusinessReferralFromCookie(cookies: any): BusinessReferral | undefined {
  const inputPartnerCode = cookies[YOMONI_BUSINESS_REFERRAL_COOKIE_NAME];
  const regex = /^\w+$/i;

  if (inputPartnerCode && regex.test(inputPartnerCode)) {
    return inputPartnerCode.toUpperCase();
  }

  return undefined;
}

function extractPreSelectedProjectType(searchParams: URLSearchParams): ProjectType | undefined {
  let preSelectedProjectTypeParam;
  const preSelectedProjectTypeParamStr = searchParams.get(QUERY_PARAMS.PROJECT_TYPE.key);

  switch (preSelectedProjectTypeParamStr) {
    case QUERY_PARAMS.PROJECT_TYPE.values.SAVINGS:
      preSelectedProjectTypeParam = ProjectType.SAVINGS;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.CHILDREN:
      preSelectedProjectTypeParam = ProjectType.CHILDREN;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.EMERGENCY_SAVINGS:
      preSelectedProjectTypeParam = ProjectType.EMERGENCY_SAVINGS;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.INHERITANCE:
      preSelectedProjectTypeParam = ProjectType.INHERITANCE;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.RETIREMENT:
      preSelectedProjectTypeParam = ProjectType.RETIREMENT;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.SIGNIFICANT_PURCHASE:
      preSelectedProjectTypeParam = ProjectType.SIGNIFICANT_PURCHASE;
      break;
    case QUERY_PARAMS.PROJECT_TYPE.values.LEGAL_ENTITY:
      preSelectedProjectTypeParam = ProjectType.LEGAL_ENTITY;
      break;
    default:
      break;
  }
  return preSelectedProjectTypeParam;
}
