import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import QuestionLayout from '@components/QuestionLayout/QuestionLayout';
import ROUTES from '@constants/routes.const';
import useProductfunnelQuestionsContext, {
  ProductFunnelQuestionsContext,
} from '@features/productFunnel/components/ProductFunnelQuestions.context';
import QUESTIONS from '@features/productFunnel/questionList';
import useQuestion from '@hooks/useQuestion';
import useTransition from '@hooks/useTransition';

export default function ProductFunnelQuestions() {
  const params = useParams<{ questionPath: string }>();
  const navigate = useNavigate();
  const { isTransitioning, transition } = useTransition();
  const context = useProductfunnelQuestionsContext();

  const { question, previousQuestion } = useQuestion(QUESTIONS, params.questionPath);
  const Component = question?.Component;

  const changePage = (callback: () => void) => {
    transition(() => {
      context.reset();
      callback();
    });
  };

  const handleBack = useCallback(() => {
    changePage(() => {
      if (context.specificPreviousRoute) {
        // we can go back from question 3 to question 1
        navigate(context.specificPreviousRoute);
      } else if (previousQuestion) {
        navigate(`/${ROUTES.PRODUCT_FUNNEL}/${previousQuestion.path}`);
      } else {
        navigate(`/${ROUTES.FUNNEL_SELECTION}`);
      }
    });
  }, [previousQuestion, context.specificPreviousRoute]);

  return (
    <ProductFunnelQuestionsContext.Provider value={context}>
      <QuestionLayout
        headerTitle={question.headerTitle}
        title={context.questionTitleId ?? question.questionTitle}
        subtitle={question.questionSubtitle}
        fade={!isTransitioning}
        handleBack={handleBack}
        isBackDisabled={isTransitioning}
        handleNext={() => {}} // to display the button
        isNextDisabled // but always disabled because we don't save the answer
        padding={4}>
        <Component handleNext={changePage} />
      </QuestionLayout>
    </ProductFunnelQuestionsContext.Provider>
  );
}
