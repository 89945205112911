import { PROJECT_ROUTES } from '@constants/routes.const';
import QuestionWithdrawalTwoYears from '@features/project/components/questions/QuestionWithdrawalTwoYears/QuestionWithdrawalTwoYears';
import checkRulesQuestionWithdrawalTwoYears from '@features/project/components/questions/QuestionWithdrawalTwoYears/QuestionWithdrawalTwoYears.rules';
import { Question } from '@features/project/question.utils';
import { CdpEventName, FunnelAnswersKeys } from '@shared/types';

const baseDescription: Question = {
  path: PROJECT_ROUTES.WITHDRAWAL_BEFORE_2_YEARS,
  headerTitle: 'step1.question.layout.header.title',
  questionTitle: 'step1.question.withdrawal-2-years.title',
  cdpEvent: CdpEventName.QUESTION_PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS,
  Component: QuestionWithdrawalTwoYears,
  payloadKeys: [FunnelAnswersKeys.PROBABILITY_OF_WITHDRAWAL_WITHIN_TWO_YEARS],
  rulesFn: checkRulesQuestionWithdrawalTwoYears,
};

export default {
  classic: baseDescription,
  kid: {
    ...baseDescription,
    questionSubtitle: 'step1.question.withdrawal-2-years.subtitle.kid',
  },
  realEstate: baseDescription,
  legalEntity: baseDescription,
};
