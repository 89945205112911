import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import YomoniSnack from '@components/YomoniSnack/YomoniSnack';
import useResume from '@hooks/useResume';
import {
  investmentSelector,
  isSimulationErrorState,
  profileSelector,
  projectSelector,
} from '@state';
import { checkError } from '@utils/error.utils';
import { checkWarning } from '@utils/warning.utils';

function WarningSnack(key: string, message: string) {
  return <YomoniSnack id={key} message={message} isWarning />;
}

export default function useHandleSimulationAndRecommendationErrors() {
  const [allSimulationErrors, setAllSimulationErrors] = useState<string[]>([]);
  const [simulationBlockingErrors, setSimulationBlockingErrors] = useState<string[]>([]);

  const { resume } = useResume();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const investmentStateValues = useRecoilValue(investmentSelector);
  const setIsSimulationError = useSetRecoilState(isSimulationErrorState);

  const { fiscalPackage: packageStateValue, riskProfile: riskStateValue } =
    useRecoilValue(profileSelector);
  const { isTransfer } = useRecoilValue(projectSelector);

  const queueMessage = (
    messages: string[],
    content: (id: string, message: string) => JSX.Element,
  ): string[] => {
    return messages.map((message) => {
      enqueueSnackbar(message, { preventDuplicate: true, content, key: message });
      return message;
    });
  };

  useEffect(() => {
    if (
      investmentStateValues.startingCapitalStateValue === 0 &&
      investmentStateValues.monthlyInvestmentStateValue === 0 &&
      investmentStateValues.investmentHorizonStateValue === 0
    ) {
      return;
    }

    const currentErrors = checkError({
      initialInvestmentStateValue: investmentStateValues.startingCapitalStateValue,
      packageStateValue,
      monthlyInvestmentStateValue: investmentStateValues.monthlyInvestmentStateValue,
      projectType: resume?.step0.projectType,
      riskStateValue,
      hasActiveLifeInsurance: resume?.hasActiveLifeInsurance,
      horizonStateValue: investmentStateValues.investmentHorizonStateValue,
      mayWithdrawBefore2Years: resume?.step1.mayWithdrawBefore2Years,
      recommendedRisk: resume?.step1Response.riskLevel,
      businessReferral: resume?.step0.businessReferral,
      isTransfer,
    });

    setSimulationBlockingErrors(currentErrors);
    setIsSimulationError(currentErrors.length > 0);

    const currentWarnings = checkWarning({
      horizonStateValue: investmentStateValues.investmentHorizonStateValue,
      packageStateValue,
      riskStateValue,
    });

    allSimulationErrors.forEach((message) => {
      if (!currentWarnings.includes(message)) {
        closeSnackbar(message);
      }
    });

    if (!currentErrors || currentErrors.length === 0) {
      const currentNotificationIds = queueMessage(currentWarnings, WarningSnack);
      setAllSimulationErrors(currentNotificationIds);
    }
  }, [investmentStateValues, packageStateValue, riskStateValue, isTransfer]);

  return { allSimulationErrors, simulationBlockingErrors };
}
