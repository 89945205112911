import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import CenteredColumnContainer from '@components/CenteredColumnContainer/CenteredColumnContainer';
import Loading from '@components/Loading/Loading';
import ROUTES, { PROJECT_ROUTES } from '@constants/routes.const';
import useProjectParams from '@features/project/hooks/useProjectParams';
import useQuestionLists from '@features/project/hooks/useQuestionLists';
import useDimensions from '@hooks/useDimensions';
import { UserContext } from '@providers/UserProvider';
import { Action, Thematic, UserDetail } from '@shared/types';
import { projectTypeQuestionState } from '@state';
import { METHOD_BOX_GRAY } from '@theme';

function shouldDisplayIntro(user?: UserDetail, thematicParamFromUrl?: Thematic) {
  return (
    user?.actions.includes(Action.CREATE_PROJECT) || thematicParamFromUrl === Thematic.REAL_ESTATE
  );
}

export default function SubscriptionProject() {
  const { isMobile } = useDimensions();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ questionPath: string }>();
  const { user, isLoading: isLoadingUser } = useContext(UserContext);
  const { thematicParam, preSelectedProjectTypeParam } = useProjectParams();
  const [, setProjectTypeStateValue] = useRecoilState(projectTypeQuestionState);

  useEffect(() => {
    if (preSelectedProjectTypeParam) {
      setProjectTypeStateValue(preSelectedProjectTypeParam);
    }
  }, []);

  const { questions } = useQuestionLists();
  const displayIntro = shouldDisplayIntro(user, thematicParam);

  useEffect(() => {
    if (!isLoadingUser) {
      if (displayIntro) {
        navigate(`/${ROUTES.PROJECT}/${PROJECT_ROUTES.INTRO}${location.search}`);
      } else if (params.questionPath === undefined) {
        navigate(`${questions.toDisplay.at(0)!.path}${location.search}`, { replace: true });
      }
    }
  }, [displayIntro, isLoadingUser]);

  return (
    <CenteredColumnContainer
      px={isMobile ? 0 : undefined}
      py={isMobile ? 0 : 4}
      alignItems="stretch"
      backgroundColor={METHOD_BOX_GRAY}
      maxWidth={isMobile ? '100%' : undefined}
      disableGutters={isMobile}>
      <Loading />
    </CenteredColumnContainer>
  );
}
